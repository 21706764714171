import { useEffect, useState } from 'react';
import {
  DKLabel,
  DKButton,
  showAlert,
  showLoader,
  removeLoader,
  DKIcons
} from 'deskera-ui-library';
import IFormField, { IForm, IRefSubField } from '../../model/Form';
import FieldHolder from './FieldsHolder';
import Utility, {
  isNotEmptyObject,
  swapArrayElement
} from '../../utility/Utility';
import FormSettings from './FormSettings';
import FieldSettings from './FieldSettings';
import AppIcons from '../../assets/icons/AppIcons';
import '../../styles/form-builder.scss';
import {
  convertTableToForm,
  mapColumnToFormField,
  mapFieldToColumn,
  mapSubFieldToColumn,
  validateForm
} from '../../utility/FormBuilder';
import FormList from './FormList';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  addForms,
  fetchAllForms,
  selectFormByAppId
} from '../../redux/slices/formBuilderSlice';
import { addColumns, fetchTablesByAppId } from '../../services/table';
import { saveForm, updateForm } from '../../services/form';
import { FORM_BUILDER_ACTION, OBJECT_TYPE } from '../../constants/Enum';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import { fetchTables } from '../../redux/slices/tableSlice';

interface IFormBuilderProps {
  appId: string;
  tableId: string;
  onClose: () => void;
  onSave: () => void;
}
/**
 * @description- This function calls async API to add column and build payload based on response
 * @param form -IForm Object
 * @returns - payload containing existing and new fields with column id received in response
 */
const addColumnUpdatePayload = async (form) => {
  try {
    let columns = [];
    let newFields = form.fields.filter((field) => !!field.isNew);

    if (!Utility.isEmptyObject(newFields)) {
      newFields = newFields.map(mapFieldToColumn).map((column, index) => ({
        ...column,
        index: index + ((form as any)?.table?.columnsMetaData?.length ?? 0) + 1
      }));
      showLoader('Adding columns to table...');
      const columnResponse: any = await addColumns(form.tableId, newFields);
      columns = columnResponse?.columns ?? [];
    }
    const payload: IForm = {
      ...form,
      fields: [
        ...form.fields.filter((field) => !field.isNew),
        ...columns.map(mapColumnToFormField)
      ]
    };
    return Promise.resolve(payload);
  } catch (error) {
    return Promise.reject(error);
  }
};

const addSubFieldsAsColumn = async (form: IForm) => {
  try {
    const subFieldsToAdd: IRefSubField[] = [];
    form.fields.reduce((acc, field: IFormField) => {
      if (
        field.type === CUSTOM_INPUT_TYPE.TABLE &&
        isNotEmptyObject(field?.refTable?.subFields)
      ) {
        field?.refTable?.subFields?.forEach((subField) => {
          if (Utility.isEmptyObject(subField?.refTableColumnId)) {
            acc.push({ ...subField, refTableId: field?.refTable?._id } as any);
          }
        });
      }
      return acc;
    }, subFieldsToAdd);
    if (isNotEmptyObject(subFieldsToAdd)) {
      showLoader('Adding sub field to table...');
      const columnResponse: any = await addColumns(
        form.tableId,
        subFieldsToAdd.map(mapSubFieldToColumn)
      );
      let columns = columnResponse?.columns ?? [];
      form.fields.forEach((field: IFormField) => {
        if (field.type === CUSTOM_INPUT_TYPE.TABLE) {
          field?.refTable?.subFields?.forEach((subField) => {
            if (Utility.isEmptyObject(subField.refTableColumnId)) {
              const subFieldColumn = columns.find(
                (col) => col.name == subField.displayName
              );
              if (!Utility.isEmptyObject(subFieldColumn)) {
                subField['refTableColumnId'] = subFieldColumn.id;
              }
            }
          });
        }
      });
    }
    return Promise.resolve(form);
  } catch (error) {
    return Promise.reject(error);
  }
};

const FormBuilder: React.FC<IFormBuilderProps> = ({
  onClose,
  appId,
  tableId
}) => {
  const dispatch = useAppDispatch();
  const forms = useAppSelector<IForm[]>(selectFormByAppId(appId));

  const [form, setForm] = useState<IForm>(null);

  const [selectedField, setSelectedField] = useState<IFormField | null>(null);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await fetchTablesByAppId({ appId });
        const { data: tables = [] } = response;
        const { data: formsData = [] } = await dispatch(
          fetchAllForms({ appId })
        ).unwrap();
        var formsToSave = [];

        tables?.forEach((table) => {
          const formFound = formsData.find(
            (item) => item.tableId === table._id
          );
          if (Utility.isEmptyObject(formFound)) {
            formsToSave.push({ ...convertTableToForm(table), table });
          } else {
            formsToSave.push({ ...formFound, table });
          }
        });
        // Remove Automation from forms
        formsToSave = formsToSave.filter(
          (element: any) => element.table.objectType !== OBJECT_TYPE.AUTOMATION
        );
        dispatch(addForms({ appId, forms: formsToSave }));

        return tables;
      } catch (error) {
        console.log(error);
      }
    };
    fetchTables();
  }, [appId, dispatch]);

  const handleFieldChange = (field: IFormField) => {
    const copyOfFields = [...form.fields];
    const index = copyOfFields.findIndex((item) => item._id === field._id);
    copyOfFields[index] = field;
    setSelectedField(copyOfFields[index]);
    setForm((prev) => ({ ...prev, fields: copyOfFields }));
  };

  const handleNewField = (column) => {
    const copyOfFields = [...form.fields];
    copyOfFields.push({ ...mapColumnToFormField(column), isNew: true });
    setForm((prev) => ({ ...prev, fields: copyOfFields }));
  };

  const handleFormChange = (key, data) => {
    if (key === 'title') {
      setForm((pre) => ({ ...pre, [key]: data }));
    }
  };

  const handleDeleteField = (field) => {
    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m'
      },
      {
        title: 'Delete',
        className: 'bg-red text-white ml-r',
        onClick: () => deleteField(field)
      }
    ];
    showAlert(
      'Delete Record?',
      'Deleting this record will delete it permanently you will not be able to restore it.',
      buttons
    );
  };

  const deleteField = (fieldToDelete: IFormField) => {
    const indexOfField = form.fields.findIndex(
      (field) => field._id === fieldToDelete._id
    );
    if (indexOfField !== -1) {
      setForm((prev) => {
        const previousFields = [...prev.fields];
        previousFields.splice(indexOfField, 1);
        return { ...prev, fields: previousFields };
      });
      setSelectedField(null);
    }
  };

  const handleFormEdit = (formToEdit) => {
    setSelectedField(null);
    setForm(formToEdit);
  };

  const handleFormDelete = (formToDelete: IForm) => {};

  const handleFormAction = (type, form) => {
    switch (type) {
      case FORM_BUILDER_ACTION.EDIT:
        handleFormEdit(form);
        break;
      case FORM_BUILDER_ACTION.DELETE:
        handleFormDelete(form);
        break;
      default:
        break;
    }
  };

  const handleOnDragChange = (oldIndex: number, newIndex: number) => {
    const fields = [...(form.fields ?? [])];
    let newSeqFields = swapArrayElement([...fields], oldIndex, newIndex);
    setForm((pre) => ({ ...pre, fields: newSeqFields }));
  };

  const onSave = async () => {
    try {
      if (validateForm(form)) {
        showLoader('Saving forms...');

        let payload = await addColumnUpdatePayload(form);
        payload = await addSubFieldsAsColumn(payload);

        if (form._id) showLoader('Updating forms with new fields...');

        delete (payload as any).table;
        await (form._id ? updateForm(payload) : saveForm(payload));
        onClose();
        showAlert('Success', 'Form saved successfully.');
        dispatch(fetchTables({ appId }));
      } else {
        showAlert('Error', 'Please fill all required details');
      }
    } catch (error) {
      showAlert('Error', 'Unable to save form, please try again');
    } finally {
      removeLoader();
    }
  };

  const renderHeader = () => {
    return (
      <div
        id="fb-header-wrap"
        className="row bg-app pr-m p-v-s justify-content-between"
      >
        <DKButton
          title="Form editor"
          className="text-white"
          icon={DKIcons.white.ic_arrow_left}
          onClick={onClose}
        />
        <div className="row width-auto">
          <DKButton
            title="Close"
            className="bg-app text-white"
            onClick={onClose}
          />
          <DKButton
            title="Save changes"
            icon={AppIcons.ic_save}
            className="bg-white ml-r"
            style={{
              borderColor: 'rgba(255, 255, 255, 0.5)'
              // backgroundColor: 'rgba(0, 0, 0, 0.2)'
            }}
            onClick={onSave}
          />
        </div>
      </div>
    );
  };

  const renderFormList = () => {
    return (
      <div
        id="fb-form-list-wrap"
        className="column parent-height flex-1 align-items-center overflow-auto hide-scroll-bar p-l bg-white border-m"
        style={{ minWidth: '20%', maxWidth: '20%' }}
      >
        <FormList onFormAction={handleFormAction} forms={forms} />
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div
        id="fb-holder-wrap"
        className="column parent-height flex-1 align-items-center p-l"
        style={{ width: '50%', maxWidth: '50%' }}
      >
        <div className="bg-white shadow-s border-radius-m border-s parent-size overflow-auto hide-scroll-bar p-l">
          <FieldHolder
            formTitle={`${form.title}`}
            fields={form.fields}
            selectedField={selectedField}
            onFieldClick={(field) => {
              setSelectedField(field);
            }}
            onFieldChange={handleOnDragChange}
          />
        </div>
      </div>
    );
  };

  const renderSettings = () => {
    return (
      <div
        className="column parent-height bg-white border-m"
        style={{ width: '20%', maxWidth: '20%' }}
      >
        {showFormSettings ? (
          <FormSettings
            appId={appId}
            fields={form.fields}
            onAddNewField={handleNewField}
            onChange={handleFormChange}
            title={form.title}
            onFieldClicked={(action, field) => {
              if (action === 'edit') {
                setSelectedField(field);
              } else {
                handleDeleteField(field);
              }
            }}
            linkedTable={(form as any).table}
            onDragChange={handleOnDragChange}
          />
        ) : (
          <FieldSettings
            linkedTable={(form as any).table}
            field={selectedField}
            onChange={handleFieldChange}
            onClose={() => setSelectedField(null)}
            onDelete={handleDeleteField}
          />
        )}
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-height align-items-center justify-content-center parent-width">
        <DKLabel text="No form selected" className="fw-m text-align-center" />
        <DKLabel
          text="Select form from left panel and start editing"
          className="mt-s text-gray  text-align-center"
        />
      </div>
    );
  };

  const showFormSettings = Utility.isEmptyObject(selectedField);
  const isFormSelected = !Utility.isEmptyObject(form);
  return (
    <div
      className="popup-window bg-gray1 column"
      style={{
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        padding: 0,
        overflow: 'hidden',
        borderRadius: 0
      }}
    >
      {renderHeader()}
      <div className="row parent-size bg-gray1 justify-content-between">
        {renderFormList()}
        {isFormSelected && (
          <>
            {renderForm()}
            {renderSettings()}
          </>
        )}
        {!isFormSelected && renderEmptyState()}
      </div>
    </div>
  );
};

export default FormBuilder;
