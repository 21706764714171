import React from 'react';
import { ReactComponent as ERPAIIcon } from '../../assets/icons/ERP-AI-Logo.svg';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '../../managers/RouteManager';
import { useAuth, UserButton } from '@clerk/clerk-react';
import house from '../../assets/lucide/house.svg';
import appIcon from '../../assets/lucide/app-icon.svg';
import marketplaceIcon from '../../assets/icons/ic_marketplace.png';

const LeftBar = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  const onItemClick = ({ action, route }) => {
    switch (action) {
      case 'home':
        navigate(route);
        break;
      case 'app':
        navigate(route);
        break;
      case 'templates':
        navigate(route);
        break;
      case 'setting':
        window.open(process.env.REACT_APP_ERP_AI + 'settings', '_self');
        break;
      case 'send':
        window.open(process.env.REACT_APP_ERP_AI + 'chat', '_self');
        break;
      default:
        window.open(process.env.REACT_APP_ERP_AI + 'dashboard', '_self');
        break;
    }
  };

  const getButton = ({ icon, action, route }) => {
    const isActive = window.location.pathname === route;
    return (
      <div
        className={
          'transition-colors cursor-hand p-2 border-radius-s-2 text-xs ' +
          (isActive ? 'bg-stone-200/70 opacity-100' : 'opacity-50')
        }
        onClick={() => onItemClick({ action, route })}
      >
        <img src={icon} width={24} alt={route} style={{ height: 22 }} />
      </div>
    );
  };

  return (
    <aside className="relative left-0 top-0 z-[2] my-auto hidden h-screen flex-col bg-transparent lg:flex">
      <nav className="grid gap-2 p-2 px-0 pt-0 lg:gap-4 lg:p-4 align-items-between">
        <button className="whitespace-nowrap rounded-md text-sm font-medium transition-colors  size-12 mb-6 flex h-fit w-[90px] items-center justify-center md:flex lg:mb-1 lg:w-10">
          <div
            className="size-full items-center justify-center text-red-600 dark:text-core-ochre"
            onClick={() =>
              onItemClick({ action: 'home', route: PAGE_ROUTES.HOME })
            }
          >
            <ERPAIIcon style={{ color: 'rgb(210, 14, 30)', width: 40 }} />
          </div>
        </button>
        {getButton({ icon: house, action: 'home', route: PAGE_ROUTES.HOME })}
        {isSignedIn &&
          getButton({
            icon: appIcon,
            action: 'app',
            route: PAGE_ROUTES.APP_DETAILS
          })}
        {isSignedIn &&
          getButton({
            icon: marketplaceIcon,
            action: 'templates',
            route: PAGE_ROUTES.MARKETPLACE
          })}
      </nav>
      <nav className="mt-auto grid gap-2 p-4 px-0 pt-0 lg:gap-4 lg:px-4 lg:pt-4">
        {/* {getButton({ icon: sendIcon, action: 'send' })}
        {getButton({ icon: settingsIcon, action: 'setting' })} */}
        {isSignedIn && (
          <>
            {/* <div
              className="opacity-2 bg-gray m-auto"
              style={{ height: 1, width: '90%' }}
            /> */}
            <div className="flex m-auto">
              <UserButton
                appearance={{
                  baseTheme: undefined,
                  elements: {
                    avatarBox: '',
                    userButtonPopoverCard: { pointerEvents: 'initial' }
                  }
                }}
              />
            </div>
          </>
        )}
      </nav>
    </aside>
  );
};

export default LeftBar;
