import React, { Component } from 'react';
import {
  DKLabel,
  DKButton,
  DKIcons,
  INPUT_TYPE,
  showAlert,
  DKIcon,
  DKListPicker,
  DKListPicker2,
  INPUT_VIEW_DIRECTION,
  DKInput
} from 'deskera-ui-library';
import {
  ALLOWED_COLUMN_TYPES,
  AUTOMATION_ACTIONS,
  AUTOMATION_ELEMENT,
  AUTOMATION_TRIGGERS,
  CONDITIONAL_FIELD_MAPPER_TYPE,
  DROPDOWN_DEFAULT_OPTIONS,
  OPERATOR_LIST
} from '../../constants/Automation';
import { isEqual } from 'lodash';
import Utility, {
  getCapitalized,
  isNotEmptyObject
} from '../../utility/Utility';

import { DateUtil, DATE_FORMATS } from '../../utility/Date';
import { IColumn, IColumnOption } from '../..//model/Table';
import { FILTER_OPERATORS } from '../../constants/Enum';
import { fetchAllRecords, isSelectField } from '../../services/table';
import AppIcons from '../../assets/icons/AppIcons';
export interface IFieldMapperProps {
  onClose: () => void;
  onSave: (field: any, logicalOperator: any, optional: any) => void;
  stepData: any;
  tables;
}
export interface IFieldMapperState {
  fields: any[];
  selectedTable: any;
  logicalOperator: any;
  showColumns: boolean;
  showOptions: boolean;
}

const getFieldData = (
  key: string,
  value: any,
  opr = OPERATOR_LIST.EQUAL.token
) => ({
  key,
  value,
  opr
});

export default class FieldMapper extends Component<
  IFieldMapperProps,
  IFieldMapperState
> {
  columns: any = {};
  data = this.props.stepData?.configuration
    ? this.props.stepData.configuration.fields
    : null;

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      logicalOperator:
        this.props.stepData && this.props.stepData.logicalOperator
          ? this.props.stepData.logicalOperator
          : 'and',
      showColumns: false,
      selectedTable: null,
      showOptions: false
    };
  }
  componentDidMount() {
    if (isNotEmptyObject(this.props.stepData?.configuration?.tableId)) {
      const selectedTable = this.props.tables?.find(
        (table) => table._id === this.props.stepData?.configuration?.tableId
      );
      this.setState({
        selectedTable: selectedTable
      });
      this.modifyColumns(Utility.makeCopyOfObject(selectedTable));
    }
    this.getData();
  }
  isThisTrigger() {
    return this.props.stepData.elementType === AUTOMATION_ELEMENT.TRIGGER;
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{ padding: 0, maxWidth: 510, overflow: 'visible' }}
        >
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderBottom()}
        </div>
      </div>
    );
  }
  renderHeader() {
    return (
      <div className="row justify-content-between p-h-m p-v-s data-grid-bottom-border">
        <DKLabel text={this.props.stepData.title} className="fw-m" />
        <DKButton
          icon={AppIcons.ic_close}
          onClick={() => this.props.onClose()}
        />
      </div>
    );
  }
  renderTableDropDown() {
    return (
      <div className="row mt-l">
        <DKInput
          value={this.state.selectedTable?.name ?? null}
          formatter={(obj) => {
            return obj;
          }}
          title="Select record type"
          valueStyle={{ width: 200 }}
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={true}
          onChange={(value) => {}}
          dropdownConfig={{
            style: {
              position: 'absolute'
            },
            allowSearch: true,
            searchableKey: 'name',
            searchApiConfig: null,
            data: this.props.tables ?? [],
            renderer: (index, obj) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index, value) => {
              this.setState({ selectedTable: value, fields: [] });
              this.modifyColumns(Utility.makeCopyOfObject(value));
            }
          }}
        />
      </div>
    );
  }
  renderBody() {
    const title =
      this.props.stepData.elementType === AUTOMATION_ELEMENT.ACTION
        ? `Map the fields when... <i>${this.props.stepData.title}</i>`
        : 'Map the conditions to trigger this automation...';

    let showFields =
      isNotEmptyObject(this.props.tables) &&
      isNotEmptyObject(this.state.selectedTable);

    return (
      <div className="column p-v-l p-h-xl parent-width">
        <DKLabel text={`${title} `} className="" />
        {this.renderTableDropDown()}

        {isNotEmptyObject(this.state.selectedTable) && (
          <div
            className={`parent-width bg-gray1 border-radius-s border-m p-m border-box mt-l show-scroll-bar ${
              this.state.fields?.length > 7 ? 'overflow-y-auto pr-s' : ''
            }`}
            style={{ maxHeight: 450, scrollbarWidth: 'thin' }}
          >
            <DKLabel
              text={
                this.isThisTrigger()
                  ? 'Conditions to match'
                  : 'Assign field values'
              }
              className="fw-m mb-l"
            />
            {showFields &&
              this.state.fields.map((field, index) => {
                return (
                  <FieldRow
                    elementType={this.props.stepData.elementType}
                    field={field}
                    columns={this.columns}
                    totalFields={this.state.fields.length}
                    onChange={(newFieldData, oldFieldKey) => {
                      this.onChange(newFieldData, oldFieldKey, index);
                    }}
                    operator={field.opr}
                    type={this.props.stepData.type}
                    onConditionChange={(opr) =>
                      this.onConditionChange(opr, index)
                    }
                    allowDelete={true}
                    restrictedFields={[]}
                    onFieldRemove={() => this.onFieldRemove(field.key, index)}
                    tables={this.props.tables}
                  />
                );
              })}
            {(CONDITIONAL_FIELD_MAPPER_TYPE.includes(
              this.props.stepData.type
            ) ||
              Object.keys(this.columns).length !==
                this.state.fields.length) && (
              <DKButton
                title={
                  this.props.stepData.elementType === AUTOMATION_ELEMENT.ACTION
                    ? '+ Add Row'
                    : 'Add a condition'
                }
                className="mt-m text-underline "
                style={{ paddingTop: 3, paddingBottom: 3 }}
                onClick={() => this.addNewRandomField()}
              />
            )}
          </div>
        )}
        {this.isThisTrigger() &&
          this.state.fields.length > 1 &&
          this.getLogicalOperatorButtons()}
      </div>
    );
  }
  renderBottom() {
    return (
      <div className="row p-h-xl pb-l" style={{ bottom: 0 }}>
        <DKButton
          title={this.isThisTrigger() ? 'Add this trigger' : 'Add this action'}
          icon={AppIcons.white.ic_send}
          isReverse
          className="bg-button text-white "
          onClick={() => this.save()}
        />
      </div>
    );
  }
  getLogicalOperatorButtons() {
    return (
      <div
        className="bg-white align-self-center border-m shadow-s-2 border-radius-s row width-auto mt-s"
        style={{ overflow: 'hidden' }}
      >
        <div
          className="cursor-hand"
          onClick={() => {
            this.setState({ logicalOperator: 'and' });
          }}
        >
          <DKLabel
            text="And"
            className={
              'p-v-xs p-h-s  ' +
              (this.state.logicalOperator === 'and'
                ? 'bg-gray1 fw-m'
                : 'text-gray')
            }
          />
        </div>
        <div
          className="cursor-hand"
          onClick={() => {
            this.setState({ logicalOperator: 'or' });
          }}
        >
          <DKLabel
            text="Or"
            className={
              'p-v-xs p-h-s  ' +
              (this.state.logicalOperator === 'or'
                ? 'bg-gray1 fw-m'
                : 'text-gray')
            }
          />
        </div>
      </div>
    );
  }
  save() {
    let fieldsData = [];
    let allowToSave = true;
    if (Utility.isEmptyObject(this.state.selectedTable)) {
      showAlert(
        'Missing record type',
        'Please select record type to create/update record'
      );
      return;
    }
    this.state.fields.forEach((field) => {
      let fieldKey = field.key;
      let fieldValue = field.value;
      let fieldIsDynamicValue = false;
      if (
        this.columns[fieldKey]?.options?.length ||
        this.columns[fieldKey].type === 'ref'
      ) {
        if (fieldValue === null) {
          // fail validation if no value selected
          allowToSave = false;
        }
        fieldValue = [field.value];
      } else if (
        [
          INPUT_TYPE.TEXT,
          INPUT_TYPE.EMAIL,
          INPUT_TYPE.LONG_TEXT,
          INPUT_TYPE.PHONE,
          INPUT_TYPE.NUMBER
        ].includes(this.columns[fieldKey].type)
      ) {
        if (fieldValue?.length === 0) {
          allowToSave = false;
        }
      }

      fieldsData.push({
        [fieldKey]: fieldValue,
        opr: field.opr || OPERATOR_LIST.EQUAL.token,
        isDynamicValue: fieldIsDynamicValue
      });
    });

    if (
      Utility.isEmptyObject(fieldsData) &&
      [
        AUTOMATION_TRIGGERS.VALUE_CHANGED,
        AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED,
        AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED,
        AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED
      ].includes(this.props.stepData.type)
    ) {
      showAlert(
        "Can't save trigger?",
        'Please add at least one condition for triggering this workflow.'
      );
      return;
    }
    if (
      !allowToSave &&
      ![
        AUTOMATION_TRIGGERS.VALUE_CHANGED,
        AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED,
        AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED,
        AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED
      ].includes(this.props.stepData.type)
    ) {
      showAlert('Empty Value', 'Please enter field value');
      return;
    } else {
      this.props.onSave(
        fieldsData,
        this.state.logicalOperator,
        this.state.selectedTable
      );
    }
  }
  modifyColumns = (selectedTable) => {
    const { name: tableName = '', columnsMetaData = [] } = selectedTable ?? {};
    columnsMetaData.sort((col1, col2) => col1.index - col2.index);
    this.columns = columnsMetaData.reduce((acc, column) => {
      if (ALLOWED_COLUMN_TYPES.includes(column.type)) {
        column.name =
          getCapitalized(tableName) +
          '.' +
          getCapitalized(column.name.toLowerCase());
        column.tableName = tableName;
        column.key = column.id;
        acc[column.id] = column;
      }
      return acc;
    }, {});
  };
  getData() {
    if (!this.data) {
      this.addNewRandomField();
    } else {
      let fields = [];
      let fieldHasDynamicValue = false;

      this.data.forEach((data) => {
        let key = Object.keys(data)?.find(
          (dataKey) => dataKey !== 'opr' && dataKey !== 'isDynamicValue'
        );
        let value = data[key];
        if (Utility.isEmptyObject(this.columns[key])) return;

        fieldHasDynamicValue = data.isDynamicValue;

        const columnHasOptions =
          !Utility.isEmptyObject(this.columns[key]?.options) ||
          this.columns[key].type === 'ref';

        let fieldValue =
          columnHasOptions && !fieldHasDynamicValue ? value?.[0] : value;

        let parsedData = getFieldData(
          key,
          fieldValue,
          data.opr || OPERATOR_LIST.EQUAL.token
        );

        if (fieldHasDynamicValue) {
          parsedData['isDynamicValue'] = fieldHasDynamicValue;
        }

        fields.push(parsedData);
      });
      this.setState({ fields });
    }
  }

  addField(column) {
    let fields = [...this.state.fields];

    let fieldToAdd = getFieldData(column.id, isSelectField(column) ? null : '');

    fields.push(fieldToAdd);

    this.setState({
      fields
    });
  }
  addNewRandomField() {
    let allAddedFields = this.state.fields.map((field) => field.key);
    for (let key of Object.keys(this.columns)) {
      if (CONDITIONAL_FIELD_MAPPER_TYPE.includes(this.props.stepData.type)) {
        /* for conditional mappers, allowing multiple condition on same column */
        this.addField(this.columns[key]);
        return;
      }

      if (!allAddedFields.includes(key)) {
        this.addField(this.columns[key]);
        return;
      }
    }
  }
  onChange = (newField, oldFieldKey: any, indexToUpdate: number) => {
    let fields = [...this.state.fields];
    const fieldToUpdate = fields[indexToUpdate];
    if (oldFieldKey) {
      if (CONDITIONAL_FIELD_MAPPER_TYPE.includes(this.props.stepData.type)) {
        fieldToUpdate.key = newField.key;
        fieldToUpdate.value = newField.value;
        fieldToUpdate.opr = newField.opr;
      } else {
        fields = this.reorderFields(
          fields,
          oldFieldKey,
          newField.key,
          newField.value
        );
      }
    } else {
      fieldToUpdate.value = newField.value;
    }

    this.setState({ fields });
  };
  onConditionChange = (opr, index) => {
    const fields = [...this.state.fields];
    const fieldToUpdate = fields[index];
    fieldToUpdate.opr = opr;
    this.setState({ fields });
  };

  onFieldRemove = (fieldKey: string, index: number) => {
    let fields = [...this.state.fields];
    if (
      fields.length === 1 &&
      this.props.stepData.elementType === AUTOMATION_ELEMENT.ACTION
    ) {
      showAlert("Can't delete", 'You should at-least have one mapping field.');
      return;
    } else {
      fields.splice(index, 1);

      this.setState({ fields });
    }
  };
  reorderFields(fields, oldKey, newKey, newValue) {
    let newFields = [];
    fields.forEach((field) => {
      if (field.key === oldKey) {
        newFields.push(getFieldData(newKey, newValue));
      } else if (field.key !== newKey) {
        newFields.push(field);
      }
    });
    return newFields;
  }
}

type IField = { key: string; value: any; opr: FILTER_OPERATORS };
export interface IFieldRowProps {
  elementType: AUTOMATION_ELEMENT;
  field: IField;
  columns: { [key: string]: IColumn };
  totalFields: number;
  onChange: (newFieldData: IField, oldFieldKey: string) => void;
  operator?: FILTER_OPERATORS;
  type: AUTOMATION_ACTIONS | AUTOMATION_TRIGGERS;
  onConditionChange?: (opr: FILTER_OPERATORS) => void;
  allowDelete: boolean;
  readOnly?: boolean;
  restrictedFields?: string[];
  onFieldRemove: () => void;
  tables: any;
}
export interface IFieldRowState {
  showColumns: boolean;
  showOptions: boolean;
  needConditionPicker: boolean;
  operator: FILTER_OPERATORS;
  refColOptions: any[];
}
export class FieldRow extends Component<IFieldRowProps, IFieldRowState> {
  constructor(props) {
    super(props);
    this.state = {
      showColumns: false,
      showOptions: false,
      needConditionPicker: false,
      operator: this.props.operator,
      refColOptions: []
    };
  }
  componentDidMount() {
    this.checkAndUpdateDateField();

    if (this.props.columns?.[this.props.field.key]?.type === 'ref') {
      this.fetchRefColumnOptions(
        this.props.columns[this.props.field.key]?.refTable?._id
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props, prevProps)) {
      this.setState({ operator: this.props.operator });

      this.checkAndUpdateDateField();
    }
  }
  hideUnHideColumns = (flag = null) => {
    if (flag === null) flag = !this.state.showColumns;
    this.setState({ showColumns: flag });
  };
  hideUnHideOptions = (flag = null) => {
    if (flag === null) flag = !this.state.showOptions;
    this.setState({ showOptions: flag });
  };
  checkAndUpdateDateField() {
    /* auto selecting today's date for date type field if value is not set */
    const isDateField = ['Date', INPUT_TYPE.DATE].includes(
      this.props.columns[this.props.field.key]?.type
    );
    if (
      isDateField &&
      Utility.isEmptyObject(this.props.field.value?.toString())
    ) {
      this.onDateChange(new Date());
    }
  }
  onDateChange(value: Date) {
    let newDate = DateUtil.getDateStrFromDate(
      value,
      DATE_FORMATS['DD-MM-YYYY']
    );
    this.props.onChange({ ...this.props.field, value: newDate }, null);
  }

  getSelectValueForLabel = (field, users) => {
    let displayValue = this.getOptionsWithDefaultOptions().find(
      (option) => option.id === field.value
    )?.name;
    return displayValue;
  };

  render() {
    const allowFieldSelector =
      !this.props.restrictedFields?.includes(this.props.field.key) &&
      this.props.allowDelete &&
      this.props.type !== AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY;
    const userList = [];
    return (
      <div
        className="row  mt-r bg-white border-radius-m border-m justify-content-evenly p-h-s"
        style={{ height: 40 }}
      >
        <div
          className={`row ${
            allowFieldSelector ? 'cursor-hand' : ''
          } position-relative justify-content-between`}
          style={{ width: 170 }}
        >
          <div
            className="parent-width parent-height position-absolute "
            style={{ top: 0, left: 0 }}
            onClick={() => {
              if (!allowFieldSelector) return;

              this.hideUnHideColumns();
            }}
          />
          <DKLabel
            text={this.props.columns[this.props.field.key]?.name}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          />
          {allowFieldSelector && (
            <DKIcon src={AppIcons.ic_arrow_down} className="ic-s" />
          )}
          {this.state.showColumns && (
            <DKListPicker
              className="position-absolute shadow-m border-m z-index-1 "
              style={{ width: 220 }}
              data={Object.keys(this.props.columns).map(
                (column) => this.props.columns[column].name
              )}
              onClose={() => {
                this.hideUnHideColumns(false);
              }}
              onSelect={(index, value) => {
                this.onColumnChange(index);
                this.hideUnHideColumns();
              }}
            />
          )}
        </div>
        {this.getConditionSelection()}
        {['Date', INPUT_TYPE.DATE].includes(
          this.props.columns[this.props.field.key]?.type
        ) ? (
          <div
            className=" row  cursor-hand position-relative justify-content-between"
            style={{ width: 170 }}
          >
            <DKInput
              valueStyle={{ border: 'none', backgroundColor: 'white' }}
              value={DateUtil.getDateFromStr(
                this.props.field.value !== ''
                  ? this.props.field.value
                  : DateUtil.formatDate(new Date(), DATE_FORMATS['DD-MM-YYYY']),
                DATE_FORMATS['DD-MM-YYYY']
              )}
              type={INPUT_TYPE.DATE}
              onChange={(value: any) => this.onDateChange(value)}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
            />
          </div>
        ) : ![
            INPUT_TYPE.SELECT,
            'Select',
            INPUT_TYPE.MULTI_SELECT,
            'ref'
          ].includes(this.props.columns[this.props.field.key]?.type) ? (
          <div
            className="cursor-hand position-relative justify-content-between"
            style={{ overflow: 'hidden', width: 170 }}
          >
            <DKInput
              valueStyle={{
                border: 'none',
                backgroundColor: 'white'
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              autoFocus={false}
              value={this.props.field.value}
              placeholder={'Enter value'}
              readOnly={this.props.readOnly}
              onChange={(e) => this.onOptionChange(-1, e)}
            />
          </div>
        ) : (
          <div
            className="row cursor-hand position-relative justify-content-between"
            style={{ width: 170 }}
          >
            <div
              className=" parent-width parent-height position-absolute"
              style={{ top: 0, left: 0 }}
              onClick={() => this.hideUnHideOptions()}
            />
            <DKLabel
              text={this.getSelectValueForLabel(this.props.field, userList)}
              style={{
                // maxWidth: 130,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
              className="pl-s"
            />
            <DKIcon src={AppIcons.ic_arrow_down} className="ic-s pr-s" />
            {this.state.showOptions && (
              <div className="position-absolute shadow-m border-m z-index-1 parent-width">
                <DKListPicker2
                  className=""
                  height={200}
                  data={this.getOptionsWithDefaultOptions()}
                  onClose={() => {
                    this.hideUnHideOptions(false);
                  }}
                  allowSearch={true}
                  searchableKey="name"
                  displayKey="name"
                  onSelect={(index, value) => {
                    this.onOptionChange(index);
                    this.hideUnHideOptions();
                  }}
                />
              </div>
            )}
          </div>
        )}
        {this.props.totalFields > 0 &&
          this.props.type !==
            AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY && (
            <DKButton
              icon={AppIcons.ic_close}
              className="opacity-6"
              style={{
                paddingRight: 0,
                visibility: this.props.allowDelete ? 'visible' : 'hidden'
              }}
              onClick={() => this.props.onFieldRemove()}
            />
          )}
      </div>
    );
  }
  onOptionChange(index, value = '') {
    let newOption =
      index !== -1 ? this.getOptionsWithDefaultOptions()[index].id : value;
    this.props.onChange(
      getFieldData(this.props.field.key, newOption, this.props.field.opr),
      null
    );
  }
  onColumnChange(index) {
    let newColumn = Object.keys(this.props.columns)[index];
    const column = this.props.columns[newColumn];
    if (newColumn === this.props.field.key) return;

    if (column?.type === 'ref') {
      this.fetchRefColumnOptions(column.refTable?._id);
    } else {
      this.setState({ refColOptions: [] });
    }

    this.props.onChange(
      getFieldData(newColumn, isSelectField(column) ? null : ''),
      this.props.field.key
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  getOperatorList() {
    const columnType = this.props.columns[this.props.field.key].type;
    const { EQUAL, CONTAINS, GREATER, LESSER, GREATER_EQUAL, NOT_EQUAL } =
      OPERATOR_LIST;
    switch (columnType) {
      case INPUT_TYPE.NUMBER:
        return [EQUAL, NOT_EQUAL, GREATER, LESSER].map(
          (operator) => operator.displayName
        );
      case INPUT_TYPE.DATE:
        return [EQUAL, NOT_EQUAL, GREATER_EQUAL].map(
          (operator) => operator.displayName
        );
      default:
        return [EQUAL, NOT_EQUAL, CONTAINS].map(
          (operator) => operator.displayName
        );
    }
  }

  getOperatorSymbol(token) {
    const filteredOperatorKey = Object.keys(OPERATOR_LIST).find(
      (operatorKey) => OPERATOR_LIST[operatorKey].token === token
    );
    const filteredOperator = OPERATOR_LIST[filteredOperatorKey];
    return filteredOperator?.symbol || OPERATOR_LIST.EQUAL.symbol;
  }

  getOperatorToken(operatorText) {
    const filteredOperatorKey = Object.keys(OPERATOR_LIST).find(
      (operatorKey) => OPERATOR_LIST[operatorKey].displayName === operatorText
    );
    const filteredOperator = OPERATOR_LIST[filteredOperatorKey];
    return filteredOperator?.token || OPERATOR_LIST.EQUAL.token;
  }

  getConditionSelection() {
    const allowConditionSelection =
      (this.props.elementType === AUTOMATION_ELEMENT.TRIGGER &&
        this.props.type !== AUTOMATION_TRIGGERS.DATE_ALERT) ||
      this.props.type === AUTOMATION_ACTIONS.SPLIT_WORKFLOW ||
      this.props.type === AUTOMATION_ACTIONS.WAIT_TILL ||
      this.props.type === AUTOMATION_ACTIONS.CRM_APPROVAL;

    return (
      <div
        className={
          ' row width-auto parent-height ' +
          (allowConditionSelection ? 'cursor-hand  ' : '  ') +
          ' position-relative '
        }
      >
        <div
          className="m-h-s parent-height bg-gray1"
          style={{ width: 2 }}
        ></div>
        <div
          onClick={() =>
            allowConditionSelection
              ? this.setVisibilityOfConditionPicker(true)
              : null
          }
        >
          <DKLabel
            text={
              allowConditionSelection
                ? this.getOperatorSymbol(
                    this.state.operator || OPERATOR_LIST.EQUAL.token
                  )
                : 'to'
            }
          />
        </div>
        <div
          className="m-h-s parent-height bg-gray1"
          style={{ width: 2 }}
        ></div>
        {this.state.needConditionPicker && (
          <DKListPicker
            className="position-absolute border-m shadow-s-2 border-radius-s z-index-2"
            style={{ top: 10, left: 10 }}
            data={this.getOperatorList()}
            onSelect={(index, displayName) => {
              this.setVisibilityOfConditionPicker(false);
              let opr = this.getOperatorToken(displayName);
              this.props.onConditionChange(opr);
            }}
            width={120}
            onClose={() => this.setVisibilityOfConditionPicker(false)}
          />
        )}
      </div>
    );
  }
  setVisibilityOfConditionPicker = (visible) => {
    this.setState({ needConditionPicker: visible });
  };

  // add default options [Any, None] for Select/MultiSelect/Ref table column type
  getOptionsWithDefaultOptions = () => {
    let options: IColumnOption[] = [];

    if (this.props.elementType === AUTOMATION_ELEMENT.TRIGGER) {
      options.push(...DROPDOWN_DEFAULT_OPTIONS);
    }

    if (this.props.columns?.[this.props.field.key]?.options?.length) {
      options.push(...this.props.columns[this.props.field.key].options);
    } else if (this.state.refColOptions?.length) {
      options.push(...this.state.refColOptions);
    }

    return options;
  };

  fetchRefColumnOptions = async (refTableId: string) => {
    if (refTableId) {
      this.setState({ refColOptions: [{ name: 'Loading ...' }] });
      try {
        // fetch table data
        const table: any = this.props.tables.find(
          (table: any) => table._id === refTableId
        );
        if (!table) {
          throw new Error('Failed to fetch table data');
        }

        // fetch all records
        const response = await fetchAllRecords(refTableId);
        if (response?.length) {
          // parse records
          const parsedData = response.map((obj) => {
            let name: any = '';

            if (table.nameField) {
              name = obj.cells[table.nameField] || '';
            } else if (obj && typeof obj === 'object' && obj.cells) {
              const [[firstKey, firstValue]] = Object.entries(obj.cells);
              name = firstValue;
            }

            return { name, id: obj._id };
          });
          this.setState({ refColOptions: parsedData });
        }
      } catch (error) {
        console.error('Failed to feth options');
        this.setState({ refColOptions: [] });
      }
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
}
