import React, { useEffect, useState } from 'react';
import {
  DKListPicker,
  DKButton,
  DKIcon,
  DKLabel,
  showLoader,
  removeLoader,
  showAlert
} from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';
import { ReactComponent as Logo } from '../../assets/icons/ERP-AI-Logo.svg';
import { useAppSelector } from '../../redux/hooks';
import {
  selectOrganizationInfo,
  selectUsersList
} from '../../redux/slices/authSlice';
import { LIST_MODULE } from '../../constants/Enum';
import Utility from '../../utility/Utility';
import ApplicationService from '../../services/applications';

const ApplicationCard = ({
  listModule,
  app,
  onCardClick,
  handleRename,
  handleDelete,
  handleCopy,
  refreshData
}) => {
  const orgInfo = useAppSelector(selectOrganizationInfo());
  const [showOptions, setShowOptions] = useState(false);
  const userData = useAppSelector(selectUsersList());
  const [userName, setUserName] = useState('');
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (app && userData) {
      getCreatedByUserName();
    }
  }, [app, userData]);

  const openOptions = (e) => {
    e.stopPropagation();
    setShowOptions(true);
  };

  const closeOptions = () => {
    setShowOptions(false);
  };

  const confirmLandingPageByTemplateId = (landingPageData, templateId) => {
    if (
      landingPageData &&
      landingPageData.data &&
      landingPageData.data.landingPage
    ) {
      showLoader('Confirming your landing page...');
      ApplicationService.confirmLandingPage(
        landingPageData.data.landingPage,
        templateId
      )
        .then((response) => {
          console.log(response, 'Landing page confirmed');
          removeLoader();
          showAlert('Success', 'Landing page generated successfully.');
          refreshData && refreshData();
        })
        .catch((error) => {
          removeLoader();
        });
    }
  };

  const callGenerateLandingPage = () => {
    showLoader('Generating landing page...');
    ApplicationService.generateLandingPage(app._id)
      .then((response) => {
        removeLoader();
        confirmLandingPageByTemplateId(response, app._id);
        console.log(response, 'Landing page genrrated');
      })
      .catch((error) => {
        removeLoader();
      });
  };

  const callUpdatePublishStatus = (status) => {
    showLoader();
    ApplicationService.updatePublishedStatus(app._id, status)
      .then((response) => {
        removeLoader();
        refreshData && refreshData();
        console.log(response, 'App published');
      })
      .catch((error) => {
        removeLoader();
      });
  };

  const generateLandingPage = () => {
    callGenerateLandingPage();
  };

  const publishApp = () => {
    callUpdatePublishStatus(true);
  };

  const unpublishApp = () => {
    callUpdatePublishStatus(false);
  };

  const onSelectOption = (option) => {
    closeOptions();
    if (option === 'Edit') {
      handleRename(app);
    } else if (option === 'Delete') {
      handleDelete(app);
    } else if (option === 'Copy') {
      handleCopy(app);
    } else if (option === 'Generate Landing Page') {
      generateLandingPage(app);
    } else if (option === 'Publish App') {
      publishApp(app);
    } else if (option === 'Unpublish App') {
      unpublishApp(app);
    }
  };

  const getOptionsList = () => {
    let optionsList = [];
    let iconsList = [];
    switch (listModule) {
      case LIST_MODULE.APPLICATIONS:
        optionsList = ['Edit', 'Delete'];
        iconsList = [AppIcons.ic_edit, AppIcons.ic_delete];
        break;
      case LIST_MODULE.MARKETPLACE:
        optionsList.push('Edit');
        iconsList.push(AppIcons.ic_edit);
        if (!app.published) {
          if (Utility.isEmptyObject(app?.landingPage)) {
            optionsList.push('Generate Landing Page');
            iconsList.push(AppIcons.ic_automation);
          } else {
            optionsList.push('Publish App');
            iconsList.push(AppIcons.ic_deployment);
          }
        } else {
          optionsList.push('Unpublish App');
          iconsList.push(AppIcons.ic_deployment);
        }
        optionsList.push('Delete');
        iconsList.push(AppIcons.ic_delete);

        break;
      default:
        break;
    }
    return [optionsList, iconsList];
  };

  const renderListPicker = () => {
    let optionsList = getOptionsList()?.[0] ?? [];
    let iconsList = getOptionsList()?.[1] ?? [];

    return (
      <div
        className={'z-index-8'}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {showOptions && listModule === LIST_MODULE.APPLICATIONS && (
          <DKListPicker
            data={optionsList}
            onSelect={(index, value) => onSelectOption(value)}
            onClose={closeOptions}
            className="border-m"
            style={{
              position: 'absolute',
              right: '20px',
              top: '26px',
              zIndex: '4'
            }}
            icons={iconsList}
          />
        )}
        {showOptions && listModule === LIST_MODULE.MARKETPLACE && (
          <DKListPicker
            data={optionsList}
            onSelect={(index, value) => onSelectOption(value)}
            onClose={closeOptions}
            className="border-m"
            style={{
              position: 'absolute',
              right: '20px',
              top: '26px',
              zIndex: '4'
            }}
            icons={iconsList}
          />
        )}
      </div>
    );
  };

  const shortenURL = (hexString) => {
    // Check if the string is long enough to shorten
    if (hexString.length <= 4) {
      return hexString; // Return as is if it's too short
    }

    // Extract the first two and last two characters
    const start = hexString.slice(0, 2);
    const end = hexString.slice(-2);

    // Return the shortened format
    return `${start}....${end}`;
  };

  const getCreatedByUserName = () => {
    let user = userData?.[app.createdBy];
    let userName = `${user?.firstName} ${user?.lastName}`;
    setUserName(user?.firstName || user?.lastName ? userName : 'Unknown'); //setting Unknowm if user first name and last name both are not available.
  };

  const getActionButton = () => {
    switch (listModule) {
      case LIST_MODULE.APPLICATIONS:
        return (
          <DKButton
            disabled={!app.internalAppUrl}
            title={app.internalAppUrl ? 'Open' : 'App not published'}
            icon={app.internalAppUrl ? AppIcons.ic_open : null}
            className={
              app.internalAppUrl
                ? ' border-m bg-gray1 mt-l'
                : 'bg-chip-blue mt-l cursor-none'
            }
            style={{
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 8,
              paddingRight: 8
            }}
            onClick={() => {
              if (app.internalAppUrl) {
                window.open(app.internalAppUrl);
              }
            }}
          />
        );
      case LIST_MODULE.MARKETPLACE:
        return app?.companyName ? (
          <DKLabel
            disabled={!app.internalAppUrl}
            text={`Created by - ${app?.companyName}`}
            className={'bg-chip-blue mt-l border-radius-m'}
            style={{
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 8,
              paddingRight: 8
            }}
          />
        ) : (
          <></>
        );
      default:
        return <></>;
    }
  };

  const getPreviewButtonTitle = () => {
    switch (listModule) {
      case LIST_MODULE.APPLICATIONS:
        return 'Preview';

      case LIST_MODULE.MARKETPLACE:
        return process.env.REACT_APP_GEN_LP ? 'Preview' : 'Install';
      default:
        return '';
    }
  };

  const showInstallPreviewButton = () => {
    if (listModule === LIST_MODULE.APPLICATIONS) {
      return true;
    } else if (listModule === LIST_MODULE.MARKETPLACE) {
      if (app.published) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const getLogoImage = () => {
    if (app?.logoData) {
      return `data:image/png;base64,${app.logoData}`;
    }
    return AppIcons.ic_app; // Default icon if no logoData
  };

  const isEditingAllowed = () => {
    if (listModule === LIST_MODULE.APPLICATIONS) {
      return true;
    } else if (
      listModule === LIST_MODULE.MARKETPLACE &&
      app?.tenantId === orgInfo?.org_id
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className=" position-relative bg-gray1 border-m border-radius-m"
      style={{ overflow: 'hidden' }}
    >
      <div
        className="column p-xxl border-radius-m bg-white  shadow-s-2 "
        style={{ height: '80%' }}
      >
        <div className="row justify-content-between align-items-start">
          {app.logoData ? (
            <img
              onClick={() => {}}
              src={getLogoImage()}
              alt=""
              className={`product-image border text-blue border-radius-m cursor-pointer flex items-center justify-center`}
              style={{
                height: 50
                // width: 50
              }}
            />
          ) : (
            <Logo style={{ color: 'rgb(50,50,50)', width: 40 }} />
          )}
          {isEditingAllowed() && (
            <DKIcon
              src={AppIcons.ic_more}
              className="cursor-hand p-0"
              onClick={openOptions}
            />
          )}
        </div>
        <DKLabel text={app.name} className="fw-m  fs-xl mt-xl" />
        {listModule === LIST_MODULE.APPLICATIONS && (
          <DKLabel text={`Created by ${userName}`} className="text-gray mt-s" />
        )}

        {getActionButton()}
      </div>

      <div
        className="row justify-content-between p-v-m p-h-xl data-grid-top-border"
        style={{ height: '20%' }}
      >
        <DKLabel
          text={app.updatedAt ? `Updated ${formatDate(app.updatedAt)}` : ''}
          className=" text-gray"
        />
        {showInstallPreviewButton() && (
          <DKButton
            title={getPreviewButtonTitle()}
            className="bg-buttons text-whites "
            isReverse
            icon={
              AppIcons.ic_arrow_right_long
              // listModule === LIST_MODULE.MARKETPLACE
              //   ? AppIcons.ic_install
              //   : AppIcons.ic_arrow_right_long
            }
            style={{
              paddingLeft: 10,
              paddingTop: 3,
              paddingBottom: 3,
              paddingRight: 4
            }}
            onClick={() => onCardClick(app._id)}
          />
        )}
      </div>
      {renderListPicker()}
    </div>
  );
};

export default ApplicationCard;
