// useWebSocket.js
import { useEffect, useRef } from 'react';
import httpClient from '../../http';

const useWebSocket = (url: string, onMessageReceived: (data: any) => void) => {
  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    // Initialize WebSocket
    ws.current = new WebSocket(url);
    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
      authenticate();
    };

    ws.current.onmessage = (event: any) => {
      if (onMessageReceived) {
        onMessageReceived(event.data); // Call the callback with the received message
      }
    };

    ws.current.onclose = () => console.log('WebSocket connection closed');

    // Clean up on component unmount
    return () => {
      ws.current.close();
    };
  }, [url, onMessageReceived]);

  // Function to send a message
  const sendMessage = (message: string) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(message);
    }
  };

  const authenticate = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      console.log('Authenticating socket connection...');
      const token =
        httpClient.defaults.headers.common['Authorization'].toString();
      if (token) {
        const authMessage = {
          eventType: 'new-connection',
          message: token.replace('Bearer ', '')
        };
        ws.current.send(JSON.stringify(authMessage));
      }
    }
  };

  return { sendMessage, authenticate };
};

export default useWebSocket;
