import { INPUT_TYPE } from 'deskera-ui-library';
import { PAGE_ROUTES } from '../managers/RouteManager';
import { COUNTRIES_WITH_CURRENCIES } from './Currencies';

export const importOptions = (tableName: string) =>
  JSON.stringify([
    {
      title: 'Import Options',
      options: [
        {
          value: 'ADD',
          displayValue: 'Add New Records',
          checked: false
        },
        {
          value: 'UPDATE',
          displayValue: 'Update Existing Records',
          checked: false
        },
        {
          value: 'ADD_AND_UPDATE',
          displayValue: 'Add New, And Update Existing Records',
          checked: true
        }
      ],
      selectedOption: 'ADD_AND_UPDATE',
      payloadKey: 'importOption',
      applicableFor: []
    },
    {
      title: 'In case of Duplicate Records',
      options: [
        {
          value: 'REJECT',
          displayValue: 'Reject the duplicate',
          checked: true
        },
        {
          value: 'IMPORT',
          displayValue: 'Import the duplicate rows',
          checked: false
        }
      ],
      selectedOption: 'IMPORT',
      payloadKey: 'duplicateRecordOption',
      applicableFor: []
    },
    {
      title: 'In case of Invalid Values',
      options: [
        {
          value: 'REJECT_ROW',
          displayValue: 'Reject the Entire Row',
          checked: false
        },
        {
          value: 'REJECT_VALUE',
          displayValue: 'Reject the Invalid Value, But Import the Row',
          checked: false
        },
        {
          value: 'ADD_VALUE',
          displayValue: 'Add the Value in the Dropdown',
          checked: true
        }
      ],
      selectedOption: 'ADD_VALUE',
      payloadKey: 'invalidValueOption',
      applicableFor: []
    }
  ]);
export const PERSISTED_IMPORT_SOURCE_KEY = `importSourceApp`;

export const IMPORT_SOURCE_TYPE = {
  importFromLocal: 'CRM_IMPORT_FILE_FROM_COMPUTER',
  importOffice365: 'CRM_OFFICE_365',
  importGoogle: 'CRM_GOOGLE',
  importFromDeskeraBooks: 'DESKERA_BOOKS'
};
export const supportedFileTypes = {
  CSV: 'csv',
  XLSX: 'xlsx'
};

export const IMPORT_SOURCE_APP_CONFIG = {
  // [IMPORT_SOURCE_TYPE.importOffice365]: {
  //   appName: 'OFFICE_365',
  //   appId: process.env.REACT_APP_OFFICE_365_APP_ID,
  //   redirectUrl: window.location.origin + PAGE_ROUTES.IMPORT_PORTAL,
  //   scopes:
  //     'user.read+calendars.ReadWrite+contacts.ReadWrite+offline_access+openid+profile',
  //   tenantId: 'common',
  //   terminal: 'WEB'
  // },
  // [IMPORT_SOURCE_TYPE.importGoogle]: {
  //   appName: 'GOOGLE',
  //   appId: process.env.REACT_APP_GOOGLE_CONTACTS_APP_ID,
  //   redirectUrl: window.location.origin + PAGE_ROUTES.IMPORT_PORTAL,
  //   scopes:
  //     'email%20https://www.googleapis.com/auth/contacts.readonly%20https://www.googleapis.com/auth/contacts.other.readonly',
  //   terminal: 'WEB'
  // },
  // [IMPORT_SOURCE_TYPE.importFromDeskeraBooks]: {
  //   appName: 'DESKERA_BOOKS',
  //   redirectUrl: window.location.origin + PAGE_ROUTES.IMPORT_PORTAL,
  //   tenantId: 'common',
  //   terminal: 'WEB'
  // }
};

export const IMPORT_MODULE_TYPE = {
  [IMPORT_SOURCE_TYPE.importFromDeskeraBooks]: 'CRM_CONTACT',
  [IMPORT_SOURCE_TYPE.importOffice365]: 'CRM_CONTACT'
};

export const IMPORT_FILE_MODULE = {};
export const defaultRequiredColumnsToImport = {};
export const excludedColumnCodes = [];
export const excludedExternalSourceColumns = [
  'Source',
  'Source Id',
  'SourceId'
];
export const IMPORT_DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const MODULES_WITH_NESTED_IMPORT = [];
export const PRICE_BOOK_NAME_HEADER = 'Price List';
export const excludedColumnsFromValidations = [];

export const supportedMimeTypes = {
  csv: 'text/csv;charset=utf-8;',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};
export const bufferTypes = {
  csv: 'string',
  xlsx: 'array'
};
export const DETAIL_ADDRESS_CUSTOM_FIELDS = [
  {
    name: 'Address name',
    code: 'contactName',
    type: INPUT_TYPE.TEXT
  },
  {
    name: 'Street',
    code: 'address1',
    type: INPUT_TYPE.TEXT
  },
  {
    name: 'Address line 2',
    code: 'address2',
    type: INPUT_TYPE.TEXT
  },
  {
    name: 'City',
    code: 'city',
    type: INPUT_TYPE.TEXT
  },
  {
    name: 'State',
    code: 'state',
    type: INPUT_TYPE.TEXT
  },
  {
    name: 'Country',
    code: 'country',
    options: COUNTRIES_WITH_CURRENCIES.map((countryData) => ({
      id: countryData.country,
      name: countryData.country,
      color: 'data-grid-badge-color-1'
    })),
    type: INPUT_TYPE.SELECT
  },
  {
    name: 'Postal code',
    code: 'postalCode',
    type: INPUT_TYPE.TEXT
  }
];

export const headerMapDisplaySections = [
  {
    getSourceHeaderName: (selectedSource) => {
      const importSourceType = IMPORT_SOURCE_TYPE[selectedSource];
      switch (importSourceType) {
        case IMPORT_SOURCE_TYPE.importFromDeskeraBooks:
          return `ERP Header`;
        case IMPORT_SOURCE_TYPE.importOffice365:
          return `Office 365 Header`;
        case IMPORT_SOURCE_TYPE.importGoogle:
          return `Google Header`;
        default:
          return `Imported Header`;
      }
    },
    name: 'Imported Header',
    key: 'importedKey'
    /* column Type => Text */
  },
  {
    name: 'Table Header',
    key: 'dkColKey'
    /* column Type => Select */
  },
  {
    name: 'Sample',
    key: 'sampleData'
    /* column Type => Text */
  }
];

export const errorInfoTypes = {
  unmapped:
    'Please either map to existing header/create new field or check the option to skip unmapped fields',
  duplicateMapping:
    'Duplicate Mappings present, please choose different header or create a new field',
  escaped: 'Data for this field will be excluded from import',
  dateWarning:
    'Please ensure date is in recommended format i.e. (DD-MM-YYYY) or (MM/DD/YYYY) in uploaded file for correct results.'
};
export const maxFileNameLength = 100;

export const acceptedFileFormats = ['.csv', '.xlsx'];
