import httpClient from '../http/index';
import { IMPORT_SOURCE_TYPE } from '../constants/EximConstants';
import Utility from '../utility/Utility';
import { API_ENDPOINTS } from '../constants/Endpoints';
import { store } from '../redux/store';

class ImportExportService {
  static getRequestURL(
    endPointKey: string,
    queryParamObj?: any,
    endPointOptions?: any,
    isLinkApp?: boolean,
    appName?: string
  ) {
    const { BASE, IMPORT_EXPORT, LINK_APP } = API_ENDPOINTS;
    let queryParams = '';

    if (queryParamObj && Object.keys(queryParamObj).length) {
      queryParams = `?${new URLSearchParams(queryParamObj).toString()}`;
    }

    let endPoint = isLinkApp
      ? LINK_APP[endPointKey]
      : IMPORT_EXPORT[endPointKey];

    if (endPointOptions) {
      endPoint = endPoint(endPointOptions);
    }

    if (!Utility.isEmptyObject(appName)) {
      queryParams = `&query=appName=${appName}`;
    }

    return `${BASE}${endPoint}${queryParams}`;
  }

  static getApiRequestOptions(method: string, body = null, type?: string) {
    const requestOptions = {
      method: method,
      credentials: 'include',
      withCredentials: true,
      mode: 'cors',
      headers: {}
    };

    if (type === 'json') {
      return {
        ...requestOptions,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      };
    } else if (method === 'POST' || method === 'PUT') {
      return {
        ...requestOptions,
        body
      };
    }

    return requestOptions;
  }

  static async getUploadFileURL(fileData, fileEntity) {
    console.log(
      httpClient.defaults.headers.common['Authorization'],
      'Authorisation token '
    );
    const url = ImportExportService.getRequestURL('UPLOAD_FILE');

    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('file-entity', fileEntity);

    const requestOptions: any = ImportExportService.getApiRequestOptions(
      'POST',
      formData
    );

    requestOptions.headers['Authorization'] =
      httpClient.defaults.headers.common['Authorization'];

    return fetch(url, requestOptions)
      .then((res) => res.json())
      .catch((err) => Promise.reject(err));
  }

  static async uploadRecords(
    queryParamObj = {},
    payload,
    isBooksImport?: boolean
  ) {
    const tableId = store.getState().import.table?._id;
    let url = ImportExportService.getRequestURL(
      'UPLOAD_RECORDS',
      queryParamObj
    );
    url = url.replace(':tableId', tableId);

    const requestOptions: any = ImportExportService.getApiRequestOptions(
      'POST',
      payload,
      'json'
    );

    requestOptions.headers['Authorization'] =
      httpClient.defaults.headers.common['Authorization'];

    return fetch(url, requestOptions)
      .then((res) => res.json())
      .catch((err) => Promise.reject(err));
  }

  static async saveAuditLog(payload) {
    const url = ImportExportService.getRequestURL('AUDIT_LOG');
    const requestOptions: any = ImportExportService.getApiRequestOptions(
      'POST',
      payload,
      'json'
    );

    return fetch(url, requestOptions)
      .then((res) => res.json())
      .catch((err) => Promise.reject(err));
  }

  static async getExportDataFile(
    queryParamObj: { format: string; timezoneOffset: number },
    payload = { filter: null, ids: null },
    options
  ) {
    return httpClient
      .post(
        API_ENDPOINTS.IMPORT_EXPORT.EXPORT_FILE(options.endPointOptions),
        payload,
        { params: queryParamObj }
      )
      .catch((err) =>
        Promise.reject('Export records failed, please try again later.')
      );
  }

  static async getExistingConnectedUsers(appName?) {
    const url = ImportExportService.getRequestURL(
      'GET_CONNECTED_USERS',
      null,
      null,
      true,
      appName
    );

    const requestOptions: any = ImportExportService.getApiRequestOptions('GET');
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .catch((err) =>
        Promise.reject('Getting existing users failed, please try again later.')
      );
  }

  static async connectDataSourceAccount(authCode: string, appConfig: any) {
    const url = ImportExportService.getRequestURL(
      'CONNECT_USER_ACCOUNT',
      null,
      null,
      true
    );

    const payload = {
      code: authCode,
      appName: appConfig.appName,
      realmId: appConfig.appId,
      redirectUrl: encodeURIComponent(appConfig.redirectUrl),
      state: `12345`,
      terminal: appConfig.terminal
    };

    const requestOptions: any = ImportExportService.getApiRequestOptions(
      'POST',
      payload,
      'json'
    );

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .catch((err) =>
        Promise.reject(
          'Connecting microsoft user failed, please try again later.'
        )
      );
  }

  static async disconnectLinkedAccount(username: string, appName: string) {
    const url = ImportExportService.getRequestURL(
      'DISCONNECT_LINKED_ACCOUNT',
      null,
      {
        appName,
        username
      },
      true
    );

    const requestOptions: any = ImportExportService.getApiRequestOptions(
      'POST',
      ''
    );

    return fetch(url, requestOptions).catch((err) =>
      Promise.reject(
        'Disconnecting selected user failed, please try again later.'
      )
    );
  }

  static async getLinkedAccountExportDataUrl(
    appName: string,
    module: string,
    username: string
  ) {
    let url =
      appName === IMPORT_SOURCE_TYPE.importFromDeskeraBooks
        ? ImportExportService.getRequestURL('GET_BOOKS_FILE', null)
        : ImportExportService.getRequestURL(
            'GET_LINKED_ACCOUNT_EXPORT_DATA',
            null,
            {
              appName,
              module,
              username
            }
          );

    const requestOptions: any = ImportExportService.getApiRequestOptions('GET');

    try {
      const response = await fetch(url, requestOptions);
      if (response && response.ok) {
        const relativePath = await response
          .clone()
          .json()
          .catch(function () {
            return response.text();
          });
        const absolutePath = await ImportExportService.getS3DownloadURL(
          relativePath
        );
        if (absolutePath) {
          return Promise.resolve({ relativePath, absolutePath });
        } else {
          return Promise.reject(
            'Operation for generating S3 download file url failed, please try again later'
          );
        }
      } else {
        return Promise.reject(
          'Export data operation from selected user failed, please try again later.'
        );
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(
        'Export data operation from selected user failed, please try again later.'
      );
    }
  }

  static async getS3DownloadURL(relativePath) {
    const url = ImportExportService.getRequestURL(
      'FILE_DOWNLOAD',
      null,
      relativePath
    );

    const requestOptions: any = ImportExportService.getApiRequestOptions('GET');

    return fetch(url, { ...requestOptions, responseType: 'text' }).then(
      (response) => response.text()
    );
  }

  /* Not required currently */
  static async getState(redirectURL) {
    const url = ImportExportService.getRequestURL(
      'GET_STATE',
      redirectURL ? { redirectUrl: redirectURL } : null
    );
    return fetch(url)
      .then((response) => response.json())
      .catch((err) =>
        Promise.reject(
          'Generation of state for new connection failed, please try again later.'
        )
      );
  }

  /* Not required currently */
  static async validateState(state) {
    const url = ImportExportService.getRequestURL('VALIDATE_STATE');
    const requestOptions: any = ImportExportService.getApiRequestOptions(
      'POST',
      { state },
      'json'
    );
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .catch((err) =>
        Promise.reject(
          'Validation of state for new connection failed, please try again later.'
        )
      );
  }
}

export default ImportExportService;
