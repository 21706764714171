import React, { useState } from 'react';
import {
  DKLabel,
  DKInput,
  Toggle,
  INPUT_TYPE,
  DKCheckMark,
  DKButton,
  INPUT_VIEW_DIRECTION,
  DKIcon,
  DKSegmentControl
} from 'deskera-ui-library';
import IFormField from '../../model/Form';
import AppIcons from '../../assets/icons/AppIcons';
import { useAppSelector } from '../../redux/hooks';
import Utility, {
  getDisplayNameForType,
  getRandomNumber
} from '../../utility/Utility';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import { isEmpty } from '../../utility/DKUtility';
import { FIELD_ALIGNMENT } from '../../constants/Enum';
import { updateColumn } from '../../services/table';
import { selectTableById } from '../../redux/slices/tableSlice';

interface IFieldSettingsProps {
  field: IFormField;
  linkedTable: any;
  onChange: (field: IFormField) => void;
  onClose?: () => void;
  onDelete: (field: IFormField) => void;
}

const FieldSettings: React.FC<IFieldSettingsProps> = ({
  field,
  onDelete,
  linkedTable,
  onChange,
  onClose
}) => {
  const titleWidth = 80;
  const {
    placeHolder,
    title,
    defaultValue,
    type,
    style,
    options = [],
    required,
    refTable
  } = field;

  let { subFields = [] } = refTable ?? {};
  let { _id: tableId, name: tableName = '' } = linkedTable ?? {};
  const [optionFieldText, setOptionFieldText] = useState<string>(null);
  const [showOptionInput, setShowOptionInput] = useState<boolean>(false);

  const tableData = useAppSelector(selectTableById(refTable?._id)) ?? {};

  const handleOnChange = (key: keyof IFormField, data: any) => {
    onChange({ ...field, [key]: data });
  };

  const onSubFieldChange = (subField, index) => {
    const previousFields = [...subFields];
    previousFields[index] = subField;
    onChange({
      ...field,
      refTable: { ...refTable, subFields: previousFields }
    });
  };
  const onSubFieldRemove = (index) => {
    const previousFields = [...subFields];
    previousFields.splice(index, 1);
    onChange({
      ...field,
      refTable: { ...refTable, subFields: previousFields }
    });
  };

  const selectedSubFieldsId: string[] = subFields.map((field) => field.id);

  const data = tableData.columnsMetaData?.reduce((acc, column) => {
    if (
      !selectedSubFieldsId.includes(column.id) &&
      (column.type === CUSTOM_INPUT_TYPE.NUMBER ||
        column.type === CUSTOM_INPUT_TYPE.FORMULA)
    ) {
      acc.push({
        id: column.id,
        name: column.name
      });
    }
    return acc;
  }, []);

  const showSubFieldConfig =
    !Utility.isEmptyObject(tableData) && type === CUSTOM_INPUT_TYPE.TABLE;

  const getSelectedAlignmentIndex = () => {
    if (style.alignSelf === FIELD_ALIGNMENT.START) {
      return 0;
    } else if (style.alignSelf === FIELD_ALIGNMENT.CENTER) {
      return 1;
    } else if (style.alignSelf === FIELD_ALIGNMENT.END) {
      return 2;
    }
  };

  const onOptionChange = (value) => {
    setOptionFieldText(value);
  };

  const onOptionAdd = () => {
    if (!showOptionInput) {
      setShowOptionInput(true);
      return;
    }
    if (isEmpty(optionFieldText)) return;
    let newOptions = [...options];
    const newID = parseInt(getRandomNumber(1000) + '' + newOptions.length);
    newOptions.push({
      id: newID,
      name: optionFieldText,
      color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`
    });
    handleOnChange('options', newOptions);
    setOptionFieldText(null);
  };

  const onOptionRemove = (index) => {
    let newOptions = [...options];
    newOptions.splice(index, 1);
    handleOnChange('options', newOptions);
  };

  const getOptionList = () => {
    return (
      <div className="row flex-wrap">
        {options.map((option, index) => {
          return (
            <div
              className={`p-xs border-radius-s mr-s mt-s d-flex align-items-center ${option.color}`}
              id={`${option.id}`}
            >
              <span>{option.name}</span>
              <DKIcon
                src={AppIcons.ic_close}
                className="ic-s ml-s cursor-hand"
                onClick={() => onOptionRemove(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const renderAddOptions = () => {
    return (
      <>
        <div className="row justify-content-between" style={{}}>
          <div className="column" style={{ width: '100%' }}>
            <DKLabel text={'Options'} />
            {getOptionList()}
          </div>
        </div>
        {showOptionInput && (
          <div className="row mt-xs" style={{ width: '100%' }}>
            <DKInput
              title=""
              value={optionFieldText}
              titleWidth={titleWidth}
              required={false}
              readOnly={false}
              onChange={onOptionChange}
            />
          </div>
        )}
        <div className="row width-auto mt-m mb-m">
          <DKButton
            title={'Add options'}
            className="bg-button text-white width-auto cursor-hand"
            onClick={onOptionAdd}
          />
        </div>
      </>
    );
  };
  return (
    <div
      className="column parent-size p-xl overflow-auto hide-scroll-bar border-box"
      style={{ paddingBottom: 60 }}
    >
      <div className="row mb-xxl align-items-center justify-content-between">
        <DKLabel text="Field settings" className="fw-m" />
        <DKIcon
          src={AppIcons.ic_close}
          className="ic-s cursor-hand"
          onClick={onClose}
        />
      </div>

      <DKInput
        title="Title"
        value={title}
        titleWidth={titleWidth}
        onChange={(text) => {
          handleOnChange('title', text);
        }}
      />

      <DKLabel text="Properties" className="mt-xxl fw-m mb-m" />

      <DKInput
        title="Type"
        value={getDisplayNameForType(type, { tableName })}
        titleWidth={titleWidth}
        className="mb-m"
        required={false}
        readOnly
      />

      {type !== CUSTOM_INPUT_TYPE.TABLE && (
        <DKInput
          title="Placeholder"
          value={placeHolder}
          titleWidth={titleWidth}
          className="mb-m"
          required={false}
          onChange={(text) => {
            handleOnChange('placeHolder', text);
          }}
        />
      )}
      {type !== CUSTOM_INPUT_TYPE.TABLE && (
        <DKInput
          title="Default value"
          value={defaultValue}
          titleWidth={titleWidth}
          className="mb-m"
          required={false}
          onChange={(text) => {
            handleOnChange('defaultValue', text);
          }}
        />
      )}
      {(type === CUSTOM_INPUT_TYPE.SELECT ||
        type === CUSTOM_INPUT_TYPE.MULTI_SELECT) && <>{renderAddOptions()}</>}

      <DKInput
        title="Width (%)"
        type={INPUT_TYPE.NUMBER}
        allowNegativeNumber={false}
        value={style.width ?? 100}
        titleWidth={titleWidth}
        className="mb-m"
        required={false}
        onChange={(text) => {
          handleOnChange('style', { ...style, width: `${text}` });
        }}
      />
      <div className="row row-responsive justify-content-between align-items-center position-relative">
        <DKLabel className="" text="Align" style={{ width: titleWidth + 90 }} />

        <DKSegmentControl
          segments={[
            { icon: AppIcons.ic_align_left },
            { icon: AppIcons.ic_align_center },
            { icon: AppIcons.ic_align_right }
          ]}
          backgroundColor="bg-gray1"
          selectedColor="text-white"
          barColor="bg-white"
          selectedIndex={getSelectedAlignmentIndex()}
          onSelect={(index: number) => {
            let alignment = '';
            if (index === 0) {
              alignment = 'start';
            } else if (index === 1) {
              alignment = 'center';
            } else if (index === 2) {
              alignment = 'end';
            }
            handleOnChange('style', { ...style, alignSelf: alignment });
          }}
        />
      </div>

      <DKLabel text="Rules" className="mt-xxl fw-m mb-m" />
      <div className="row">
        <DKLabel text="Set as mandatory" style={{ width: 120 }} />
        <Toggle
          color="bg-button"
          isOn={required}
          onChange={() => {
            handleOnChange('required', !required);
          }}
        />
      </div>
      {/* <div className="row mt-m">
        <DKLabel text="Visible" style={{ width: 120 }} />
        <Toggle
          color="bg-button"
          isOn={uiVisible}
          onChange={() => {
            handleOnChange('uiVisible', !uiVisible);
          }}
        />
      </div> */}

      {showSubFieldConfig && (
        <>
          <DKLabel text="Sub Fields" className="mt-xxl fw-m mb-m" />
          {subFields.map((field, index) => {
            return (
              <div className="column parent-width border-m border-radius-s p-s mb-l">
                <div
                  className="row gap-1 align-items-center sub-field-row"
                  key={index}
                >
                  <DKInput
                    type={INPUT_TYPE.TEXT}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    title="title"
                    value={field.displayName}
                    titleWidth={titleWidth}
                    className="mb-m"
                    required={false}
                    onChange={(text) => {
                      onSubFieldChange({ ...field, displayName: text }, index);
                    }}
                  />
                  <DKInput
                    type={INPUT_TYPE.DROPDOWN}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    title="Sum of field"
                    value={field.id ? field : null}
                    titleWidth={titleWidth}
                    className="mb-m"
                    required={false}
                    onChange={(value) => {
                      onSubFieldChange({ ...field, ...value }, index);
                    }}
                    dropdownConfig={{
                      data,
                      renderer: (index, obj) => {
                        return <DKLabel needIcon text={`${obj.name}`} />;
                      }
                    }}
                    renderer={(data) => {
                      return data.name;
                    }}
                  />
                  <DKIcon
                    src={AppIcons.ic_close}
                    className="subfield-remove ic-s mt-m cursor-hand"
                    onClick={() => onSubFieldRemove(index)}
                  />
                </div>
                <DKCheckMark
                  title="Show column on grid"
                  className="mb-s ml-s"
                  isSelected={field.uiVisible}
                  onClick={(value) => {
                    if (field.refTableColumnId) {
                      updateColumn(tableId, field.refTableColumnId, {
                        uiVisible: !field.uiVisible
                      });
                    }
                    onSubFieldChange(
                      { ...field, uiVisible: !field.uiVisible },
                      index
                    );
                  }}
                  color="bg-blue"
                />
              </div>
            );
          })}

          {!Utility.isEmptyObject(data) && (
            <DKButton
              title="+Add sub field"
              className="d-flex fw-m text-blue p-0"
              style={{ padding: 0 }}
              onClick={() => {
                const previousFields = [...subFields];
                previousFields.push({
                  name: null,
                  refTableColumnId: null,
                  id: null,
                  displayName: 'Sub-field ' + (subFields.length + 1),
                  uiVisible: false
                });
                onChange({
                  ...field,
                  refTable: { ...refTable, subFields: previousFields }
                });
              }}
            />
          )}
        </>
      )}

      <DKButton
        title="Delete"
        icon={AppIcons.white.ic_delete}
        className="bg-red mt-xxl text-white"
        style={{ paddingTop: 4, paddingBottom: 4 }}
        onClick={() => onDelete(field)}
      />
    </div>
  );
};

export default FieldSettings;
