import ApiConstants from '../constants/ApiConstants';
import httpClient from '../http/';

class OnboardingService {
  static onboardingPost(payload?: any) {
    return httpClient.post(ApiConstants.URL.COMMON.ONBOARDING, payload);
  }
  static onboardingPatch(payload: any, id: string) {
    return httpClient.patch(
      `${ApiConstants.URL.COMMON.ONBOARDING}/${id}`,
      payload
    );
  }
  static onboardingFinalize(payload: any, id: string) {
    return httpClient.post(
      ApiConstants.URL.COMMON.ONBOARDING_FINALIZE(id),
      payload
    );
  }
}
export default OnboardingService;
