import React, { useState } from 'react';

import { DKIcon, DKLabel, DKButton, DKInput } from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';

const NewPromptForm = ({ data, onClose, onSave }) => {
  const [prompt, setPrompt] = useState({ title: '', prompt: '' });

  return (
    <div className="transparent-background">
      <div className="popup-window">
        <div className="row justify-content-between">
          <div className="row width-auto">
            <DKIcon src={AppIcons.ic_app} className="ic-s-2 mr-r opacity-7" />
            <DKLabel text="New prompt and name" />
          </div>
          <DKButton
            icon={AppIcons.ic_close}
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <DKLabel text="Name" className="fw-m mt-l mb-r" />
        <DKInput
          value={prompt.title}
          onChange={(value) => {
            setPrompt({ ...prompt, title: value });
          }}
          placeholder="Prompt display name. (e.g. build ERP app)"
        />
        <DKLabel text="Prompt" className="fw-m mb-r mt-l" />
        <DKInput
          type="long text"
          value={prompt.prompt}
          onChange={(value) => {
            setPrompt({ ...prompt, prompt: value });
          }}
          placeholder="Prompt details. (e.g. create ERP app with all features of accounting)"
        />
        <div className="row-reverse">
          <DKButton
            title="Save Prompt"
            className="bg-button text-white parent-width mt-l mb-m align-self-end"
            onClick={() => {
              onSave(prompt);
            }}
          />
          <DKButton
            title="Cancel"
            className="mr-r"
            onClick={() => {
              onClose();
            }}
          />
        </div>
        {/* <DKLabel
          text={data.prompt}
          className="bg-gray1 p-l border-radius-m mt-r mb-m"
        /> */}
      </div>
    </div>
  );
};

export default NewPromptForm;
