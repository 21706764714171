// applicationSlice.ts

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import httpClient from '../../http';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import { API_Params, APIPayload } from '../../model/Api';
import { RootState } from '../store';

interface ApplicationState {
  applicationData: APIPayload;
  templatesData: APIPayload;
  isLoading: boolean;
  error: string;
  searchTerm: string;
}

const initialState: ApplicationState = {
  applicationData: { data: [] },
  templatesData: { data: [] },
  isLoading: false,
  error: '',
  searchTerm: ''
};

export const fetchApplications = createAsyncThunk(
  'applications/fetchApplications',
  async (params: API_Params) => {
    const response = await httpClient.get(API_ENDPOINTS.APP.FETCH, {
      params
    });
    return response;
  }
);

export const fetchTemplates = createAsyncThunk(
  'applications/fetchTemplates',
  async (params: any) => {
    let paramsMapping = {
      name: params.q,
      page: params.pageNo,
      limit: params.pageSize,
      createdByUser: params.createdByUser,
      published: params.published
    };
    const response = await httpClient.get(API_ENDPOINTS.TEMPLATE.GET_TEMPLATE, {
      params: paramsMapping
    });
    return response;
  }
);

export const editApplication = createAsyncThunk(
  'applications/editApplication',
  async (app: any) => {
    const response = await httpClient.put(
      API_ENDPOINTS.APP.UPDATE(app._id),
      app
    );
    return response;
  }
);

export const deleteApplication = createAsyncThunk(
  'applications/deleteApplication',
  async (appId: string) => {
    const response = await httpClient.delete(API_ENDPOINTS.APP.DELETE(appId));
    return response.data;
  }
);

export const copyApplication = createAsyncThunk(
  'applications/copy',
  async ({ appId, newName }: { appId: string; newName: string }) => {
    const response = await httpClient.put(API_ENDPOINTS.APP.COPY(appId), {
      name: newName
    });
    return response;
  }
);

export const updateTemplate = createAsyncThunk(
  'applications/updateTemplate',
  async (app: any) => {
    let req = {
      appId: app.appId,
      description: app.description,
      logoData: app.logoData,
      name: app.name
    };
    const response = await httpClient.put(
      API_ENDPOINTS.TEMPLATE.UPDATE_TEMPLATE(app._id),
      req
    );
    return response;
  }
);

export const deleteTemplate = createAsyncThunk(
  'applications/deleteTemplate',
  async (templateID: string) => {
    const response = await httpClient.delete(
      API_ENDPOINTS.TEMPLATE.DELETE_TEMPLATE(templateID)
    );
    return response.data;
  }
);

export const copyTemplate = createAsyncThunk(
  'applications/copyTemplate',
  async ({ appId, newName }: { appId: string; newName: string }) => {
    const response = await httpClient.put(API_ENDPOINTS.APP.COPY(appId), {
      name: newName
    });
    return response;
  }
);

const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplications.fulfilled, (state, action) => {
      state.applicationData = action.payload;
      state.error = '';
      state.isLoading = false;
    });
    builder.addCase(fetchApplications.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchApplications.rejected, (state) => {
      state.error = 'Failed to load applications';
      state.isLoading = false;
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.templatesData = action?.payload?.['body'] ?? {};
      state.error = '';
      state.isLoading = false;
    });
    builder.addCase(fetchTemplates.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTemplates.rejected, (state) => {
      state.error = 'Failed to load templates';
      state.isLoading = false;
    });
    builder.addCase(editApplication.fulfilled, (state) => {
      // Handle success if needed
    });
    builder.addCase(deleteApplication.fulfilled, (state) => {
      // Handle success if needed
    });
    builder.addCase(updateTemplate.fulfilled, (state) => {
      // Handle success if needed
    });
    builder.addCase(deleteTemplate.fulfilled, (state) => {
      // Handle success if needed
    });
    builder.addCase(copyTemplate.fulfilled, (state) => {
      // Handle success if needed
    });
  }
});
const selfSelector = (state: RootState) => state?.applications;

export const selectAppState = () =>
  createSelector(selfSelector, (state: ApplicationState) => state);
export const { setSearchTerm } = applicationSlice.actions;

export default applicationSlice.reducer;
