import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchTable, fetchTablesByAppId } from '../../services/table';

interface ITableState {
  tables: any;
  filters: any;
}
const initialState: ITableState = {
  tables: {},
  filters: {}
};

export const fetchTables = createAsyncThunk(
  'fetchTable',
  async ({ appId }: any, thunkAPI) => {
    const response = await fetchTablesByAppId({ appId });
    return response;
  }
);
export const fetchTableById = createAsyncThunk(
  'fetchTableById',
  async ({ tableId }: any, thunkAPI) => {
    const response = await fetchTable({ tableId });
    return response;
  }
);

const tableState = createSlice({
  name: 'tableSlice',
  initialState,
  reducers: {
    addTable: (state, action: PayloadAction<any>) => {
      const { _id } = action.payload;
      state.tables[_id] = action.payload;
    },
    addTables: (state, action: PayloadAction<any>) => {
      state.tables = action.payload;
    },
    addColumn: (state, action: PayloadAction<any>) => {
      const { tableId, column } = action.payload;
      const tables = state.tables;
      tables[tableId].columnsMetaDat.push(column);
      state.tables = tables;
    },
    updateColumn: (state, action: PayloadAction<any>) => {
      const { tableId, column } = action.payload;
      const tables = state.tables;
      tables[tableId].columnsMetaData.forEach((col) => {
        if (col.id === column.id) {
          col = column;
        }
      });
      state.tables = tables;
    },
    deleteColumn: (state, action: PayloadAction<any>) => {
      const { tableId, column } = action.payload;
      const tables = state.tables;
      const index = tables[tableId].columnsMetaData?.findIndex(
        (col) => col.id === column.id
      );
      if (index !== -1) {
        tables[tableId].columnsMetaData.splice(index, 1);
      }
      state.tables = tables;
    },
    updateFilter: (state, action: PayloadAction<any>) => {
      let filterState = state.filters;
      let actionPayload = action.payload;
      filterState[actionPayload.tableId] = actionPayload.filter;
      state.filters = filterState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTables.fulfilled, (state, action) => {
      if (action?.payload?.data?.length) {
        const tablesMap = {};
        action?.payload?.data.reduce((acc, table) => {
          acc[table._id] = table;
          return acc;
        }, tablesMap);
        state.tables = tablesMap;
      }
    });
    builder.addCase(fetchTableById.fulfilled, (state, action) => {
      const { _id }: any = action.payload;
      state.tables[_id] = action.payload;
    });
  }
});

const selfSelector = (state: RootState) => state?.table;
export const selectTableById = (tableId) =>
  createSelector(selfSelector, (state: ITableState) => state.tables[tableId]);
export const selectTables = () =>
  createSelector(selfSelector, (state: ITableState) => state.tables);
export const selectFilter = () =>
  createSelector(selfSelector, (state: ITableState) => state.filters);

export const {
  addTable,
  addTables,
  updateColumn,
  addColumn,
  deleteColumn,
  updateFilter
} = tableState.actions;
export default tableState.reducer;
