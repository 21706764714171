import { getFile } from '../utility/ExportData';

/* Accepted File Types: CSV, XLSX or other valid types supported in SpreadSheets. */
function excelFileReadHelper(event, onSuccess) {
  const content = event.target.result;
  import('xlsx')
    .then((XLSX) => {
      const workbook = XLSX.read(content, {
        type: 'binary',
        cellDates: true,
        cellText: false,
        cellNF: false,
        dateNF: 'dd-mm-yyyy'
      });

      return workbook.SheetNames.map((sheet) => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
          header: true,
          defval: '',
          dateNF: 'mm/dd/yyyy',
          raw: false,
          rawNumbers: true
        });

        return rowObject;
      });
    })
    .then((res) => {
      onSuccess(res);
    });
}

function getSupportedFileReader(fileType) {
  switch (fileType) {
    case 'csv':
    case 'xlsx':
      return excelFileReadHelper;
    default:
  }

  return null;
}

export async function fileReadUtil(selectedFile, fileType) {
  return new Promise((onSuccess, onFailure) => {
    const fileReadHelper = getSupportedFileReader(fileType);
    try {
      if (fileReadHelper) {
        const fileReader = new FileReader();
        fileReader.onload = (event) => fileReadHelper(event, onSuccess);
        fileReader.readAsBinaryString(selectedFile);
      } else {
        onFailure('File type not supported');
      }
    } catch (err) {
      onFailure('File not readable');
    }
  });
}

export async function urlReadUtil(downloadFileUrl, fileType = 'csv') {
  const downloadFileResponse = await fetch(downloadFileUrl, {
    responseType: 'blob'
  });
  const fileContent = await downloadFileResponse.blob();
  const selectedFile = getFile(
    fileContent,
    `OFFICE_365_EXPORT_DATA_${new Date().getTime()}`,
    fileType
  );
  return fileReadUtil(selectedFile, fileType);
}
