import React from 'react';
import Lottie from 'react-lottie';

const LottiePlayer = ({
  animationData,
  loop = false,
  width = null,
  height = null
}) => {
  const defaultOptions = {
    loop: loop || false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid'
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      width={width || null}
      height={height || null}
    />
  );
};

export default LottiePlayer;
