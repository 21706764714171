import React, { useState } from 'react';
import IFormField from '../../model/Form';
import { DKLabel, DKInput, DKIcon, DKButton } from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';
import AddColumnPopup from '../AddColumnPopup';
import { IColumn } from '../../model/Table';
import { getIconByDataType } from '../../utility/Utility';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppSelector } from '../../redux/hooks';
import { selectTableById } from '../../redux/slices/tableSlice';

interface IFormSettingsProps {
  title: string;
  appId: string;
  fields: IFormField[];
  linkedTable?: any;
  onChange: (key: 'title' | 'fields', data) => void;
  onFieldClicked: (action: 'edit' | 'delete', field: IFormField) => void;
  onAddNewField: (column: IColumn) => void;
  onDragChange: (dragIndex: number, dropIndex: number) => void;
}

const FormSettings: React.FC<IFormSettingsProps> = ({
  title,
  appId,
  fields,
  linkedTable,
  onFieldClicked,
  onAddNewField,
  onChange,
  onDragChange
}) => {
  const { name: tableName, _id: tableId } = linkedTable;
  const [showColumnPopup, setColumnPopup] = useState<boolean>(false);
  const tableConfig = useAppSelector(selectTableById(tableId));

  const renderField = (field: IFormField, index: number) => {
    const { type, title, _id } = field;
    return (
      <Draggable key={`item-${_id}`} draggableId={`item-${_id}`} index={index}>
        {(providedR, snapshot) => (
          <div
            className="row p-v-m p-h-m  justify-content-between cursor-hand data-grid-bottom-border"
            ref={providedR.innerRef}
            {...providedR.draggableProps}
            {...providedR.dragHandleProps}
          >
            <div className="row width-auto">
              <DKIcon
                src={getIconByDataType(type)}
                className={`${
                  type === CUSTOM_INPUT_TYPE.TABLE ||
                  type === CUSTOM_INPUT_TYPE.REF
                    ? 'ic-s'
                    : 'ic-xs'
                }  mr-r`}
              />
              <DKLabel text={title} />
            </div>
            <div className="row width-auto">
              <DKIcon
                src={AppIcons.ic_edit}
                className="ic-s cursor-hand opacity-6"
                onClick={() => {
                  onFieldClicked('edit', field);
                }}
              />
              <DKIcon
                src={AppIcons.ic_delete}
                className="ic-s cursor-hand ml-r opacity-6"
                onClick={() => {
                  onFieldClicked('delete', field);
                }}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const getAddNewButton = () => {
    return (
      <>
        <DKButton
          title="+ Add new field"
          className="bg-button mt-m text-white"
          style={{ paddingTop: 4, paddingBottom: 4 }}
          onClick={() => setColumnPopup(true)}
        />
      </>
    );
  };

  const handleDragEnd = (event: any) => {
    if (event.source && event.destination) {
      onDragChange(event.source.index, event.destination.index);
    }
  };

  const handleDragStart = (event: any) => {};

  return (
    <div
      className="column parent-size p-xl hide-scroll-bar border-box"
      style={{ paddingBottom: 60 }}
    >
      <DKLabel text="Form settings" className="fw-m mb-xxl" />

      <DKInput
        title="Title"
        value={title}
        titleWidth={60}
        onChange={(text) => {
          onChange('title', text);
        }}
      />
      <>
        <DKLabel text="Data" className="fw-m mt-xxl" />
        <DKInput
          title="Target"
          value={`${tableName} Table`}
          titleWidth={60}
          readOnly
          required={false}
        />
      </>

      <DKLabel text="Fields" className="mt-xxl fw-m mb-m" />
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="column parent-width border-m border-radius-m p-s gap-1"
            >
              {fields.map(renderField)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {getAddNewButton()}
      {showColumnPopup && (
        <AddColumnPopup
          appId={appId}
          tableId={tableId}
          tableName={tableName}
          source="OTHER"
          onSave={({ columnData }) => {
            onAddNewField(columnData);
            setColumnPopup(false);
          }}
          onClose={() => setColumnPopup(false)}
          columnDList={tableConfig.columnsMetaData}
        />
      )}
    </div>
  );
};

export default FormSettings;
