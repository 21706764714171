import {
  DKButton,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showToast,
  DKIcons
} from 'deskera-ui-library';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { attachmentRendererForRecord } from '../../Helper/Renderers';
import {
  COLUMN_DETAIL_SUFFIX,
  CUSTOM_INPUT_TYPE,
  MAX_FILE_SIZE
} from '../../constants/Constants';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import httpClient from '../../http/index';
import { getDefaultColumn } from '../../Helper/table';
import ApiConstants from '../../constants/ApiConstants';
import Popup from './Popup';
import Utility, { isString, getDKInputValueStyle } from '../../utility/Utility';
import { getHumanReadableFileSize, uploadFileToAWS } from '../../services/file';
import AddEditRecordFormDKTableView from './AddEditRecordFormDKTableView';
import AppIcons from '../../assets/icons/AppIcons';

interface IAddNewRecordProps {
  onSave: (data) => void;
  onCancel: () => void;
  record: any;
  appId: string;
  tableId: string;
  isReadOnly?: boolean;
  columns: any[];
  isBulkUpdateSelected?: boolean;
  selectedRecordIds?: string[];
}
const getAttachmentPicker = ({ ref, onClick, onChange }) => {
  return (
    <input
      id="dynamicFormAttachment"
      type="file"
      accept="image/*, application/pdf, .txt, .doc, .xls , .ppt, .docx, .xlsx, .pptx"
      multiple={true}
      ref={ref}
      style={{ display: 'none' }}
      onClick={onClick}
      onChange={onChange}
    />
  );
};
const checkIt = () => {
  if (document.querySelector('#dynamicFormAttachment')) {
    const fileElement: HTMLInputElement = document.querySelector(
      '#dynamicFormAttachment'
    );
    if (fileElement.files && fileElement.files.length === 0) {
      return;
    }
  }
};
const initializeImageSelect = () => {
  document.body.onfocus = checkIt;
};
const getColumnDropDownConfig = (item) => {
  return {
    style: {},
    allowSearch: true,
    searchableKey: 'name',
    data: [],
    renderer: (index, obj) => {
      let value: any = '';
      if (obj && typeof obj === 'object' && obj.cells) {
        const [[firstKey, firstValue]] = Object.entries(obj.cells);
        value = firstValue;
      }
      return <DKLabel needIcon text={`${value ?? ''}`} />;
    },
    searchApiConfig: {
      method: 'POST',
      getUrl: (val) => {
        return item?.refTable?._id
          ? `${API_ENDPOINTS.BASE}/v1/app-builder/table/${item.refTable?._id}/paged-record`
          : '';
      },
      getHeaders: () => {
        return {
          'Access-Control-Allow-Credentials': true,
          Authorization: httpClient.defaults.headers.common['Authorization']
        };
      },
      dataParser: (response) => {
        return response.data;
      },
      getPayload: (searchText) => {
        return {
          pageNo: 1,
          pageSize: 30,
          fetchAllRef: true,
          q: `search=${searchText}`,
          conditions: [],
          logicalOperator: 'and'
        };
      }
    },
    onSelect: (index: number, value: any) => {
      console.log(value, index);
    }
  };
};
const getMappedColumns = (columns, entryForm = false) => {
  let mappedColumns = [];
  if (!Utility.isEmptyObject(columns)) {
    mappedColumns = columns.map((item) => {
      let columnData: any = getDefaultColumn(item);
      columnData.placeHolder = item.placeHolder;
      columnData.options = (columnData.options || []).map((e) => e.name);
      if (columnData.type === CUSTOM_INPUT_TYPE.REF && columnData.refTable) {
        columnData.type = CUSTOM_INPUT_TYPE.DROPDOWN;
        columnData.allowSearch = true;
        columnData.formatter = (data) => {
          let value: any = '';
          if (data && typeof data === 'object' && data.cells) {
            const [[firstKey, firstValue]] = Object.entries(data.cells);
            value = firstValue;
          }
          return value;
        };
        columnData.dropdownConfig = getColumnDropDownConfig(item);
      }
      if (entryForm) {
        columnData.id = item._id;
        columnData.name = item.title;
        columnData.title = item.title;
        columnData.key = item._id;
      }
      return columnData;
    });
  }
  return mappedColumns;
};
const isFieldTypeNeedsDropdown = (type) => {
  return (
    type === CUSTOM_INPUT_TYPE.SELECT ||
    type === CUSTOM_INPUT_TYPE.MULTI_SELECT ||
    type === CUSTOM_INPUT_TYPE.DROPDOWN ||
    type === CUSTOM_INPUT_TYPE.REF ||
    type === CUSTOM_INPUT_TYPE.DATE
  );
};
const transformData = (data, columns) => {
  return columns.reduce(
    (newData, { editable, options, required, title, type, id, refTable }) => {
      let value = data[id];
      if (
        type === CUSTOM_INPUT_TYPE.SELECT ||
        type === CUSTOM_INPUT_TYPE.MULTI_SELECT
      ) {
        const newValue = [];
        if (!Utility.isEmptyObject(options)) {
          if (
            !Utility.isEmptyObject(options) &&
            !Utility.isEmptyObject(value)
          ) {
            value.forEach((val) => {
              if (!Utility.isEmptyObject(options?.[val])) {
                newValue.push(options?.[val]?.id);
              }
            });
          }
        }
        newData[id] = newValue;
      } else if (
        (type === CUSTOM_INPUT_TYPE.REF ||
          type === CUSTOM_INPUT_TYPE.DROPDOWN) &&
        !Utility.isEmptyObject(refTable) &&
        !Utility.isEmptyObject(value)
      ) {
        newData[id] = !Utility.isEmptyObject(value?._id) ? [value._id] : [];
      } else if (type === CUSTOM_INPUT_TYPE.TABLE) {
        newData[`${id}_detail`] = data[`${id}_detail`];
      } else {
        newData[id] = value;
      }
      return newData;
    },
    {}
  );
};
const convertRecordForForm = (columns, row) => {
  const payload = {};
  if (!Utility.isEmptyObject(columns) && !Utility.isEmptyObject(row)) {
    columns.forEach(({ id, type, options }) => {
      payload[id] = row[id];
      if (
        type === CUSTOM_INPUT_TYPE.REF ||
        type === CUSTOM_INPUT_TYPE.DROPDOWN
      ) {
        if (!Utility.isEmptyObject(row[`${id}${COLUMN_DETAIL_SUFFIX}`])) {
          const [refObj] = row[`${id}${COLUMN_DETAIL_SUFFIX}`];
          payload[id] = refObj;
        }
      } else if (
        type === CUSTOM_INPUT_TYPE.SELECT ||
        type === CUSTOM_INPUT_TYPE.MULTI_SELECT
      ) {
        let arr = row[id];
        if (!arr) {
          return;
        }
        if (!Array.isArray(row[id])) {
          arr = [row[id]];
        }
        payload[id] = arr?.map((item) =>
          options?.findIndex((option) => option.id === item)
        );
      } else if (type === CUSTOM_INPUT_TYPE.TABLE) {
        payload[`${id}_detail`] = row[`${id}_detail`];
      }
      try {
        if (type === CUSTOM_INPUT_TYPE.DATE) {
          if (!Utility.isEmptyObject(row[id])) {
            payload[id] = new Date(row[id]);
          }
        }
      } catch (error) {
        payload[id] = null;
      }
    });
  }
  return payload;
};

const AddNewRecord: React.FC<IAddNewRecordProps> = ({
  onCancel,
  record,
  onSave,
  columns,
  tableId,
  appId,
  isReadOnly = false,
  isBulkUpdateSelected = false,
  selectedRecordIds = []
}) => {
  const [formData, setFormData] = useState({});
  const browseAttachmentColumnRef = useRef();
  const formBeingFetched = useRef<boolean>();
  const attachmentOpenFileRef = useRef<HTMLElement>();
  const [canValidate, setCanValidate] = useState(false);
  const fields: any[] = useMemo(() => {
    return getMappedColumns(columns);
  }, [columns]);

  const [isAIGenerating, setIsAIGenerating] = useState({
    fieldName: '',
    showLoader: false
  });

  // Data from API - If present use this to create fields
  const [formConfigData, setFormConfigData] = useState<any>();

  useEffect(() => {
    setFormData(convertRecordForForm(columns, record?.cells));
  }, [columns, record]);

  useEffect(() => {
    getFormDataForTableId();
  }, [tableId]);

  // Call api to get table Data
  const getFormDataForTableId = async () => {
    formBeingFetched.current = true;
    await httpClient
      .get(API_ENDPOINTS.FORM.GET(tableId))
      .then((response: any) => {
        let data = { ...response?.data };
        if (!Utility.isEmptyObject(data)) {
          let fieldsCopy = getMappedColumns([...data.fields], true);
          data.fields = fieldsCopy;
          setFormConfigData(data);
        } else {
          setFormConfigData({ fields });
        }
      })
      .catch((error: any) => {
        console.error(error, 'Error fetching formData');
      })
      .finally(() => {
        formBeingFetched.current = false;
      });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderHeader = () => {
    return (
      <div className="row justify-content-between mb-s p-h-xl">
        <DKLabel
          text={
            isBulkUpdateSelected
              ? 'Edit Record(s)'
              : formConfigData?.title
              ? formConfigData?.title
              : record?._id
              ? 'Edit Record'
              : 'Add Record'
          }
          className="fw-m"
          style={{ whiteSpace: 'nowrap' }}
        />
        <div className="row width-auto gap-2">
          <DKButton
            icon={AppIcons.ic_close}
            style={{ paddingRight: 0 }}
            onClick={onCancel}
          />
          {/* <DKButton title="Cancel" className="" onClick={onCancel} />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={handleFormSubmit}
          /> */}
        </div>
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const onChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field?.key]: value }));
  };

  const addAttachment = (field) => {
    browseAttachmentColumnRef.current = field;
    openAttachmentPicker();
  };

  const openAttachmentPicker = () => {
    if (attachmentOpenFileRef?.current) {
      attachmentOpenFileRef?.current?.click();
    }
  };

  const renderAttachment = (column) => {
    const encodedAttachment = formData[column.key];
    return attachmentRendererForRecord({
      encodedAttachment
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const fetchAIDataForAttachment = async (attachmentList: any) => {
    const columnKey = (browseAttachmentColumnRef?.current as any)?.key;
    await httpClient
      .post(ApiConstants.URL.TABLE.AUTO_FILL_DATA, {
        tableId: tableId,
        columnId: columnKey,
        attachment: attachmentList?.[0]
      })
      .then((response: any) => {
        let copyFormData = { ...formData };
        if (response?.data) {
          let columnToTypeMapping: any = {};
          columns.forEach((column: any) => {
            columnToTypeMapping[column.id] = column.type;
          });
          response.data.forEach((item: any) => {
            if (columnToTypeMapping[item.colId] === CUSTOM_INPUT_TYPE.DATE) {
              copyFormData[item.colId] = new Date(item.value);
            } else {
              copyFormData[item.colId] = item.value;
            }
          });
          setFormData((prev) => ({ ...prev, ...copyFormData }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const handleFileSelect = (e) => {
    if (!e.target.files && !browseAttachmentColumnRef.current) return;
    const files: File[] = Array.from(e.target.files);
    const fileExceedingLimit = files.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (fileExceedingLimit.length) {
      showAlert(
        'File size exceeds! ',
        `File size should not be more than ${getHumanReadableFileSize(
          MAX_FILE_SIZE
        )}. Please optimize <em>${fileExceedingLimit
          .map((file) => file.name)
          .join(', ')}</em> or select different file.`
      );
      return;
    }
    showToast('Uploading attachments...');
    const columnKey = (browseAttachmentColumnRef?.current as any)?.key;
    Promise.all(files.map((file) => uploadFileToAWS(file)))
      .then((responseList) => {
        let hasValidAttachment = false;
        let encodedAttachment = null;
        encodedAttachment = formData[columnKey];
        let attachments = Utility.isEmptyObject(encodedAttachment)
          ? []
          : Utility.decodeJSON(encodedAttachment);

        responseList.forEach((attachmentPath, index) => {
          const file = files[index];
          if (
            !Utility.isEmptyObject(attachmentPath) &&
            file &&
            isString(attachmentPath)
          ) {
            attachments.push({
              path: attachmentPath,
              title: file.name,
              type: file.type
            });
            hasValidAttachment = true;
          }
        });

        if (!hasValidAttachment) return;
        onChange(
          browseAttachmentColumnRef.current,
          Utility.encodeJSON(attachments)
        );
        if (
          !Utility.isEmptyObject(
            (browseAttachmentColumnRef?.current as any)?.aiConfig
          )
        ) {
          fetchAIDataForAttachment(responseList);
        }
      })
      .catch((err) => {
        console.error('Error while attaching files ', err);
      })
      .finally(() => {});
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const validateFormData = (payload: any) => {
    let result = true;
    for (let i = 0; i < formConfigData?.fields.length; i++) {
      let element = formConfigData?.fields[i];
      if (element.required) {
        if (!payload[element.key]) {
          result = false;
          break;
        }
      }
    }
    return result;
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const handleFormSubmit = async () => {
    setCanValidate(true);
    const transformedData = transformData(formData, columns);

    if (isBulkUpdateSelected) {
      Object.keys(transformedData).forEach((key) => {
        if (
          transformedData[key] === null ||
          transformedData[key] === undefined ||
          (Array.isArray(transformedData[key]) &&
            transformedData[key].length <= 0)
        ) {
          delete transformedData[key];
        }
      });
    }

    if (
      (!Utility.isEmptyObject(transformedData) &&
        validateFormData(transformedData)) ||
      isBulkUpdateSelected
    ) {
      let response: any;
      if (isBulkUpdateSelected && selectedRecordIds.length) {
        response = await bulkUpdateRecords(transformedData);
      } else {
        response = await addUpdateRecord(
          { cells: transformedData },
          record?._id
        );
      }
      onSave(response);
    } else {
      // showAlert('Error', 'Please fill required data');
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const addUpdateRecord = async (record, recordId = null) => {
    try {
      let response;
      if (recordId) {
        response = await httpClient.put(
          API_ENDPOINTS.RECORDS.UPDATE(tableId, recordId),
          record
        );
      } else {
        response = await httpClient.post(
          API_ENDPOINTS.RECORDS.ADD(tableId),
          record
        );
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const bulkUpdateRecords = async (transformedData) => {
    let payload = selectedRecordIds.map((recordId) => {
      return {
        _id: recordId,
        cells: transformedData
      };
    });
    try {
      const response = await httpClient.put(
        API_ENDPOINTS.RECORDS.BULK_UPDATE(tableId),
        payload
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderInput = (index, field) => {
    return (
      <DKInput
        valueStyle={{
          ...{
            width: isFieldTypeNeedsDropdown(field?.type) ? 'auto' : null,
            paddingTop: isFieldTypeNeedsDropdown(field?.type) ? 0 : null,
            paddingBottom: isFieldTypeNeedsDropdown(field?.type) ? 0 : null
          },
          ...getDKInputValueStyle()
        }}
        canValidate={canValidate}
        autoFocus={index === 0}
        icon={field?.icon}
        title={isFieldTypeNeedsDropdown(field?.type) ? null : field?.title}
        placeholder={
          isFieldTypeNeedsDropdown(field?.type)
            ? field?.title
            : 'Add ' + field?.title.toLowerCase()
        }
        value={formData[field?.key]}
        options={field?.options}
        type={field?.type}
        validator={field?.validator}
        tooltip={field?.tooltip}
        dropdownConfig={field?.dropdownConfig}
        formatter={field?.formatter}
        onChange={(value) => onChange(field, value)}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={
          isBulkUpdateSelected
            ? false
            : field?.required
            ? field?.required
            : field?.requiredByUser ?? false
        }
        readOnly={field?.readOnly || false}
      />
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderAttachmentSection = (field) => {
    return (
      <div
        className="row-responsive justify-content-between align-items-start position-relative bg-white border-s border-radius-s-2 mt-l overflow-hidden"
        style={getDKInputValueStyle()}
      >
        <div className="row p-v-s">
          <DKButton
            icon={AppIcons.ic_attachment}
            className=" p-0"
            title={'Add ' + field?.title}
            onClick={(e) => {
              browseAttachmentColumnRef.current = null;
              e?.stopPropagation?.();
              addAttachment(field);
            }}
          />
        </div>
        <div className="row parent-width flex-wrap p-h-s " style={{ gap: 10 }}>
          {renderAttachment(field)}
        </div>
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderAutoFillButton = (field) => {
    return (
      <DKButton
        title="Autofill other fields"
        className={`text-white mt-s ${
          isAIGenerating.fieldName === field?.key && isAIGenerating.showLoader
            ? 'bg-gray1'
            : 'bg-button'
        }`}
        style={{ paddingTop: 3, paddingBottom: 3 }}
        onClick={async () => {
          if (
            isAIGenerating.fieldName === field?.key &&
            isAIGenerating.showLoader
          ) {
            return;
          }
          if (Utility.isEmptyObject(formData?.[field?.key])) {
            showAlert('Error', 'Please enter text to continue');
            return;
          }
          setIsAIGenerating({
            fieldName: field?.key,
            showLoader: true
          });
          let payload = {
            tableId: tableId,
            columnId: field?.key,
            url: formData?.[field?.key],
            text: formData?.[field?.key]
          };
          if (Utility.checkValidUrl(formData?.[field?.key])) {
            delete payload.text;
          } else {
            delete payload.url;
          }
          let autoFillData = await httpClient
            .post(ApiConstants.URL.TABLE.AUTO_FILL_DATA, payload)
            .then((response: any) => {
              setIsAIGenerating({
                fieldName: '',
                showLoader: false
              });
              let copyFormData = { ...formData };
              if (response?.data) {
                let columnToTypeMapping: any = {};
                columns.forEach((column: any) => {
                  columnToTypeMapping[column.id] = column.type;
                });
                response.data.forEach((item: any) => {
                  if (
                    columnToTypeMapping[item.colId] === CUSTOM_INPUT_TYPE.DATE
                  ) {
                    copyFormData[item.colId] = new Date(item.value);
                  } else {
                    copyFormData[item.colId] = item.value;
                  }
                });
                setFormData((prev) => ({
                  ...prev,
                  ...copyFormData
                }));
              }
            })
            .catch((error) => {
              setIsAIGenerating({
                fieldName: '',
                showLoader: false
              });
              console.log(error);
            });
          console.log(autoFillData);
        }}
      />
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const fieldsToShow = Utility.isEmptyObject(formConfigData?.fields)
    ? []
    : [
        ...formConfigData?.fields.filter((field) => {
          return !isFieldTypeNeedsDropdown(field?.type);
        })
      ];

  const drowpdownAndDateFields = Utility.isEmptyObject(formConfigData?.fields)
    ? []
    : [
        ...formConfigData?.fields.filter((field) => {
          return isFieldTypeNeedsDropdown(field?.type);
        })
      ];

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderGridTypeField = (field) => {
    return (
      <AddEditRecordFormDKTableView
        field={field}
        formData={formData}
        onChange={(rows: any) => {
          if (rows && rows.length > 0) {
            let formDataCopy = { ...formData };
            formDataCopy[`${field.id}_detail`] = rows.map((item: any) => {
              let newItem: any = { _id: item?._id ?? null };
              delete item._id;
              newItem.cells = item;
              return newItem;
            });
            setFormData(formDataCopy);
          }
        }}
      />
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Popup
        className=""
        popupWindowStyles={{
          maxHeight: '96%',
          width: '90%',
          maxWidth: 600,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        {renderHeader()}
        {!formBeingFetched.current && (
          <div className="p-h-xl">
            {fieldsToShow?.map((field, index) => {
              switch (field?.type) {
                case CUSTOM_INPUT_TYPE.TABLE:
                  if (!isBulkUpdateSelected) {
                    return renderGridTypeField(field);
                  } else {
                    return;
                  }
                case CUSTOM_INPUT_TYPE.FILE:
                  return renderAttachmentSection(field);
                default:
                  return (
                    <div className="column parent-width">
                      <div
                        className="column mt-l"
                        style={{
                          ...field?.style,
                          gap: 6,
                          width: `${field?.style?.width ?? 100}%`
                        }}
                      >
                        {renderInput(index, field)}
                      </div>
                      <div className="row width-auto position-relative">
                        {field?.type === CUSTOM_INPUT_TYPE.TEXT &&
                          !Utility.isEmptyObject(field?.aiConfig) &&
                          !Utility.isEmptyObject(formData[field?.key]) &&
                          renderAutoFillButton(field)}
                        {isAIGenerating.fieldName === field?.key &&
                          isAIGenerating.showLoader && (
                            <DKSpinner className="row position-absolute right-14 bg-gray1 opacity-40 parent-size" />
                          )}
                      </div>
                    </div>
                  );
              }
            })}

            <div
              className="row mt-xl gap-3  flex-shrink-0 flex-wrap"
              id="dk-input-holder"
            >
              {drowpdownAndDateFields?.map((field, index) => {
                return (
                  <div className="column">{renderInput(index, field)}</div>
                );
              })}
            </div>
            {/* <div className="pb-r" /> */}
          </div>
        )}
        {formBeingFetched.current && (
          <div className="row parent-height align-items-center justify-content-center">
            <DKSpinner title="Loading..." />
          </div>
        )}

        <div className="row bg-gray2 mt-xl" style={{ height: 1 }} />

        <div className="row-reverse mt-l p-h-xl">
          <DKButton
            title="Save changes"
            className="bg-button text-white ml-s"
            onClick={handleFormSubmit}
          />
          <DKButton title="Cancel" onClick={onCancel} />
        </div>
      </Popup>
      {getAttachmentPicker({
        ref: attachmentOpenFileRef,
        onChange: (e) => {
          handleFileSelect(e);
        },
        onClick: (e) => {
          initializeImageSelect();
          e.stopPropagation();
        }
      })}
    </>
  );
};

export default AddNewRecord;
