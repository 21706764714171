import { DKIcon } from 'deskera-ui-library';
import { isMobileAppWebView } from '../../utility/GetViewportSize';
import AppIcons from '../../assets/icons/AppIcons';
import { useClickOutSide } from '../../hooks/ClickOutside';
import { useRef } from 'react';

export default function Popup(props: PopupProps) {
  const ref = useRef();
  const onClose = () => {
    if (props.onClose) props.onClose();
  };

  useClickOutSide(ref, () => {
    if (!!props.closeOnOutsideClick) onClose();
  });

  return (
    <div className="transparent-background fade-animation">
      <div
        ref={ref}
        id="dk-form-popup-window "
        className="popup-window prompt-editor-shadow fade-animation "
        style={{
          marginTop: isMobileAppWebView() ? 24 : 0,
          padding: 20,
          borderRadius: 10,
          overflow: props.isScrollable ? 'visible' : 'auto',
          ...(props.popupWindowStyles || {})
        }}
      >
        <div
          className={`popup-wrapper parent-width parent-height ${props.className}`}
          style={props.style}
        >
          {props.title && props.allowClose && (
            <div className="popup-header row parent-width justify-content-between">
              {props.title && (
                <div className="column fw-m fs-m ">{props.title}</div>
              )}
              {props.allowClose && (
                <div className="column">
                  <DKIcon
                    src={AppIcons.ic_close}
                    className="ic-s cursor-hand"
                    onClick={() => onClose()}
                  />
                </div>
              )}
            </div>
          )}
          <div className="popup-body">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export interface PopupProps {
  closeOnOutsideClick?: boolean;
  title?: string;
  allowClose?: boolean;
  onClose?: any;
  className?: string;
  style?: any;
  children?: any;
  overflowType?: any;
  popupWindowStyles?: any;
  isScrollable?: boolean;
}
