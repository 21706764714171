import {
  DKButton,
  DKCheckMark,
  DKIcons,
  DKLabel,
  DKListPicker2,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import AppIcons from '../../assets/icons/AppIcons';
import Utility, { getRandomHexString } from '../../utility/Utility';

interface IContextMenuProps {
  columnData: any;
  tableName: string;
  maxWidth: number;
  displayTableName: string;
  className?: string;
  onExport?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onApply?: (data) => void;
  onAdvanceBulkEdit?: () => void;
  allowBulkDelete?: boolean;
  allowAdvancedBulkEdit?: boolean;
  allowBulkExport?: boolean;
  allowActive?: boolean;
  allowInActive?: boolean;
  onActiveApply?: () => void;
  onInActiveApply?: () => void;
}
const ContextMenu = (props: IContextMenuProps) => {
  const wrapperRef = useRef<any>();
  let columnsToShow =
    props.columnData.filter(
      (column) =>
        (column.type === INPUT_TYPE.SELECT ||
          column.type === INPUT_TYPE.DROPDOWN ||
          column.type === INPUT_TYPE.MULTI_SELECT) &&
        column.editable &&
        !column.refTable
    ) || [];
  let filteredColumns = Utility.deepCloneObject(columnsToShow);
  filteredColumns?.forEach((column) => {
    if (!Utility.isEmptyObject(column.options)) {
      column.options = column.options?.filter(
        (option) => option?.name && !Utility.isEmptyObject(option?.name)
      );
    }
  });
  filteredColumns = Utility.deepCloneObject(filteredColumns);

  let [count, setCount] = useState(0);
  let [columns, setColumns] = useState([]);
  let [opData, setOpData] = useState({});

  useEffect(() => {
    setColumns(Utility.deepCloneObject(filteredColumns));
  }, [count]);

  const onSelect = (column, data) => {
    let newOpData = opData;
    opData[column.id] = data;
    setOpData(newOpData);
    setCount((prevCount) => prevCount + 1);
  };

  const onApply = () => {
    if (props.onApply) props.onApply(opData);
  };
  const isColumnAllowed = (column) => true;
  let tableName = props.tableName;

  return (
    <div
      className="row parent-width mt-xs"
      style={{
        overflowX: 'hidden',
        maxWidth: props.maxWidth || '90%',
        minHeight: 37
      }}
    >
      <DKLabel
        text={props.tableName}
        className="fw-m mr-l text-align-left white-space-no-wrap"
      />
      <div
        className="row width-auto hide-scroll-bar"
        style={{
          overflowX: 'auto'
        }}
        ref={wrapperRef}
      >
        {columns.map((column: any) => {
          /**@description- some columns are not allowed in the context menu that columnCode must be added in the COLUMNS_TO_HIDE_IN_CONTEXT_MENU*/
          if (!isColumnAllowed(column)) return null;
          const id = getRandomHexString(8);
          switch (column.type) {
            case INPUT_TYPE.MULTI_SELECT:
              return (
                <MultiSelectList
                  wrapperRef={wrapperRef}
                  {...column}
                  id={id}
                  className={`mr-r `}
                  onSelect={(data) => onSelect(column, data)}
                />
              );
            case INPUT_TYPE.SELECT:
              return (
                <SelectList
                  wrapperRef={wrapperRef}
                  {...column}
                  id={id}
                  className={`mr-r `}
                  onSelect={(data) => onSelect(column, [data])}
                />
              );
            case INPUT_TYPE.DROPDOWN:
              return (
                <DropDownList
                  wrapperRef={wrapperRef}
                  {...column}
                  id={id}
                  className={`mr-r `}
                  onSelect={(data) => onSelect(column, [data])}
                />
              );
          }
        })}
        {props.allowAdvancedBulkEdit && (
          <DKButton
            title="Advanced"
            icon={AppIcons.ic_apply}
            className="border-m mr-r bg-white"
            onClick={() =>
              props.onAdvanceBulkEdit ? props.onAdvanceBulkEdit() : false
            }
          />
        )}
        {props.allowBulkDelete && (
          <DKButton
            title="Delete"
            icon={AppIcons.red.ic_delete}
            className=" mr-r border-red text-red bg-white"
            onClick={() => (props.onDelete ? props.onDelete() : false)}
          />
        )}
        {props.allowBulkExport && (
          <DKButton
            title="Export"
            className="border-m bg-white mr-r"
            onClick={() => (props.onExport ? props.onExport() : false)}
          />
        )}
        {Object.keys(opData).length > 0 && (
          <DKButton
            title="Apply"
            icon={AppIcons.white.ic_checkmark}
            className="border-m mr-r bg-button text-white"
            onClick={() => onApply()}
          />
        )}
        {props.allowActive && (
          <DKButton
            title="Active"
            className="border-green mr-r bg-green text-white"
            onClick={() => props.onActiveApply?.()}
          />
        )}
        {props.allowInActive && (
          <DKButton
            title="Inactive"
            className="border-red mr-r bg-red text-white"
            onClick={() => props.onInActiveApply?.()}
          />
        )}
        <DKButton
          icon={AppIcons.ic_close}
          className="ic-m cursor-hand border-m mr-r bg-white"
          onClick={() => (props.onCancel ? props.onCancel() : false)}
        />
      </div>
    </div>
  );
};
const SelectList = (props: any) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState(props.options);
  const buttonRef = useRef<any>();
  useEffect(() => {
    document.addEventListener('onContextMenuClick', onClick);
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('onContextMenuClick', onClick);
    };
  });
  const onOptionSelect = (index) => {
    let newOptions = [...options].filter((item, i) => {
      item.selected = false;
      if (index === i) {
        item.selected = true;
        props.onSelect(item);
      }
      return item;
    });
    setOptions(newOptions);
    setShowList(false);
  };
  const getOptionList = () => {
    return (
      <div
        id={props.id}
        className="option-list-wrapper column bg-white p-v-s shadow-l"
        style={{
          position: 'absolute',
          zIndex: 2,
          maxWidth: 200,
          left:
            (buttonRef?.current?.offsetLeft || 0) -
            (props?.wrapperRef?.current?.scrollLeft || 0)
        }}
      >
        {options.map((option, index) => {
          return (
            <div
              className="p-h-r cursor-hand row listPickerBG"
              style={{ paddingTop: 6, paddingBottom: 6 }}
              onClick={() => onOptionSelect(index)}
            >
              <DKLabel
                text={option.name}
                className="fw-r"
                style={{
                  overflowWrap: 'break-word',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const onClick = (e) => {
    if (e.target !== document.getElementById(props.id)) {
      setShowList(false);
    }
  };
  const onButtonClick = (e) => {
    document.dispatchEvent(
      new CustomEvent('onContextMenuClick', { detail: { id: props.id } })
    );
    e.stopPropagation();
    setShowList(!showList);
  };
  return (
    <div
      className={`multi-select-list parent-width  postion-relative ${props.className}`}
      style={props.style}
      ref={buttonRef}
    >
      <DKButton
        id={props.id + 'btn'}
        className={`border-m bg-white shadow-s ${
          options.find((i) => i.selected) ? '' : ''
        }`}
        icon={showList ? AppIcons.ic_arrow_down : AppIcons.ic_arrow_down}
        isReverse={true}
        onClick={(e) => onButtonClick(e)}
        title={
          options.find((i) => i.selected)
            ? options.find((i) => i.selected).name
            : props.name
        }
      />
      {showList && options.length > 0 && getOptionList()}
    </div>
  );
};
const MultiSelectList = (props: any) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState(props.options);
  const buttonRef = useRef<any>();

  const onOptionSelect = (index) => {
    let newOptions = [...options];
    newOptions[index]['selected'] = !newOptions[index]['selected'];
    setOptions(newOptions);
    props.onSelect(newOptions.filter((option) => option.selected));
  };
  useEffect(() => {
    document.addEventListener('onContextMenuClick', onClick);
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('onContextMenuClick', onClick);
    };
  });
  const onClick = (e) => {
    if (
      (e.detail && e.detail.id !== props.id) ||
      e.target !== document.getElementById(props.id)
    ) {
      setShowList(false);
    }
  };
  const onButtonClick = (e) => {
    document.dispatchEvent(
      new CustomEvent('onContextMenuClick', { detail: { id: props.id } })
    );
    e.stopPropagation();
    setShowList(!showList);
  };
  const getOptionList = () => {
    return (
      <div
        id={props.id}
        className="option-list-wrapper column bg-white shadow-l p-v-s"
        style={{
          position: 'absolute',
          zIndex: 2,
          left:
            (buttonRef?.current?.offsetLeft || 0) -
            (props?.wrapperRef?.current?.scrollLeft || 0)
        }}
      >
        {options.map((option, index) => {
          return (
            <div
              className="p-h-s cursor-hand row listPickerBG"
              style={{ paddingTop: 5, paddingBottom: 5 }}
              onClick={() => onOptionSelect(index)}
            >
              <DKCheckMark isSelected={option.selected} className="mr-r" />
              <DKLabel text={option.name} className="fs-r fw-r " />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`multi-select-list parent-width  ${props.className}`}
      style={props.style}
      ref={buttonRef}
    >
      <DKButton
        id={props.id + 'btn'}
        className={`border-m bg-white shadow-s ${
          options.find((i) => i.selected) ? 'border-blue text-blue' : ''
        }`}
        icon={showList ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
        isReverse={true}
        onClick={(e) => onButtonClick(e)}
        title={
          options.find((i) => i.selected)
            ? options.find((i) => i.selected).name
            : props.name
        }
      />
      {showList && options.length > 0 && getOptionList()}
    </div>
  );
};

const DropDownList = (props: any) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    document.addEventListener('onContextMenuClick', onClick);
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('onContextMenuClick', onClick);
    };
  });
  const buttonRef = useRef<any>();

  const setOptionToBlank = () => {
    if (!Utility.isEmptyObject(searchText)) {
      setSearchText('');
      setOptions([]);
    }
  };

  const onOptionSelect = (index, obj, colId) => {
    props.onSelect({ id: obj._id });
    setSelectedValue(obj.cells[colId]);
    setOptionToBlank();
    setShowList(false);
  };

  const getDropdown = (propsData) => {
    return (
      <div id={props.id}>
        <DKListPicker2
          onChange={() => {}}
          onSelect={(value, selectedObj) => {}}
          data={options}
          renderer={(index, obj) => {
            return (
              <div
                style={{
                  overflowWrap: 'break-word',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {JSON.stringify(obj)}
              </div>
            );
          }}
          style={{
            maxWidth: 250,
            left:
              (buttonRef?.current?.offsetLeft || 0) -
              (props?.wrapperRef?.current?.scrollLeft || 0)
          }}
          allowSearch={propsData.dropdownConfig.allowSearch}
          title={propsData.dropdownConfig.title}
          className="position-absolute z-index-3 shadow-m border-s"
          searchableKey={propsData.dropdownConfig.searchableKey}
          searchApiConfig={{
            method: propsData.dropdownConfig.searchApiConfig.method || 'GET',
            getUrl: () => '',
            // propsData.dropdownConfig.searchApiConfig?.url
            //   ? propsData.dropdownConfig.searchApiConfig?.url
            //   : `${
            //       ApiConstants.URL.BASE
            //     }${ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
            //       getTableId
            //     )}?pageNo=1&pageSize=20`,
            getPayload: (search) => {
              setSearchText(search);
              return {
                logicalOperator: 'and',
                conditions: [
                  {
                    // colId: getColumnId,
                    colId: '',
                    opr: 'c',
                    value: search
                  }
                ]
              };
            },
            dataParser: (response) => {
              // let options =
              //   response?.data?.filter(
              //     (option) =>
              //       !Utility.isEmptyObject(option?.cells?.[getColumnId])
              //   ) || [];
              // setOptions(options);
              return response?.data;
            },
            debounceTime: 300
          }}
          onClose={() =>
            setTimeout(() => {
              setOptionToBlank();
              setShowList(false);
            }, 10)
          }
        />
      </div>
    );
  };
  const onClick = (e) => {
    if (
      (e.detail && e.detail.id !== props.id) ||
      e.target !== document.getElementById(props.id)
    ) {
      setOptionToBlank();
      setShowList(false);
    }
  };
  const onButtonClick = (e) => {
    document.dispatchEvent(
      new CustomEvent('onContextMenuClick', { detail: { id: props.id } })
    );
    e.stopPropagation();
    if (!showList) {
      setOptionToBlank();
    }
    setShowList(!showList);
  };
  return (
    <div
      className={`dropdown-select-list parent-width border-radius-m bg-white ${props.className}`}
      style={props.style}
      ref={buttonRef}
    >
      <DKButton
        id={props.id + 'btn'}
        className={`border-m bg-white shadow-s ${
          !Utility.isEmptyObject(selectedValue) ? 'border-blue text-blue' : ''
        }`}
        icon={showList ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
        isReverse={true}
        onClick={(e) => onButtonClick(e)}
        title={
          !Utility.isEmptyObject(selectedValue) ? selectedValue : props.name
        }
      />
      {showList && getDropdown(props)}
    </div>
  );
};
export default ContextMenu;
