import {
  DKButton,
  DKIcon,
  DKInput,
  DKInputForm,
  DKLabel,
  DKSpinner,
  showAlert,
  DKCheckMark
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppIcons from '../../assets/icons/AppIcons';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import httpClient from '../../http';
import {
  PAGE_ROUTES,
  ROUTE_APP_DETAIL_ID_IDENTIFIER
} from '../../managers/RouteManager';
import {
  deleteApplication,
  deleteTemplate,
  fetchApplications,
  fetchTemplates,
  selectAppState,
  updateTemplate
} from '../../redux/slices/applicationSlice';
import Popup from '../common/Popup';
import ApplicationCard from './ApplicationCard';

import { CARD_ACTION } from '../../constants/Enum';
import { useAppSelector } from '../../redux/hooks';
import { selectAppInfo, setAppInfo } from '../../redux/slices/CommonSlice';
import Utility, { changeTheme, isNotEmptyObject } from '../../utility/Utility';
import EditApplicationAndTemplate from '../prompt-editor/EditApplicationAndTemplate';
import ThemePopup from '../prompt-editor/ThemePopup';

import ic_no_data from '../../assets/icons/ic_no_data.png';
import animationData from '../../assets/lottie/Animation - 1727086246021.json'; //
import { LIST_MODULE } from '../../constants/Enum';
import { initialAPIParams } from '../../model/Api';
import { useDebounce } from '../../utility/Debounce';
import LottiePlayer from '../LottiePlayer';
import InstallAppPopup from '../prompt-editor/InstallAppPopUp';
import LoadingAnimation from '../prompt-editor/LoadingAnimation';
import CreateApplicationPopup from './CreateApplicationPopup';

const ApplicationList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appInformation = useAppSelector(selectAppInfo);
  const [activePopup, setActivePopup] = useState({
    isOpen: false,
    type: null,
    app: null
  });
  const [searchTerm, setSearchTerm] = useState('');
  const { applicationData, templatesData, isLoading, error } = useAppSelector(
    selectAppState()
  );
  const [recordsArray, setRecordsArray] = useState([]);
  const [themeColor, setThemeColor] = useState('rgb(29, 29, 31)');
  const [pageParams, setPageParam] = useState(initialAPIParams);
  const [myTemplatesChecked, setMyTemplatesChecked] = useState(false);
  const [isPublishedChecked, setIsPublishedChecked] = useState(false);
  const ref = useRef();
  const intersected = useRef(false);
  let debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [needAppCreateAnimation, setAnimationVisibility] = useState(false);
  const [needLoadingAnimation, setLoadingAnimationVisibility] = useState(false);
  const [showInstallAppPopup, setShowInstallAppPopup] = useState(false);
  const [currentApp, setCurrentApp] = useState();

  const [showCreateApplication, setShowCreateApplication] = useState(false);

  const callFetchApplicationList = () => {
    dispatch(
      fetchApplications({
        ...pageParams,
        q: debouncedSearchTerm
      })
    ).then(() => {
      intersected.current = false;
    });
  };

  const callFetchTemplatesList = () => {
    dispatch(
      fetchTemplates({
        ...pageParams,
        q: debouncedSearchTerm,
        createdByUser: myTemplatesChecked,
        published: isPublishedChecked ? true : ''
      })
    ).then(() => {
      intersected.current = false;
    });
  };

  useEffect(() => {
    if (!isLoading && props.listModule === LIST_MODULE.MARKETPLACE) {
      callFetchTemplatesList();
    }
  }, [isPublishedChecked, myTemplatesChecked]);

  useEffect(() => {
    if (!isLoading) {
      if (props.listModule === LIST_MODULE.APPLICATIONS) {
        callFetchApplicationList();
      } else if (props.listModule === LIST_MODULE.MARKETPLACE) {
        callFetchTemplatesList();
      }
    }
  }, [debouncedSearchTerm, pageParams]);

  useEffect(() => {
    changeTheme(themeColor);
  }, []);

  useEffect(() => {
    createObserver();
  }, [recordsArray]);

  useEffect(() => {
    if (applicationData?.data || templatesData?.data) {
      const items =
        props.listModule === LIST_MODULE.APPLICATIONS
          ? applicationData?.data
          : props.listModule === LIST_MODULE.MARKETPLACE
          ? templatesData?.data
          : [];
      setRecordsArray(items);
    }
  }, [applicationData, templatesData]);

  const createObserver = () => {
    const options = {
      root: document.querySelector('#application-wrapper'),
      rootMargin: '1px',
      threshold: 1
    };
    let observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.target === ref.current && entry.isIntersecting) {
        onIntersectBottom();
      }
    }, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
  };
  const onIntersectBottom = () => {
    let totalCount =
      props.listModule === LIST_MODULE.APPLICATIONS
        ? applicationData.totalCount
        : templatesData.totalCount;
    if (
      !intersected.current &&
      !isLoading &&
      (totalCount > recordsArray?.length ?? 0)
    ) {
      intersected.current = true;
      setPageParam((pre) => ({ ...pre, pageSize: pre.pageSize + 20 }));
    }
  };

  const fetchData = (searchTerm) => {
    switch (props.listModule) {
      case LIST_MODULE.APPLICATIONS:
        dispatch(fetchApplications({ ...pageParams, q: searchTerm }));
        break;
      case LIST_MODULE.MARKETPLACE:
        dispatch(
          fetchTemplates({
            ...pageParams,
            q: searchTerm,
            createdByUser: myTemplatesChecked,
            published: isPublishedChecked
          })
        );
        break;
      default:
        console.log('List Module type is not found');
    }
  };

  const handleCardClick = (appId) => {
    dispatch(setAppInfo({ appId: appId, isPublishedApp: false }));
    if (appInformation.isPublishedApp) {
      navigate(PAGE_ROUTES.APP_DETAILS);
    } else {
      let url = PAGE_ROUTES.APP_DETAILS_ADMIN.replace(
        ROUTE_APP_DETAIL_ID_IDENTIFIER,
        appId
      );
      navigate(url);
    }
  };

  const editTemplateById = async (app) => {
    let req = {
      _id: app._id,
      appId: app.appId,
      name: app.name,
      description: app.description,
      logoData: app.logoData
    };
    try {
      const actionResult = await dispatch(updateTemplate(req));
      if (updateTemplate.fulfilled.match(actionResult)) {
        await fetchData(searchTerm);
      }
      closePopup();
    } catch (error) {
      showAlert('Failed to rename application');
    }
  };

  const handleEditClick = (app, newName) => {
    if (props.listModule === LIST_MODULE.APPLICATIONS) {
      fetchData(searchTerm);
      closePopup();
    } else if (props.listModule === LIST_MODULE.MARKETPLACE) {
      editTemplateById(app);
    }
  };

  const copyApplication = (appId, newName) => {};

  const copyTemplate = (appId, newName) => {
    installAppAPICall(newName, appId);
  };

  const handleCopyClick = async (appId, newName) => {
    if (props.listModule === LIST_MODULE.APPLICATIONS) {
      copyApplication(appId, newName);
    } else if (props.listModule === LIST_MODULE.MARKETPLACE) {
      copyTemplate(appId, newName);
    }
  };

  const deleteApp = async (appId) => {
    try {
      closePopup();
      const actionResult = await dispatch(deleteApplication(appId));
      if (deleteApplication.fulfilled.match(actionResult)) {
        await fetchData(searchTerm);
      }
    } catch (error) {
      showAlert('Failed to delete application');
    }
  };
  const deleteTemplateBYId = async (templateId) => {
    try {
      closePopup();
      const actionResult = await dispatch(deleteTemplate(templateId));
      if (deleteTemplate.fulfilled.match(actionResult)) {
        await fetchData(searchTerm);
      }
    } catch (error) {
      showAlert('Failed to delete application');
    }
  };
  const handleDeleteClick = (appId) => {
    if (props.listModule === LIST_MODULE.APPLICATIONS) {
      deleteApp(appId);
    } else if (props.listModule === LIST_MODULE.MARKETPLACE) {
      deleteTemplateBYId(appId);
    }
  };

  const openPopup = (type, app) => {
    setActivePopup({ isOpen: true, type, app });
  };

  const closePopup = () => {
    setActivePopup({ isOpen: false, type: null, app: null });
  };

  const renderRightSection = () => {
    return (
      <div className="row justify-content-end">
        {props.listModule === LIST_MODULE.MARKETPLACE && (
          <DKCheckMark
            className="mr-r border border-radius-m p-h-s p-v-s bg-white"
            isSelected={myTemplatesChecked}
            onClick={(value) => setMyTemplatesChecked(!myTemplatesChecked)}
            title="My Templates"
            color="bg-button"
          />
        )}
        {myTemplatesChecked && props.listModule === LIST_MODULE.MARKETPLACE && (
          <DKCheckMark
            className="mr-r border border-radius-m p-h-s p-v-s bg-white"
            isSelected={isPublishedChecked}
            onClick={(value) => setIsPublishedChecked(!isPublishedChecked)}
            title="Published"
            color="bg-button"
          />
        )}
        <div
          className="row align-items-center bg-white border-m border-radius-s-2 p-h-s "
          style={{ maxWidth: 230, maxHeight: 30 }}
        >
          <DKIcon src={AppIcons.ic_search} className="ic-s" />
          <DKInput
            placeholder="Search by app title..."
            value={searchTerm}
            valueStyle={{
              backgroundColor: 'rgba(0,0,0,0)',
              border: null
            }}
            onChange={setSearchTerm}
          />
        </div>

        {/* <div className="row bg-white p-h-s p-v-s border-m border-radius-m width-auto ml-r">
          <DKIcon src={AppIcons.ic_grid} className="ic-s-2" />
          <DKIcon src={AppIcons.ic_list} className="ic-s-2 ml-r" />
        </div> */}
        {props.listModule === LIST_MODULE.APPLICATIONS && (
          <DKButton
            title="+ New Application"
            className="bg-button text-white ml-r"
            onClick={() => {
              setShowCreateApplication(true);
              // setNewAppFormVisibility(true);
            }}
          />
        )}
      </div>
    );
  };

  const appCreated = (appId) => {
    setAnimationVisibility(true);
    setTimeout(() => {
      showAlert(
        'Congratulations!',
        'Your template has been installed successfully.',
        [
          { title: 'Back', className: 'bg-gray1 border-m' },
          {
            title: 'Go to App',
            className: 'bg-button text-white ml-r',
            onClick: () => {
              dispatch(setAppInfo({ appId: appId, isPublishedApp: false }));
              navigate(`/apps/${appId}`);
            }
          }
        ]
      );
    }, 1000);
    setTimeout(() => {
      setAnimationVisibility(false);
    }, 5000);
  };

  const callInstallTemplate = (appObj) => {
    setShowInstallAppPopup(false);
    setLoadingAnimationVisibility(true);
    installAppAPICall(appObj.name, appObj._id);
  };

  const installAppClicked = async (app) => {
    setCurrentApp(app);
    showAlert('Confirm', `Do you want to install <b> ${app.name} </b>.`, [
      {
        title: 'Cancel',
        className: 'bg-gray1 text-black mr-m',
        onClick: () => {}
      },
      {
        title: 'Install',
        className: 'bg-button text-white',
        onClick: () => {
          callInstallTemplate(app);
        }
      }
    ]);
    // setShowInstallAppPopup(true);
  };

  const getTitle = () => {
    if (props.listModule === LIST_MODULE.APPLICATIONS) {
      return 'My Applications';
    } else if (props.listModule === LIST_MODULE.MARKETPLACE) {
      return 'Templates';
    }
    return 'Items';
  };

  const installAppAPICall = async (name, id) => {
    try {
      const response = await httpClient.post(
        API_ENDPOINTS.TEMPLATE.INSTALL_TEMPLATE(id),
        {
          name: name
        }
      );
      setLoadingAnimationVisibility(false);
      appCreated(response.id);
    } catch (error) {
      setLoadingAnimationVisibility(false);
    }
  };

  const getNoDataView = () => {
    let noDataTitle = '';
    let noDataText = '';

    if (props.updating) {
      noDataTitle = 'Loading...';
      noDataText = 'Please wait';
    } else {
      noDataTitle = props.noDataTitle
        ? props.noDataTitle
        : 'No application found';
      noDataText = props.noDataText
        ? props.noDataText
        : 'Once applications are available, it will appear here';
    }

    return (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute"
        style={{ top: '40%', pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={noDataTitle} className="fw-m mt-l" />
        <DKLabel text={noDataText} className="text-gray mt-s" />
      </div>
    );
  };

  return (
    <>
      <div
        className="column parent-width pt-s pb-xxl screen-height"
        style={{ paddingLeft: 50, paddingRight: 50 }}
      >
        {needLoadingAnimation && <LoadingAnimation />}
        <div className="row justify-content-between mb-xl">
          <div className="row align-items-start">
            <DKLabel
              text={getTitle()}
              className="fw-m fs-xxl"
              // style={{ minWidth: 300 }}
            />
            {isLoading && <DKSpinner className="ml-l" />}
          </div>

          {renderRightSection()}
        </div>
        {Utility.isEmptyObject(recordsArray) && getNoDataView()}
        {isLoading && Utility.isEmptyObject(recordsArray) && (
          <div className="row parent-size justify-content-center align-items-center">
            <DKSpinner title="Loading application list..." />
          </div>
        )}

        {isNotEmptyObject(recordsArray) && (
          <div
            id="application-wrapper"
            className="scroll-y-only-web pr-xs hide-scroll-bar parent-size"
            style={{ paddingBottom: 100 }}
          >
            <div className="grid grid-cols-2 gap-5 w-full xl:grid-cols-4  xxl:grid-cols-5">
              {recordsArray?.map((app) => (
                <ApplicationCard
                  listModule={props.listModule}
                  key={app._id}
                  app={app}
                  onCardClick={(appId) => {
                    if (props.listModule === LIST_MODULE.APPLICATIONS) {
                      handleCardClick(appId);
                    } else if (props.listModule === LIST_MODULE.MARKETPLACE) {
                      process.env.REACT_APP_GEN_LP
                        ? window.open(`/marketplace/${appId}`, '_blank')
                        : installAppClicked(app);
                    }
                  }}
                  handleRename={(appObject) =>
                    openPopup(CARD_ACTION.RENAME, appObject)
                  }
                  handleDelete={(appObject) =>
                    openPopup(CARD_ACTION.DELETE, appObject)
                  }
                  handleCopy={(appObject) => {
                    setCurrentApp(app);
                    openPopup(CARD_ACTION.COPY, appObject);
                  }}
                  refreshData={() => {
                    fetchData(searchTerm);
                  }}
                />
              ))}
            </div>
            <div
              className="row"
              ref={ref}
              style={{ visibility: 'hidden', height: 10, rootMargin: '0px' }}
            ></div>
          </div>
        )}
      </div>

      {activePopup.type === CARD_ACTION.RENAME &&
        activePopup.isOpen &&
        (props.listModule === LIST_MODULE.APPLICATIONS ? (
          <CreateApplicationPopup
            appObj={activePopup.app}
            onSave={(appObj) => {
              if (activePopup.app) {
                handleEditClick(appObj);
              }
            }}
            onCancel={() => {
              closePopup();
            }}
          />
        ) : (
          <EditApplicationAndTemplate
            isAlreadyPublic={true}
            app={activePopup.app}
            onSave={(appObj) => {
              if (activePopup.app) {
                handleEditClick(appObj);
              }
            }}
            onCancel={() => {
              closePopup();
            }}
          />
        ))}

      {activePopup.type === CARD_ACTION.DELETE &&
        activePopup.isOpen &&
        showAlert(
          'Delete Application?',
          'Deleting this app will delete it permanently.',
          [
            {
              title: 'Cancel',
              onClick: closePopup,
              className: 'bg-gray1 border-m'
            },
            {
              title: 'Delete',
              className: 'bg-red ml-r text-white',
              onClick: () => {
                if (activePopup.app) {
                  handleDeleteClick(
                    activePopup.app.id ?? activePopup.app._id ?? ''
                  );
                }
              }
            }
          ]
        )}

      {activePopup.type === CARD_ACTION.COPY && activePopup.isOpen && (
        <Popup className="" onClose={closePopup}>
          <DKInputForm
            fields={[
              {
                key: 'newName',
                placeholder: 'Enter New Application Name',
                required: true,
                title: 'Name',
                value: recordsArray.find((obj) => activePopup.appId === obj._id)
                  .name,
                type: 'text'
              }
            ]}
            onCancel={closePopup}
            onSubmit={(data) => {
              if (activePopup.app) {
                handleCopyClick(
                  activePopup.app.id ?? activePopup.app._id ?? '',
                  data.find((obj) => obj.key === 'newName').value
                );
              }
            }}
            title="Copy  Application"
            direction="vertical"
          />
        </Popup>
      )}
      {needAppCreateAnimation && (
        <div className="div position-absolute">
          <LottiePlayer animationData={animationData} />
        </div>
      )}
      {showCreateApplication && (
        <CreateApplicationPopup
          onCancel={() => {
            setShowCreateApplication(false);
          }}
          onSave={async () => {
            await fetchData(searchTerm);
            setShowCreateApplication(false);
          }}
        />
      )}
      {showInstallAppPopup && (
        <InstallAppPopup
          app={currentApp}
          onSave={async (appObj) => {
            setShowInstallAppPopup(false);
            setLoadingAnimationVisibility(true);
            installAppAPICall(appObj.name, appObj._id);
          }}
          onCancel={(appObj) => {
            setShowInstallAppPopup(false);
          }}
        />
      )}
    </>
  );
};
export default ApplicationList;
