import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  removeLoader,
  showLoader
} from 'deskera-ui-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppIcons from '../../assets/icons/AppIcons';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import { INPUT_VIEW_DIRECTION } from '../../constants/Enum';
import { PAGE_ROUTES } from '../../managers/RouteManager';
import OnboardingService from '../../services/Onboarding';
import Utility, {
  getDKInputValueStyle,
  getERPAILogo
} from '../../utility/Utility';
import { Options } from './Options';
import { useAuth, useOrganizationList } from '@clerk/clerk-react';
import httpClient from '../../http/index';

export default function Onboarding(props: any) {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [canValidate, setCanValidate] = useState(false);
  const { setActive } = useOrganizationList();
  const [formData, setFormData] = useState<any>();
  const getOrgNameField = () => {
    return (
      <div className="column parent-width justify-content-start gap-4">
        <DKInput
          valueStyle={getDKInputValueStyle()}
          canValidate={canValidate}
          autoFocus={true}
          title={'Company Name'}
          placeholder={'Company Name'}
          value={formData?.companyName}
          type={INPUT_TYPE.TEXT}
          onChange={(value) => {
            setFormData({ ...formData, companyName: value, name: value });
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={true}
          readOnly={false}
        />
        <DKInput
          valueStyle={getDKInputValueStyle()}
          required={false}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title="Company Size"
          value={
            formData?.companySize
              ? [Options.COMPANY_SIZE?.indexOf(formData?.companySize)]
              : null
          }
          type={CUSTOM_INPUT_TYPE.SELECT}
          options={Options.COMPANY_SIZE}
          onChange={(value: any) => {
            setFormData({
              ...formData,
              companySize: Options.COMPANY_SIZE[value?.[0]]
            });
          }}
        />
        <DKInput
          valueStyle={getDKInputValueStyle()}
          required={false}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={CUSTOM_INPUT_TYPE.SELECT}
          title="Country Of Origin"
          value={
            formData?.countryOfOrigin
              ? [Options.COUNTRIES?.indexOf(formData?.countryOfOrigin) ?? 0]
              : null
          }
          options={Options.COUNTRIES}
          onChange={(value: any) => {
            setFormData({
              ...formData,
              countryOfOrigin: Options.COUNTRIES[value?.[0]]
            });
          }}
        />
      </div>
    );
  };

  const finalizeOrgInfo = (payload) => {
    OnboardingService.onboardingFinalize(
      {
        organizationData: payload
      },
      payload.id
    )
      .then((response: any) => {
        setActive({ organization: response.org_id });
        console.log(response, 'Response received for onboarding post');
        setTimeout(async () => {
          const token = await getToken({
            template: 'web-api'
          });
          httpClient.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${token}`;
          navigate(PAGE_ROUTES.HOME);
          removeLoader();
        }, 1000);
      })
      .catch((error: any) => {
        console.log('Failed in onboarding ');
      });
  };

  return (
    <div
      className="column parent-size position-absolute z-index-4 align-items-center justify-content-center overflow-scroll hide-scroll-bar"
      style={{ left: 0, top: 0, backgroundColor: 'rgb(237,237,237)' }}
    >
      <div
        className="column border border-radius-r bg-white align-items-center shadow-xl"
        style={{ width: '90%', maxWidth: 450, padding: 30 }}
      >
        {getERPAILogo(true)}
        <DKLabel
          className="mt-r text-gray"
          text="Take a moment to setup your organization"
        />
        <div
          className="column justify-content-start gap-2 mt-6"
          style={{ width: 400 }}
        >
          {getOrgNameField()}
          <DKButton
            icon={AppIcons.white.ic_arrow_right}
            className="row-reverse text-white align-self-end bg-black mt-4"
            title="Continue"
            onClick={() => {
              setCanValidate(true);
              if (Utility.isEmptyObject(formData?.companyName)) {
                return;
              }
              let payload = { ...formData };
              showLoader('Creating your organisation...');
              OnboardingService.onboardingPost({
                organizationData: payload
              })
                .then((response: any) => {
                  setCanValidate(false);
                  setFormData({ ...formData, id: response._id });
                  payload.id = response._id;
                  OnboardingService.onboardingPatch(
                    {
                      organizationData: payload
                    },
                    payload.id
                  )
                    .then((response: any) => {
                      console.log(
                        response,
                        'Response received for onboarding post'
                      );
                      finalizeOrgInfo({
                        ...payload,
                        onboardingComplete: true
                      });
                    })
                    .catch((error: any) => {
                      removeLoader();
                      console.log('Failed in onboarding ');
                    });
                })
                .catch((error: any) => {
                  removeLoader();
                  console.log('Failed in onboarding ');
                });
            }}
          />
        </div>
      </div>
    </div>
  );
}
