import { DKButton, DKLabel, DKLine } from 'deskera-ui-library';
import { useState } from 'react';
import Utility from '../../utility/Utility';
import CompanySettings from './Company';
import UserProfile from './UserProfile';
import Account from './Account';
import { useAppSelector } from '../../redux/hooks';
import { selectOrganizationInfo } from '../../redux/slices/authSlice';

const SETTINGS = {
  COMPANY_SETTINGS: 'Company Settings',
  PROFILE: 'Profile',
  ACCOUNT: 'Account'
};

export default function Settings(props: any) {
  const [selectedButton, setSelectedButton] = useState<string>(
    SETTINGS.COMPANY_SETTINGS
  );
  const organizationInfo = useAppSelector(selectOrganizationInfo());

  const getActionButtons = () => {
    let allOptions = Object.values(SETTINGS).filter((v) => isNaN(Number(v)));
    return (
      <div className="column gap-3 mt-l" style={{ width: 250 }}>
        {allOptions.map((settingOptions: any) => {
          return (
            <DKButton
              className={`row ${
                selectedButton === settingOptions ? 'bg-gray2 fw-500 ' : ''
              }`}
              style={{ width: '100%' }}
              title={Utility.convertInTitleCase(
                settingOptions.replaceAll('_', ' ')
              )}
              onClick={() => {
                setSelectedButton(settingOptions);
              }}
            />
          );
        })}
      </div>
    );
  };

  const getDetailedView = () => {
    switch (selectedButton) {
      case SETTINGS.COMPANY_SETTINGS:
        return (
          <CompanySettings
            settings={{
              baseCurrency: organizationInfo?.baseCurrency,
              companyAddress: organizationInfo?.companyAddress,
              companyName: organizationInfo?.name,
              companySize: organizationInfo?.companySize,
              countryOfOrigin: organizationInfo?.countryOfOrigin,
              taxCycle: organizationInfo?.taxCycle,
              financialYearStartDate: organizationInfo?.financialYearStartDate
            }}
          />
        );
      case SETTINGS.ACCOUNT:
        return <Account />;
      case SETTINGS.PROFILE:
        return <UserProfile />;
    }
  };

  return (
    <div className="column parent-width p-4 ">
      <DKLabel className="fs-xl fw-m" text="Settings" />
      <DKLine />
      <div className="row justify-content-start align-items-start gap-8">
        {getActionButtons()}
        {getDetailedView()}
      </div>
    </div>
  );
}
