import ApiConstants from '../constants/ApiConstants';
import http from '../http';

export default class BulkOperationsService {
  static deleteRows(rows: any[], tableId: any) {
    let deleteEndPoint = `${ApiConstants.URL.BASE}v1/app-builder/table/${tableId}/record`;
    return http
      .delete(deleteEndPoint, {
        data: rows
      })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateBulkRecord(record: any, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_BULK_RECORD(tableId)}`, record)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
