import { CUSTOM_INPUT_TYPE } from '../constants/Constants';
import IFormField, { IForm, IRefSubField } from '../model/Form';
import { IColumn } from '../model/Table';
import { computeExpressionValue } from './DataGridUtility';
import Utility, {
  getFormulaScope,
  getRandomHexString,
  sortArray
} from './Utility';
export const mapColumnToFormField = (column: IColumn): IFormField => {
  return {
    _id: column.id ?? getRandomHexString(4),
    index: column.index,
    placeHolder: column.name,
    options: column.options,
    title: column.name,
    readOnly: false,
    refTable: column.refTable,
    required: column.required,
    style: { width: '100' },
    type: column.type,
    uiVisible: column.uiVisible,
    defaultValue: null,
    formula: column.formula
  };
};
export const mapFieldToColumn = (field: IFormField): IColumn => {
  return {
    id: null,
    type: field.type,
    name: field.title,
    editable: !field.readOnly,
    columnCode: null,
    options: field.options,
    key: null,
    refTable: field.refTable,
    systemField: false,
    width: 120,
    required: field.required,
    uiVisible: field.type === CUSTOM_INPUT_TYPE.TABLE ? false : field.uiVisible,
    formula: field.formula
  };
};
export const mapSubFieldToColumn = (field: IRefSubField): IColumn => {
  return {
    id: null,
    type: CUSTOM_INPUT_TYPE.NUMBER,
    name: field.displayName,
    editable: false,
    columnCode: null,
    options: null,
    key: null,
    refTable: null,
    systemField: false,
    width: 120,
    required: false,
    uiVisible: field.uiVisible,
    subFieldConfig: {
      tableId: (field as any)?.refTableId,
      colId: field.id
    }
  };
};
export const convertColumnsToFormConfig = (
  columns: IColumn[]
): IFormField[] => {
  return columns.map(mapColumnToFormField);
};

export const convertTableToForm = (table: any): IForm => {
  return {
    _id: null,
    tableId: table._id,
    fields: convertColumnsToFormConfig(table.columnsMetaData),
    title: table.name
  };
};
export const sortFormFields = <T, K extends keyof T>(
  fields: T[],
  key: K
): T[] => {
  return sortArray(fields, 'ACS', key);
};
export const validateForm = (form: IForm): Boolean => {
  let isValid = false;
  isValid = form.fields.every((field) => !Utility.isEmptyObject(field.title));
  if (Utility.isEmptyObject(form.title)) {
    isValid = false;
  }
  const tableFieldsWithSubFields = form.fields.filter(
    (field) =>
      field.type === CUSTOM_INPUT_TYPE.TABLE &&
      !Utility.isEmptyObject(field?.refTable?.subFields)
  );
  if (!Utility.isEmptyObject(tableFieldsWithSubFields)) {
    isValid = tableFieldsWithSubFields.every(
      (field) =>
        !!field.refTable.subFields.every(
          (subField) => !Utility.isEmptyObject(subField.id)
        )
    );
  }
  return isValid;
};

export const getAggregatedValue = (
  field: IRefSubField,
  rows: any[],
  columns: IColumn[]
) => {
  const { id } = field;
  const currentColumn = columns.find((col) => col.id === id);
  return rows?.reduce((acc: number, curr: any) => {
    try {
      if (currentColumn.type === CUSTOM_INPUT_TYPE.FORMULA) {
        const formulaScope = getFormulaScope(columns, curr);
        acc = acc + computeExpressionValue(currentColumn.formula, formulaScope);
      } else {
        acc = acc + Number(Number(curr[id] ?? 0).toFixed(2));
      }
    } catch (error) {
      acc = acc + 0;
    }
    return acc;
  }, 0);
};
