import { useAuth } from '@clerk/clerk-react';
import {
  DKButton,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import animationData from '../../assets/lottie/Animation - 1727086246021.json';
import LottiePlayer from '../../components/LottiePlayer';
import LoadingAnimation from '../../components/prompt-editor/LoadingAnimation';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import httpClient from '../../http';
import { PAGE_ROUTES } from '../../managers/RouteManager';
import { setAppInfo } from '../../redux/slices/CommonSlice';
import ApplicationService from '../../services/applications';

function TemplateDetails() {
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(true);
  const [templateDetails, setTemplateDetails] = useState<any>();
  const [needLoadingAnimation, setLoadingAnimationVisibility] = useState(false);
  const [needAppCreateAnimation, setAnimationVisibility] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();
  const { id } = useParams();
  const iframeRef = useRef<any>(null);

  useEffect(() => {
    if (id) {
      showLoader();
      const getTemplate = isSignedIn
        ? ApplicationService.getTemplateDetails
        : ApplicationService.getPublicTemplateDetails;
      getTemplate(id)
        .then((response: any) => {
          if (response?.body) {
            setTemplateDetails(response.body);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoadingTemplate(false);
          removeLoader();
        });
    }
  }, [id]);

  useEffect(() => {
    if (templateDetails?.landingPage?.html) {
      const iframe = iframeRef.current;
      const iframeDoc =
        iframe.contentDocument || iframe.contentWindow?.document;

      if (iframeDoc) {
        // Load HTML content into the iframe
        iframeDoc.open();
        iframeDoc.write(templateDetails.landingPage.html);
        iframeDoc.close();

        // Add event listener to the button after the iframe loads
        iframe.onload = () => {
          const button = iframeDoc.getElementById('copyAppButton');
          if (button) {
            button.addEventListener('click', handleInstallBtnClick);
          }
        };

        // Cleanup: Remove the event listener when the component unmounts
        return () => {
          const button = iframeDoc.getElementById('copyAppButton');
          if (button) {
            button.removeEventListener('click', handleInstallBtnClick);
          }
        };
      }
    }
  }, [templateDetails]);

  const handleInstallBtnClick = (event: Event) => {
    event.preventDefault();
    if (isSignedIn) {
      // install app
      installAppClicked();
    } else {
      // redirect to login
      const redirectUrl =
        PAGE_ROUTES.SIGN_IN + `?redirect_url=/marketplace/${id}`;
      navigate(redirectUrl);
    }
  };

  const installAppClicked = () => {
    showAlert(
      'Confirm',
      `Do you want to install <b> ${templateDetails.name} </b>.`,
      [
        {
          title: 'Cancel',
          className: 'bg-gray1 text-black mr-m',
          onClick: () => {}
        },
        {
          title: 'Install',
          className: 'bg-button text-white',
          onClick: () => {
            installAppAPICall();
          }
        }
      ]
    );
  };

  const installAppAPICall = async () => {
    try {
      setLoadingAnimationVisibility(true);
      const response: any = await httpClient.post(
        API_ENDPOINTS.TEMPLATE.INSTALL_TEMPLATE(templateDetails._id),
        {
          name: templateDetails.name
        }
      );
      setLoadingAnimationVisibility(false);
      appCreated(response.id);
    } catch (error) {
      setLoadingAnimationVisibility(false);
    }
  };

  const appCreated = (appId: string) => {
    setAnimationVisibility(true);
    setTimeout(() => {
      showAlert(
        'Congratulations!',
        'Your template has been installed successfully.',
        [
          { title: 'Back', className: 'bg-gray1 border-m' },
          {
            title: 'Go to App',
            className: 'bg-button text-white ml-r',
            onClick: () => {
              dispatch(setAppInfo({ appId: appId, isPublishedApp: false }));
              navigate(`/apps/${appId}`);
            }
          }
        ]
      );
    }, 1000);
    setTimeout(() => {
      setAnimationVisibility(false);
    }, 5000);
  };

  return (
    <>
      {!loadingTemplate && !templateDetails?.landingPage?.html && (
        <div className="flex h-screen flex-col align-items-center justify-content-center gap-8">
          <p className="fs-xxl text-gray">
            Oops! The page you're looking for doesn't exist.
          </p>
          <DKButton
            className="ml-r bg-button text-white"
            title="Go back home"
            onClick={() => {
              navigate(PAGE_ROUTES.HOME);
            }}
          />
        </div>
      )}
      {templateDetails?.landingPage?.html && (
        <>
          <iframe
            title="inline-frame"
            ref={iframeRef}
            style={{ width: '100vw', height: '100vh', border: 'none' }}
          />
          {needLoadingAnimation && <LoadingAnimation />}
          {needAppCreateAnimation && (
            <div className="div position-absolute">
              <LottiePlayer animationData={animationData} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default TemplateDetails;
