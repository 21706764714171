import ApiConstants from '../constants/ApiConstants';
import { removeLoader, showAlert } from 'deskera-ui-library';
import IAM from '../services/iam';
import { PAGE_GROUP_REDIRECTS, PAGE_ROUTES } from './RouteManager';
import { matchPath } from 'react-router-dom';
import { isUndefined } from '../utility/Utility';
import { isMobileAppWebView } from '../utility/GetViewportSize';
import { BOOK_A_DEMO } from '../constants/Constant';
import { SessionStorageService } from '../services/common/SessionStorageService';

export default class AppManager {
  static didUserLoggedIn = false;
  static didSessionExpired = false;
  static date_format = 'MMM dd, yyyy';
  static needSubscriptionApiCall = true;

  // for preloading, not needed right now, but later we can use this
  static chunksToPreloadOnPageLoad = [];

  static userLoggedIn() {
    AppManager.didUserLoggedIn = true;
  }

  static isUserLoggedIn() {
    return AppManager.didUserLoggedIn;
  }

  static getDateFormat() {
    return AppManager.date_format;
  }

  static gotoLoginPage(needAlert = true) {
    removeLoader();
    if (!needAlert) {
      window.open(
        ApiConstants.URL.IAM.REDIRECT + window.location.href,
        '_self'
      );
      return;
    }

    if (!isMobileAppWebView() && !AppManager.didSessionExpired) {
      showAlert(
        'Session expired!',
        'Your session is expired, please login and try again.',
        [
          {
            title: 'Ok',
            className: 'bg-button text-white fw-m',
            onClick: () => {
              window.open(
                ApiConstants.URL.IAM.REDIRECT + window.location.href,
                '_self'
              );
            }
          }
        ]
      );
    }

    AppManager.didSessionExpired = true;
  }

  static logout() {
    IAM.logOut().then((res) => {
      window.open(
        ApiConstants.URL.IAM.REDIRECT + window.location.href,
        '_self'
      );
    });
  }

  static showLogoutConfirmation() {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Log out',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          SessionStorageService.deleteSessionStorage();
          AppManager.logout();
        }
      }
    ];
    showAlert('Log out', 'Are you sure want to log out the session?', buttons);
  }

  static scrollToTop() {
    var myDiv = document.getElementById('containerDiv');
    if (myDiv) myDiv.scrollTop = 0;
  }

  static reloadApp() {
    // RouteManager.navigateToPage(PAGE_ROUTES.HOME);
    if (typeof window.history.pushState != 'undefined') {
      var obj = { Page: 'new', Url: '/' };
      window.history.pushState(obj, obj.Page, obj.Url);
    }
    window.location.reload();
  }

  static showTrialActivationWarning(onContinue) {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m fw-m',
        onClick: () => {}
      },
      {
        title: 'Start trial',
        className: 'bg-blue text-white ml-r fw-m',
        onClick: () => {
          onContinue();
        }
      }
    ];
    showAlert(
      'Trial activation',
      "You are about to start your 15 days FREE trial. Once your trial expires, you will be automatically put on a paid plan.<br><br>You can <b>cancel or change your trial at anytime</b>. We'll remind you before your trial ends.",
      buttons
    );
  }

  static generateDirectLink(path) {
    if (path === undefined || path === null || path.trim() === '') {
      return '';
    }

    if (path && path.includes('http')) {
      return path;
    }
    return window.location.origin + PAGE_ROUTES.PUBLIC_PAGE_PATH + path;
  }

  static isPublicURL() {
    return window.location.pathname.includes(PAGE_ROUTES.PUBLIC_PAGE_PATH);
  }

  static isBookADemoURL() {
    return window.location.pathname.includes(BOOK_A_DEMO);
  }

  static isMobileAppURL() {
    return window.location.pathname.includes(PAGE_ROUTES.MOBILE_PAGE_PATH);
  }

  static isCurrentRoute(route) {
    return matchPath(window.location.pathname, route)?.isExact;
  }

  static getFormSubmissionURL() {
    return window.location.origin + '/FormSubmission.js';
  }

  static showComingSoonWarning = () => {
    showAlert('Coming soon!', 'We are working on it.');
  };

  static handleWindowFocusListeners = (callback, register = false) => {
    if (register) {
      /* Register visibility listeners */
      if (isUndefined(document.hidden)) {
        window.addEventListener('focus', callback);
      } else {
        document.addEventListener('visibilitychange', callback, false);
      }
    } else {
      /* Remove visibility listeners */
      if (isUndefined(document.hidden)) {
        window.removeEventListener('focus', callback);
      } else {
        document.removeEventListener('visibilitychange', callback, false);
      }
    }
  };

  static redirectPageGroupRoute = () => {
    const pageGroupRoute = Object.keys(PAGE_GROUP_REDIRECTS).find(
      (route) => matchPath(window.location.pathname, route)?.isExact
    );
    const redirectTo = PAGE_GROUP_REDIRECTS[pageGroupRoute];
    if (!redirectTo) return;

    window.location = window.origin + redirectTo;
  };
}
