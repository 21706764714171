import React from 'react'
import StickySidebar from '../sticky-sidebar'
import { Link } from 'react-router-dom'


export default function TermsAndConditions() {
  const termsSidebarItems = [
    { name: 'Introduction', url: 'terms-section-introduction' },
    { name: 'Website Use', url: 'terms-section-1' },
    { name: 'Website User Conduct And Restrictions — License Terms', url: 'terms-section-2' },
    {
      name: 'Our Privacy Statement And Data Processing Addendum And Your Personal Information',
      url: 'terms-section-3',
    },
    {
      name: 'Information You Provide; Registration; Passwords; Prohibition Against Hosting Third-Party AGENCY ACCOUNTS',
      url: 'terms-section-4',
    },
    { name: 'Order Placement And Acceptance', url: 'terms-section-5' },
    { name: 'Refunds', url: 'terms-section-6' },
    { name: 'Payment And Cancellation', url: 'terms-section-7' },
    { name: 'Subscription Terms And Automatic Payment', url: 'terms-section-8' },
    { name: 'Products, Services, And Prices Available On The Website', url: 'terms-section-9' },
    { name: "Disclaimer - Your Business' Individual Results Will Vary", url: 'terms-section-10' },
    { name: 'Your Responsibilities In Running Your Business', url: 'terms-section-11' },
    { name: 'Testimonials, Reviews, And Pictures/Videos', url: 'terms-section-12' },
    { name: 'Compliance With The Laws', url: 'terms-section-13' },
    { name: 'Disclaimers Of Other Warranties', url: 'terms-section-14' },
    { name: 'Limitations Of Liabilities', url: 'terms-section-15' },
    {
      name: 'Dispute Resolution By Mandatory Binding Arbitration And Class Action Waiver',
      url: 'terms-section-16',
    },
    { name: "ERP.AI'S Additional Remedies", url: 'terms-section-17' },
    { name: 'Indemnification', url: 'terms-section-18' },
    { name: 'Notice And Takedown Procedures; Copyright Act', url: 'terms-section-19' },
    { name: 'Third-Party Links', url: 'terms-section-20' },
    { name: 'Termination', url: 'terms-section-21' },
    { name: 'No Waiver', url: 'terms-section-22' },
    { name: 'Governing Law And Venue', url: 'terms-section-23' },
    { name: 'Force Majeure', url: 'terms-section-24' },
    { name: 'Assignment', url: 'terms-section-25' },
    { name: 'Electronic Signature', url: 'terms-section-26' },
    { name: 'Changes To The Agreement', url: 'terms-section-27' },
    { name: 'Your Additional Representations And Warranties', url: 'terms-section-28' },
    { name: 'Severability', url: 'terms-section-29' },
    { name: 'Entire Agreement', url: 'terms-section-30' },
    { name: 'Contacting Us', url: 'terms-section-31' },
  ]

  return (
    <div className='relative z-0 min-h-screen text-stone-950'>
    <section className="flex items-start justify-center font-geist-sans h-screen overflow-y-auto">
      <div className="py-12 lg:py-20 container px-4 flex flex-col items-start justify-center gap-[30px] w-[90%]">
        <div className="prose prose-stone mb-8">
        <h1 className="font-instrument-serif">Terms of Service</h1>
        </div>
        <div className="flex w-full flex-col-reverse justify-between lg:flex-row lg:gap-[30px]">
          <div className="prose prose-stone w-auto grow text-start lg:max-w-[460px] xl:max-w-screen-sm">
            <section id="terms-section-introduction">
              <p>
                IMPORTANT - PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS AND CONDITIONS OF USE &
                SALE (THESE &ldquo;TERMS&rdquo;) BEFORE ACCESSING, USING, OR SUBSCRIBING OR PLACING
                AN ORDER OVER ERP.AI OR OTHER OF OUR SITES WHICH LINK TO THESE TERMS.
              </p>
              <p>
                THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES AND LIMITATIONS OF LIABILITIES
                INCLUDING ARBITRATION AND CLASS ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A
                COURT HEARING, RIGHT TO A JURY TRIAL, AND RIGHT TO PARTICIPATE IN A CLASS ACTION
                (SEE SECTION 19). ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND
                ALL DISPUTES UNLESS SPECIFIED BELOW. THESE TERMS FORM AN ESSENTIAL BASIS OF OUR
                AGREEMENT.
              </p>
              <p>
                The use of erp.ai or other sites or apps to which these Terms are linked (each, a
                &ldquo;Website&rdquo;), owned, operated, and maintained by ERP.AI US Inc. and its
                subsidiaries and affiliated entities (collectively &ldquo;ERP.AI&rdquo;), are
                governed by these Terms. We offer the Website, including all information, tools, and
                services available from the Website to you, the user, conditioned upon your
                acceptance of all terms and conditions stated herein. By accessing, using,
                subscribing, or placing an order over the Website, you and your business (including
                any sub-users you may have) agree to the terms and conditions set forth herein. If
                you do not agree to these Terms in their entirety, you are not authorized to use the
                Website in any manner or form whatsoever.
              </p>
              <p>
                THIS IS A BINDING AGREEMENT. THESE TERMS TOGETHER WITH OUR PRIVACY STATEMENT AND
                DATA PROCESSING ADDENDUM FORM A LEGALLY BINDING AGREEMENT (THE
                &ldquo;AGREEMENT&rdquo;) BETWEEN YOU OR YOUR BUSINESS (&ldquo;YOU&rdquo;) AND
                ERP.AI. THIS AGREEMENT GOVERNS YOUR ACCESS TO AND USE OF THE WEBSITE AND THE
                SERVICES PROVIDED BY ERP.AI, ANY ORDER YOU PLACE THROUGH THE WEBSITE, BY TELEPHONE,
                OR OTHER ACCEPTED METHOD OF PURCHASE AND, AS APPLICABLE, YOUR USE OR ATTEMPTED USE
                OF THE PRODUCTS OR SERVICES OFFERED ON OR AVAILABLE THROUGH THE WEBSITE. PLEASE
                PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS.
              </p>
            </section>

            <section id="terms-section-1">
              <h2 className='font-instrument-serif'>Section 1 - Website use</h2>
              <p>
                The Website is intended for businesses operated by adults. If you use the Website,
                you are affirming that you are at least 18 years old or the legal age of majority in
                your state or province of residence (whichever is greater), operate a business, have
                the legal capacity to enter into a binding contract with us, and have read this
                Agreement and understand and agree to its terms.
              </p>
              <p>
                We recognize in certain instances; the Website may be accessed by youth under the
                age of 18. In these instances, by accessing the Website, you are affirming that your
                parent or guardian, of at least 18 years old, has given requisite verifiable consent
                for you to do so and that they agree to these terms on your behalf.
              </p>
            </section>

            <section id="terms-section-2">
              <h2 className='font-instrument-serif'>Section 2 - Website User Conduct And Restrictions — License Terms</h2>
              <p>
                All aspects of our Website are protected by U.S. and international copyright,
                trademark, and other intellectual property laws, including all content, information,
                design elements, text material, logos, taglines, metatags, hashtags, photographic
                images, testimonials, personal stories, icons, video and audio clips, and downloads.
                No material on the Website may be copied, reproduced, distributed, republished,
                uploaded, displayed, posted, or transmitted in any way whatsoever. The ERP.AI
                trademark, symbol, and logo are proprietary marks of ERP.AI, and the use of those
                marks is strictly prohibited. Nothing herein gives you the right to use, copy,
                register as a domain name, reproduce, or otherwise display any logo, tagline,
                trademark, trade name, copyrighted material, patent, trade dress, trade secret, or
                confidential information owned by ERP.AI.Subject to your continued strict compliance
                with these Terms, ERP.AI provides to you a revocable, limited, non-exclusive,
                royalty-free, non-sublicensable, non-transferable license to use the Website. You
                acknowledge and agree that you do not acquire any ownership rights in any material
                protected by intellectual property laws. Subject to your continued strict compliance
                with these Terms, ERP.AI provides to you a revocable, limited, non-exclusive,
                royalty-free, non-sublicensable, non-transferable license to use the Website. You
                acknowledge and agree that you do not acquire any ownership rights in any material
                protected by intellectual property laws. If you purchase a subscription to ERP.AI
                software over the Website, ERP.AI provides to you a revocable, limited,
                non-exclusive, non-sublicensable, non-transferable license to use the software.
              </p>
              <p>You acknowledge and agree that: </p>
              <ol>
                <li>
                  the software is copyrighted material under United States and international
                  copyright laws that is exclusively owned by ERP.AI;{' '}
                </li>
                <li>you do not acquire any ownership rights in the software;</li>
                <li>
                  you may not modify, publish, transmit, participate in the transfer or sale, or
                  create derivative works from the content of the software;{' '}
                </li>
                <li>
                  except as otherwise expressly permitted under copyright law, you may not copy,
                  redistribute, publish, display or commercially exploit any material from the
                  software without the express written permission of ERP.AI; and{' '}
                </li>
                <li>
                  in the event of any permitted copying (e.g., from the Website to your computer
                  system), no changes in or deletion of author attribution, trademark, legend or
                  copyright notice shall be made. You agree not to use or attempt to use the
                  Website, or any software provided by ERP.AI, whether alone, or in conjunction with
                  other software or hardware, in any unlawful manner or a manner harmful to ERP.AI.{' '}
                </li>
              </ol>
              <p>
                You further agree not to commit any harmful or unlawful act or attempt to commit any
                harmful or unlawful act on or through the Website or through use of any software or
                hardware including, but not limited to, refraining from:{' '}
              </p>
              <ol>
                <li>
                  <strong>HARMFUL OR PROHIBITED ACTS.</strong> Any violation of the law; infliction
                  of harm to ERP.AI&apos;s reputation; hacking and other digital or physical attacks
                  on the Website; scraping, crawling, downloading, screen-grabbing, or otherwise
                  copying content on the Website and/or transmitting it in any way we haven&apos;t
                  specifically permitted; introducing, transmitting, or storing viruses or other
                  malicious code; interfering with the security or operation of the Website; framing
                  or mirroring the Website; creating, benchmarking, or gathering intelligence for a
                  competitive offering; infringing another party&apos;s intellectual property
                  rights, including failing to obtain permission to upload/transfer/display works of
                  authorship; intercepting or expropriating data; deceptive manipulation of media;
                  and the violation of the rights of ERP.AI or any third party;
                </li>
                <li>
                  <strong>&ldquo;SPAMMING&rdquo; AND UNSOLICITED COMMUNICATIONS.</strong> We have
                  zero-tolerance for spam and unsolicited communications. Any communications sent or
                  authorized by you reasonably deemed &ldquo;spamming,&rdquo; or any other
                  unsolicited solicitations (including without limitation postings on social media
                  or third-party blogs) will be deemed a material threat to ERP.AI&apos;s reputation
                  and to the rights of third parties. It is your obligation, exclusively, to ensure
                  that all business communications comply with state and local anti-spamming or
                  analogous laws.{' '}
                </li>
                <li>
                  <strong>OFFENSIVE COMMUNICATIONS.</strong> Any communication sent, posted, or
                  authorized by you, including without limitation postings on any website operated
                  by you, or social media or blog, which are: sexually explicit, obscene, vulgar, or
                  pornographic; offensive, profane, hateful, threatening, harmful, inciting,
                  defamatory, libelous, harassing, or discriminatory; misleading, false, or
                  misinforming; graphically violent; or solicitous of unlawful behavior.{' '}
                </li>
                <li>
                  <strong>ILLEGAL BUSINESS ACTIVITY.</strong> Any promotion of illegal business
                  activity, including without limitation promoting the sale or use of illegal drugs;
                  infringing or promoting the infringement of the intellectual property rights of
                  another; or trading or doing business with those whom doing so is restricted by
                  law. You further agree to conduct yourself and all of your businesses in full
                  compliance with all applicable laws, whether through the use of ERP.AI or
                  otherwise. To the extent you breach any of the terms referenced herein, your
                  rights will be immediately terminated without notice.
                </li>
              </ol>
            </section>

            <section id="terms-section-3">
              <h2 className='font-instrument-serif'>
                Section 3 - Our Privacy Statement And Data Processing Addendum And Your Personal
                Information
              </h2>
              <p>
                We respect your privacy and the use and protection of your non-public, personal
                information. Your submission of personal information through the website is governed
                by our Privacy Statement and, if you and/or your end users are located in the
                European Union, United Kingdom, Brazil, or California (USA), our Data Processing
                Addendum (&ldquo;DPA&rdquo;) as well. Our Privacy Statement may be viewed at ERP.AI
                Privacy Policy and our DPA may be viewed at ERP.AI Data Processing Addendum. ERP.AI
                reserves the right to modify its Privacy Statement and DPA in its reasonable
                discretion from time-to-time. Our Privacy Statement and DPA are incorporated into
                this Agreement by reference.
              </p>
            </section>

            <section id="terms-section-4">
              <h2 className='font-instrument-serif'>
                Section 4 - Information You Provide; Registration; Passwords; Prohibition Against
                Hosting Third-Party AGENCY ACCOUNTS
              </h2>
              <p>
                As a ERP.AI user, you will be required to create an account with ERP.AI. You warrant
                that the information you provide us is truthful and accurate, and that you are not
                impersonating another person. You are responsible for maintaining the
                confidentiality of any password you may use to access your ERP.AI user account, and
                you agree not to transfer your password or username or lend or otherwise transfer
                your use of or access to your user account, to any third party. You are also
                responsible for maintaining the confidentiality of proprietary or non-public
                information we may share with you as a ERP.AI user, such as technical information,
                our pricing, our business strategy, and data about other past or current ERP.AI
                users or their customers.
              </p>
              <p>
                You are fully responsible for all transactions with, and information conveyed to,
                ERP.AI under your user account. You agree to immediately notify ERP.AI of any
                unauthorized use of your password or username or any other breach of security
                related to your user account. You agree that ERP.AI is not liable, and you will
                defend and hold ERP.AI harmless, for any loss or damage arising from your failure to
                comply with any of the foregoing obligations. Please see Section 21 below for
                additional information.
              </p>
            </section>

            <section id="terms-section-5">
              <h2 className='font-instrument-serif'>Section 5 - Order Placement And Acceptance</h2>
              <p>
                If you order a service or product, payment must be received by us before your order
                is accepted. We may require additional information regarding your order if any
                required information was missing or inaccurate and may cancel or limit an order any
                time after it has been placed.
              </p>
              <p>
                All items are subject to availability. We will notify you if any item is not
                available, the expected availability date, and may offer you an alternative product
                or service. If the availability of any product or service is delayed and you do not
                wish to substitute the product or service, upon your request, we will cancel your
                order and if previously charged, your payment card will be fully refunded for that
                specific order. We reserve the right to limit the sales of our products and services
                to any person, geographic region, or jurisdiction. We may exercise this right on a
                case-by-case basis at our sole and exclusive discretion.
              </p>
            </section>

            <section id="terms-section-6">
              <h2 className='font-instrument-serif'>Section 6 - Refunds</h2>
              <p>
                No refunds are issued on termination of a subscription or service or ERP.AI
                terminates service.
              </p>
            </section>

            <section id="terms-section-7">
              <h2 className='font-instrument-serif'>Section 7 - Payment And Cancellation</h2>
              <p>
                Where we offer you a free trial of ERP.AI such free trial will continue for the free
                trial offer period as indicated on the Website. Free trial subscriptions are only
                available to new subscribers of ERP.AI and for the limited periods as set out on the
                Website. Previous subscribers or those subscribers who have already benefited from a
                free trial subscription to ERP.AI do not qualify for a further free trial period.
              </p>
              <p>
                If you do not want to continue your subscription after your free trial comes to an
                end, you must cancel your free trial through your ERP.AI account or by contacting us
                at least 24 hours before your free trial period ends by submitting a cancellation
                request to us via our support email address at&nbsp;
                <Link
                to={'mailto:care@erp.ai'} 
                className="underline"
              >
               care@erp.ai
              </Link>
                . If you do not contact us
                at least 24 hours before your free trial period ends to cancel, your subscription
                will automatically continue and the payment card that you provided at the time of
                enrollment online will be charged the monthly membership subscription rate provided
                at the time of enrollment each month until you cancel. ERP.AI can change the
                membership subscription rates and period at any time. If the membership subscription
                rate changes after you subscribe, we will notify you and give you an opportunity to
                cancel.
              </p>
              <p>
                If you wish to cancel your ERP.AI subscription (including subscriptions for
                services) at any time, you must cancel your subscription under your ERP.AI account
                on the ERP.AI platform or Website. You will not be entitled to prorate your usage,
                nor will you be entitled to any refund for any payments to ERP.AI.
              </p>
            </section>

            <section id="terms-section-8">
              <h2 className='font-instrument-serif'>Section 8 - Subscription Terms And Automatic Payment</h2>
              <p>
                A ERP.AI user is responsible for paying all sums due to ERP.AI in connection with
                their monthly subscription in accordance with these Terms. The first fee payable in
                accordance with these Terms is due when the user account is set up and payment of
                the fee is a condition of access, or after your free trial ends and you have not
                canceled the automatic subscription with us. Thereafter, your account will be
                charged up to the subscription fee plus applicable tax and any accumulated charges
                for any past period (collectively, &ldquo;Fees&rdquo;). Failure by the ERP.AI user
                to use any of the services available through the service provided by ERP.AI does not
                relieve the ERP.AI user of their payment obligations under these Terms. Payment
                details shall be collected by us through our secure financial data collection
                mechanism. You acknowledge and agree that we hold data relating to the transaction,
                including the last four digits and the expiration date of the card used to purchase
                the products or services together with details on when payment is due. You further
                acknowledge and agree that payments are due on a recurring basis in accordance with
                the payment terms for the specific service purchased (unless the subscription is
                cancelled in accordance with these Terms) and therefore authorize the automatic
                payment collection terms applicable to that specific service (e.g., on a monthly
                basis and for a specific amount). If for whatever reason, we are unable to process
                your monthly subscription payment, we may in our sole discretion, charge you a
                lesser amount to &ldquo;pause&rdquo; your account and retain your information as we
                attempt to contact you for updated payment information. IF YOU ARE A ERP.AI USER
                WITH A MONTHLY OR ANNUAL SUBSCRIPTION AND YOU HAVE PROVIDED US WITH A VALID CREDIT
                OR DEBIT CARD NUMBER OR AN ALTERNATE PAYMENT METHOD, EACH PAYMENT WILL BE
                AUTOMATICALLY PROCESSED AT THE TIME OF YOUR DESIGNATED PAYMENT DATE (MONTHLY OR
                ANNUALLY) AND WILL BE BILLED TO THE PAYMENT METHOD YOU PROVIDED TO US AT THE TIME OF
                YOUR ENROLLMENT. AS NOTED ABOVE, IF WE DO NOT RECEIVE SUFFICIENT FUNDS, WE MAY
                CHARGE YOU A LESSER AMOUNT TO PAUSE YOUR ACCOUNT. IF YOU WISH TO CANCEL YOUR
                SUBSCRIPTION TO ERP.AI, YOU MAY DO SO THROUGH YOUR ACCOUNT DASHBOARD OR BY EMAILING&nbsp;
                <Link
                to={'mailto:care@erp.ai'} 
                className="underline"
              >
               CARE@ERP.AI
              </Link>
              &nbsp;
                AT LEAST TEN (10) DAYS BEFORE THE FIRST DAY OF YOUR NEXT SUBSCRIPTION
                MONTH. ERP.AI reserves the right to immediately terminate a user&apos;s account
                and/or service for any unpaid (in whole or part) period of the subscription (with or
                without notice). Termination of service in no way relieves or excuses the user from
                any obligation to pay outstanding charges or expenses. In the event ERP.AI starts
                collection processes of any type, you will be liable for all collection costs,
                including legal fees and expenses, as provided herein. No refunds are issued on
                termination of a subscription or service. In addition to any fees, ERP.AI may also
                charge applicable value added or other tax.
              </p>
            </section>

            <section id="terms-section-9">
              <h2 className='font-instrument-serif'>Section 9 - Products, Services, And Prices Available On The Website</h2>
              <p>
                Products, services, and prices are generally posted on the Website, but are subject
                to change. ERP.AI may also offer additional products and services through other
                sites to which it owns or controls; such sites are also governed by the terms
                herein. ERP.AI reserves the right, without notice, to discontinue products or
                services or modify specifications and prices on products and services without
                incurring any obligation to you. Except as otherwise expressly provided for in these
                Terms, any price changes to your subscription or purchase of product(s) or services
                will take effect following notice to you. By accessing, using, subscribing or
                placing an order over the Website, you authorize ERP.AI to charge your account in
                the amount indicated for the value of the services you select, including any future
                price changes. If you request a downgrade in services, the downgrade (and
                corresponding price reduction) will become effective on the first day of the month
                following your requested downgrade. By your continued use of ERP.AI services, and
                unless you terminate your subscription as provided herein, you agree that ERP.AI may
                charge your credit card monthly for the products and services you have selected, and
                you consent to any price changes for such services after notice has been provided to
                you.
              </p>
            </section>

            <section id="terms-section-10">
              <h2 className='font-instrument-serif'>Section 10 - Disclaimer - Your Business&apos; Individual Results Will Vary</h2>
              <p>
                Every business is different. Therefore, individual results on ERP.AI will vary from
                user to user. YOUR BUSINESS&apos; INDIVIDUAL RESULTS WILL VARY DEPENDING UPON A
                VARIETY OF FACTORS UNIQUE TO YOUR BUSINESS, INCLUDING BUT NOT LIMITED TO YOUR
                CONTENT, BUSINESS MODEL, AND PRODUCT AND SERVICE OFFERINGS. When ordering products
                or services, please note that ERP.AI does not warrant that the ERP.AI products or
                services descriptions are error-free or that ERP.AI&apos;s service is fit for the
                purpose you intend. ERP.AI does not promise, guarantee, or warrant your
                business&apos; success, income, or sales and based upon many market factors that we
                cannot control, the software and tools we provide may or may not be applicable to
                your specific business. Further, we do not make earnings claims, efforts claims,
                return on investment claims, or claims that our software, tools, or other offerings
                will make your business any specific amount of money, and it is possible that you
                will not earn your investment back. Instead, you should purchase with the
                understanding that using the information and software purchased will take time and
                effort and may be applicable in some situations but not others. Also, we do not
                offer any tax, accounting, financial, or legal advice. You should consult your
                business&apos; accountant, attorney, or financial advisor for advice on these
                topics.
              </p>
            </section>

            <section id="terms-section-11">
              <h2 className='font-instrument-serif'>Section 11 - Your Responsibilities In Running Your Business</h2>
              <p>
                You represent that you and your business will only use ERP.AI&apos;s products and
                services for lawful purposes and that you shall not use such products or services,
                whether alone or in connection with other software, hardware, or services, for any
                unlawful or harmful purpose. You are solely and exclusively responsible for
                complying with any and all applicable laws and regulations in running your business.
                ERP.AI shall have no liability for your violation of any laws. You agree to
                indemnify ERP.AI as set out in Section 21 below in the event that you and/or your
                business violates any law, and a claim is threatened or asserted against ERP.AI as a
                result.
              </p>
            </section>

            <section id="terms-section-12">
              <h2 className='font-instrument-serif'>Section 12 - Testimonials, Reviews, And Pictures/Videos</h2>
              <p>
                ERP.AI is pleased to hear from users and customers and welcomes your comments
                regarding our services and products. ERP.AI may use testimonials and/or product
                reviews in whole or in part together with the name and location of the person
                submitting it. Testimonials may be used for any form of activity relating to
                ERP.AI&apos;s services or products, in printed and online media, as ERP.AI
                determines in its sole and exclusive discretion. Testimonials represent the unique
                experience of the participants and customers submitting the testimonial, and do not
                necessarily reflect the experience that you and your business may have using our
                services or products. As set forth above in Section 11, your business&apos; results
                will vary depending upon a variety of factors unique to your business and market
                forces beyond ERP.AI&apos;s control. Note that testimonials, photographs, and other
                information that you provide to us will be treated as non-confidential and
                nonproprietary, and, by providing them, you grant ERP.AI a royalty-free, worldwide,
                perpetual, non-exclusive and irrevocable license to use them. Additionally, ERP.AI
                reserves the right to correct grammatical and typing errors, to shorten testimonials
                prior to publication or use, and to review all testimonials prior to publication or
                use. ERP.AI shall be under no obligation to use any, or any part of, any testimonial
                or product review submitted.
              </p>
            </section>

            <section id="terms-section-13">
              <h2 className='font-instrument-serif'>Section 13 - Compliance With The Laws</h2>
              <p>
                As a ERP.AI user you must comply with all laws, both U.S. and foreign, including,
                but not limited to, laws prohibiting deceptive and misleading advertising and
                marketing, e-mail marketing laws (including the federal CAN-SPAM Act (15 U.S.C. §
                7701)), data protection laws (including but not limited to the European Union
                General Data Protection Regulation, U.K. Data Protection Act, California Consumer
                Privacy Act, and Brazilian General Data Protection Regulation), telemarketing laws
                (including the federal Telephone Consumer Protection Act (47 U.S.C. § 227) and the
                Federal Trade Commission&apos;s Telemarketing Sales Rule (16 C.F.R. § 310)), laws
                governing testimonials (including the Federal Trade Commission&apos;s Revised
                Endorsements and Testimonials Guides (16 CFR Part 255)), and/or any similar laws,
                laws relating to intellectual property, privacy, security, terrorism, corruption,
                child protection, or import/export laws. You are solely responsible for ensuring
                their compliance with all applicable laws, rules, regulations, and court orders of
                any kind of any jurisdiction applicable to you and your business, and any recipient
                to whom you send digital messages using our products or services. You have the
                responsibility to be aware of, understand, and comply with all applicable laws and
                ensure that you and all users of your account comply with such applicable laws at
                all times.
              </p>
            </section>

            <section id="terms-section-14">
              <h2 className='font-instrument-serif'>Section 14 - Disclaimers Of Other Warranties</h2>
              <p>
                EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW: THE WEBSITE AND ALL
                CONTENT ARE PROVIDED ON AN &ldquo;AS IS&rdquo;, &ldquo;AS AVAILABLE&rdquo; BASIS
                WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
                TO WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE. WE MAKE NO, AND EXPRESSLY DISCLAIM ANY AND ALL, REPRESENTATIONS
                AND WARRANTIES AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY,
                AVAILABILITY, ACCURACY, AND/OR COMPLETENESS OF ANY INFORMATION ON THIS WEBSITE. WE
                DO NOT REPRESENT OR WARRANT, AND EXPRESSLY DISCLAIM THAT: (A) THE USE OF THE WEBSITE
                OR ANY SOFTWARE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE, OR OPERATE IN
                COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE WEBSITE,
                SOFTWARE, OR SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED
                DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES,
                SOFTWARE, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
                WEBSITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS IN THE
                WEBSITE WILL BE CORRECTED, OR (F) THE WEBSITE OR THE SERVER(S) THAT MAKE THE WEBSITE
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ALL CONDITIONS,
                REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
                INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ARE HEREBY
                DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
              </p>
            </section>

            <section id="terms-section-15">
              <h2 className='font-instrument-serif'>Section 15 - Limitations Of Liabilities</h2>
              <p>
                EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, IN NO EVENT SHALL ERP.AI
                OR ANY OF ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, INDEPENDENT CONTRACTORS,
                TELECOMMUNICATIONS PROVIDERS, AND/OR AGENTS BE LIABLE FOR ANY INDIRECT, SPECIAL,
                INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE, OR ANY OTHER DAMAGES, FEES, COSTS OR
                CLAIMS ARISING FROM OR RELATED TO THIS AGREEMENT INCLUDING THE PRIVACY STATEMENT AND
                DPA, THE SERVICES OR PRODUCTS, YOUR OR A THIRD PARTY&apos;S USE OR ATTEMPTED USE OF
                THE WEBSITE OR ANY SOFTWARE, SERVICE, OR PRODUCT, REGARDLESS OF WHETHER ERP.AI HAS
                HAD NOTICE OF THE POSSIBILITY OF SUCH DAMAGES, FEES, COSTS, OR CLAIMS. THIS
                INCLUDES, WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA, LOSS
                OF GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE SERVICES OR PRODUCTS, OR ANY OTHER
                INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR OTHER DAMAGES. THIS
                APPLIES REGARDLESS OF THE MANNER IN WHICH DAMAGES ARE ALLEGEDLY CAUSED, AND ON ANY
                THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND
                STRICT LIABILITY), WARRANTY, OR OTHERWISE. IN NO EVENT SHALL ERP.AI&apos;S LIABILITY
                TO YOU OR YOUR BUSINESS EXCEED THE AMOUNT PAID BY YOU TO ERP.AI DURING THE ONE YEAR
                PRECEDING THE DATE IN WHICH THE FACTS GIVING RISE TO A CLAIM AGAINST ERP.AI
                OCCURRED.
              </p>
            </section>

            <section id="terms-section-16">
              <h2 className='font-instrument-serif'>
                Section 16 - Dispute Resolution By Mandatory Binding Arbitration And Class Action
                Waiver
              </h2>
              <p>
                If you have a complaint, dispute, or controversy, you agree to first contact us at&nbsp;
                <Link
                to={'mailto:legal@erp.ai'} 
                className="underline"
              >
               legal@erp.ai
              </Link>
              &nbsp;attempt to resolve the dispute or controversy informally. PLEASE READ
                THIS DISPUTE RESOLUTION PROVISION CAREFULLY TO UNDERSTAND YOUR AND YOUR
                BUSINESS&apos; RIGHTS. EXCEPT WHERE PROHIBITED BY LAW, YOU AND YOUR BUSINESS AGREE
                THAT ANY CLAIM THAT YOU OR YOUR BUSINESS MAY HAVE IN THE FUTURE MUST BE RESOLVED
                EXCLUSIVELY THROUGH FINAL AND BINDING CONFIDENTIAL ARBITRATION. YOU ACKNOWLEDGE AND
                AGREE THAT YOU AND YOUR BUSINESS ARE WAIVING ANY RIGHT TO A TRIAL BY JURY. THE
                RIGHTS THAT YOU AND YOUR BUSINESS WOULD HAVE IF YOU WENT TO COURT, SUCH AS DISCOVERY
                OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT EXIST. YOU AGREE THAT YOU AND
                YOUR BUSINESS MAY ONLY BRING A CLAIM IN AN INDIVIDUAL CAPACITY AND NOT AS A
                PLAINTIFF (LEAD OR OTHERWISE) OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE
                PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR
                CLASS PROCEEDING. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN
                ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL
                BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY
                RELIEF OR STATUTORY DAMAGES) AND MUST FOLLOW THESE TERMS AS A COURT WOULD. Any
                controversy or claim arising out of or related to the use of the Website, any
                product, service, or software, these Terms, the Privacy Statement, the DPA, or your
                relationship with us that cannot be resolved through an informal process or through
                negotiation shall be resolved exclusively as follows:
              </p>
              <h3 className='font-instrument-serif'>FOR CUSTOMERS WITH A PRINCIPAL PLACE OF BUSINESS IN THE UNITED STATES:</h3>
              <p>
                Any controversy or claim arising out of or relating to Terms, the Privacy Statement,
                the DPA, or the breach thereof, shall be settled by arbitration administered by the
                American Arbitration Association (&ldquo;AAA&rdquo;) in accordance with its
                Commercial Arbitration Rules, and judgment on the award rendered by the
                arbitrator(s) may be entered in any court having jurisdiction thereof. The number or
                arbitrators shall be one. The place of arbitration shall be San Francisco,
                California. The language of the arbitration shall be English. The governing law
                shall be the law of the State of California without regard to the law of the
                conflict of laws. The arbitrator shall have the exclusive and sole authority to
                resolve any dispute relating to the interpretation, construction, validity,
                applicability, or enforceability of these Terms, the Privacy Statement, the DPA,
                this arbitration provision, and any other terms incorporated by reference into these
                Terms. The arbitrator shall have the exclusive and sole authority to determine
                whether any dispute is arbitrable. The arbitrator shall have the exclusive and sole
                authority to determine whether this arbitration agreement can be enforced against a
                non-signatory to this agreement and whether a non-signatory to this agreement can
                enforce this provision against you or ERP.AI. Payment of all filing, administration,
                and arbitrator fees will be governed by rules of the AAA. In all other respects, the
                parties shall each pay their own additional fees, costs, and expenses, including,
                but not limited to, those for any attorneys, experts, documents, and witnesses. You
                and ERP.AI agree that disputes will only be arbitrated on an individual basis and
                shall not be consolidated, on a class wide, representative basis, or with any other
                arbitration(s) or other proceedings that involve any claim or controversy of any
                other party. You and ERP.AI expressly waive any right to pursue any class or other
                representative action against each other. Failure or any delay in enforcing this
                arbitration provision in connection with any particular claim will not constitute a
                waiver of any rights to require arbitration at a later time or in connection with
                any other claims except that all claims must be brought within 1 year after the
                claim arises. If the class action waiver is deemed unenforceable (i.e.,
                unenforceability would allow arbitration to proceed as a class or representative
                action), then the remaining portions of this arbitration provision shall remain in
                full force and effect. In order to use the payment functionality of our application,
                you must open a &quot;Dwolla Platform&quot; account provided by Dwolla, Inc. and you
                must accept the Dwolla Terms of Service and Privacy Policy. Any funds held in the
                Dwolla account are held by Dwolla&apos;s financial institution partners as set out
                in the Dwolla Terms of Service. You authorize us to collect and share with Dwolla
                your personal information including full name, date of birth, social security
                number, physical address, email address and financial information, and you are
                responsible for the accuracy and completeness of that data. You understand that you
                will access and manage your Dwolla account through our application, and Dwolla
                account notifications will be sent by us, not Dwolla. We will provide customer
                support for your Dwolla account activity, and can be reached at https://erp.ai/,&nbsp;
                <Link
                to={'mailto:care@erp.ai'} 
                className="underline"
              >
               care@erp.ai
              </Link>
                . Any user who wants to utilize the Payment functionality of Dwolla must
                be at least 18 years old. For employees only: You expressly authorize our service
                provider, Dwolla, Inc. to originate credit transfers to your financial institution
                account. You authorize us to collect and share with Dwolla your personal information
                including full name, email address and financial information, and you are
                responsible for the accuracy and completeness of that data. Dwolla&apos;s Privacy
                Policy is available here. YOU UNDERSTAND THAT YOU AND YOUR BUSINESS WOULD HAVE HAD A
                RIGHT TO LITIGATE THROUGH A COURT, TO HAVE A JUDGE OR JURY DECIDE YOUR CASE, AND TO
                BE PARTY TO A CLASS OR REPRESENTATIVE ACTION. HOWEVER, YOU UNDERSTAND AND AGREE TO
                HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL, AND
                CONFIDENTIAL ARBITRATION IN ACCORDANCE WITH THIS ARBITRATION PROVISION.
              </p>
              <h3 className='font-instrument-serif'>
                FOR CUSTOMERS WITH A PRINCIPAL PLACE OF BUSINESS OUTSIDE OF THE UNITED STATES:
              </h3>
              <p>
                By arbitration administered by the Singapore International Arbitration Centre
                (&ldquo;SIAC&rdquo;) in accordance with the Arbitration Rules of the Singapore
                International Arbitration Centre (&quot;SIAC Rules&quot;) for the time being in
                force, which rules are deemed to be incorporated by reference in this clause. The
                seat of the arbitration shall be Singapore. The Tribunal shall consist of one
                arbitrator. The language of the arbitration shall be English. This contract is
                governed by the laws of Singapore. The arbitrator shall have the exclusive and sole
                authority to resolve any dispute relating to the interpretation, construction,
                validity, applicability, or enforceability of these Terms, the Privacy Statement,
                the DPA, this arbitration provision, and any other terms incorporated by reference
                into these Terms. The arbitrator shall have the exclusive and sole authority to
                determine whether any dispute is arbitrable. The arbitrator shall have the exclusive
                and sole authority to determine whether this arbitration agreement can be enforced
                against a non-signatory to this agreement and whether a non-signatory to this
                agreement can enforce this provision against you or ERP.AI. Payment of all filing,
                administration, and arbitrator fees will be governed by the SIAC Rules. In all other
                respects, the parties shall each pay their own additional fees, costs, and expenses,
                including, but not limited to, those for any attorneys, experts, documents, and
                witnesses. You and ERP.AI agree that disputes will only be arbitrated on an
                individual basis and shall not be consolidated, on a class wide, representative
                basis, or with any other arbitration(s) or other proceedings that involve any claim
                or controversy of any other party. You and ERP.AI expressly waive any right to
                pursue any class or other representative action against each other. Failure or any
                delay in enforcing this arbitration provision in connection with any particular
                claim will not constitute a waiver of any rights to require arbitration at a later
                time or in connection with any other claims except that all claims must be brought
                within 1 year after the claim arises. If the class action waiver is deemed
                unenforceable (i.e., unenforceability would allow arbitration to proceed as a class
                or representative action), then the remaining portions of this arbitration provision
                shall remain in full force and effect.{' '}
                <strong>
                  YOU UNDERSTAND THAT YOU AND YOUR BUSINESS MAY HAVE HAD A RIGHT TO LITIGATE THROUGH
                  A COURT, TO HAVE A JUDGE OR JURY DECIDE YOUR CASE, AND TO BE PARTY TO A CLASS OR
                  REPRESENTATIVE ACTION. HOWEVER, YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS
                  DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL, AND CONFIDENTIAL ARBITRATION
                  IN ACCORDANCE WITH THIS ARBITRATION PROVISION.
                </strong>
              </p>
            </section>

            <section id="terms-section-17">
              <h2 className='font-instrument-serif'>Section 17 - ERP.AI&apos;S Additional Remedies</h2>
              <p>
                In order to prevent or limit irreparable injury to ERP.AI, in the event of any
                breach or threatened breach by you of the provisions of this Agreement or any
                infringement or threatened infringement by you of the intellectual property of
                ERP.AI or a third-party, ERP.AI shall be entitled to seek a temporary restraining
                order and preliminary and permanent injunctions or other equitable relief from a
                court of competent jurisdiction restraining such breach, threatened breach,
                infringement, or threatened infringement. Nothing in this Agreement shall be
                construed as prohibiting ERP.AI from pursuing in court any other remedies available
                to it for such breach, threatened breach, infringement, or threatened infringement,
                including the recovery of monetary damages from you and your business.
              </p>
            </section>

            <section id="terms-section-18">
              <h2 className='font-instrument-serif'>Section 18 - Indemnification</h2>
              <p>
                To the fullest extent permitted by law, you agree to defend, indemnify, and hold
                harmless ERP.AI, its directors, officers, employees, shareholders, licensors,
                independent contractors, subcontractors, suppliers, affiliates, parent companies,
                subsidiaries, and agents from and against any and all claims, actions, loss,
                liabilities, damages, expenses, demands, and costs of any kind, including, but not
                limited to attorneys&apos; fees and costs of any litigation or other dispute
                resolution, arising out of, resulting from, or in any way connected with or related
                to (1) your use, misuse, or attempt to use the Website, software, products, or
                services, (2) information you submit or transmit through the Website, (3) your
                breach of these Terms, the documents they incorporate by reference, the Agreement,
                or the representations and warranties provided by you in this Agreement, or (4) your
                violation of any law or the rights of a third-party.
              </p>
            </section>

            <section id="terms-section-19">
              <h2 className='font-instrument-serif'>Section 19 - Notice And Takedown Procedures; Copyright Act</h2>
              <p>
                If you believe that materials or content available on the Website infringes any
                copyright you own, you or your agent may send ERP.AI a notice requesting that ERP.AI
                remove the materials or content from the Website. If you believe that someone has
                wrongly filed a notice of copyright infringement against you, you may send ERP.AI a
                counter-notice. Notices and counter-notices should be sent to ERP.AI, Attention
                Legal Department, by e-mail to&nbsp;
                <Link
                to={'mailto:legal@erp.ai'} 
                className="underline"
              >
               legal@erp.ai
              </Link>
              </p>
            </section>

            <section id="terms-section-20">
              <h2 className='font-instrument-serif'>Section 20 - Third-Party Links</h2>
              <p>
                The Website may contain links to other websites. ERP.AI assumes no responsibility
                for the content or functionality of any non-ERP.AI website to which we provide a
                link.
              </p>
            </section>

            <section id="terms-section-21">
              <h2 className='font-instrument-serif'>Section 21 - Termination</h2>
              <p>
                This Agreement will take effect (or shall re-take effect) at the time you click{' '}
                <strong>&ldquo;ACTIVATE MY ACCOUNT NOW,&rdquo;</strong>{' '}
                <strong>&ldquo;PAY NOW,&rdquo;</strong> <strong>&ldquo;ORDER NOW&rdquo;,</strong>{' '}
                <strong>&ldquo;SUBMIT&rdquo;,</strong> <strong>&ldquo;BUY NOW&rdquo;,</strong>{' '}
                <strong>&ldquo;PURCHASE&rdquo;,</strong>{' '}
                <strong>&ldquo;START MY TRIAL&rdquo;</strong>{' '}
                <strong>&ldquo;I ACCEPT&rdquo;,</strong> <strong>&ldquo;I AGREE&rdquo;</strong> or
                similar links or buttons, otherwise submit information through the Website, respond
                to a request for information, begin installing, accessing, or using the Website,
                complete a purchase, select a method of payment, and/or enter in payment method
                information, whichever is earliest. If, in our sole discretion, you fail, or we
                suspect that you have failed, to comply with any term or provision of the Agreement
                or violated any law, whether in connection with your use of ERP.AI or otherwise, we
                may terminate the Agreement or suspend your access to the Website at any time
                without notice to you. Sections 11, 12, 15 through 21, and 24 through 33 of this
                Agreement, as well as any representations, warranties, and other obligations made or
                undertaken by you, shall survive the termination of this Agreement and/or your
                account or relationship with ERP.AI. Upon termination, you remain responsible for
                any outstanding payments due to ERP.AI.
              </p>
            </section>

            <section id="terms-section-22">
              <h2 className='font-instrument-serif'>Section 22 - No Waiver</h2>
              <p>
                No failure or delay on the part of ERP.AI in exercising any right, power or remedy
                under this Agreement may operate as a waiver, nor may any single or partial exercise
                of any such right, power, or remedy preclude any other or further exercise of such
                right, power, or remedy, or the exercise of any other rights, power, or remedy under
                this Agreement. A waiver of any right or obligation under this Agreement shall only
                be effective if in writing and signed by ERP.AI.
              </p>
            </section>

            <section id="terms-section-23">
              <h2 className='font-instrument-serif'>Section 23 - Governing Law And Venue</h2>
              <p>
                This Agreement and any issue or dispute arising out of or otherwise related to this
                Agreement or your access to or use of the Website, our Privacy Statement or DPA, or
                any matter concerning ERP.AI, including your purchase and use or attempted use of
                any service or product, shall be governed exclusively by the laws of State of Idaho
                without regard to its conflicts of laws principles. To the extent that any claim or
                dispute is found by the arbitrator or (if proper) a court of competent jurisdiction
                to be excluded from the arbitration agreement in Section 19 above, the parties agree
                any such claim or dispute shall be exclusively brought in and decided by the state
                or federal courts located in Ada County, Idaho, and you hereby irrevocably consent
                to the exclusive personal jurisdiction of, and exclusive venue in, such courts, and
                forever waive any challenge to said courts&apos; exclusive jurisdiction or venue.
                All such claims must be brought on an individual and non-class, non-representative
                basis, and you forever waive any right to bring such claims on a class wide or
                representative basis.
              </p>
            </section>

            <section id="terms-section-24">
              <h2 className='font-instrument-serif'>Section 24 - Force Majeure</h2>
              <p>
                ERP.AI will not be responsible to you for any delay, damage, or failure caused or
                occasioned by any act of nature or other causes beyond our reasonable control.
              </p>
            </section>

            <section id="terms-section-25">
              <h2 className='font-instrument-serif'>Section 25 - Assignment</h2>
              <p>
                ERP.AI may assign its rights under this Agreement at any time, without notice to
                you. Your rights arising under this Agreement cannot be assigned without
                ERP.AI&apos;s (or its assigns&apos;) express written consent.
              </p>
            </section>

            <section id="terms-section-26">
              <h2 className='font-instrument-serif'>Section 26 - Electronic Signature</h2>
              <p>
                All information communicated on the Website is considered an electronic
                communication. When you communicate with ERP.AI through or on the Website or via
                other forms of electronic media, such as e-mail, you are communicating with the
                company electronically. You agree that we may communicate electronically with you
                and that such communications, as well as notices, disclosures, agreements, and other
                communications that we provide to you electronically, are equivalent to
                communications in writing and shall have the same force and effect as if they were
                in writing and signed by the party sending the communication.
              </p>
            </section>

            <section id="terms-section-27">
              <h2 className='font-instrument-serif'>Section 27 - Changes To The Agreement</h2>
              <p>
                We reserve the right, at our sole discretion, to update, change or replace any part
                of the Agreement, including the Privacy Statement or Data Processing Addendum by
                posting updates and changes to our Website. It is your responsibility to check our
                Website periodically for changes. Your continued use of or access to our Website
                following the posting of any changes to the Agreement constitutes acceptance of
                those changes.
              </p>
            </section>

            <section id="terms-section-28">
              <h2 className='font-instrument-serif'>Section 28 - Your Additional Representations And Warranties</h2>
              <h3 className='font-instrument-serif'>You hereby further represent and warrant:</h3>
              <ol>
                <li>
                  that you are at least eighteen (18) years of age, or the legal age of majority in
                  your jurisdiction, whichever is greater;
                </li>
                <li>
                  that you own, operate, and/or have the right to bind the business for which you
                  are using the Website;
                </li>
                <li>
                  have read this Agreement and thoroughly understand and agree to the terms
                  contained in this Agreement; and
                </li>
                <li>
                  that you will not resell, re-distribute, or export any product or service that you
                  order from the Website. You further represent that ERP.AI has the right to rely
                  upon all information provided to ERP.AI by you, and ERP.AI may contact you, your
                  business, and any subaccounts you create by email, telephone, or postal mail for
                  any purpose, including but not limited to
                  <p>(i) follow-up calls,</p>
                  <p>(ii) satisfaction surveys, and</p>
                  <p>
                    (iii) inquiries about any orders you placed, or considered placing, on or
                    through the Website.{' '}
                  </p>
                </li>

                <p>
                  You further represent and warrant that there are no prior or pending government
                  investigations or inquiries of, or prosecutions against you, or any business
                  related to you, by the Federal Trade Commission, any other federal or state
                  governmental agency, or any industry regulatory authority, anywhere in the world,
                  nor any prior or pending private lawsuits against you. If at any time during the
                  life of the Agreement you, or any business related to you, becomes the subject of
                  a government investigation, inquiry, or prosecution by a federal or state
                  governmental agency, or any industry regulatory authority anywhere in the world,
                  or the subject of any lawsuit, you will notify ERP.AI of the same within
                  twenty-four (24) hours. ERP.AI, at its sole discretion, may terminate the
                  Agreement based on any investigation, proceeding, or lawsuit identified pursuant
                  to this paragraph or otherwise discovered by ERP.AI without incurring any
                  obligation or liability to you.
                </p>
              </ol>
            </section>

            <section id="terms-section-29">
              <h2 className='font-instrument-serif'>Section 29 - Severability</h2>
              <p>
                If any provision of this Agreement is found by the arbitrator or (if proper) a court
                of competent jurisdiction to be invalid or unenforceable, the remaining provisions
                shall not be affected thereby and shall continue in full force and effect and such
                provision may be modified or severed from this Agreement to the extent necessary to
                make such provision enforceable and consistent with the remainder of the Agreement.
              </p>
            </section>

            <section id="terms-section-30">
              <h2 className='font-instrument-serif'>Section 30 - Entire Agreement</h2>
              <p>
                These Terms, the Privacy Statement and DPA, and any policies or operating rules
                posted by us on the Website or in respect to the Website constitutes the entire
                agreement and understanding between you and your business and ERP.AI and governs
                your access to and use of the Website and your ordering, purchasing, and use and/or
                attempted use of any service or product, and supersedes and replaces any prior or
                contemporaneous agreements, representations, communications, and proposals, whether
                oral or written, between you and ERP.AI. We may also, in the future, offer new
                services and/or features through the Website. Such new features and/or services
                shall also be subject to this Agreement, and any policies or operating rules posted
                by us on the Website. Any ambiguities in the interpretation of these Terms or the
                Agreement shall not be construed against the drafting party.
              </p>
            </section>

            <section id="terms-section-31">
              <h2 className='font-instrument-serif'>Section 31 - Contacting Us</h2>
              <p>
                We encourage our customers to contact us with questions or comments about our
                products and services. Please feel free to do so by sending an email to&nbsp;
                <Link
                to={'mailto:care@erp.ai'} 
                className="underline"
              >
               care@erp.ai
              </Link>
              </p>
            </section>
          </div>
          <StickySidebar data={termsSidebarItems} />
        </div>
      </div>
    </section>
    </div>
  )
}
