import { Fragment, useEffect, useState } from 'react';

import { DKLabel, DKButton, DKLine, DKIcons, DKIcon } from 'deskera-ui-library';
import ic_tick_green from '../../assets/icons/ic_tick_green.png';
import ic_cross_red from '../../assets/icons/ic_cross_red.png';

import {
  AUTOMATION_ACTIONS,
  AUTOMATION_ELEMENT,
  AUTOMATION_TRIGGERS,
  CONNECTOR_DIMENSION,
  DROPDOWN_DEFAULT_OPTIONS,
  MAX_VISIBLE_STEP_VALUE_CHARACTERS,
  SPLIT_TYPE_ACTIONS,
  STEP_CARD_WIDTH,
  WAIT_INTERVALS
} from '../../constants/Automation';
import Utility, {
  getCapitalized,
  getFilterOperatorName,
  isNotEmptyObject
} from '../../utility/Utility';

import { FILTER_OPERATORS } from '../../constants/Enum';
import AutomationManager from '../../managers/AutomationManager';
import { fetchAllRecords, isSelectField } from '../../services/table';
import LineArrow from '../../components/automation-ui/LineArrow';
import ActionIcon from '../../components/automation-ui/ActionIcon';
import AppIcons from '../../assets/icons/AppIcons';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import { useAppSelector } from '../../redux/hooks';
import { selectTables } from '../../redux/slices/tableSlice';

interface IStepProps {
  table: any;
  stepData: {
    id?: string;
    title: string;
    elementType: string;
    type: string;
    configuration: any;
  };
  splitDimensions: null | {
    stepWidth: number;
    leftWingPadding: number;
    rightWingPadding: number;
    leftWingHangerWidth: number;
    rightWingHangerWidth: number;
    justifyWings: 'start' | 'end' | 'center';
  };
  canShowAddActionOption: (splitIndex: number) => boolean;
  onEdit: () => void;
  onDelete: () => void;
  onAddButtonClick: (childIndex: number) => void;
  leftChild: JSX.Element;
  rightChild: JSX.Element;
}

const Step = (props: IStepProps) => {
  let tableName = props.table?.name ?? '';

  // moved to component GetStepColumnInfo, can be removed
  // const getStepColumnInfo = () => {
  //   const { configuration: stepConfiguration } = props.stepData;
  //   let info = [];

  //   let columns = (props.table?.columnsMetaData ?? []).map((column) => ({
  //     ...column,
  //     key: column.id
  //   }));

  //   stepConfiguration.fields.forEach(async (data, index) => {
  //     let key = Object.keys(data)?.find(
  //       (dataKey) => dataKey !== 'opr' && dataKey !== 'isDynamicValue'
  //     );

  //     let column = columns?.find((column) => column.id === key);

  //     let columnValue = data[key];
  //     if (Utility.isEmptyObject(column) || Utility.isEmptyObject(columnValue))
  //       return;
  //     if (isSelectField(column) && isNotEmptyObject(column.options)) {
  //       if (
  //         column.type === CUSTOM_INPUT_TYPE.MULTI_SELECT ||
  //         column.type === CUSTOM_INPUT_TYPE.SELECT ||
  //         column.type === CUSTOM_INPUT_TYPE.REF
  //       ) {
  //         let options = [];
  //         if (column?.options?.length) {
  //           options.push(...column.options);
  //         }
  //         options.push(...DROPDOWN_DEFAULT_OPTIONS);

  //         columnValue =
  //           options.find((option) => option.id === columnValue?.[0])?.name ??
  //           columnValue;
  //       } else if (column.type === CUSTOM_INPUT_TYPE.REF) {
  //         try {
  //           const refTableId = column.refTable?._id;
  //           // fetch table data
  //           const table: any = await fetchTable({ tableId: refTableId });
  //           if (!table) {
  //             throw new Error('Failed to fetch table data');
  //           }

  //           // fetch all records
  //           const response = await fetchAllRecords(refTableId);
  //           if (response?.length) {
  //             // find selected option
  //             const selectedOption = response.find(
  //               (obj) => obj._id === data[key]
  //             );
  //             if (selectedOption) {
  //               if (table.nameField) {
  //                 columnValue = selectedOption.cells[table.nameField] || '';
  //               } else if (
  //                 selectedOption &&
  //                 typeof selectedOption === 'object' &&
  //                 selectedOption.cells
  //               ) {
  //                 const [[firstKey, firstValue]] = Object.entries(
  //                   selectedOption.cells
  //                 );
  //                 columnValue = firstValue;
  //               }
  //             }
  //           }
  //         } catch (error) {
  //           console.error('Failed to feth options');
  //         }
  //       }
  //     } else {
  //       if (
  //         typeof columnValue === 'string' &&
  //         columnValue?.length > MAX_VISIBLE_STEP_VALUE_CHARACTERS
  //       ) {
  //         columnValue =
  //           columnValue.slice(0, MAX_VISIBLE_STEP_VALUE_CHARACTERS) + '...';
  //       }
  //     }

  //     const operatorStr =
  //       props.stepData.elementType == AUTOMATION_ELEMENT.TRIGGER
  //         ? getFilterOperatorName(data['opr'])
  //         : 'to';

  //     info.push(
  //       <div className="mt-r row justify-content-between">
  //         <DKLabel text={`${column.name} ${operatorStr} ${columnValue}`} />
  //         {/* <DKButton
  //           icon={AppIcons.white.ic_close}
  //           onClick={() => {
  //             console.log(data);
  //           }}
  //         /> */}
  //       </div>
  //     );
  //   });
  //   return info;
  // };

  const getSplitConditionsColumnInfo = (
    configurationList = [],
    wait = null
  ) => {
    let info = `<div class="mb-xs">Split into two branches</div>`;

    if (wait) {
      info = `<div class="text-align-left">Wait for ${wait.count} ${
        WAIT_INTERVALS[wait.intervalIndex]
      } or proceed when</div>`;
    }

    configurationList?.forEach((conditionsByType) => {
      let columns = (props.table?.columnsMetaData ?? []).map((column) => ({
        ...column,
        key: column.id
      }));

      conditionsByType.fields.forEach((data) => {
        let key = Object.keys(data)?.find((dataKey) => dataKey !== 'opr');

        let column = columns?.find((column) => column.id === key);

        if (Utility.isEmptyObject(column)) return;

        let columnValue = isSelectField(column)
          ? column.options?.find(
              (option) => option.id === data[key][0] || option.id === data[key]
            )?.name
          : data[key];

        if (Utility.isEmptyObject(columnValue)) return;

        if (
          typeof columnValue === 'string' &&
          columnValue?.length > MAX_VISIBLE_STEP_VALUE_CHARACTERS
        ) {
          columnValue =
            columnValue.slice(0, MAX_VISIBLE_STEP_VALUE_CHARACTERS) + '...';
        }
        info += `<b>${getCapitalized(tableName)}.${column.name}</b> ${
          data.opr === FILTER_OPERATORS.EQUAL ? '==' : '=>'
        } ${columnValue}<br>`;
      });
    });

    return info.slice(0, -2);
  };

  const getStepInfo = () => {
    try {
      const { type: stepType, configuration: stepConfiguration } =
        props.stepData;

      if (
        stepType === AUTOMATION_ACTIONS.SPLIT_WORKFLOW &&
        stepConfiguration?.length > 0
      ) {
        return getSplitConditionsColumnInfo(stepConfiguration);
      }

      if (
        stepType === AUTOMATION_ACTIONS.WAIT_TILL &&
        isNotEmptyObject(stepConfiguration?.condition)
      ) {
        return getSplitConditionsColumnInfo(
          stepConfiguration.condition,
          stepConfiguration.wait
        );
      }

      if (isNotEmptyObject(stepConfiguration?.fields)) {
        return (
          <GetStepColumnInfo stepData={props.stepData} table={props.table} />
        );
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const getStepTitle = () => {
    const { type, title, configuration } = props.stepData;

    let titleToDisplay = title;
    switch (type) {
      case AUTOMATION_ACTIONS.UPDATE_RECORD:
        titleToDisplay = `Update a ${tableName}`;
        break;
      case AUTOMATION_ACTIONS.CREATE_RECORD:
        titleToDisplay = `Create a ${tableName}`;
        break;
      case AUTOMATION_TRIGGERS.RECORD_UPDATED:
        titleToDisplay = `Start when ${tableName} is updated`;
        break;
      case AUTOMATION_TRIGGERS.RECORD_CREATED:
        titleToDisplay = `Start when ${tableName} is created`;
        break;
      case AUTOMATION_ACTIONS.WAIT_FOR_TIME:
        titleToDisplay = `Wait for ${AutomationManager.getWaitIntervals(
          configuration.intervalIndex,
          configuration.count
        )}`;
        break;
      default:
        break;
    }
    return titleToDisplay;
  };

  /****************** RENDERERS ***************** */

  const renderMoreSection = ({ className = '', style = {} }) => {
    const { onEdit, onDelete } = props;
    return (
      <div
        className={`step-action-wrapper border-radius-s ml-l cursor-hand position-relative ${className}`}
        style={{ ...style, ...{ padding: 2 } }}
      >
        <DKIcon src={AppIcons.ic_more} className="more-icon ic-s opacity-8" />
        <div
          className="column step-actions-list column border-radius-m position-absolute bg-white border-s"
          style={{ left: 0, top: 0 }}
        >
          <div className="row  listPickerBG p-h-s p-v-xs" onClick={onEdit}>
            <DKIcon src={AppIcons.ic_edit} className="mr-r ic-s" />
            <DKLabel text="Edit" />
          </div>
          <div className="row listPickerBG p-h-s p-v-xs" onClick={onDelete}>
            <DKIcon src={AppIcons.red.ic_delete} className="mr-r ic-s" />
            <DKLabel text="Delete" />
          </div>
        </div>
      </div>
    );
  };

  const renderStepCard = () => {
    const { stepData } = props;
    if (stepData.elementType === AUTOMATION_ELEMENT.TRIGGER) {
      return (
        <>
          <div className="column width-auto p-h-r p-v-s bg-app mt-xxl border-radius-m cursor-hand">
            <div className="row justify-content-between">
              <div className="row width-auto">
                <DKIcon src={AppIcons.automation.ic_trigger} />
                <DKLabel
                  text={getStepTitle()}
                  className="ml-r text-white fw-m"
                />
              </div>
              {renderMoreSection({
                style: { backgroundColor: 'rgba(255, 255, 255, 0.3)' }
              })}
            </div>
            {isNotEmptyObject(stepData?.configuration?.fields) && (
              <div className="column parent-width text-white">
                <DKLine className="m-v-s opacity-2" />
                {getStepInfo()}
              </div>
            )}
          </div>
        </>
      );
    }
    return (
      <div
        className="column p-h-l p-v-m bg-white shadow-s-2 border-radius-m border-s"
        style={{ marginTop: -6, minWidth: STEP_CARD_WIDTH, flexShrink: 0 }}
        id={`step-${stepData.id}`}
      >
        <div className="row justify-content-between">
          <div className="row width-auto ">
            <ActionIcon type={stepData.type} />
            <DKLabel text={getStepTitle()} className="ml-r " />
          </div>
          {renderMoreSection({ className: 'bg-gray1' })}
        </div>
        {isNotEmptyObject(stepData?.configuration?.fields) && (
          <>
            <DKLine className="m-v-s" />
            {getStepInfo()}
          </>
        )}
      </div>
    );
  };

  const renderPlusButton = (splitIndex: number) => {
    const { stepData, canShowAddActionOption, onAddButtonClick } = props;

    return stepData.type !== AUTOMATION_ACTIONS.END_WORKFLOW ? (
      <div className={`column align-items-center width-auto`}>
        <div className="bg-gray4" style={{ ...CONNECTOR_DIMENSION }} />
        {canShowAddActionOption(splitIndex) ? (
          <DKButton
            className="bg-white shadow-s border-radius-l unselectable"
            icon={DKIcons.ic_add}
            onClick={
              onAddButtonClick ? () => onAddButtonClick(splitIndex || 0) : null
            }
          />
        ) : null}
        <div className="bg-gray4" style={{ ...CONNECTOR_DIMENSION }} />
      </div>
    ) : null;
  };

  const renderSplitWings = () => {
    const { splitDimensions, leftChild, rightChild } = props;

    return (
      <Fragment>
        <DKLine className="bg-gray4" style={{ ...CONNECTOR_DIMENSION }} />
        <div
          className={`row justify-content-${splitDimensions.justifyWings} align-items-start`}
          style={{
            minWidth: 2 * splitDimensions.stepWidth,
            width:
              2 *
              Math.min(
                splitDimensions.leftWingPadding,
                splitDimensions.rightWingPadding
              )
          }}
        >
          <div
            className="column align-items-center position-relative"
            style={{
              width: splitDimensions.stepWidth,
              flexShrink: 0,
              paddingRight: splitDimensions.leftWingPadding
            }}
          >
            <DKButton
              className="bg-chip-green shadow-m border-radius-l border-green position-absolute text-green unselectable z-index-3"
              style={{
                top: -18,
                right: '10%'
              }}
              title={
                props.stepData.type === AUTOMATION_ACTIONS.WAIT_TILL
                  ? 'MATCH'
                  : 'YES'
              }
              icon={ic_tick_green}
              onClick={() => {}}
            />
            <DKLine
              className="bg-gray4 position-absolute width-auto"
              style={{
                top: 0,
                right: 0,
                height: CONNECTOR_DIMENSION.width,
                width: splitDimensions.leftWingHangerWidth
              }}
            />
            {renderPlusButton(0)}
            {leftChild}
          </div>
          <div
            className="column align-items-center position-relative"
            style={{
              width: splitDimensions.stepWidth,
              flexShrink: 0,
              paddingLeft: splitDimensions.rightWingPadding
            }}
          >
            <DKLine
              className="bg-gray4 position-absolute width-auto"
              style={{
                top: 0,
                left: 0,
                height: CONNECTOR_DIMENSION.width,
                width: splitDimensions.rightWingHangerWidth
              }}
            />
            <DKButton
              className="bg-chip-red text-red border-radius-l border-red position-absolute shadow-m unselectable z-index-3"
              style={{
                top: -18,
                left: '10%'
              }}
              title={
                props.stepData.type === AUTOMATION_ACTIONS.WAIT_TILL
                  ? 'FAIL'
                  : 'NO'
              }
              icon={ic_cross_red}
              onClick={() => {}}
            />
            {renderPlusButton(1)}
            {rightChild}
          </div>
        </div>
      </Fragment>
    );
  };

  const renderStepChildWithConnector = () => {
    const { stepData, leftChild, onAddButtonClick } = props;

    return SPLIT_TYPE_ACTIONS.includes(stepData.type as AUTOMATION_ACTIONS) ? (
      renderSplitWings()
    ) : (
      <Fragment>
        {stepData.type !== AUTOMATION_ACTIONS.END_WORKFLOW && (
          <LineArrow onClick={() => onAddButtonClick(0)} />
        )}
        {leftChild}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderStepCard()}
      {renderStepChildWithConnector()}
    </Fragment>
  );
};

const GetStepColumnInfo = ({ stepData, table }) => {
  const [info, setInfo] = useState([]);
  const tables = useAppSelector(selectTables());

  useEffect(() => {
    fetchInfo();
  }, [stepData, table]);

  const fetchInfo = async () => {
    const infoList = [];
    const { configuration: stepConfiguration } = stepData;

    let columns = (table?.columnsMetaData ?? []).map((column) => ({
      ...column,
      key: column.id
    }));

    for (let data of stepConfiguration.fields) {
      let key = Object.keys(data)?.find(
        (dataKey) => dataKey !== 'opr' && dataKey !== 'isDynamicValue'
      );

      let column = columns?.find((column) => column.id === key);

      let columnValue = data[key];
      if (Utility.isEmptyObject(column) || Utility.isEmptyObject(columnValue))
        return;
      if (isSelectField(column) && isNotEmptyObject(column.options)) {
        if (
          column.type === CUSTOM_INPUT_TYPE.MULTI_SELECT ||
          column.type === CUSTOM_INPUT_TYPE.SELECT ||
          column.type === CUSTOM_INPUT_TYPE.REF
        ) {
          let options = [];
          if (column?.options?.length) {
            options.push(...column.options);
          }
          options.push(...DROPDOWN_DEFAULT_OPTIONS);

          columnValue =
            options.find((option) => option.id === columnValue?.[0])?.name ??
            columnValue;
        }
      } else if (column.type === CUSTOM_INPUT_TYPE.REF) {
        try {
          const defaultOption = DROPDOWN_DEFAULT_OPTIONS.find(
            (option) => option.id === columnValue?.[0]
          );
          if (defaultOption) {
            columnValue = defaultOption.name;
          } else {
            const refTableId = column.refTable?._id;
            // fetch table data
            const table: any = tables[refTableId];
            if (!table) {
              throw new Error('Failed to fetch table data');
            }

            // fetch all records
            const response = await fetchAllRecords(refTableId);
            if (response?.length) {
              // find selected option
              const selectedOption = response.find(
                (obj) => obj._id === columnValue?.[0]
              );
              if (selectedOption) {
                if (table.nameField) {
                  columnValue = selectedOption.cells[table.nameField] || '';
                } else if (
                  selectedOption &&
                  typeof selectedOption === 'object' &&
                  selectedOption.cells
                ) {
                  const [[firstKey, firstValue]] = Object.entries(
                    selectedOption.cells
                  );
                  columnValue = firstValue;
                }
              }
            }
          }
        } catch (error) {
          console.error('Failed to feth options');
        }
      } else {
        if (
          typeof columnValue === 'string' &&
          columnValue?.length > MAX_VISIBLE_STEP_VALUE_CHARACTERS
        ) {
          columnValue =
            columnValue.slice(0, MAX_VISIBLE_STEP_VALUE_CHARACTERS) + '...';
        }
      }

      const operatorStr =
        stepData.elementType == AUTOMATION_ELEMENT.TRIGGER
          ? getFilterOperatorName(data['opr'])
          : 'to';

      infoList.push(
        <div className="mt-r row justify-content-between">
          <DKLabel text={`${column.name} ${operatorStr} ${columnValue}`} />
        </div>
      );
    }

    setInfo(infoList);
  };

  return <>{info}</>;
};

export default Step;
