import { isEmpty } from 'lodash';
import { getDateAsString } from 'deskera-ui-library';
import { IMPORT_DEFAULT_DATE_FORMAT } from '../constants/EximConstants';
import { ITableFilterCondition } from '../model/Table';

const matchFilterConditionAgainstRow = (
  filter: ITableFilterCondition,
  value: any,
  dateFormat: string = IMPORT_DEFAULT_DATE_FORMAT
) => {
  let filterValue = filter.value;

  try {
    if (filterValue === null || typeof filterValue === 'undefined') {
      filterValue = '';
    }

    if (value === null || typeof value === 'undefined') {
      value = '';
    }

    if (Array.isArray(filterValue)) {
      filterValue = filterValue.toString();
    }

    if (Array.isArray(value)) {
      value = value.toString();
    }

    if (filterValue instanceof Date) {
      filterValue = new Date(filterValue);
      ['eq', 'neq'].includes(filter.condition) && filterValue.setHours(0, 0, 0);
      filterValue = getDateAsString(filterValue, dateFormat);
    }

    if (value instanceof Date) {
      value = new Date(value);
      ['eq', 'neq'].includes(filter.condition) && value.setHours(0, 0, 0);
      value = getDateAsString(value, dateFormat);
    }

    if (
      ['eq', 'neq'].includes(filter.condition) &&
      (Number(filterValue) || Number(filterValue) === 0)
    ) {
      filterValue = Number(filterValue);
    }

    if (
      ['eq', 'neq'].includes(filter.condition) &&
      (Number(value) || Number(value) === 0)
    ) {
      value = Number(value);
    }

    if (typeof filterValue === 'string') {
      filterValue = filterValue.toLowerCase();
    }

    if (typeof value === 'string') {
      value = value.toLowerCase();
    }
  } catch (err) {}

  switch (filter.condition) {
    // case 'is':
    //   return value === filter.value;
    // case 'isnt':
    //   return value !== filter.value;
    // case 'in':
    //   return Boolean(filter.value?.includes(value));
    // case 'nin':
    //   return !filter.value?.includes(value);
    case 'c':
      return value.includes(filterValue);
    case 'nc':
      return !value.includes(filterValue);
    case 'sw':
      return value.toString().startsWith(filter.value?.toString());
    case 'ew':
      return value.toString().endsWith(filter.value?.toString());
    case 'eq':
      return value === filterValue;
    case 'neq':
      return value !== filterValue;
    case 'emp':
      return isEmpty(value);
    case 'nemp':
      return !isEmpty(value);
    case 'lt':
      return value < filterValue;
    case 'gt':
      return value > filterValue;
    case 'lte':
      return value <= filterValue;
    case 'gte':
      return value >= filterValue;
    default:
      return false;
  }
};

export const checkFilterConditionsForRecord = (
  filterConditions: ITableFilterCondition[],
  record: Record<string, any>,
  config: {
    logicalOperator: 'and' | 'or';
    dateFormat?: string;
  }
) => {
  let result;

  try {
    const dateFormat = config?.dateFormat || IMPORT_DEFAULT_DATE_FORMAT;
    const logicalOperator = config?.logicalOperator || 'and';
    if (logicalOperator === 'and') {
      const hasNonMatchingCondition = filterConditions.some(
        (filter) =>
          !matchFilterConditionAgainstRow(
            filter,
            record[filter.key],
            dateFormat
          )
      );
      result = hasNonMatchingCondition ? false : true;
    } else {
      const hasAnyMatchingCondition = filterConditions.some((filter) =>
        matchFilterConditionAgainstRow(filter, record[filter.key], dateFormat)
      );
      result = hasAnyMatchingCondition ? true : false;
    }
  } catch (err) {
    return false;
  }

  return result;
};

export const checkSearchQueryInRecord = (
  searchQuery: string,
  record: Record<string, any>
) => {
  if (!searchQuery?.trim()) return true;

  let result = false;
  const {
    expandableView,
    invalidFields,
    lineItem,
    recordIndex,
    ...recordToSearch
  } = record;
  const recordValues = Object.values(recordToSearch);
  try {
    result = recordValues.some((value) => {
      if (!value) return false;

      if (!['string', 'number'].includes(typeof value)) return false;

      return value.toString().toLowerCase().includes(searchQuery.toLowerCase());
    });
  } catch (err) {}

  return result;
};
