import { DKDataGrid, DKLabel } from 'deskera-ui-library';
import { getActionColumn } from '../../Helper/table';
import { useEffect, useState } from 'react';
import httpClient from '../../http/index';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import { getAggregatedValue } from '../../utility/FormBuilder';
import AppIcons from '../../assets/icons/AppIcons';

interface AddEditRecordTableProps {
  field?: any;
  onChange: (data: any) => void;
  formData?: any;
}

export default function AddEditRecordFormDKTableView(
  props: AddEditRecordTableProps
) {
  const { subFields } = props?.field?.refTable ?? {};
  const [columnData, setColumnData] = useState<any>([]);
  const [rows, setRows] = useState<any[]>([{}]);
  var refTablesData: any = {};

  const getColumnDropDownConfig = (tableData: any) => {
    return {
      style: {},
      className: 'border-m',
      allowSearch: true,
      searchableKey: 'name',
      data: [],
      renderer: (index, obj) => {
        let value: any = '';
        if (obj && typeof obj === 'object' && obj.cells) {
          const [[firstKey, firstValue]] = Object.entries(obj.cells);
          value = firstValue;
        }
        return <DKLabel needIcon text={`${value ?? ''}`} />;
      },
      searchApiConfig: {
        method: 'POST',
        getUrl: (val) => {
          let url = tableData
            ? `${API_ENDPOINTS.BASE}/v1/app-builder/table/${tableData?.refTable?._id}/paged-record`
            : '';
          return url;
        },
        dataParser: (response) => {
          return response.data;
        },
        getPayload: (searchText) => {
          return {
            pageNo: 1,
            pageSize: 30,
            fetchAllRef: true,
            q: `search=${searchText}`,
            conditions: [],
            logicalOperator: 'and'
          };
        },
        getHeaders: () => {
          return {
            'Access-Control-Allow-Credentials': true,
            Authorization: httpClient.defaults.headers.common['Authorization']
          };
        }
      },
      onSelect: (index: number, value: any) => {}
    };
  };

  const getTableDetailsForAllRefTypeColumns = (refTableId: string) => {
    httpClient
      .get(API_ENDPOINTS.TABLE.FETCH(refTableId))
      .then((response: any) => {
        refTablesData[refTableId] = response;
      })
      .catch((error: any) => {});
  };

  const getColumnConfigs = () => {
    httpClient
      .get(API_ENDPOINTS.TABLE.FETCH(props?.field?.refTable?._id))
      .then((response: any) => {
        refTablesData[props?.field?.refTable?._id] = response;
        let columnConfigs = [...response?.columnsMetaData].map(
          (item: any, index: number) => {
            // since we have no idea which column will be dropdownable
            if (item?.refTable && item?.refTable?._id) {
              item.type = CUSTOM_INPUT_TYPE.DROPDOWN;
              item.allowSearch = true;
              item.formatter = (data) => {
                if (data?.rowData) {
                  // let allValues =
                  //   data.rowData?.[`${item.id}_detail`]?.[item.id]?.cells;
                  let allValues =
                    data.rowData?.[`${item.id}_detail`]?.[0]?.cells;
                  let nameValueField =
                    refTablesData?.[item?.refTable?._id]?.nameField;
                  return nameValueField && allValues
                    ? allValues?.[nameValueField]
                    : allValues
                    ? Object.values(allValues)?.[0]
                    : '';
                } else {
                  return '';
                }
              };
              item.dropdownConfig = getColumnDropDownConfig(item);
              getTableDetailsForAllRefTypeColumns(item?.refTable?._id);
            }
            item.key = item.id;
            return item;
          }
        );

        setColumnData(columnConfigs);
      })
      .catch((error: any) => {
        console.log(error, 'error while fetching the table data');
      });
  };

  useEffect(() => {
    if (props?.field?.refTable?._id) {
      getColumnConfigs();
    }
  }, [props?.field]);

  useEffect(() => {
    if (props?.formData) {
      let values = props?.formData?.[`${props.field.id}_detail`];
      values = values?.map((item: any) => {
        return {
          ...item.cells,
          _id: item._id
        };
      });
      setRows(values);
    }
  }, [props?.formData]);

  return (
    <div className="column parent-size mt-m">
      <DKDataGrid
        tableName={'lineItems'}
        title={'Line Items'}
        buttons={[
          {
            className: 'text-blue fw-m p-0',
            title: '+ Add Item',
            onClick: () => {
              let rowsCopy = [...(rows ?? [])];
              rowsCopy.push({});
              setRows(rowsCopy);
              props.onChange(rowsCopy);
            }
          }
        ]}
        updating={false}
        allowBulkOperation={false}
        allowColumnEdit={false}
        allowColumnShift={false}
        allowColumnSort={false}
        allowColumnWebhook={false}
        allowExpand={false}
        allowFilter={false}
        allowRowEdit
        allowColumnDelete={false}
        allowSearch={false}
        currentPage={0}
        needShadow={true}
        needBorder={true}
        width={'100%'}
        needTrailingColumn={true}
        allowFormulaColumn={false}
        onPageChange={(data) => {}}
        totalPageCount={1}
        filterData={(filter: any) => {}}
        onRowUpdate={(data) => {
          let rowsCopy = [...rows];
          let currentRowData = { ...data.rowData };

          if (data?.columnData?.refTable?._id) {
            // if is a dropdown column
            currentRowData[`${data.columnKey}_detail`] = [
              data?.rowData?.[data.columnKey]
            ];
            currentRowData[data.columnKey] = [
              data?.rowData?.[data.columnKey]?._id
            ];
          }
          rowsCopy[data.rowIndex] = currentRowData;
          setRows(rowsCopy);
          props.onChange(rowsCopy);
        }}
        onColumnUpdate={(data) => {}}
        onSearch={(searchText: string) => {}}
        columns={[
          ...columnData,
          {
            ...getActionColumn(),
            options: [
              rows?.length > 1 && {
                icon: AppIcons.ic_delete,
                className: 'p-0',
                onClick: (data: any) => {
                  let rowsCopy = [...rows];
                  rowsCopy.splice(data.rowIndex, 1);
                  setRows(rowsCopy);
                  props.onChange(rowsCopy);
                }
              }
            ]
          }
        ]}
        rows={rows || []}
        allowAdvancedBulkEdit={false}
      />
      {subFields?.map((field) => {
        return (
          <div
            className="row justify-content-between align-self-end mt-s"
            key={field.id}
            style={{ width: '40%' }}
          >
            <div className="column fw-b">{field.displayName}</div>
            <div className="column">
              {getAggregatedValue(field, rows, columnData) ?? 0}
            </div>
          </div>
        );
      })}
    </div>
  );
}
