import httpClient from '../http/index';
import { API_ENDPOINTS } from '../constants/Endpoints';

export const getHumanReadableFileSize = (fileSize: number): string => {
	const fileSizeInMB = fileSize / (1024 * 1024);
	if (fileSizeInMB >= 1) {
		return `${fileSizeInMB.toFixed(0)} MB`;
	} else {
		const fileSizeInKB = fileSize / 1024;
		return `${fileSizeInKB.toFixed(0)} KB`;
	}
};
export const uploadFileToAWS = async (
	file: File,
	entity = 'REPORT_THUMBNAIL'
): Promise<string> => {
	let awsURL = '';
	let formData = new FormData();
	formData.append('file', file);
	formData.append('file-entity', entity);
	try {
		const response: any = await httpClient.post(
			API_ENDPOINTS.FILES.UPLOAD,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		awsURL = response?.publicUrl || '';
	} catch (error) {
		console.log('Error uploading file', error);
	}
	return Promise.resolve(awsURL);
};
