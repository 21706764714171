import React from 'react';
import ReactDOM from 'react-dom';
import httpClient from '../http';
import Popup from './common/Popup';
import {
  DKButton,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  isEmpty,
  showToast,
  TOAST_TYPE,
  DKLine
} from 'deskera-ui-library';
import DKFormEditorPers from './DKFormEditorPers';

import { CHAR_CODES } from '../constants/Constant';
import Utility, {
  getDisplayNameForType,
  getIconByDataType,
  getRandomNumber,
  getDKInputValueStyle
} from '../utility/Utility';
import { CUSTOM_INPUT_TYPE } from '../constants/Constants';
import { API_ENDPOINTS } from '../constants/Endpoints';
import AppIcons from '../assets/icons/AppIcons';

export interface IAddColumnPopupProps {
  tableName: string;
  popupId?: string;
  onSave?: (response?: any) => void;
  onClose?: () => void;
  appId: string;
  tableId: string;
  onFormulaEdit?: any;
  columnDList?: any;
  source?: 'TABLE' | 'OTHER';
}

export interface IAddColumnPopupState {
  formData: any;
  optionFieldText: string;
  submitted: boolean;
  refTableID: string;
  refTabName: string;
  tableName: string;
  needFormulaEditorPopup: boolean;
}

class AddColumnPopup extends React.Component<
  IAddColumnPopupProps,
  IAddColumnPopupState
> {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        type: INPUT_TYPE.TEXT,
        required: false,
        editable: true,
        hidden: false,
        systemField: false,
        uiVisible: true,
        options: [],
        formula: [],
        isAIColumn: false,
        selectedColumn: [],
        selectedColumnIndexes: [],
        promptMessage: 'Get data for selected columns'
      },
      optionFieldText: '',
      submitted: false,
      refTableID: '',
      refTabName: '',
      tableName: props.tableName,
      needFormulaEditorPopup: false
    };
  }
  hideFormulaEditorPopup = () => {
    this.setState({
      needFormulaEditorPopup: false
    });
  };
  getFormulaEditorPopup() {
    return (
      <DKFormEditorPers
        columns={this.props.columnDList}
        formula={this.state.formData.formula}
        onSaveFormula={(formula) => {
          this.state.formData['formula'] = formula;

          this.hideFormulaEditorPopup();
        }}
        onClose={() => this.hideFormulaEditorPopup()}
      />
    );
  }
  onOptionChange = (value) => {
    this.setState({
      optionFieldText: value
    });
  };
  onOptionKeyPress = (e) => {
    if (e.charCode === CHAR_CODES.ENTER) {
      this.onOptionAdd();
    }
  };
  onOptionAdd = () => {
    if (isEmpty(this.state.optionFieldText)) return;
    let options = [...this.state.formData.options];
    let { optionFieldText } = this.state;
    const newID = parseInt(
      getRandomNumber(1000) + '' + this.state.formData.options.length
    );
    options.push({
      id: newID,
      name: optionFieldText,
      color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`
    });
    this.onFormValueChange('options', options);
    this.setState({
      optionFieldText: ''
    });
  };
  onFormValueChange = (key, value) => {
    if (value === CUSTOM_INPUT_TYPE.FORMULA) {
      this.setState({
        needFormulaEditorPopup: true
      });
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  };
  onSave = async () => {
    this.setState({
      submitted: true
    });
    const { name, type, options, required } = this.state.formData;
    // Early return conditions
    if (name?.trim()?.length === 0) {
      return;
    }

    if (
      required &&
      (type === 'SELECT' || type === 'MULTI_SELECT') &&
      (!options || options.length === 0)
    ) {
      showToast('Please add at least one option', TOAST_TYPE.FAILURE);
      return;
    }
    if (
      (type === CUSTOM_INPUT_TYPE.TABLE || type === CUSTOM_INPUT_TYPE.REF) &&
      Utility.isEmptyObject(this.state.refTableID)
    ) {
      showToast('Please pick a table', TOAST_TYPE.FAILURE);
      return;
    }

    // Prepare column data with assumed mappings and defaults
    const columnData = {
      name: this.state.formData.name,
      index: 0,
      type: this.state.formData.type.toLowerCase(), // Convert type as needed
      options: this.state.formData.options || [],
      required: this.state.formData.required,
      width: this.state.formData.width || 200, // Adjust default width as needed
      editable: this.state.formData.editable,
      hidden: this.state.formData.hidden,
      systemField: this.state.formData.systemField,
      uiVisible: this.state.formData.uiVisible,
      refTable: this.state.refTableID ? { _id: this.state.refTableID } : null,
      formula: this.state.formData.formula || [],
      aiConfig: this.state.formData.isAIColumn
        ? {
            instruction: this.state.formData.promptMessage,
            colWhoseValuesToExtract: this.state.formData.selectedColumn?.map(
              (column) => column.id
            )
          }
        : {}
    };

    // API call to add column
    try {
      const { tableId } = this.props;
      // TODO - remove this later, added till API's are available
      if (this.props.source !== 'OTHER' && !Utility.isEmptyObject(tableId)) {
        let response;

        response = await httpClient.post(
          API_ENDPOINTS.COLUMN.ADD(tableId),
          columnData
        );

        const addedColumn = response.data;

        // Call onSave callback with the added column information
        if (this.props.onSave) {
          this.props.onSave({ columnData: addedColumn });
          this.removePopUp();
        }
      } else {
        if (this.props.onSave) {
          this.props.onSave({ columnData });
          this.removePopUp();
        }
      }
    } catch (error) {
      console.error('Error adding column:', error);
      showToast('Error adding column', TOAST_TYPE.FAILURE);
    }
  };
  onCancel = () => {
    this.removePopUp();
  };
  removePopUp = () => {
    if (this.props.popupId)
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
    document.getElementById(this.props.popupId)?.remove();
    if (this.props.onClose) this.props.onClose();
  };
  render() {
    return (
      <Popup isScrollable={true}>
        {this.getHeader()}
        {this.getForm()}
        {this.getAIConfigSection()}
        {this.getFooter()}
      </Popup>
    );
  }
  getHeader = () => {
    return (
      <div className="row align-items-center justify-content-between parent-width mb-m">
        <div className="row fw-m ">New {this.getFieldDisplayName()}</div>
        <DKButton icon={AppIcons.ic_close} onClick={this.onCancel} />
      </div>
    );
  };

  getFooter = () => {
    return (
      <div className="column parent-width mt-s">
        <DKLine />
        <div className="row-reverse action-btn-wrapper mt-l">
          <DKButton
            className="ml-r bg-button text-white"
            title="Save"
            onClick={this.onSave}
          />
          <DKButton className="" title="Cancel" onClick={this.onCancel} />
        </div>
      </div>
    );
  };
  getFieldDisplayName = () => {
    let title = 'Column';
    if (this.props.source === 'OTHER') {
      title = 'Field';
    }
    return title;
  };

  onOptionRemove = (index) => {
    let options = [...this.state.formData.options];
    options.splice(index, 1);
    this.onFormValueChange('options', options);
  };
  getForm = () => {
    const { appId, tableId } = this.props;
    const showTableDropDown =
      this.state.formData.type === CUSTOM_INPUT_TYPE.REF ||
      this.state.formData.type === CUSTOM_INPUT_TYPE.TABLE;
    return (
      <div
        className="column parent-width bg-white mt-m "
        style={{ paddingBottom: 0 }}
      >
        <div className="row">
          <DKInput
            valueStyle={getDKInputValueStyle()}
            required={true}
            autoFocus={true}
            haserror={
              this.state.submitted &&
              this.state.formData.name.trim().length === 0
            }
            title={`${this.getFieldDisplayName()} Name`}
            placeholder="Enter column display name"
            type={INPUT_TYPE.TEXT}
            value={this.state.formData.name}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(value) => this.onFormValueChange('name', value)}
          />
        </div>
        <div className="row mt-l">{this.getFieldTypeSelection()}</div>
        {(this.state.formData.type === CUSTOM_INPUT_TYPE.SELECT ||
          this.state.formData.type === CUSTOM_INPUT_TYPE.MULTI_SELECT) && (
          <div className="row mt-l">{this.getAddOptionField()}</div>
        )}
        {showTableDropDown && (
          <div className="row mt-l">
            <DKInput
              value={this.state.refTabName}
              formatter={(obj) => {
                return obj;
              }}
              title="Pick a table"
              type={INPUT_TYPE.DROPDOWN}
              required={true}
              onChange={(value) => {}}
              dropdownConfig={{
                style: {
                  position: 'absolute'
                },
                allowSearch: true,
                searchableKey: 'name',
                searchApiConfig: {
                  getUrl: (val) => {
                    return `${
                      API_ENDPOINTS.BASE
                    }/v1/app-builder/table?includeFilters=false&appId=${appId}&q=${encodeURI(
                      val
                    )}`;
                  },
                  dataParser: (response) => {
                    const { tableId } = this.props;
                    return response?.data?.filter(
                      (table) => table._id !== tableId
                    );
                  },
                  getHeaders: () => {
                    return {
                      'Access-Control-Allow-Credentials': true,
                      Authorization:
                        httpClient.defaults.headers.common['Authorization']
                    };
                  },
                  getPayload: () => {
                    return null;
                  }
                },
                data: [],
                renderer: (index, obj) => {
                  if (obj.name !== this.state.tableName)
                    return <DKLabel needIcon text={`${obj.name}`} />;
                },

                onSelect: (index, value) => {
                  const selectedTable = value._id;
                  const ReftANameSelected = value.name;
                  this.setState({
                    refTableID: selectedTable,
                    refTabName: ReftANameSelected
                  });
                }
              }}
            />
          </div>
        )}

        {this.state.formData.type === CUSTOM_INPUT_TYPE.FORMULA &&
          this.state.needFormulaEditorPopup &&
          this.getFormulaEditorPopup()}
        {this.state.formData.options.length > 0 && this.getOptionList()}

        <div className="row mt-xl mb-m gap-6">
          <DKCheckMark
            className=""
            isSelected={this.state.formData.required}
            onClick={(value) =>
              this.onFormValueChange('required', !this.state.formData.required)
            }
            title="Mandatory"
            color="bg-button"
          />

          <DKCheckMark
            className=" "
            isSelected={this.state.formData.editable}
            onClick={(value) =>
              this.onFormValueChange('editable', !this.state.formData.editable)
            }
            title="Editable"
            color="bg-button"
          />

          {/* <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-l mb-m"
              isSelected={this.state.formData.systemField}
              onClick={(value) =>
                this.onFormValueChange(
                  'systemField',
                  !this.state.formData.systemField
                )
              }
              title="Is systemField?"
              color="bg-blue"
            />
          </div> */}
          {/* <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-s mb-m"
              isSelected={this.state.formData.hidden}
              onClick={(value) =>
                this.onFormValueChange('hidden', !this.state.formData.hidden)
              }
              title="Is hidden?"
              color="bg-blue"
            />
          </div> */}

          <DKCheckMark
            isSelected={this.state.formData.uiVisible}
            onClick={(value) =>
              this.onFormValueChange(
                'uiVisible',
                !this.state.formData.uiVisible
              )
            }
            title="UI Visible"
            color="bg-button"
          />

          {(this.state.formData.type === CUSTOM_INPUT_TYPE.FILE ||
            this.state.formData.type === CUSTOM_INPUT_TYPE.TEXT) && (
            <DKCheckMark
              className=""
              isSelected={this.state.formData.isAIColumn}
              onClick={(value) =>
                this.onFormValueChange(
                  'isAIColumn',
                  !this.state.formData.isAIColumn
                )
              }
              title="AI Column"
              color="bg-button"
            />
          )}
        </div>
      </div>
    );
  };
  getAIConfigSection = () => {
    let columns = this.props?.columnDList?.filter(
      (element: any) =>
        element.key !== 'actions' && Utility.isEmptyObject(element.aiConfig)
    );
    return (
      this.state.formData.isAIColumn && (
        <div className="Column parent-width p-r border-m border-radius-m bg-white mt-m">
          <div className="row">
            <DKLabel className=" fw-m" text="AI Configuration" />
            <DKLabel
              text="New"
              className="bg-chip-green border-radius-m text-green ml-r p-h-s"
            />
          </div>
          <div className="column parent-width">
            <DKLabel
              className="mt-xs text-gray mb-xl"
              text="Use AI to extract data from uploaded file or any URL"
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title="Select column(s) to extract data for"
              titleStyle={{ width: '100%' }}
              options={columns?.map((item) => item.name)}
              onChange={(value: any) => {
                console.log('value', value);
                let selectedColumnsArray: any = [];
                value.forEach((index: number) => {
                  selectedColumnsArray.push(columns[index]);
                });
                this.setState({
                  formData: {
                    ...this.state.formData,
                    selectedColumn: selectedColumnsArray,
                    selectedColumnIndexes: value
                  }
                });
              }}
              value={this.state.formData.selectedColumnIndexes}
              canValidate={false}
              type={INPUT_TYPE.MULTI_SELECT}
              dropdownConfig={{
                title: 'Select Column'
              }}
            />
            <div className="column parent-width mt-l">
              <DKInput
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title="Prompt to extract data"
                value={this.state.formData.promptMessage}
                onChange={(value: any) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      promptMessage: value
                    }
                  });
                }}
              />
            </div>
          </div>
        </div>
      )
    );
  };
  getFieldTypeSelection = () => {
    const INPUT_TYPE_ARR = [
      CUSTOM_INPUT_TYPE.TEXT,
      CUSTOM_INPUT_TYPE.NUMBER,
      CUSTOM_INPUT_TYPE.DATE,
      CUSTOM_INPUT_TYPE.SELECT,
      CUSTOM_INPUT_TYPE.MULTI_SELECT,
      CUSTOM_INPUT_TYPE.FORMULA,
      CUSTOM_INPUT_TYPE.FILE,
      CUSTOM_INPUT_TYPE.REF,
      CUSTOM_INPUT_TYPE.LONG_TEXT2
    ];
    if (this.props.source === 'OTHER') {
      INPUT_TYPE_ARR.push(CUSTOM_INPUT_TYPE.TABLE);
    }
    const UPDATED_ARR = [...INPUT_TYPE_ARR];

    return (
      <div className="parent-width">
        <DKLabel text={'Select field type'} />
        <div className={`column-type-wrapper`}>
          {UPDATED_ARR.map((item) => {
            return (
              <div
                className={`p-h-s p-v-xs bg-white mr-r mt-r border-radius-s border-m d-flex align-items-center cursor-hand
                            ${
                              this.state.formData.type === item
                                ? 'border-m '
                                : ''
                            }`}
                style={{
                  borderColor:
                    this.state.formData.type === item ? 'black' : null
                }}
                onClick={() => {
                  this.onFormValueChange('type', item);
                  setTimeout(() => {
                    this.onFormValueChange('options', []);
                    this.onFormValueChange('isAIColumn', false);
                  }, 0);
                }}
              >
                <DKIcon
                  src={getIconByDataType(item)}
                  className={`${
                    item === CUSTOM_INPUT_TYPE.TABLE ||
                    item === CUSTOM_INPUT_TYPE.REF
                      ? 'ic-s'
                      : 'ic-xs'
                  }  cursor-hand`}
                />
                <span className="ml-s">{getDisplayNameForType(item, {})}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  getAddOptionField = () => {
    return (
      <div className="row align-items-end" style={{ width: '80%' }}>
        <DKInput
          valueStyle={{ backgroundColor: 'white' }}
          required={true}
          autoFocus={true}
          haserror={
            this.state.submitted && this.state.formData.name.trim().length === 0
          }
          title="Options"
          type={INPUT_TYPE.TEXT}
          value={this.state.optionFieldText}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(value) => this.onOptionChange(value)}
          onKeyPress={(e) => this.onOptionKeyPress(e)}
        />
        <DKButton
          title="Add"
          className="ml-r bg-button text-white"
          onClick={this.onOptionAdd}
        />
      </div>
    );
  };
  getOptionList = () => {
    return (
      <div className="row pt-s flex-wrap">
        {this.state.formData.options.map((option, index) => {
          return (
            <div
              className={`p-s border-radius-s mr-s mt-s d-flex align-items-center ${option.color}`}
              id={`${option.id}`}
            >
              <span>{option.name}</span>
              <DKIcon
                src={DKIcons.ic_close}
                className="ic-s ml-s cursor-hand"
                onClick={() => this.onOptionRemove(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

export const showAddColumnPopup = (
  config: {
    tableName: string;
    appId: string;
    tableId: string;
    columnDList: any;
  },
  onSave?: (response?: any) => void,
  onClose?: () => void
) => {
  const id = `add-new-col-popup-${new Date().getTime()}`;

  let div = document.createElement('div');
  div.className = 'app-font';
  div.setAttribute('id', id);
  ReactDOM.render(
    <AddColumnPopup
      popupId={id}
      tableName={config.tableName}
      onSave={onSave}
      onClose={onClose}
      appId={config.appId}
      tableId={config.tableId}
      columnDList={config.columnDList}
    />,
    document.body.appendChild(div)
  );
};
export default AddColumnPopup;
