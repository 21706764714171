import { DKLabel, DKLine } from 'deskera-ui-library';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import TextField from '../../components/common/TextFields';
import { IProfile } from '../../model/Settings';
interface IUserProfile {
  settings?: IProfile;
}
export default function UserProfile(props: IUserProfile) {
  const getHeader = () => {
    return (
      <div className="column parent-width">
        <DKLabel className="fs-xl fw-m" text="Profile" />
        <DKLabel
          className=""
          text="This is how other will see you on ths site."
        />
      </div>
    );
  };

  return (
    <div className="column parent-width">
      {getHeader()}
      <DKLine className="mt-l mb-l" />
      <div className="column parent-width gap-4">
        <div>
          <TextField
            required={true}
            title="User Name"
            placeHolder="User Name"
            type={CUSTOM_INPUT_TYPE.TEXT}
            onChange={(text: any) => {}}
            value=""
            options={[]}
          />
          <DKLabel
            className="text-gray mt-xs"
            text="This is your public display name. It can be your real name or a pseudonym.
        You can only change this once every 30 days."
          />
        </div>
        <TextField
          required={true}
          title="Email"
          placeHolder="Email"
          type={CUSTOM_INPUT_TYPE.TEXT}
          onChange={(text: any) => {}}
          value=""
          options={[]}
        />
        <div>
          <TextField
            required={false}
            title="Display Email"
            placeHolder="Select a verified email to display"
            type={CUSTOM_INPUT_TYPE.SELECT}
            onChange={(text: any) => {}}
            value=""
            options={[
              'test1@getnada.com',
              'test2@getnada.com',
              'test3@getnada.com'
            ]}
          />
          <DKLabel
            className="text-gray mt-xs"
            text="You can manage verified email addresses in your email settings."
          />
        </div>
        <div>
          <TextField
            required={false}
            title="Tell Us A bit About yourself"
            placeHolder="Tell Us A bit About yourself"
            type={CUSTOM_INPUT_TYPE.TEXT}
            onChange={(text: any) => {}}
            value=""
            options={[]}
          />
          <DKLabel
            className="text-gray mt-xs"
            text="You can @mention other users and organizations to link to them."
          />
        </div>
        <TextField
          required={false}
          title="Links"
          placeHolder="Links"
          type={CUSTOM_INPUT_TYPE.TEXT}
          onChange={(text: any) => {}}
          value=""
          options={[]}
        />
      </div>
    </div>
  );
}
