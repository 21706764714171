import React, { useEffect, useState } from 'react';
import { DKDataGrid } from 'deskera-ui-library';
import IFormField from '../../model/Form';
import { fetchTable } from '../../services/table';
import Utility from '../../utility/Utility';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addTable, selectTableById } from '../../redux/slices/tableSlice';

interface ITableInputProps {
  field: IFormField;
}

const TableInput: React.FC<ITableInputProps> = ({ field }) => {
  const dispatch = useAppDispatch();
  const { refTable, title } = field;
  const [tableData, setTableData] = useState(
    useAppSelector(selectTableById(refTable._id))
  );

  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const getTable = async () => {
      setLoading(true);
      try {
        const response = await fetchTable({ tableId: refTable._id });
        dispatch(addTable(response));
        setTableData(response);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (
      !Utility.isEmptyObject(refTable._id) &&
      Utility.isEmptyObject(tableData)
    ) {
      getTable();
    }
  }, [refTable]);

  return (
    <div className="column parent-width">
      <DKDataGrid
        showHeader
        title={title}
        updating={loading}
        allowBulkOperation={false}
        allowColumnEdit={false}
        allowColumnShift={false}
        allowColumnSort={false}
        allowColumnWebhook={false}
        allowExpand={false}
        allowFilter={false}
        allowRowEdit={false}
        allowColumnDelete={false}
        allowSearch={false}
        width={'100%'}
        needShadow={false}
        needBorder={false}
        needTrailingColumn={true}
        allowFormulaColumn={false}
        columns={tableData?.columnsMetaData || []}
        rows={[{}]}
      />

      {!Utility.isEmptyObject(refTable?.subFields) && (
        <div className="column parent-width">
          {refTable?.subFields?.map((field) => {
            return (
              <div
                className="row justify-content-between align-self-end mt-s"
                key={field.id}
                style={{ width: '40%' }}
              >
                <div className="column fw-b">{field.displayName}</div>
                <div className="column">0.00</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TableInput;
