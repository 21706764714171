import React, { useState, useEffect, useRef } from 'react';

import animationData from '../../assets/lottie/loading_1.json';

import LottiePlayer from '../LottiePlayer';

import { DKLabel } from 'deskera-ui-library';

const LoadingAnimation = () => {
  const [index, setIndex] = useState(0);

  let index_ = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (index_ < textArr.length - 1) {
        index_++;
        setIndex((previndex) => previndex + 1);
      } else {
        clearInterval(interval);
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const textArr = [
    {
      title: 'Analyzing...',
      subTitle: 'Understanding the requirements '
    },
    {
      title: 'Building pages...',
      subTitle: 'Adding pages, modules to application'
    },
    {
      title: 'Creating tables...',
      subTitle: 'Building modules and related tables'
    },
    {
      title: 'Adding columns...',
      subTitle: 'Analysing data fields for columns for all tables'
    },
    {
      title: 'Setting up theme...',
      subTitle: 'Applying theme to all modules, pages '
    },
    {
      title: 'Generating app...',
      subTitle: 'Setting up your app'
    },
    {
      title: 'Finalising...',
      subTitle: 'Few more seconds and we are good to start'
    }
  ];

  return (
    <div className="transparent-background fade-animation">
      <div
        className="popup-window pop-animation prompt-editor-shadow"
        style={{
          padding: 40,
          paddingTop: 10,
          paddingBottom: 10,
          minWidth: 550,
          borderRadius: 12
        }}
      >
        <div className="row justify-content-between">
          <div className="column mr-m" style={{ width: 400 }}>
            <DKLabel text="PLEASE WAIT" className="text-gray" />
            <DKLabel
              text={textArr[index]?.title}
              className="fw-m fs-xxl mt-m"
            />
            <DKLabel text={textArr[index]?.subTitle} className="fs-m mt-m " />
          </div>
          <LottiePlayer
            animationData={animationData}
            loop={true}
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
