import { matchPath } from 'react-router-dom';
import NavigateContext from '../Helper/NavigationContext';
import Home from '../components/AdminPanel/Home';
import { ROUTE_COMPONENT_MAP } from '../model/Common';
import ImportPortal from '../components/import/Portal';
import EmailVerification from '../pages/EmailVerificationPage';
import AdminDashboard from '../components/AdminPanel/AdminDashboard';
import AppDetail from '../components/Application/AppDetail';
import TableIdDisplay from '../components/TableIdDisplay';
import NewTable from '../components/TableCreation/NewTable';
import Applications from '../components/AdminPanel/Applications';

export const ROUTE_ID_IDENTIFIER = ':id';

export const ROUTE_APP_DETAIL_ID_IDENTIFIER = ':appId';

export const ROUTE_TABLE_DETAIL_ID_IDENTIFIER = ':tableId';

export const PAGE_ROUTES = {
  ONBOARDING: '/onboarding',
  ADMIN_DASHBOARD: '',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  ROOT: '/',
  PUBLIC_PAGE_PATH: '/public/',
  EMAIL_VERIFICATION: '/public/email-verification/' + ROUTE_ID_IDENTIFIER,
  PUBLIC_PAGE: '/public/' + ROUTE_ID_IDENTIFIER,
  HOME: '/home',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  APPLICATION_LIST: '/apps',
  APP_DETAILS: '/apps',
  MARKETPLACE: '/marketplace',
  TERMS_AND_CONDITIONS: '/terms',
  PRIVACY: 'privacy',
  MARKETPLACE_DETAILS: '/marketplace/:id',
  APP_DETAILS_ADMIN: '/apps/' + ROUTE_APP_DETAIL_ID_IDENTIFIER,
  TABLE_DETAILS: ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
  TABLE_DETAILS_ADMIN: 'table/' + ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
  APP: `/apps/${ROUTE_APP_DETAIL_ID_IDENTIFIER}/table/${ROUTE_TABLE_DETAIL_ID_IDENTIFIER}`,
  APP_ADMIN: `/apps/${ROUTE_APP_DETAIL_ID_IDENTIFIER}/table/${ROUTE_TABLE_DETAIL_ID_IDENTIFIER}`,
  IMPORT_PORTAL: `/apps/import-portal`,
  IMPORT_PORTAL_ADMIN: `/apps/${ROUTE_APP_DETAIL_ID_IDENTIFIER}/import-portal`,
  CERATE_TABLE: '/create-table/' + ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
  DESKERA_HOME: '/public/deskera-home',
  PRICING_HOME: '/public/pricing',
  MOBILE_PAGE_PATH: '/mobile-app/',
  AUTOMATION: '/apps/:appId/automation',
  MOBILE_APP_LANDING: '/mobile-app/landing',
  NO_ACCESS: '/no-access',
  LOGS: '/logs'
};

export const PAGE_GROUP_REDIRECTS = {
  //not useful right now for app-builder
};

export default class RouteManager {
  static presenter = null;

  static setPresenter(presenter) {
    this.presenter = presenter;
  }

  static context = NavigateContext; // Assuming this setup, adjust based on actual implementation

  static navigateToPage(pageRoute, param = null) {
    const navigate: any = this.context;
    if (navigate) {
      navigate(pageRoute + (param ? '?' + param : ''));
    } else {
      console.error('Navigate function not found.');
    }
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
  }

  static replacePageParams(pageRoute = window.location.pathname, param = '') {
    RouteManager.presenter?.props.history?.replace(
      pageRoute + (param ? '?' + param : '')
    );
  }

  static isCurrentRoute(route) {
    const isMatch: any = matchPath(window.location.pathname, route);
    return !!isMatch?.isExact;
  }
}

export const ROUTE_COMPONENT_MAPPING: ROUTE_COMPONENT_MAP[] = [
  {
    path: PAGE_ROUTES.ROOT,
    component: Home
  },
  {
    path: PAGE_ROUTES.HOME,
    component: Home
  },
  {
    path: PAGE_ROUTES.IMPORT_PORTAL,
    component: ImportPortal
  },
  {
    path: PAGE_ROUTES.EMAIL_VERIFICATION,
    component: EmailVerification
  },
  {
    path: PAGE_ROUTES.DASHBOARD,
    component: AdminDashboard
  },
  {
    path: PAGE_ROUTES.APPLICATION_LIST,
    component: Applications
  },
  {
    path: PAGE_ROUTES.APP_DETAILS,
    component: AppDetail
  },
  {
    path: PAGE_ROUTES.TABLE_DETAILS,
    component: TableIdDisplay
  },
  {
    path: PAGE_ROUTES.APP_ADMIN,
    component: TableIdDisplay
  },
  {
    path: PAGE_ROUTES.APPLICATION_LIST,
    component: Applications
  },
  {
    path: PAGE_ROUTES.CERATE_TABLE,
    component: NewTable
  }
];
