import { Component } from 'react';
import Stepper from '../common/Stepper';
import ImportManager from './StepsManager';
import RouteManager, {
  PAGE_ROUTES,
  ROUTE_TABLE_DETAIL_ID_IDENTIFIER
} from '../../managers/RouteManager';
import {
  DKLabel,
  DKButton,
  showToast,
  TOAST_TYPE,
  showLoader,
  removeLoader,
  showAlert
} from 'deskera-ui-library';
import Utility, { getCapitalized } from '../../utility/Utility';
import {
  importOptions,
  IMPORT_SOURCE_APP_CONFIG,
  IMPORT_SOURCE_TYPE,
  PERSISTED_IMPORT_SOURCE_KEY,
  supportedFileTypes
} from '../../constants/EximConstants';
import AppManager from '../../managers/AppManager';
import { IMappedHeader } from '../../utility/DataMapHelper';
import { IColumn } from '../../model/Table';
import { withRouter } from '../../utility/WithRouter';

interface IImportPortalProps {
  location: any;
  history: any;
  navigate?: any;
}

interface IImportPortalState {
  currentStep: number;
  selectedSource: string | null;
  selectedUser: string | null;
  connectedUsers: any[];
  auditLogId: string | number;
  fileInfo: any;
  fileContent: any;
  mappedHeaders: IMappedHeader[];
  systemHeaders: IColumn[];
  importRecords: any;
  invalidRows: any;
  selectedSegmentId: any;
  selectedPipelineId: string | null;
  importProperties: any[];
}

class ImportPortal extends Component<IImportPortalProps, IImportPortalState> {
  tableName: string;
  source: string;
  authCode: any;
  steps: any[];
  allowNavigation: boolean;
  unblockHistory: () => void;
  queryParams;

  constructor(props: any) {
    super(props);
    this.queryParams = new URLSearchParams(window.location.search);
    this.tableName = 'Data';
    this.source = this.queryParams.get('origin');
    this.authCode = this.queryParams.get('code');
    this.steps = ImportManager.getAllCreationSteps(
      this.onSkipToStep,
      this.tableName
    );

    this.state = {
      currentStep: 0,
      selectedSource: null,
      selectedUser: null,
      connectedUsers: null,
      auditLogId: '',
      fileInfo: null,
      fileContent: null,
      mappedHeaders: null,
      systemHeaders: null,
      importRecords: null,
      invalidRows: null,
      selectedSegmentId: [],
      selectedPipelineId: null,
      importProperties: JSON.parse(importOptions(this.tableName))
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);

    /* TableName Query Param is required to proceed */
    if (!this.tableName) {
      RouteManager.navigateToPage(this.source);
      return;
    }

    if (this.authCode) {
      showLoader('Connecting your account, please wait..');
      const preSelectedImportSource =
        Utility.getPersistentValue(PERSISTED_IMPORT_SOURCE_KEY) ||
        this.queryParams.get('state');

      if (
        !preSelectedImportSource ||
        !IMPORT_SOURCE_TYPE[preSelectedImportSource]
      )
        return;

      ImportManager.connectUserAccount(
        this.authCode,
        IMPORT_SOURCE_APP_CONFIG[IMPORT_SOURCE_TYPE[preSelectedImportSource]]
      )
        .then((res) => {
          this.setState(
            {
              connectedUsers: res?.allConnectedUsers || [],
              selectedSource: preSelectedImportSource || null,
              currentStep:
                preSelectedImportSource &&
                IMPORT_SOURCE_TYPE[preSelectedImportSource]
                  ? 1
                  : 0
            },
            () => {
              removeLoader();
            }
          );
        })
        .catch(() => {
          removeLoader();
        });
    }

    this.unblockHistory = this.props.history?.block?.(({ pathname }) =>
      this.onPageNavigate({ pathname })
    );
  }

  componentWillUnmount() {
    this.unblockHistory?.();
  }

  onPageNavigate = ({ pathname }) => {
    if (
      this.allowNavigation ||
      (this.state.currentStep === 0 && !this.state.fileInfo)
    )
      return true;

    const buttons = [
      {
        title: 'Leave',
        className: 'bg-red text-white border-red mt-r',
        onClick: () => {
          this.unblockHistory?.();
          pathname && RouteManager.navigateToPage(pathname);
        }
      },
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m mt-r ml-r',
        onClick: () => {}
      }
    ];

    showAlert(
      'Unsaved changes!',
      'You have some unsaved changes. Are you sure you want to leave this page?',
      buttons
    );

    return false;
  };

  uploadRecords = (newFileURL: string) => {
    if (!newFileURL) {
      return Promise.reject('Unable to upload updated records.');
    }

    const data = {
      properties: this.state.importProperties,
      headers: {
        mappedHeaders: this.state.mappedHeaders,
        systemHeaders: this.state.systemHeaders
      },
      filePath: newFileURL,
      auditLogId: this.state.auditLogId,
      segmentId: this.state.selectedSegmentId,
      pipelineId: this.state.selectedPipelineId
    };

    return ImportManager.uploadRecords(
      data,
      this.tableName,
      this.state.selectedSource
    )
      .then((response) => {
        // EventManager.trackEventByModule(this.tableName, EVENT_TYPE.IMPORT, {});
        removeLoader();
        showAlert(
          'Importing data!',
          'Your data import is in progress. It will take sometime and will reflect in your account once completed. We will notify you once done.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {
                this.allowNavigation = true;
                this.props?.navigate(-1);
              }
            }
          ]
        );
      })
      .catch((err) => {
        removeLoader();
        showToast(
          'Something went wrong while Importing results, please try again later',
          TOAST_TYPE.FAILURE
        );
      });
  };

  handleImportConfirmation = () => {
    showLoader('Importing Records...');

    ImportManager.uploadNewFile(
      {
        content: this.state.importRecords,
        fileName: `import_${this.tableName}`,
        fileType: supportedFileTypes.CSV
      },
      {
        mappedHeaders: this.state.mappedHeaders,
        systemHeaders: this.state.systemHeaders
      },
      this.tableName,
      this.state.selectedSource
    )
      .then((relativeFileURL) => this.uploadRecords(relativeFileURL))
      .catch((err) => {
        removeLoader();
        showToast(err, TOAST_TYPE.FAILURE);
      });
  };

  isInvalidStep = (expectedStep: number) => {
    if (expectedStep < 0 || expectedStep >= this.steps.length) return false;

    /* Check if component has required data for expected step */
    const { requiredProps } = this.steps[expectedStep];
    return requiredProps.some(
      (requiredProp: string) => this.state[requiredProp] === null
    );
  };

  onSkipToStep = (event: any, index: number) => {
    const { currentStep } = this.state;

    /* By default expected step is to move forward */
    const expectedStep = index ?? currentStep + 1;

    /* If it's the last step */
    if (expectedStep === this.steps.length) {
      this.handleImportConfirmation();
      return;
    }

    if (this.isInvalidStep(expectedStep)) {
      const errorInfo =
        this.steps[currentStep].reqError ||
        (this.steps[currentStep].getErrorMessage &&
          this.steps[currentStep].getErrorMessage(this.state)) ||
        `Sorry can't proceed, please complete the current step first`;
      showToast(errorInfo, TOAST_TYPE.FAILURE);
    } else {
      AppManager.scrollToTop();
      this.setState({ currentStep: expectedStep });
    }
  };

  handleStepData = (data: any, callback: any) => {
    this.setState({ ...data }, callback);
  };

  getCurrentStepChild() {
    const currStep = this.steps[this.state.currentStep];
    return currStep.getChild({
      state: this.state,
      tableName: this.tableName,
      setData: this.handleStepData
    });
  }

  getControllers() {
    const { currentStep } = this.state;
    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === this.steps.length - 1;
    return (
      <div className="row parent-width justify-content-center mb-l">
        {!(isFirstStep || isLastStep) && (
          <DKButton
            title="Back"
            className="border-m ml-r bg-white"
            onClick={(e: any) => this.onSkipToStep(e, currentStep - 1)}
          />
        )}
        {this.steps[currentStep].needContinueAction(this.state) && (
          <DKButton
            title="Continue"
            className="border-m ml-r bg-button text-white"
            onClick={this.onSkipToStep}
          />
        )}
      </div>
    );
  }

  getDisplayName() {
    return getCapitalized(this.tableName);
  }

  render() {
    return (
      <div className="column parent-width">
        <DKLabel
          text={`Import ${this.getDisplayName()}`}
          className="fw-m fs-m mb-r"
        />
        <Stepper
          data={this.steps}
          currentIndex={this.state.currentStep}
          className="mb-l"
        />

        <div className="row parent-width m-v-xl justify-content-center">
          {this.getCurrentStepChild()}
        </div>

        {this.getControllers()}
      </div>
    );
  }
}

export default withRouter(ImportPortal);
