export class Options {
  static COMPANY_SIZE = ['1-10', '12-25', '26-50', '51-100', '100+'];
  static COUNTRIES = ['USA', 'India', 'UK', 'Australia', 'Canada', 'Others'];
  static INDUSTRIES = [
    'IT Services',
    'Automobile',
    'Textitles',
    'Health',
    'Real State',
    'Others'
  ];
  static TAX_CYCLES = ['Monthly', 'Quaterly', 'Yearly'];
  static CURRENCIES = ['INR', 'USD', 'EUR', 'GBP', 'CAD', 'AUD', 'Others'];
}
