import { useEffect, useState } from 'react';
import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

import { DKSidebar } from 'deskera-ui-library';
import AppManager from '../../managers/AppManager';
import SideBarService from '../../services/sidebar';
import AppIcons from '../../assets/icons/AppIcons';
import TopMenu from './TopMenu';
import {
  PAGE_ROUTES,
  ROUTE_TABLE_DETAIL_ID_IDENTIFIER
} from '../../managers/RouteManager';
import { fetchApp } from '../../services/table';
import Utility, { changeTheme, isNotEmptyObject } from '../../utility/Utility';
import PromptPopup from '../prompt-popup/PromtPopup';
import { selectAppInfo } from '../../redux/slices/CommonSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getOrCreateWorkflowConnection } from '../../services/common/workflow';
import FormBuilder from '../form-builder';
import { fetchTables, selectTables } from '../../redux/slices/tableSlice';
import { ReactComponent as ERPAIIcon } from '../../assets/icons/ERP-AI-Logo.svg';

import ThemeManager from '../../utility/ThemeManager.js';

const AppDetail = (props) => {
  const dispatch = useAppDispatch();
  const { appId, tableId } = useParams();
  const appInformation = useAppSelector(selectAppInfo);
  const [app, setApp] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [needFormBuilder, setFormBuilderVisibility] = useState(false);
  const tables = useAppSelector(useAppSelector(selectTables));

  const isLightTheme = ThemeManager.isLightTheme();

  useEffect(() => {
    let appIdString = appInformation?.isPublishedApp
      ? appInformation?.appId
      : appId;
    const fetchAppName = async () => {
      try {
        const response = await fetchApp({
          appId: appIdString
        });
        setApp(response);
        if (appInformation?.isPublishedApp && response?.name) {
          document.title = response.name;
        }
        if (response?.theme?.backgroundColor) {
          changeTheme(response?.theme?.backgroundColor);
        }
      } catch (error) {
        console.error('Failed to fetch application name:', error);
      }
    };

    if (isNotEmptyObject(appIdString)) {
      fetchAppName();
      dispatch(fetchTables({ appId: appIdString }));
      getOrCreateWorkflowConnection();
    }
  }, [appId, appInformation]);

  useEffect(() => {
    const { isPublishedApp } = appInformation;
    const processTables = async () => {
      try {
        let pathName = isPublishedApp ? '/' : PAGE_ROUTES.APP_DETAILS_ADMIN;
        if (matchPath({ path: pathName }, pathname)) {
          const [firstTable] = Object.values(tables) || [];
          const tableToNavigate = tableId ?? firstTable._id;
          if (isPublishedApp) {
            navigate(
              PAGE_ROUTES.TABLE_DETAILS.replace(
                ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
                tableToNavigate
              )
            );
          } else {
            let redirectURL = PAGE_ROUTES.TABLE_DETAILS_ADMIN.replace(
              ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
              tableToNavigate
            );

            navigate(redirectURL);
          }
        }
      } catch (error) {
        console.error('Failed to fetch tables:', error);
      }
    };
    if (isNotEmptyObject(tables)) {
      processTables();
    }
  }, [tables]);

  const handleExpandCollapse = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const customHeaderRenderer = () => {
    return (
      <div
        style={{
          alignSelf: 'center',
          borderBottom: isLightTheme
            ? '1px solid rgba(0, 0, 0, 0.1)'
            : '1px solid rgba(255, 255, 255, 0.1)'
        }}
        className={
          'row p-h-r pb-xs mb-s fs-m fw-m text-align-left ' +
          (isLightTheme ? 'text-black ' : 'text-white')
        }
      >
        <ERPAIIcon style={{ width: 26 }} className="mr-r" />
        {app.name}
      </div>
    );
  };

  const menuItemList = Object.values(tables).reduce((acc, table) => {
    const category = table.category || 'Tables';
    if (!acc[category]) {
      acc[category] = {
        name: category,
        className: 'left-menu-group',
        icon: isLightTheme ? AppIcons.ic_table : AppIcons.white.ic_table,
        visible: true,
        expandByDefault: true,
        subItems: [],
        onClick: () => {}
      };
    }
    acc[category].subItems.push({
      name: table.name,
      icon: isLightTheme ? AppIcons.ic_page : AppIcons.white.ic_page,
      onClick: () =>
        appInformation.isPublishedApp
          ? navigate(`${table._id}`)
          : navigate(`table/${table._id}`)
    });
    return acc;
  }, {});

  const menuItems = Object.values(menuItemList);

  return (
    <div className="main-holder row align-items-start app-font app-bg-color">
      {app && (
        <DKSidebar
          menuItemList={[
            ...menuItems,
            {
              icon: isLightTheme ? AppIcons.ic_form : AppIcons.white.ic_form,
              name: 'Forms',
              onClick: () => {
                setFormBuilderVisibility(true);
                console.log('clicked on forms option');
              },
              subItems: [],
              visible: true
            }
          ]}
          isLightTheme={isLightTheme}
          collapsedWidth={0}
          expandedWidth={230}
          isMenuExpanded={isSidebarExpanded}
          onExpandCollapse={handleExpandCollapse}
          customHeaderRenderer={customHeaderRenderer}
          allowTenantSelection={false}
        />
      )}
      <div
        className="parent-width parent-height column"
        style={{
          flex: 1,
          maxWidth: SideBarService.getContainerWidth(true)
        }}
      >
        {!AppManager.isPublicURL() && !AppManager.isBookADemoURL() && app && (
          <TopMenu
            app={app}
            appId={
              appInformation?.isPublishedApp ? appInformation?.appId : appId
            }
          />
        )}

        <div className={'p-h-xl pt-l w-full h-full overflow-y-auto'}>
          <Outlet />
          <PromptPopup />
        </div>
      </div>
      {needFormBuilder && (
        <FormBuilder
          tableId={''}
          appId={appInformation?.isPublishedApp ? appInformation?.appId : appId}
          onClose={() => {
            setFormBuilderVisibility(false);
          }}
          onSave={() => {
            setFormBuilderVisibility(false);
          }}
        />
      )}
    </div>
  );
};

export default AppDetail;
