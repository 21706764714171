import { useEffect, useRef, useState } from 'react';
import { fileReadUtil } from '../../../utility/ImportFile';
import {
  acceptedFileFormats,
  maxFileNameLength,
  supportedFileTypes
} from '../../../constants/EximConstants';
import {
  DKLabel,
  DKIcons,
  DKIcon,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  showLoader,
  removeLoader,
  showAlert
} from 'deskera-ui-library';
import UploadCloud from '../../../assets/importPortal/upload_cloud_outlined.svg';
import UploadCloudFilled from '../../../assets/importPortal/upload_cloud_filled.svg';
import { fetchTablesByAppId } from '../../../services/table';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useSelector } from 'react-redux';
import {
  selectTableToImport,
  setTable
} from '../../../redux/slices/importSlice';
import { useParams } from 'react-router-dom';
import Utility from '../../../utility/Utility';
import { selectAppInfo } from '../../../redux/slices/CommonSlice';

const FileImporter = (props) => {
  const uploadInput = useRef<any>();
  const cloudImageRef = useRef<any>();
  const dispatch = useAppDispatch();
  const selectedTable = useSelector(selectTableToImport());
  const table = useRef([]);
  const { appId } = useParams();
  const appInformation = useAppSelector(selectAppInfo);

  useEffect(() => {
    const fetchAppTables = async () => {
      showLoader('Loading applications tables...');
      try {
        const response = await fetchTablesByAppId({
          appId: appInformation?.isPublishedApp ? appInformation?.appId : appId
        });
        const { data } = response;
        const [firstTable = {}] = data;
        dispatch(setTable(firstTable));
        table.current = data;
      } catch (error) {
      } finally {
        removeLoader();
      }
    };

    fetchAppTables();
  }, [appId]);

  const isFileAcceptable = (selectedFile) => {
    if (!selectedFile) return false;
    if (selectedFile.name.length > maxFileNameLength) {
      showAlert(
        'Invalid File!',
        `file name can not exceed ${maxFileNameLength} characters`
      );
      return false;
    }
    let fileFormateCheck = acceptedFileFormats.some((acceptedFormat) =>
      selectedFile.name.includes(acceptedFormat)
    );
    if (!fileFormateCheck) {
      showAlert('Invalid File!', 'Only CSV/XLSX file types are allowed');
      return false;
    }
    return true;
  };

  const handleFiles = (selectedFiles) => {
    if (!selectedFiles.length) {
      showToast('File not found', TOAST_TYPE.FAILURE);
    }

    const selectedFile = selectedFiles[0];
    if (isFileAcceptable(selectedFile)) {
      showLoader('Processing the uploaded file...');

      /* Parsing the file at Client End */
      fileReadUtil(selectedFile, supportedFileTypes.CSV)
        .then((res) => {
          removeLoader();
          props.setData({
            fileInfo: {
              name: selectedFile.name,
              size: selectedFile.size,
              mimeType: selectedFile.type
            },
            fileContent: res,
            /* Reset mapped values whenever new file is uploaded */
            mappedHeaders: null,
            systemHeaders: null,
            importRecords: null,
            invalidRows: null,
            currentStep: props.currentStep + 1
          });
        })
        .catch((err) => {
          removeLoader();

          showToast(err.message, TOAST_TYPE.FAILURE);
        });

      /* Uploading the file for Import Logs */
      props
        ?.generateAuditLog?.(selectedFile)
        .then((auditLogId) => {
          /* showAlert(
              `File Uploaded`,
              `<em>${selectedFile.name}</em> uploaded successfully,
              please continue to next step for mapping your columns.`
            );
          */
          props.setData({ auditLogId });
        })
        .catch((err) => {
          showToast(
            'Something went wrong while uploading file to server',
            TOAST_TYPE.FAILURE
          );
        });
    }
  };

  const onUploadInputClicked = (event) => {
    const selectedFiles = event.target.files;
    handleFiles(selectedFiles);
  };

  const onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    cloudImageRef.current.src = UploadCloud;
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const onDragenter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    cloudImageRef.current.src = UploadCloudFilled;
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  function getImportPortal() {
    return (
      /* upload area */
      <div
        className="column parent-width bg-gray1 border-m border-radius-s align-items-center p-v-r p-h-l m-v-xl cursor-hand"
        onDragEnter={onDragenter}
        onDragOver={onDragOver}
        onDrop={onFileDrop}
        onClick={() => {
          uploadInput.current.click();
        }}
      >
        <img
          style={{ height: '100px', width: '200px' }}
          className="m-r"
          src={UploadCloud}
          ref={cloudImageRef}
          alt="Upload Cloud"
        />

        <div className="row parent-width justify-content-center position-relative p-h-xxl">
          <DKLabel text="Drop .xlsx or .csv" />
          <div className="row bg-button width-auto ml-r p-h-m fw-m text-white border-radius-m">
            <DKIcon src={DKIcons.white.ic_document} className="ic-s" />
            <DKLabel text="Choose a file" className="ml-r m-v-s" />
          </div>
          <input
            className="parent-width parent-height position-absolute"
            style={{ visibility: 'hidden' }}
            type="file"
            accept=".csv,.xlsx,text/csv"
            onChange={onUploadInputClicked}
            ref={uploadInput}
          />
        </div>

        {/* <DKLine className="m-v-m bg-gray3" style={{ width: 100, height: 2 }} /> */}
        {/* <DKButton
          icon={DKIcons.ic_download}
          title="Download Sample File"
          className=" border-m mt-xl fw-m mb-l"
          onClick={(e) => {
            e.stopPropagation();
            const hiddenColumns =
              store.getState().userPref.hiddenColumns?.[
                props.tableName.toUpperCase()
              ] || [];
            switch (props.tableName) {
              case TABLES.CONTACT:
                const contactDetailAddressColumnId = TableManger.getColumnId(
                  TABLES.CONTACT,
                  COLUMN_CODE.CONTACT.DETAILED_ADDRESS
                );
                triggerDownload(
                  null,
                  'contact_import_template.xlsx',
                  hiddenColumns.includes(contactDetailAddressColumnId)
                    ? ContactSampleFile
                    : ContactWithAddressSampleFile
                );
                break;
              case TABLES.DEAL:
                triggerDownload(
                  null,
                  'deal_import_template.xlsx',
                  DealSampleFile
                );
                break;
              case TABLES.ACCOUNT:
                const accountDetailAddressColumnId = TableManger.getColumnId(
                  TABLES.ACCOUNT,
                  COLUMN_CODE.ACCOUNT.DETAILED_ADDRESS
                );
                triggerDownload(
                  null,
                  'account_import_template.xlsx',
                  hiddenColumns.includes(accountDetailAddressColumnId)
                    ? AccountSampleFile
                    : AccountWithAddressSampleFile
                );
                break;
              case 'books_price_list':
                triggerDownload(
                  null,
                  'price_book_import_template.xlsx',
                  PriceBookSampleFile
                );
                break;
              default:
                break;
            }
          }}
        /> */}
      </div>
    );
  }

  function getFileInfoPanel() {
    return props.fileInfo ? (
      <div className="column parent-width mb-r">
        <DKLabel text="File details" className="fw-m" />
        <div className="row justify-content-between bg-gray1 border-m p-m border-radius-s mt-r">
          <DKLabel text={props.fileInfo.name} className="" />
          <DKLabel
            className="text-blue"
            text={`${Number(props.fileInfo.size) / 1000} Kb`}
          />
        </div>
      </div>
    ) : null;
  }

  const renderTableDropDown = () => {
    return (
      <div className="column parent-width">
        <DKInput
          valueStyle={{}}
          required
          autoFocus
          canValidate={false}
          title="Select Table"
          className={``}
          errorMessage="Select table in which you want to import data."
          type={INPUT_TYPE.DROPDOWN}
          validator={() => {
            return false;
          }}
          value={selectedTable}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(value) => {}}
          formatter={(data) => data.name}
          dropdownConfig={{
            style: {},
            allowSearch: true,
            searchableKey: 'name',
            data: table.current ?? [],
            renderer: (index, obj) => obj.name,
            onSelect: (index, value) => {
              dispatch(setTable(value));
            }
          }}
        />

        <DKLabel
          text="Select table in which you want to import data."
          className="fs-s text-gray mt-xs mb-l"
        />
      </div>
    );
  };

  return (
    <div
      className="column align-items-center bg-white border-radius-s shadow-s p-xxl"
      style={{
        width: 550,
        maxWidth: '90vw',
        paddingLeft: 60,
        paddingRight: 60
      }}
    >
      {renderTableDropDown()}
      <DKLabel text="File Uploader" className="fw-m fs-m" />
      <DKLabel
        text="Upload a file you want to import"
        className="text-gray mt-s text-align-center"
      />
      {getImportPortal()}
      {getFileInfoPanel()}
    </div>
  );
};

export default FileImporter;
