import { DKTooltipWrapper, DKIcon, DKIcons } from 'deskera-ui-library';
import ReactDOMServer from 'react-dom/server';
import Utility from '../utility/Utility';
import AttachmentPreview, {
  getAttachmentPreviewInPopup
} from '../components/common/AttachmentPreview';
import { Attachment } from '../model/File';

export const attachmentRenderer = ({ value, style }) => {
  let attachments = [],
    jsonData = [];
  if (!Utility.isEmptyObject(value)) {
    jsonData = Utility.decodeJSON(value);
    jsonData.forEach((file) => {
      attachments.push(
        <div
          className="row width-auto"
          onClick={(e) => getAttachmentPreviewInPopup(file)}
          style={style}
        >
          <DKTooltipWrapper
            content={ReactDOMServer.renderToStaticMarkup(renderFile(file))}
            tooltipClassName={'row'}
          >
            <DKIcon
              src={Utility.getFileIcon(file.title)}
              className="ic-s cursor-hand"
              style={{ padding: 0, opacity: 0.8 }}
            />
          </DKTooltipWrapper>
        </div>
      );
    });

    attachments.reverse();
  }

  return attachments.length === 0 ? null : attachments;
};

const renderFile = (file: Attachment) => (
  <AttachmentPreview
    attachment={file}
    maxHeight={`200px`}
    maxWidth={`225px`}
    needLoader={false}
    headerIconButton={{
      icon: DKIcons.ic_view,
      onClick: (e) => {
        e?.stopPropagation?.();
        getAttachmentPreviewInPopup(file);
      }
    }}
  />
);

export const attachmentRendererForRecord = ({ encodedAttachment }) => {
  let attachments = [],
    jsonData = [];
  if (!Utility.isEmptyObject(encodedAttachment)) {
    jsonData = Utility.decodeJSON(encodedAttachment);
    jsonData.forEach((file) => {
      attachments.push(
        <div
          className="d-flex width-auto cursor-hand p-s"
          onClick={(e) => getAttachmentPreviewInPopup(file)}
        >
          <DKIcon src={Utility.getFileIcon(file.title)} className="ic-s" />
          <span className="ml-s">{file.title}</span>
        </div>
      );
    });

    attachments.reverse();
  }
  return attachments.length === 0 ? <div /> : attachments;
};
