import { DKLabel, DKIcon, DKIcons, DKListPicker2 } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import debounce from '../../utility/Debounce';
import { fetchEditableComponents } from '../../services/table';
import { IContext } from './PromptHelper';
import AppIcons from '../../assets/icons/AppIcons';

function ContextMenu({ appId, context, setContext, disabled = false }) {
  const ref = useRef(null);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [data, setData] = useState<IContext[]>([]);
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    if (appId) {
      fetchEditableComponents({ appId }).then((response: any) =>
        setData(response?.components?.tables || [])
      );
    }
  }, [appId]);

  const onSearch = debounce((searchText: string) => {
    setSearchText(searchText);
  }, 400);

  const getData = () => {
    if (searchText) {
      return data.filter((item: IContext) =>
        new RegExp(searchText, 'i').test(item.name)
      );
    }
    return data;
  };

  const onSelect = (i: number, obj: IContext) => {
    setContext((prevState: IContext[]) => {
      const index = prevState.findIndex((item) => item.id === obj.id);
      if (index >= 0) {
        return prevState.filter((item) => item.id !== obj.id);
      }

      return [...prevState, obj];
    });
  };

  const cursorClass = disabled ? 'cursor-not-allowed' : 'cursor-hand';

  return (
    <>
      <div
        ref={ref}
        className={`d-flex align-items-center position-relative ${cursorClass}`}
        onClick={() => {
          if (!disabled) {
            setShowContextMenu((prevState) => !prevState);
          }
        }}
      >
        <DKIcon
          src={DKIcons.white.ic_plus}
          className="ic-s-2 mr-r bg-button p-xs border-radius-s"
        />
        <DKLabel text={'Add context'} className="mb-0.5 fw-m " />
      </div>
      {showContextMenu && (
        <DKListPicker2
          className="position-absolute z-index-3 shadow-m border-m"
          style={{
            bottom: 236,
            width: 200
          }}
          searchableKey={'name'}
          allowSearch={true}
          data={getData()}
          renderer={(i: number, option: IContext) => (
            <DKLabel key={option.id} text={option.name} />
          )}
          onSelect={onSelect}
          onClose={() => setShowContextMenu(false)}
          onSearchTextChange={onSearch}
          multiSelect={true}
          selectedIds={context?.map((value: IContext) => value.id) || []}
          onClear={() => setContext([])}
          checkMarkColor="bg-button"
        />
      )}
      <div className="flex flex-wrap gap-1 mt-m">
        {context.map((item: IContext) => (
          <div
            key={item.id}
            className="d-flex align-items-center bg-gray2 px-2  border-radius-l justify-content-between"
          >
            <DKLabel className="pb-0.5" text={item.name} />
            <DKIcon
              src={AppIcons.ic_close}
              className={`ic-s ml-s cursor-hand`}
              onClick={() => {
                if (!disabled) {
                  onSelect(-1, item);
                }
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default ContextMenu;
