import { DKDataGrid, showAlert } from 'deskera-ui-library';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import httpClient from '../../http/index';
import { useAppDispatch } from '../../redux/hooks';
import { deleteColumn as deleteColumnFromStore } from '../../redux/slices/tableSlice';
import BulkOperationsService from '../../services/BulkOperations';
import SideBarService from '../../services/sidebar';
import Utility from '../../utility/Utility';
import ContextMenu from './ContextMenu';

interface DataGridHolderProps {
  tableId: string;
  columnsData: any;
  totalRecords: number;
  columnData: any;
  onContextCancel: () => void;
  updating: boolean;
  currentPage: number;
  totalPageCount: number;
  onPageChange: (data: any) => void;
  filterData: any;
  filterOperator: any;
  onRowUpdate: (data: any) => void;
  onColumnAdd: (data: any) => void;
  onColumnShift: (data: any) => void;
  onColumnUpdate: (data: any) => void;
  onColumnFreezeToggle: (data: any) => void;
  onSearch: (data: any) => void;
  onFilter: (data: any) => void;
  handleSort: (data: any) => void;
  buttons: any;
  columns: any;
  rows: any;
  onRowOpenClick: (data: any) => void;
  title: string;
  onRowSelect: (data: any) => void;
  allowAdvancedBulkEdit: boolean;
  onAdvancedBulkEdit: () => void;
  refreshData: () => void;
  isAllRowSelected: boolean;
  onAllRowSelect: (data: any) => void;
  showContextMenu: boolean;
  width?: any;
}

export default function DataGridHolder(props: DataGridHolderProps) {
  const dispatch = useAppDispatch();

  const onContextMenuOp = (data: any, isAdvanceEditOperation = false) => {
    const copyOfData = { ...data };
    let dataToUpdate = [];
    if (!Utility.isEmptyObject(copyOfData)) {
      props.rows.forEach((row: any) => {
        if (row.selected) {
          let cells = {};
          Object.keys(copyOfData).forEach((columnId) => {
            if (isAdvanceEditOperation) {
              if (
                !Utility.isEmptyObject(copyOfData[columnId]) ||
                Utility.isValidDate(copyOfData[columnId])
              ) {
                cells[columnId] = copyOfData[columnId];
              }
            } else {
              cells[columnId] = [
                ...copyOfData[columnId].map((item) => item.id)
              ];
            }
          });
          dataToUpdate.push({
            _id: row?._id,
            cells
          });
        }
      });
      BulkOperationsService.updateBulkRecord(dataToUpdate, props.tableId).then(
        (data) => {
          props.refreshData();
        }
      );
    }
  };

  const deleteColumn = async (columnId: string) => {
    try {
      dispatch(
        deleteColumnFromStore({
          tableId: props.tableId,
          column: { id: columnId }
        })
      );
      await httpClient.delete(
        API_ENDPOINTS.COLUMN.DELETE_COLUMN(props.tableId, columnId)
      );
    } catch (error) {
      console.error('Failed to update column data:', error);
    }
  };

  return (
    <div className="column parent-height parent-width">
      {props.showContextMenu && (
        <ContextMenu
          tableName={props.title}
          maxWidth={SideBarService.getContainerWidth()}
          displayTableName={props.title}
          columnData={props.columns}
          allowBulkDelete={true}
          allowAdvancedBulkEdit={true}
          onAdvanceBulkEdit={() => props.onAdvancedBulkEdit()}
          onDelete={() => {
            showAlert(
              'Delete Records?',
              'Are you sure you want to delete these records?',
              [
                {
                  title: 'Cancel',
                  className: 'bg-gray1 border-m mr-r',
                  onClick: () => {}
                },
                {
                  title: 'Delete',
                  className: 'bg-red text-white',
                  onClick: () => {
                    let selectedRowIDS = props.rows
                      .filter((row: any) => row.selected)
                      .map((row: any) => row._id);
                    if (selectedRowIDS) {
                      BulkOperationsService.deleteRows(
                        selectedRowIDS,
                        props.tableId
                      )
                        .then((response) => {
                          props.refreshData();
                        })
                        .catch((error) => {
                          showAlert(
                            'Error',
                            'Failed to delete records. Please try again.'
                          );
                        });
                    } else {
                      showAlert(
                        'No records selected',
                        'Please select records to delete'
                      );
                    }
                  }
                }
              ]
            );
          }}
          onApply={(data) => {
            onContextMenuOp(data);
          }}
          onCancel={() => props.onAllRowSelect({ selected: false })}
        />
      )}
      <DKDataGrid
        showHeader={!props.showContextMenu}
        updating={props.updating}
        allowBulkOperation
        allowColumnEdit
        allowColumnShift
        allowColumnSort
        allowColumnWebhook={false}
        allowExpand={false}
        allowFilter
        allowRowEdit
        allowColumnDelete
        allowSearch
        allowColumnFreeze
        width={props.width || SideBarService.getContainerWidth()}
        currentPage={props.currentPage}
        needShadow={false}
        needBorder={false}
        needTrailingColumn={true}
        allowFormulaColumn={false}
        onPageChange={(data) => props.onPageChange(data)}
        totalPageCount={props.totalPageCount}
        filterData={props.filterData}
        filterOperator={props.filterOperator}
        onRowUpdate={(data) => props.onRowUpdate(data)}
        onColumnAdd={(data) => props.onColumnAdd(data.columnData)}
        onColumnShift={(data) => props.onColumnShift(data)}
        onColumnUpdate={(data) => props.onColumnUpdate(data)}
        onColumnFreezeToggle={props.onColumnFreezeToggle}
        onSearch={props.onSearch}
        onFilter={props.onFilter}
        onSort={(data) => props.handleSort(data)}
        buttons={props.buttons}
        columns={props.columns}
        rows={props.rows}
        onRowOpenClick={props.onRowOpenClick}
        title={props.title}
        allowAdvancedBulkEdit={true}
        onRowSelect={props.onRowSelect}
        onColumnDelete={(data: any) => {
          deleteColumn(data.columnData.id);
        }}
        onAllRowSelect={props.onAllRowSelect}
        isAllRowSelected={props.isAllRowSelected}
      />
    </div>
  );
}
