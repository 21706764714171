import { INPUT_TYPE } from 'deskera-ui-library';
import { IColumn } from '../model/Table';
import Utility from '../utility/Utility';
import { excludedColumnsFromValidations } from '../constants/EximConstants';
import { REGEX } from '../constants/Constants';
function hasImportedSelections(selectedOptions, options) {
  if (!selectedOptions?.length || !options?.length) return false;

  return selectedOptions.some((selectedOption) => {
    const optionData = options.find((option) => option.id === selectedOption);
    return optionData?.isExternal;
  });
}

function isValidField(value: any, column: IColumn, dropdownOptions: any[]) {
  let flag = true;

  /* Required Column Validation */
  if (Utility.isEmptyObject(value)) {
    flag = !column.required;
  } else if (
    excludedColumnsFromValidations.includes(
      `${column.tableName}_${column.columnCode}`
    )
  ) {
    return true;
  } else {
    switch (column.type) {
      case INPUT_TYPE.EMAIL:
        flag = Utility.isValidEmail(value);
        break;
      case INPUT_TYPE.NUMBER:
        flag = Utility.isValidNumber(value);
        break;
      case INPUT_TYPE.DATE:
        flag = Utility.isValidDate(value);
        break;
      case INPUT_TYPE.SELECT:
        flag =
          !column.systemField || !hasImportedSelections(value, dropdownOptions);
        break;
      case INPUT_TYPE.MULTI_SELECT:
        flag =
          !column.systemField || !hasImportedSelections(value, dropdownOptions);
        break;
      default:
        break;
    }
  }

  return flag;
}

export function validateRecord(
  record: any,
  columns: IColumn[],
  dropdownOptions?: Map<string, any[]>
) {
  /* Validate Each Column for a record */
  const invalidFields = [];
  columns.forEach((column) => {
    const key = column.key;
    const value = record[key];
    const options = dropdownOptions?.get(key) || column.options;

    try {
      if (
        column.isLineItemField
        // && column.columnCode === COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_PRICE
      ) {
        const isValid = Utility.isValidNumber(value) && Number(value) >= 0;
        !isValid && invalidFields.push(key);
      }

      if (
        column.isLineItemField
        // && column.columnCode === COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_DISCOUNT
      ) {
        const matcher = String(value).match(REGEX.PERCENT_NUMBER);
        const isPercentDiscount = '%' === matcher?.[4];

        if (!matcher) {
          record[key] = '';
        } else if (isPercentDiscount) {
          const percentDiscount = Number(value.replace('%', ''));
          percentDiscount > 100 && invalidFields.push(key);
        } else {
          const isValid = Utility.isValidNumber(value) && Number(value) <= 0;
          // Number(record[COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_PRICE] || 0);
          !isValid && invalidFields.push(key);
        }
      }
    } catch (err) {}

    if (!isValidField(value, column, options)) {
      invalidFields.push(key);
    }
  });

  return invalidFields;
}
