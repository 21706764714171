import { DKIcons } from 'deskera-ui-library';

export const LIST_MODULE = {
  APPLICATIONS: 'APPLICATIONS',
  MARKETPLACE: 'MARKETPLACE'
};
export const ENV = {
  DEV: 'DEV',
  QA: 'QA',
  QA2: 'QA2',
  STAGING: 'STAGING',
  PROD: 'PROD'
};
export const PRODUCTS = {
  AIO: 'aio',
  ERP: 'erp',
  CRM: 'crm',
  CRM_PLUS: 'crm_plus',
  PEOPLE: 'people',
  WEBSITE_BUILDER: 'website builder',
  SHOP: 'shop',
  REPORT_BUILDER: 'report_builder',
  DOCUMENT_BUILDER: 'document builder',
  DOCUMENT_SCANNER: 'document scanner',
  ORGANISATION: 'organisation',
  CONSOLE: 'console',
  WORKFLOW_ENGINE: 'workflow_engine',
  ATTENDANCE: 'attendance',
  EXPENSE: 'expense',
  CHAT: 'chat',
  EM: 'em', //Expense
  DS: 'ds', //Shop
  EL: 'el' //Leave Management
};

export const PLANS = {
  STARTUP: 'startup',
  ESSENTIAL: 'essential',
  PROFESSIONAL: 'professional'
};

export const AUTHORIZATION_STATUS = {
  INITIATE: 'INITIATE',
  PENDING: 'PENDING',
  DENIED: 'DENIED',
  AUTHORIZED: 'AUTHORIZED',
  TRIAL_AVAILABLE: 'TRIAL_AVAILABLE'
};

export const INTERVALS = {
  YEARLY: 'yearly',
  MONTHLY: 'monthly'
};

export const ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify'
};

export const FORM_TYPE = {
  BOOK_A_DEMO: 'BOOK_A_DEMO',
  SIGN_UP_LEAD_GENERATION: 'SIGN_UP_LEAD_GENERATION',
  CONTACT_US: 'CONTACT_US'
};

export const API_STATUS = {
  SUCCESS_200: 200,
  SUCCESS_201: 201
};

export const USER_ROLE = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  MEMBER: 'User'
};
export const USER_TYPE = {
  GUEST: 'Guest',
  PAID: 'Paid'
};
export const USER_STATUS = {
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
  PENDING: 'Pending'
};

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: 'In trial',
  TRIAL_PENDING: 'Trial available',
  EXPIRED: 'Expired',
  ACTIVE: 'Active',
  TRIAL_ENDED: 'Trial over',
  CANCELLED: 'Cancelled'
};

export const ACTION_KEY = {
  DELETE_COMAPANY: 'delete_company',
  RESET_COMPANY: 'reset_company'
};

export const DELETE_OPTIONS = [
  {
    label: "It's too costly",
    value: "It's too costly"
  },
  {
    label: 'I found another product that fulfills my needs.',
    value: 'I found another product that fulfills my needs.'
  },
  {
    label: "I don't use it enough.",
    value: "I don't use it enough."
  },
  {
    label: 'Some features I need are missing',
    value: 'Some features I need are missing'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const PASSWORD_OBJ = {
  OLD_PASSWORD: 'old password',
  NEW_PASSWORD: 'new password',
  CONFIRM_NEW_PASSWORD: 'confirm new password'
};

export const PASSWORD_SETTING = {
  SHOW: 'show',
  HIDE: 'hide'
};

export const PASSWORD_ERROR = {
  ERROR_API: 'error: api',
  ERROR_BLANK: 'error: blank',
  ERROR_NOT_MATCHED: 'error: mismatched',
  ERROR_NOT_MATCHED_FIELD: 'error: mismatched other field',
  ERROR_LENGTH: 'error: length invalid'
};

export const PLAN_UPDATE_TYPE = {
  TRIAL_START: 'TrialStart',
  PLAN_START: 'PlanStart',
  UPGRADE: 'Upgrade',
  DOWNGRADE: 'Downgrade'
};
export const INVOICE_STATUS = {
  PAID: 'paid',
  FAILED: 'failed'
};

export const CODE_TYPE = {
  PREPAID_CODE: 'prepaid',
  PROMO_CODE: 'promo / discount',
  PLAN_CODE: 'plan',
  ADDON_CODE: 'addon',
  COUPON_CODE: 'coupon'
};

export const WEBSITE_TYPE = {
  HELP_DESK: 'helpdesk',
  BUILDER: 'builder'
};

export const CHART_TYPE = {
  BAR_VERTICAL: {
    id: 'BAR_VERTICAL',
    name: 'Vertical Bar Graph'
  },
  MULTI_BAR_VERTICAL: {
    id: 'MULTI_BAR_VERTICAL',
    name: 'Vertical Multi-Bar Graph'
  },
  BAR_HORIZONTAL: {
    id: 'BAR_HORIZONTAL',
    name: 'Horizontal Bar Graph'
  },
  MULTI_BAR_HORIZONTAL: {
    id: 'MULTI_BAR_HORIZONTAL',
    name: 'Horizontal Multi-Bar Graph'
  },
  LINE: {
    id: 'LINE',
    name: 'Line Chart'
  },
  DOUGHNUT: {
    id: 'DOUGHNUT',
    name: 'Doughnut Chart'
  }
};

export const CHART_API_SOURCES = {
  INVOICES: 'INVOICES',
  AGE_RECEIVABLE: 'AGE_RECEIVABLE',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  WON_AND_LOST_DEALS: 'WON_AND_LOST_DEALS',
  PIPELINES: 'PIPELINES',
  CONTACTS: 'CONTACTS',
  EXPENSES_CLAIMED: 'EXPENSES_CLAIMED',
  PAYROLL: 'PAYROLL',
  LEAVES_PER_MONTH: 'LEAVES_PER_MONTH'
};
export const VISIBILITY_TYPE = {
  VISIBLITY_1: 'Private',
  VISIBLITY_2: 'Team',
  VISIBLITY_3: 'Everyone'
};
export const DEAL_STATUS_CODE = {
  DEFAULT: 0,
  WON: 1,
  LOST: 2
};
export const ACTIVITY_VIEW = {
  CALENDAR: 'Calendar',
  GRID: 'Grid'
};
export const ACTIVITY_STATUS_CODE = {
  COMPLETED: 1,
  PENDING: 2,
  OVERDUE: 3
};
export const VISIBILITY = [
  { name: 'Private', id: 1 },
  { name: 'Team', id: 2 },
  { name: 'Everyone', id: 3 }
];
export const CONTACT_TYPE = {
  PERSON: 1,
  ORGANIZATION: 2
};

export enum DEAL_CONTEXT_MENU_KEYS {
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
  DEAL_WON = 'DEAL_WON',
  DEAL_LOST = 'DEAL_LOST',
  ATTACHMENT = 'ATTACHMENT',
  DELETE = 'DELETE',
  ASSIGN_OWNER = 'ASSIGN_OWNER',
  ASSIGN_SUB_OWNER = 'ASSIGN_SUB_OWNER',
  ASSIGN_TEAM = 'ASSIGN_TEAM'
}

export const MESSAGE_TYPE = {
  TEXT: 'text',
  MULTIMEDIA: 'multimedia'
};
export const FILE_TYPE = {
  IMAGE: 'img',
  PDF: 'pdf',
  DOC: 'doc',
  EXCEL: 'excel',
  POWERPOINT: 'powerpoint',
  FILE: 'file'
};

export enum NOTIFICATION_ACTION_TYPE {
  REC_CREATE = 'REC_CREATE',
  REC_UPDATE = 'REC_UPDATE',
  REC_DELETE = 'REC_DELETE',
  REC_BULK_DELETE = 'REC_BULK_DELETE',
  REC_BULK_CREATE = 'REC_BULK_CREATE',
  REC_BULK_UPDATE = 'REC_BULK_UPDATE',
  REC_IMPORT = 'REC_IMPORT',
  NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
  REC_PERMISSION_CHANGED = 'REC_PERMISSION_CHANGED',
  TABLE_PERMISSION_CHANGED = 'TABLE_PERMISSION_CHANGED',
  SEGMENT_CREATE = 'SEGMENT_CREATE',
  NOTE_CREATE = 'NOTE_CREATE',
  NOTE_DELETED = 'NOTE_DELETED',
  NOTE_UPDATED = 'NOTE_UPDATED',
  REC_UPDATE_REJECTED = 'REC_UPDATE_REJECTED',
  REC_CREATE_REJECTED = 'REC_CREATE_REJECTED',
  REC_BULK_CREATE_REJECTED = 'REC_BULK_CREATE_REJECTED',
  REC_BULK_UPDATE_REJECTED = 'REC_BULK_UPDATE_REJECTED',
  OWNER_ASSIGNED = 'OWNER_ASSIGNED',
  REQUIRED_COLUMN_SETTING_CHANGED = 'REQUIRED_COLUMN_SETTING_CHANGED'
}

export const TICKET_TYPES = [
  'Incident/Support Request',
  'Technical Query',
  'Suspected Hardware Failure',
  'Question',
  'Task',
  'Problem'
];

export const TICKET_STATUS = [
  'In Progress',
  'Waiting for Support',
  'Monitoring',
  'Waiting for Customer',
  'Fix Available',
  'Resolved',
  'Closed',
  'Open'
];
export const ALL_TICKET_FILTER = 'All';

export enum CHAT_BUBBLE_POSITION {
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM_LEFT = 'BOTTOM_LEFT'
}

export enum TICKET_VALUES_NAME {
  STATUS = 'status',
  CONTACT = 'crm3_contact_id',
  PRIORITY = 'priority',
  ACCOUNT = 'crm3_account_id'
}

export const TICKET_SORTING_OPTION = [
  { name: 'Priority', value: 'priority' },
  { name: 'Status', value: 'status' },
  { name: 'Requestor', value: 'requestor' },
  { name: 'Ticket No', value: 'ticket_no' },
  { name: 'Type', value: 'type' },
  { name: 'Updated At', value: 'updated_at' },
  { name: 'Email', value: 'email' },
  { name: 'Subject', value: 'subject' }
];

export enum LOCAL_STORAGE_KEYS {
  IS_SIDE_BAR_EXPANDED = 'isSideBarExpanded',
  SELECTED_THEME = 'selectedTheme',
  SELECT_PROMPT_FROM_STORAGE = 'selectPromptFromStorage',
  PROMPT_MESSAGES = 'PROMPT_MESSAGES'
}

export enum TICKET_EVENT_TYPES {
  TICKET = 'TICKET',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL'
}

export enum FILTER_OPERATORS {
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  GREATER_EQUAL = 'gteq' /* only used in automation for dates */,
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  CONTAINS = 'c',
  IN = 'in'
}

export enum FILTER_LOGICAL_OPERATORS {
  AND = 'and',
  OR = 'or'
}

export enum AUTOMATION_LOG_NODE_TYPE {
  CREATE_DEAL = 'CREATE_DEAL',
  CREATE_CONTACT = 'CREATE_CONTACT',
  UPDATE_DEAL = 'UPDATE_DEAL',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  SEND_EMAIL = 'SEND_EMAIL',
  SPLIT = 'SPLIT',
  WAIT_FOR_TIME = 'WAIT_FOR_TIME',
  // SEND_SMS_MESSAGE = "SEND_SMS_MESSAGE",
  SEND_WHATSAPP_MESSAGE = 'SEND_WHATSAPP_MESSAGE',
  ASSIGN_OWNER = 'ASSIGN_OWNER',
  END_WORKFLOW = 'END_WORKFLOW',
  CAMPAIGN_PUBLISHED = 'CAMPAIGN_PUBLISHED',
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  CONTACT_ADDED = 'CONTACT_ADDED',
  CONTACT_UPDATED = 'CONTACT_UPDATED',
  FACEBOOK_LEAD_ADDED = 'FACEBOOK_LEAD_ADDED',
  DEAL_UPDATED = 'DEAL_UPDATED',
  DEAL_STAGE_CHANGED = 'DEAL_STAGE_CHANGED',
  ACTIVITY_UPDATED = 'ACTIVITY_UPDATED',
  CRM_DATE_ALERT_TRIGGER = 'CRM_DATE_ALERT_TRIGGER',
  BOOKS_CREATE_QUOTATION = 'BOOKS_CREATE_QUOTATION'
}

export enum AUTOMATION_ACTIONS_TITLE {
  SEND_EMAIL = 'Send an e-mail',
  CREATE_CONTACT = 'Create a contact',
  UPDATE_CONTACT = 'Update a contact',
  CREATE_DEAL = 'Create a deal',
  UPDATE_DEAL = 'Update a deal',
  SPLIT = 'Split Path',
  WAIT_FOR_TIME = 'Wait for (time)',
  // SEND_SMS_MESSAGE = "Send a text message",
  SEND_WHATSAPP_MESSAGE = 'Send a whatsapp message',
  // CRM_DATE_ALERT_TRIGGER = "Date alert trigger",
  ASSIGN_OWNER = 'Assign owner'
}

export enum AUTOMATION_TRIGGER_TITLE {
  CAMPAIGN_PUBLISHED = 'Campaign is published',
  FORM_SUBMITTED = 'Form submitted',
  CONTACT_ADDED = 'Contact added',
  CONTACT_UPDATED = 'Contact field changed',
  // FACEBOOK_LEAD_ADDED = "Facebook lead added",
  DEAL_UPDATED = 'Deal field changed',
  DEAL_STAGE_CHANGED = 'Deal stage changed',
  ACTIVITY_UPDATED = 'Activity field changed',
  CRM_DATE_ALERT_TRIGGER = 'Date alert trigger',
  BOOKS_CREATE_QUOTATION = 'Quotation added'
}

export enum AUDIT_ACTIONS_TITLE {
  REC_CREATE = 'is created',
  REC_UPDATE = 'is updated',
  REC_DELETE = 'is deleted',
  REC_BULK_CREATE = 'are created',
  REC_BULK_UPDATE = 'are updated',
  REC_BULK_DELETE = 'are deleted',
  REC_PERMISSION_CHANGED = 'permission changed',
  TABLE_PERMISSION_CHANGED = 'permission changed',
  SEGMENT_CREATE = 'Segment is created',
  REC_UPDATE_REJECTED = 'update is rejected due to duplication',
  REC_CREATE_REJECTED = 'create is rejected due to duplication',
  REC_BULK_CREATE_REJECTED = 'create is rejected due to duplication',
  REC_BULK_UPDATE_REJECTED = 'update is rejected due to duplication',
  OWNER_ASSIGNED = 'OWNER_ASSIGNED',
  REQUIRED_COLUMN_SETTING_CHANGED = 'Required Fields setting changed'
}

export enum AUDIT_ACTIONS_FILTER {
  REC_CREATE = 'Record created',
  REC_UPDATE = 'Record updated',
  REC_UPDATE_REJECTED = 'Record created is rejected',
  REC_CREATE_REJECTED = 'Record updated is rejected',
  REC_DELETE = 'Record deleted',
  REC_BULK_CREATE = 'Bulk record created',
  REC_BULK_UPDATE = 'Bulk record updated',
  REC_BULK_CREATE_REJECTED = 'Bulk record created is rejected',
  REC_BULK_UPDATE_REJECTED = 'Bulk record updated is rejected',
  REC_BULK_DELETE = 'Bulk record deleted',
  SEGMENT_CREATE = 'Segment is created',
  REQUIRED_COLUMN_SETTING_CHANGED = 'Required Fields setting changed'
}

export enum AUDIT_OBJECT_TYPE {
  ACCOUNT = 'Account',
  ACTIVITY = 'Activity',
  CAMPAIGN = 'Campaign',
  CONTACT = 'Contact',
  DEAL = 'Deal',
  FORM = 'Form',
  LANDING_PAGE = 'Landing Page',
  PIPELINE = 'Pipeline',
  STAGE = 'Stage',
  FUNNEL = 'Funnel',
  AUTOMATION = 'Automation',
  ORDER = 'Order',
  CHAT_THREAD = 'Chat Thread',
  CHAT_MESSAGE = 'Chat Message',
  TICKET = 'Ticket',
  SUPPORT_EMAIL = 'Support Email',
  ORGANIZATION = 'Organization',
  TABLE = 'Table'
}

export enum AUDIT_BULK_OBJECT_TYPE {
  ACCOUNT = 'Accounts',
  ACTIVITY = 'Activities',
  CAMPAIGN = 'Campaigns',
  CONTACT = 'Contacts',
  DEAL = 'Deals',
  FORM = 'Forms',
  LANDING_PAGE = 'Landing Pages',
  PIPELINE = 'Pipelines',
  STAGE = 'Stages',
  FUNNEL = 'Funnels',
  AUTOMATION = 'Automations',
  ORDER = 'Orders',
  CHAT_THREAD = 'Chat Threads',
  CHAT_MESSAGE = 'Chat Messages',
  TICKET = 'Tickets',
  SUPPORT_EMAIL = 'Support Emails',
  ORGANIZATION = 'Organizations'
}

export enum OBJECT_TYPE {
  ACTIVITY = 'ACTIVITY',
  CAMPAIGN = 'CAMPAIGN',
  CONTACT = 'CONTACT',
  DEAL = 'DEAL',
  FORM = 'FORM',
  LANDING_PAGE = 'LANDING_PAGE',
  PIPELINE = 'PIPELINE',
  STAGE = 'STAGE',
  FUNNEL = 'FUNNEL',
  AUTOMATION = 'AUTOMATION',
  ORDER = 'ORDER',
  CHAT_THREAD = 'CHAT_THREAD',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  TICKET = 'TICKET',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  ORGANIZATION = 'ORGANIZATION'
}

export enum REPORTS_TAB {
  STANDARD = 'standard',
  CUSTOM = 'custom'
}

export enum FILTER_CONDITION {
  EQUALS = 'EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUALS = 'LESS_THAN_EQUALS'
}

export enum THREAD_TYPE {
  OPEN = 0,
  CLOSED = 1
}

export enum PREFIXES {
  CELLS = 'cells.'
}

export enum FILE_INPUT_TYPE {
  FILE = 'file'
}

export enum TAX_RESIDENCY {
  AE = 'ae' /* uae */,
  ID = 'id' /* indonesia */,
  IN = 'in' /* india */,
  MY = 'my' /* malaysia */
}

export enum APP_NAME {
  CRM_3 = 'CRM3'
}

export enum CONTACT_DETAIL_TAB {
  EMAIL = 'contactEmail',
  TICKET = 'tickets',
  DEAL = 'deals',
  ACTIVITY = 'activities',
  QUOTES = 'quotes',
  INVOICES = 'invoices',
  NOTES = 'notes',
  ATTACHMENTS = 'Attachments',
  LOGS = 'logs'
}

export enum DEAL_DETAIL_TAB {
  ACTIVITY = 'activity',
  CONTACTS = 'contact',
  QUOTES = 'quotes',
  INVOICES = 'invoices',
  NOTES = 'notes',
  ATTACHMENTS = 'attachments',
  LOGS = 'logs'
}

export enum ACCOUNT_DETAIL_TAB {
  EMAIL = 'Email',
  DEAL = 'Deals',
  TICKET = 'Tickets',
  ACTIVITY = 'Activities',
  QUOTES = 'Quotes',
  INVOICES = 'Invoices',
  NOTES = 'Notes',
  ATTACHMENTS = 'Attachments',
  LOGS = 'Logs'
}

export enum QUERY_PARAM_KEY {
  CONTACT_DETAIL_TAB = 'activeTab'
}

export enum DATE_RNGE_FILTER_OPTION {
  ALL_DATA = 'All Data',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_QUARTER = 'This Quarter',
  THIS_YEAR = 'This Year',
  CUSTOM_DATE = 'Custom Date'
}

export enum CAMPAIGN_TYPE {
  EMAIL = 1,
  WHATSAPP = 2
}

export enum CAMPAIGN_STATUS {
  DRAFT = 1,
  RUNNING = 2,
  SENT = 3,
  SCHEDULED = 4,
  CANCELED = 5,
  FAILED = 6,
  SCHEDULED_NOW = 7,
  PARTIALLY_SENT = 8
}

export enum ACCOUNT_ACTION_TYPE {
  EDIT = 'EDIT',
  OPEN = 'OPEN',
  ASSIGN_OWNER = 'ASSIGN_OWNER',
  ASSIGN_SUB_OWNER = 'ASSIGN_SUB_OWNER',
  ASSIGN_TEAM = 'ASSIGN_TEAM',
  CREATE_QUOTE = 'CREATE_QUOTE',
  CREATE_INVOICE = 'CREATE_INVOICE',
  DELETE = 'DELETE'
}

export enum INDIA_MART_LEAD_DATA_KEYS {
  UNIQUE_QUERY_ID = 'UNIQUE_QUERY_ID',
  QUERY_TYPE = 'QUERY_TYPE',
  QUERY_TIME = 'QUERY_TIME',
  SENDER_NAME = 'SENDER_NAME',
  SENDER_MOBILE = 'SENDER_MOBILE',
  SENDER_EMAIL = 'SENDER_EMAIL',
  SUBJECT = 'SUBJECT',
  SENDER_COMPANY = 'SENDER_COMPANY',
  SENDER_ADDRESS = 'SENDER_ADDRESS',
  SENDER_CITY = 'SENDER_CITY',
  SENDER_STATE = 'SENDER_STATE',
  SENDER_PINCODE = 'SENDER_PINCODE',
  SENDER_COUNTRY_ISO = 'SENDER_COUNTRY_ISO',
  SENDER_MOBILE_ALT = 'SENDER_MOBILE_ALT',
  SENDER_PHONE = 'SENDER_PHONE',
  SENDER_PHONE_ALT = 'SENDER_PHONE_ALT',
  SENDER_EMAIL_ALT = 'SENDER_EMAIL_ALT',
  QUERY_PRODUCT_NAME = 'QUERY_PRODUCT_NAME',
  QUERY_MCAT_NAME = 'QUERY_MCAT_NAME',
  QUERY_MESSAGE = 'QUERY_MESSAGE',
  CALL_DURATION = 'CALL_DURATION',
  RECEIVER_MOBILE = 'RECEIVER_MOBILE'
}

export enum JUST_DIAL_LEAD_DATA_KEYS {
  NAME = 'name',
  MOBILE = 'mobile',
  EMAIL = 'email',
  CATEGORY = 'category',
  AREA = 'area',
  CITY = 'city',
  PINCODE = 'pincode',
  PHONE = 'phone',
  COMPANY = 'company',
  BRANCH_AREA = 'brancharea',
  BRANCH_PINCODE = 'branchpin',
  DATE = 'date'
}

export enum APPROVAL_STATUS {
  PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_REQUIRED = 'NOT_REQUIRED'
}

export enum TEMPLATE_MODULE_ID {
  TICKET = 1,
  CONTACT = 2,
  DEAL = 3,
  ACTIVITY = 4
}

export enum PIPELINE_CONTEXT_MENU_KEYS {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ASSIGN_OWNER = 'ASSIGN_OWNER',
  ASSIGN_SUB_OWNER = 'ASSIGN_SUB_OWNER',
  ASSIGN_TEAM = 'ASSIGN_TEAM'
}

export enum DUPLICATE_CHECK_OPERATORS {
  AND = 'and',
  OR = 'or'
}

export enum DUPLICATE_CHECK_ACTIONS {
  UPDATE = 'UPDATE',
  REJECT = 'REJECT'
}

export enum LOGS_TAB {
  LEAD_SYNC = 'leadSyncLogs',
  AUTOMATION = 'automationLogs',
  AUDIT = 'auditLogs',
  IMPORT = 'importLogs',
  PRICE_BOOK_LOGS = 'priceBookLogs'
}

export const COMPOSITION_TAX_PERCENT: any = {
  ONE_PERCENT: 1,
  TWO_PERCENT: 2,
  FIVE_PERCENT: 5,
  SIX_PERCENT: 6
};

export enum GST_TYPE {
  DEFAULT,
  INTER,
  INTRA,
  EXEMPT
}

export enum COMPLIANCE_SPECIFIC_FIELD_NAME {
  CONTACT = 'CONTACT',
  PRODUCT = 'PRODUCT',
  INVOICE = 'INVOICE',
  QUOTATION = 'QUOTATION',
  QUOTE = 'QUOTE'
}

export enum PRODUCT_TYPE {
  TRACKED = 'TRACKED',
  NON_TRACKED = 'NONTRACKED',
  BILL_OF_MATERIALS = 'BILL_OF_MATERIALS'
}

///formula builder enums
export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  EMAIL: 'email',
  DATE: 'date',
  URL: 'url',
  PHONE: 'phone',
  SELECT: 'select',
  MULTI_SELECT: 'multi-select',
  CHECKBOX: 'checkbox',
  BUTTON: 'button',
  MORE: 'more',
  LONG_TEXT: 'long text',
  LONG_TEXT2: 'long_text',
  DROPDOWN: 'dropdown',
  FILE: 'file',
  ADDRESS: 'address',
  FORMULA: 'formula'
};

export const INPUT_VIEW_DIRECTION = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL'
};

/* Common KeyboardEvent.key codes */
export const KEY_CODES = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  DELETE: 'Delete',
  BACKSPACE: 'Backspace',
  PERIOD: 'Period'
};

export const FILTER_OPERATOR_TYPE = {
  AND: 'and',
  OR: 'or'
};

export function getDataTypeIcon(type) {
  switch (type) {
    case INPUT_TYPE.TEXT:
      return DKIcons.data_type.ic_text;
    case INPUT_TYPE.NUMBER:
      return DKIcons.data_type.ic_number;
    case INPUT_TYPE.EMAIL:
      return DKIcons.data_type.ic_email;
    case INPUT_TYPE.DATE:
      return DKIcons.data_type.ic_date;
    case INPUT_TYPE.URL:
      return DKIcons.data_type.ic_url;
    case INPUT_TYPE.PHONE:
      return DKIcons.data_type.ic_phone;
    case INPUT_TYPE.SELECT:
      return DKIcons.data_type.ic_select;
    case INPUT_TYPE.ADDRESS:
      return DKIcons.data_type.ic_select;
    case INPUT_TYPE.DROPDOWN:
      return DKIcons.data_type.ic_select;
    case INPUT_TYPE.MULTI_SELECT:
      return DKIcons.data_type.ic_multi_select;
    case INPUT_TYPE.CHECKBOX:
      return DKIcons.data_type.ic_checkbox;
    case INPUT_TYPE.BUTTON:
      return DKIcons.data_type.ic_button;
    case INPUT_TYPE.MORE:
      return DKIcons.data_type.ic_button;
    case INPUT_TYPE.FILE:
      return DKIcons.data_type.ic_file;
    case INPUT_TYPE.FORMULA:
      return DKIcons.data_type.ic_formula;
    case null:
      return DKIcons.ic_plus;
    default:
      return DKIcons.data_type.ic_text;
  }
}

export const FORMULA_TOKEN_IDENTIFIER = 'dk-formula-token';
export const FORMULA_TOKEN_TYPES = {
  OPERATOR: 'OPERATOR',
  OPERAND: 'OPERAND',
  GROUP: 'GROUP'
};
export const FORMULA_TOKEN = {
  OPEN: '(',
  CLOSE: ')',
  PLUS: '+',
  MINUS: '-',
  MULTIPLY: '*',
  DIVIDE: '/',
  POWER: '^',
  MOD: '%'
};
export const FORMULA_TOOLBAR = {
  [FORMULA_TOKEN_TYPES.GROUP]: [
    { token: 'OPEN', value: FORMULA_TOKEN.OPEN },
    { token: 'CLOSE', value: FORMULA_TOKEN.CLOSE }
  ],
  [FORMULA_TOKEN_TYPES.OPERATOR]: [
    { token: 'PLUS', value: FORMULA_TOKEN.PLUS },
    { token: 'MINUS', value: FORMULA_TOKEN.MINUS },
    { token: 'MULTIPLY', value: FORMULA_TOKEN.MULTIPLY },
    { token: 'DIVIDE', value: FORMULA_TOKEN.DIVIDE },
    { token: 'POWER', value: FORMULA_TOKEN.POWER },
    { token: 'MOD', value: FORMULA_TOKEN.MOD }
  ]
};
export const DATA_SOURCE = {
  WEBHOOKS: 'webhooks',
  LOOKUP: 'lookup'
};
export const FIELD_ALIGNMENT = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};

export enum FORM_BUILDER_ACTION {
  EDIT,
  DELETE
}
export enum HTTP_STATUS {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  NOT_MODIFIED = 304,
  BAD_REQ = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500
}
export enum CARD_ACTION {
  RENAME,
  DELETE,
  COPY
}
