// import API from "./api";
import { DKButton } from 'deskera-ui-library';
import httpClient from '../../http';
import { API_ENDPOINTS } from '../../constants/Endpoints';
const SaveButtonComp = ({
  tableName,
  dataSourceId,
  onTableCreated,
  dataRows,
  isLoading,
  appId,
  setIntegratedDSPopup
}) => {
  const handleCreateTable = async () => {
    try {
      const tableConfig = {
        name: tableName,
        databaseName: 'test',
        datasourceId: dataSourceId || '',
        appId: appId,
        columnsMetaData: dataRows.map((row) => ({
          dsKeyPath: row.path,
          name: row.name,
          type: row.deducedType
        }))
      };

      const response = await httpClient.post(
        API_ENDPOINTS.TABLE.CREATE,
        tableConfig
      );
      onTableCreated(response);
    } catch (error) {
      console.error('Error creating table:', error);
    }
  };

  return (
    <DKButton
      className="bg-button text-white ml-r"
      onClick={() => {
        if (!dataSourceId) return;
        setIntegratedDSPopup(false);

        handleCreateTable();
      }}
      title="Save"
    />
  );
};

export default SaveButtonComp;
