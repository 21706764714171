import { INPUT_TYPE, getDateAsString } from 'deskera-ui-library';
import {
  defaultRequiredColumnsToImport,
  excludedColumnCodes,
  excludedExternalSourceColumns,
  IMPORT_DEFAULT_DATE_FORMAT,
  IMPORT_SOURCE_TYPE,
  MODULES_WITH_NESTED_IMPORT,
  PRICE_BOOK_NAME_HEADER
} from '../constants/EximConstants';
import { validateRecord } from './RecordValidator';
import Utility, { getRandomNumber } from './Utility';
import { IColumn, IColumnOption } from '../model/Table';
import { DATE_FORMATS, DateUtil } from './Date';
import { REGEX } from '../constants/Constants';

function matchOptionWithList(
  importedOption: string,
  mappedOptions: any[],
  updatedOptions: any[]
) {
  const matchFound = updatedOptions.some((option) => {
    /* need exact match here */
    if (option.name.toLowerCase() === importedOption.toLowerCase()) {
      mappedOptions.push(option.id);
      return true;
    }

    return false;
  });

  if (!matchFound) {
    const newOptionId = parseInt(
      getRandomNumber(1000) + '' + updatedOptions.length
    );
    updatedOptions.push({
      id: newOptionId,
      name: importedOption,
      color: `data-grid-badge-color-${getRandomNumber(8) + 1}`,
      isExternal: true
    });
    mappedOptions.push(newOptionId);

    /* ToDo - changes required in DataGrid for showcasing
     unmapped/external strings in selection menu */
  }
}

export function mapStringToDropdown(
  recordValue: any,
  existingOptions = [],
  type: string
) {
  recordValue = recordValue.toString().trim();

  if (!recordValue?.length)
    return { mappedOptions: [], updatedOptions: existingOptions };

  const updatedOptions = [...existingOptions];
  const mappedOptions = [];

  if (type === INPUT_TYPE.MULTI_SELECT) {
    const importedOptions = recordValue.split(',');
    importedOptions.forEach((importedOption) => {
      const optionKey = importedOption.trim();
      if (!optionKey) return;
      matchOptionWithList(optionKey, mappedOptions, updatedOptions);
    });
  } else {
    matchOptionWithList(recordValue, mappedOptions, updatedOptions);
  }

  return { mappedOptions, updatedOptions };
}

export function mapFileDataToSystem(
  headerData: {
    importTableName: string;
    importedHeaderMappings: IMappedHeader[];
    tableColumns: IColumn[];
    pipelineData?: { id: string; name: string; stages: any[] };
  },
  fileData: any
) {
  return new Promise((onSuccess, onFailure) => {
    try {
      const { importedHeaderMappings, tableColumns, pipelineData } = headerData;
      if (!importedHeaderMappings?.length || !fileData?.length) {
        onFailure('HeaderMappings or FileData is missing');
        return;
      }

      const invalidRows = [];
      const importedHeaderMap = new Map();
      const customHeaderMappings: IMappedHeader[] = [];
      // const stageColumnId = TableManger.getColumnId(
      //   TABLES.DEAL,
      //   COLUMN_CODE.DEAL.STAGE_ID
      // );
      importedHeaderMappings.forEach((headerProps) => {
        if (Utility.isEmptyObject(headerProps.importedKey)) {
          !Utility.isEmptyObject(headerProps.importedKeys) &&
            customHeaderMappings.push(headerProps);
          return;
        }

        importedHeaderMap.set(headerProps.importedKey, headerProps);

        // if (pipelineData && headerProps.dkColKey === stageColumnId) {
        //   headerProps.options = pipelineData.stages;
        // }
      });

      const newHeaderOptionSet = new Map();

      const filteredRequiredSystemHeaders = {};
      Object.keys(defaultRequiredColumnsToImport).forEach(
        (requiredColKey: string) => {
          const [tableName, columnCode] = requiredColKey.split(' ');

          if (!tableName || !columnCode) return;

          const reqSystemHeader = tableColumns.find(
            (column: IColumn) =>
              column.tableName === tableName && column.columnCode === columnCode
          );
          if (reqSystemHeader) {
            filteredRequiredSystemHeaders[reqSystemHeader.id] = reqSystemHeader;
          }
        }
      );

      /** For nested imports like Price Book/Invoice/Quote  */
      let currentLineItemParentRowData = null;
      const requiredHeaderColumnForNestedImport = tableColumns.find(
        (column) => column.required && !column.isLineItemField
      );

      let importRecords = fileData.map((row: any, rowIndex: number) => {
        /* for Each row pick up every property and return new row with mapped header */
        let newRow: any = {};

        const rowKeys = Object.keys(row);

        /* Adding default value for Type Column in case of importing Contacts by default */
        Object.keys(filteredRequiredSystemHeaders).forEach(
          (requiredColKey: string) => {
            const columnData = filteredRequiredSystemHeaders[requiredColKey];
            const defaultColData =
              defaultRequiredColumnsToImport[
                `${columnData?.tableName} ${columnData?.columnCode}`
              ];

            if (
              defaultColData &&
              !rowKeys.includes(defaultColData.importedKey)
            ) {
              row[defaultColData.importedKey] = '';
              rowKeys.push(defaultColData.importedKey);
            }
          }
        );

        rowKeys.forEach((property) => {
          const headerProps = importedHeaderMap.get(property);
          if (headerProps && (!headerProps.isEscaped || headerProps.uiHidden)) {
            const key =
              headerProps.uiHidden && headerProps.isEscaped
                ? property
                : headerProps.dkColKey;

            let value = getDefaultDataForColumn(
              filteredRequiredSystemHeaders[key],
              row[property]
            );

            if (
              headerProps.type === INPUT_TYPE.SELECT ||
              headerProps.type === INPUT_TYPE.MULTI_SELECT
            ) {
              const { mappedOptions, updatedOptions } = mapStringToDropdown(
                value || '',
                headerProps.options,
                headerProps.type
              );
              newRow[key] = mappedOptions;

              headerProps.options = updatedOptions;
              newHeaderOptionSet.set(key, updatedOptions);
            } else if (headerProps.type === INPUT_TYPE.DATE) {
              if (!Utility.isValidDate(value) && typeof value === 'string') {
                try {
                  if (new RegExp(REGEX.DDMMYYYY_DATE).test(value)) {
                    value = DateUtil.getDateFromStr(
                      value,
                      DATE_FORMATS.DD_MM_YYYY
                    );
                  }
                  /* Apply checks for other formats... */
                } catch (err) {}
              }

              newRow[key] = Utility.isValidDate(value)
                ? getDateAsString(new Date(value), IMPORT_DEFAULT_DATE_FORMAT)
                : '';
            } else {
              newRow[key] = value;
            }
          }
        });

        /* processing custom mappings data */
        customHeaderMappings.forEach((headerProps) => {
          if (
            headerProps.isEscaped ||
            Utility.isEmptyObject(headerProps.importedKeys)
          )
            return;

          const key = headerProps.dkColKey;
          let value = headerProps.importedKeys
            .map((importedKey) => row[importedKey] || '')
            .filter((value) => !Utility.isEmptyObject(value?.toString()))
            .join(', ');

          value = getDefaultDataForColumn(
            filteredRequiredSystemHeaders[key],
            value
          );

          if (
            headerProps.type === INPUT_TYPE.SELECT ||
            headerProps.type === INPUT_TYPE.MULTI_SELECT
          ) {
            const { mappedOptions, updatedOptions } = mapStringToDropdown(
              value || '',
              headerProps.options,
              headerProps.type
            );
            newRow[key] = mappedOptions;

            headerProps.options = updatedOptions;
            newHeaderOptionSet.set(key, updatedOptions);
          } else {
            newRow[key] = value;
          }
        });

        if (
          MODULES_WITH_NESTED_IMPORT.includes(headerData.importTableName) &&
          requiredHeaderColumnForNestedImport
        ) {
          const requiredHeaderDataForRow =
            newRow[requiredHeaderColumnForNestedImport.id];

          if (Utility.isEmptyObject(requiredHeaderDataForRow)) {
            const dataToUpdate: any = {};
            currentLineItemParentRowData &&
              headerData.tableColumns.forEach((column) => {
                if (column.isLineItemField) return;

                dataToUpdate[column.id] =
                  currentLineItemParentRowData[column.id];
              });

            newRow = {
              ...newRow,
              ...dataToUpdate,
              isLineItem: true
            };
          } else {
            currentLineItemParentRowData = newRow;
          }
        }

        return newRow;
      });

      if (MODULES_WITH_NESTED_IMPORT.includes(headerData.importTableName)) {
        const requiredColumnForLineItem = tableColumns.find(
          (column) => column.required && column.isLineItemField
        );
        importRecords = importRecords.filter(
          (record) =>
            record.isLineItem &&
            (requiredColumnForLineItem
              ? !Utility.isEmptyObject(record[requiredColumnForLineItem.key])
              : true)
        );
      }

      /** Validations & index/id provision  */
      importRecords = importRecords.map((row, rowIndex: number) => {
        const newRow = { ...row };
        newRow.recordIndex = rowIndex;
        newRow.invalidFields = validateRecord(
          newRow,
          tableColumns,
          newHeaderOptionSet
        );

        if (newRow.invalidFields.length) {
          invalidRows.push(rowIndex);
        }

        return newRow;
      });

      onSuccess({
        importRecords,
        invalidRows,
        newHeaderOptionSet
      });
    } catch (err) {
      console.log(err);
      onFailure(
        'Something went wrong with importing file records with mapped headers'
      );
    }
  });
}

function stringComparator(str1: string, str2: string) {
  if (!str1 || !str2) {
    return false;
  }

  str1 = str1.trim().toLowerCase();
  str2 = str2.trim().toLowerCase();

  if (str1.length < str2.length) {
    const regex = new RegExp(str1);
    return regex.test(str2);
  } else {
    const regex = new RegExp(str2);
    return regex.test(str1);
  }
}

export interface IMappedHeader {
  rowId: string;
  importedKey: string;
  dkColKey: string | number;
  selectedOption: string;
  type: INPUT_TYPE;
  options: IColumnOption[];
  sampleData: any;
  isValid: boolean;
  isEscaped?: boolean;
  uiHidden: boolean;
  showPicker?: boolean;
  colTableName?: string;
  errorInfo?: string;
  importedKeys?: string[];
  isCustomMapping?: boolean;
  showImportHeaderPicker?: boolean;
}

export function handleUnmappedRequiredColumn(
  existingHeaders: IColumn[],
  headerMappings: IMappedHeader[]
) {
  /* Pushing Column by default
  in case it's data is mandatory &
  is not mapped by user to any file column
  i.e. (Contact Type column for contacts, Stage Id column for Deal, Owner Id for Deal)
  */

  const mappedHeaderId = [];
  headerMappings?.forEach((header) => {
    if (header.dkColKey) mappedHeaderId.push(header.dkColKey);
  });

  existingHeaders?.forEach((systemHeader: IColumn) => {
    const defaultColData =
      defaultRequiredColumnsToImport[
        `${systemHeader.tableName} ${systemHeader.columnCode}`
      ];

    if (
      Utility.isEmptyObject(defaultColData) ||
      mappedHeaderId.indexOf(systemHeader.id) !== -1
    )
      return;

    const existingMapping = headerMappings.find(
      (mapping) => mapping.importedKey === defaultColData.importedKey
    );

    if (!existingMapping) {
      headerMappings.push({
        rowId: `row-${defaultColData.importedKey}`,
        importedKey: defaultColData.importedKey,
        dkColKey: systemHeader.id,
        selectedOption: systemHeader.name,
        type: systemHeader.type,
        options: systemHeader.options,
        sampleData: defaultColData.defaultValue,
        isValid: true,
        uiHidden: true,
        colTableName: systemHeader.tableName
      });
    } else if (existingMapping && !existingMapping.dkColKey) {
      existingMapping.dkColKey = systemHeader.id;
      existingMapping.selectedOption = systemHeader.name;
      existingMapping.type = systemHeader.type;
      existingMapping.options = systemHeader.options;
      existingMapping.colTableName = systemHeader.tableName;
    }
  });
}

function getDefaultDataForColumn(systemHeader: IColumn, sampleData: any) {
  if (!systemHeader) return sampleData;

  const defaultColData =
    defaultRequiredColumnsToImport[
      `${systemHeader.tableName} ${systemHeader.columnCode}`
    ];

  if (
    !Utility.isEmptyObject(defaultColData) &&
    Utility.isEmptyObject(sampleData)
  ) {
    return defaultColData.defaultValue;
  }

  return sampleData;
}

function generateMappings(
  importedData: { mainRowData: any; lineItemRowData: any },
  existingHeaders: IColumn[],
  headerMappings: IMappedHeader[],
  sourceType: any
) {
  const { mainRowData: importedDataRow, lineItemRowData } = importedData;
  const unMappedHeaders: IMappedHeader[] = [];
  const mappedHeaderId = [];

  Object.keys(importedDataRow).forEach((importedKey) => {
    /* To exclude the status/segment columns from the imported columns */
    if (excludedColumnCodes.includes(importedKey.toLowerCase())) return;

    const isUiHiddenMapping =
      sourceType &&
      IMPORT_SOURCE_TYPE[sourceType] !== IMPORT_SOURCE_TYPE.importFromLocal &&
      excludedExternalSourceColumns.some(
        (excludedColName) => importedKey === excludedColName
      );

    let matchFound = false;
    for (let index in existingHeaders) {
      if (isUiHiddenMapping) {
        break;
      }

      const systemHeader = existingHeaders[index];

      matchFound =
        mappedHeaderId.indexOf(systemHeader.id) === -1 &&
        (stringComparator(systemHeader.columnCode, importedKey) ||
          stringComparator(systemHeader.name, importedKey));

      if (matchFound && importedKey && importedKey.trim()) {
        const sampleValue = getDefaultDataForColumn(
          systemHeader,
          systemHeader.isLineItemField && lineItemRowData
            ? lineItemRowData[importedKey]
            : importedDataRow[importedKey]
        );

        headerMappings.push({
          rowId: `row-${importedKey}`,
          importedKey: importedKey,
          dkColKey: systemHeader.id,
          selectedOption: systemHeader.name,
          type: systemHeader.type,
          options: systemHeader.options,
          sampleData: sampleValue,
          isValid: true,
          uiHidden: false,
          colTableName: systemHeader.tableName
        });

        mappedHeaderId.push(systemHeader.id);

        break;
      }
    }

    if (!matchFound) {
      unMappedHeaders.push({
        rowId: `row-${importedKey}`,
        importedKey,
        dkColKey: 0,
        selectedOption: 'Assign Field',
        type: INPUT_TYPE.TEXT,
        options: null,
        sampleData: importedDataRow[importedKey],
        isValid: false,
        uiHidden: isUiHiddenMapping
      });
    }
  });

  /* For keeping all the unmapped headers last in order */
  headerMappings.push(...unMappedHeaders);
}

/* This utility maps data headers obtained from users to the existing headers present in system. */
export function dataMapper(
  importedRows: any,
  existingHeaders: IColumn[],
  sourceType: any
) {
  const headerMappings: IMappedHeader[] = [];
  if (typeof importedRows === 'object' && !Array.isArray(importedRows)) {
    importedRows = [importedRows];
  }

  const [mainRowData, lineItemRowData] = importedRows;
  generateMappings(
    { mainRowData, lineItemRowData },
    existingHeaders,
    headerMappings,
    sourceType
  );

  return headerMappings;
}

/* For Bulk Records Upload API
 - Removes the blank cells
*/
export function generateImportRecordsPayload(records) {
  return records.map((record) => {
    const newRecord = {};
    Object.keys(record).forEach((key) => {
      const value = record[key];
      if (Array.isArray(value)) {
        if (value.length) {
          newRecord[key] = value;
        }
      } else if (value) {
        newRecord[key] = value;
      }
    });

    return { cells: newRecord };
  });
}

function optionsToString(selectedIds, options) {
  if (!selectedIds?.length) return '';

  const res = selectedIds.reduce((cellValue, selectedId) => {
    const selectedOption = options.filter(
      (option) => option.id === selectedId
    )[0];
    return `${cellValue}${selectedOption.name},`;
  }, '');

  return res.slice(0, -1);
}

// function checkAndPopulateOwnerSubownerForLinkedTableRecords(
//   newRow: any,
//   key: string,
//   columnData: any
// ) {
//   /* Populating AccountOwner same as ContactOwner, while importing contacts */
//   if (
//     key ===
//     TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.OWNER_ID)
//   ) {
//     newRow[`${TABLES.ACCOUNT}_${COLUMN_CODE.ACCOUNT.OWNER_ID}`] =
//       newRow[columnData.importedKey];
//   } else if (
//     key ===
//     TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.SUB_OWNER_ID)
//   ) {
//     newRow[`${TABLES.ACCOUNT}_${COLUMN_CODE.ACCOUNT.SUB_OWNER_ID}`] =
//       newRow[columnData.importedKey];
//   }

//   /* Populating ContactOwner same as DealOwner, while importing deals */
//   if (key === TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.OWNER_ID)) {
//     newRow[`${TABLES.CONTACT}_${COLUMN_CODE.CONTACT.OWNER_ID}`] =
//       newRow[columnData.importedKey];
//     newRow[`${TABLES.ACCOUNT}_${COLUMN_CODE.ACCOUNT.OWNER_ID}`] =
//       newRow[columnData.importedKey];
//   } else if (
//     key === TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.SUB_OWNER_ID)
//   ) {
//     newRow[`${TABLES.CONTACT}_${COLUMN_CODE.CONTACT.SUB_OWNER_ID}`] =
//       newRow[columnData.importedKey];
//     newRow[`${TABLES.ACCOUNT}_${COLUMN_CODE.ACCOUNT.SUB_OWNER_ID}`] =
//       newRow[columnData.importedKey];
//   }

//   /* Populating ContactOwner/DealOwner same as AccountOwner, while importing Accounts */
//   if (
//     key ===
//     TableManger.getColumnId(TABLES.ACCOUNT, COLUMN_CODE.ACCOUNT.OWNER_ID)
//   ) {
//     newRow[`${TABLES.CONTACT}_${COLUMN_CODE.CONTACT.OWNER_ID}`] =
//       newRow[columnData.importedKey];
//     newRow[`${TABLES.DEAL}_${COLUMN_CODE.DEAL.OWNER_ID}`] =
//       newRow[columnData.importedKey];
//   } else if (
//     key ===
//     TableManger.getColumnId(TABLES.ACCOUNT, COLUMN_CODE.ACCOUNT.SUB_OWNER_ID)
//   ) {
//     newRow[`${TABLES.CONTACT}_${COLUMN_CODE.CONTACT.SUB_OWNER_ID}`] =
//       newRow[columnData.importedKey];
//     newRow[`${TABLES.DEAL}_${COLUMN_CODE.DEAL.SUB_OWNER_ID}`] =
//       newRow[columnData.importedKey];
//   }
// }

/* Need to convert the deskera column headers to the original File Headers
which user uploaded initially for Import Logs
*/
export function modifyRecordForUpload(
  importRecords: any[],
  { mappedHeaders, systemHeaders }: IHeadersGroup,
  sourceData: { selectedSource: string; tableName: string }
) {
  const importTableName = sourceData.tableName;
  const sourceType = sourceData.selectedSource;
  const keyColumnMap = new Map<string, any>();
  const systemHeaderKeyColumnMap = new Map<string, any>();
  let requiredColumnData: any;

  systemHeaders.forEach((column) => {
    keyColumnMap.set(column.id, column);
    systemHeaderKeyColumnMap.set(column.id, column);

    if (column.required && !column.isLineItemField && !requiredColumnData) {
      requiredColumnData = column;
    }
  });

  mappedHeaders.forEach((header) => {
    keyColumnMap.set(header.dkColKey.toString(), header);
  });

  let parsedRecords = [];
  const requiredUniqueColumnValueSet: { [key: string]: boolean } = {};
  const isNestedImport = MODULES_WITH_NESTED_IMPORT.includes(importTableName);

  importRecords.forEach((record) => {
    const newRow = {};
    const mainRowRecord = {};

    Object.keys(record).forEach((key) => {
      let mappingColumnData = keyColumnMap.get(key);
      const systemColumnData = systemHeaderKeyColumnMap.get(key);
      if (mappingColumnData) {
        if (Utility.isEmptyObject(mappingColumnData?.importedKey)) {
          mappingColumnData.importedKey = mappingColumnData?.isCustomMapping
            ? `custom_mapping_${key}`
            : `${mappingColumnData?.tableName}_${mappingColumnData?.name}_${key}`;
        }

        if (
          mappingColumnData.type === INPUT_TYPE.SELECT ||
          mappingColumnData.type === INPUT_TYPE.MULTI_SELECT
        ) {
          newRow[mappingColumnData.importedKey] = optionsToString(
            record[key],
            mappingColumnData.options
          );
        } else {
          newRow[mappingColumnData.importedKey] = record[key];
        }

        if (isNestedImport && !systemColumnData.isLineItemField) {
          mainRowRecord[mappingColumnData.importedKey] =
            newRow[mappingColumnData.importedKey];
          delete newRow[mappingColumnData.importedKey];
        }
      }
    });

    /** Creating Main row for nested imports, based on primary column  */
    const requiredColumnValue = record[requiredColumnData?.id];
    if (
      !Utility.isEmptyObject(mainRowRecord) &&
      !requiredUniqueColumnValueSet[requiredColumnValue]
    ) {
      requiredUniqueColumnValueSet[requiredColumnValue] = true;
      parsedRecords.push(mainRowRecord);
    }

    parsedRecords.push(newRow);
  });

  return parsedRecords;
}

function columnDataHelperForPayload(
  keyColumnMap: Map<string, any>,
  columnData: IColumn,
  importedKey: string,
  tableName?: string
) {
  const updatedColumnData = Utility.makeCopyOfObject(columnData);
  updatedColumnData.importedKey = importedKey;
  updatedColumnData.selectedOption = updatedColumnData.name;
  updatedColumnData.dkColKey = updatedColumnData.id;
  updatedColumnData.colTableName = tableName ?? updatedColumnData.tableName;
  keyColumnMap.set(updatedColumnData.dkColKey, updatedColumnData);
}

function populateAdditionalColumnsForLinkedTables(
  importTableName: string,
  keyColumnMap: Map<string, any>
) {
  /* Push contact owner id mapping,
  In case of Deal/Account Import, if not already present */
  // if ([TABLES.ACCOUNT, TABLES.DEAL].includes(importTableName)) {
  //   let contactOwnerIdColumn: any = TableManger.getColumn(
  //     TABLES.CONTACT,
  //     COLUMN_CODE.CONTACT.OWNER_ID
  //   );
  //   !keyColumnMap.has(contactOwnerIdColumn) &&
  //     columnDataHelperForPayload(
  //       keyColumnMap,
  //       contactOwnerIdColumn,
  //       `${TABLES.CONTACT}_${contactOwnerIdColumn.columnCode}`,
  //       TABLES.CONTACT
  //     );
  //   let contactSubOwnerIdColumn: any = TableManger.getColumn(
  //     TABLES.CONTACT,
  //     COLUMN_CODE.CONTACT.SUB_OWNER_ID
  //   );
  //   !keyColumnMap.has(contactSubOwnerIdColumn) &&
  //     columnDataHelperForPayload(
  //       keyColumnMap,
  //       contactSubOwnerIdColumn,
  //       `${TABLES.CONTACT}_${contactSubOwnerIdColumn.columnCode}`,
  //       TABLES.CONTACT
  //     );
  // }
  /* Push account owner id mapping,
  In case of Deal/Contact Import, if not already present */
  // if ([TABLES.CONTACT, TABLES.DEAL].includes(importTableName)) {
  //   let accountOwnerIdColumn: any = TableManger.getColumn(
  //     TABLES.ACCOUNT,
  //     COLUMN_CODE.ACCOUNT.OWNER_ID
  //   );
  //   !keyColumnMap.has(accountOwnerIdColumn) &&
  //     columnDataHelperForPayload(
  //       keyColumnMap,
  //       accountOwnerIdColumn,
  //       `${TABLES.ACCOUNT}_${accountOwnerIdColumn.columnCode}`,
  //       TABLES.ACCOUNT
  //     );
  //   let accountSubOwnerIdColumn: any = TableManger.getColumn(
  //     TABLES.ACCOUNT,
  //     COLUMN_CODE.ACCOUNT.SUB_OWNER_ID
  //   );
  //   !keyColumnMap.has(accountSubOwnerIdColumn) &&
  //     columnDataHelperForPayload(
  //       keyColumnMap,
  //       accountSubOwnerIdColumn,
  //       `${TABLES.ACCOUNT}_${accountSubOwnerIdColumn.columnCode}`,
  //       TABLES.ACCOUNT
  //     );
  // }
  /* Push deal owner id & contact id mapping,
  In case of Account Import, if not already present */
  // if (importTableName === TABLES.ACCOUNT) {
  //   let contactAccountIdColumn: any = TableManger.getColumn(
  //     TABLES.CONTACT,
  //     COLUMN_CODE.CONTACT.ACCOUNT
  //   );
  //   !keyColumnMap.has(contactAccountIdColumn) &&
  //     columnDataHelperForPayload(
  //       keyColumnMap,
  //       contactAccountIdColumn,
  //       `${TABLES.CONTACT}_${contactAccountIdColumn.columnCode}`,
  //       TABLES.CONTACT
  //     );
  //   let dealContactIdColumn: any = TableManger.getColumn(
  //     TABLES.DEAL,
  //     COLUMN_CODE.DEAL.CONTACT_ID
  //   );
  //   !keyColumnMap.has(dealContactIdColumn) &&
  //     columnDataHelperForPayload(
  //       keyColumnMap,
  //       dealContactIdColumn,
  //       `${TABLES.DEAL}_${dealContactIdColumn.columnCode}`,
  //       TABLES.DEAL
  //     );
  //   // let dealOwnerIdColumn: any = TableManger.getColumn(
  //   //   TABLES.DEAL,
  //   //   COLUMN_CODE.DEAL.OWNER_ID
  //   // );
  //   // !keyColumnMap.has(dealOwnerIdColumn) &&
  //   //   columnDataHelperForPayload(
  //   //     keyColumnMap,
  //   //     dealOwnerIdColumn,
  //   //     `${TABLES.DEAL}_${dealOwnerIdColumn.columnCode}`,
  //   //     TABLES.DEAL
  //   //   );
  //   // let dealSubOwnerIdColumn: any = TableManger.getColumn(
  //   //   TABLES.DEAL,
  //   //   COLUMN_CODE.DEAL.SUB_OWNER_ID
  //   // );
  //   // !keyColumnMap.has(dealSubOwnerIdColumn) &&
  //   //   columnDataHelperForPayload(
  //   //     keyColumnMap,
  //   //     dealSubOwnerIdColumn,
  //   //     `${TABLES.DEAL}_${dealSubOwnerIdColumn.columnCode}`,
  //   //     TABLES.DEAL
  //   //   );
  // }
}

export interface IHeadersGroup {
  mappedHeaders: IMappedHeader[];
  systemHeaders: IColumn[];
}

export function modifyMappingsForPayload({
  headers,
  tableName,
  selectedSource
}) {
  const { mappedHeaders, systemHeaders } = headers as IHeadersGroup;
  const keyColumnMap = new Map<string, any>();
  const systemHeaderKeyColumnMap = new Map<string, any>();

  systemHeaders.forEach((column) => {
    columnDataHelperForPayload(
      keyColumnMap,
      column,
      `${column.tableName}_${column.name}_${column.id}`
    );

    systemHeaderKeyColumnMap[column.id] = column;
  });

  mappedHeaders.forEach((header) => {
    if (header.dkColKey.toString() !== '0') {
      if (!header.importedKey && header.isCustomMapping) {
        header.importedKey = `custom_mapping_${header.dkColKey}`;
      }
      keyColumnMap.set(header.dkColKey.toString(), header);
    } else if (header.uiHidden && !header.colTableName) {
      // const loweredImportedKey = `${header.importedKey}`.trim().toLowerCase();
      let columnData = null;

      // switch (loweredImportedKey) {
      //   case `source`:
      //     columnData = TableManger.getColumn(
      //       tableName,
      //       COLUMN_CODE.CONTACT.SOURCE
      //     );
      //     break;
      //   case `sourceid`:
      //   case `source id`:
      //     columnData = TableManger.getColumn(
      //       tableName,
      //       COLUMN_CODE.CONTACT.SOURCE_ID
      //     );
      //     break;
      //   default:
      // }

      if (Utility.isEmptyObject(columnData)) return;

      columnDataHelperForPayload(
        keyColumnMap,
        columnData,
        header.importedKey,
        tableName
      );

      /* Need books contact id, similar to sourceId, in case of books contact sync */
      // if (
      //   IMPORT_SOURCE_TYPE[selectedSource] ===
      //     IMPORT_SOURCE_TYPE.importFromDeskeraBooks &&
      //   tableName === TABLES.CONTACT &&
      //   columnData.columnCode === COLUMN_CODE.CONTACT.SOURCE_ID
      // ) {
      //   let booksContactIdColumn: any = TableManger.getColumn(
      //     tableName,
      //     COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID
      //   );
      //   columnDataHelperForPayload(
      //     keyColumnMap,
      //     booksContactIdColumn,
      //     booksContactIdColumn.columnCode,
      //     tableName
      //   );
      // }
    }
  });

  populateAdditionalColumnsForLinkedTables(tableName, keyColumnMap);

  const headerMappingsPayload = [];

  keyColumnMap.forEach((header) => {
    const headerMapping: any = {
      colHeader: header.importedKey,
      displayName: header.selectedOption,
      fieldName: header.dkColKey,
      tableName: header.colTableName
    };

    const systemFieldData = systemHeaderKeyColumnMap[header.dkColKey];
    if (MODULES_WITH_NESTED_IMPORT.includes(tableName) && systemFieldData) {
      headerMapping.lineItem = systemFieldData.isLineItemField;

      if (!Utility.isEmptyObject(header.options))
        headerMapping.possibleValues = header.options.map(
          (option) => option.name
        );

      /**
       * @todo: Need to handle this from BE
       * For Price book import, we need to send custom keys instead of column id in fieldName
       */
      // switch (systemFieldData.columnCode) {
      //   case COLUMN_CODE.BOOKS_PRICE_LIST.NAME:
      //     headerMapping.colHeader = PRICE_BOOK_NAME_HEADER;
      //     headerMapping.displayName = PRICE_BOOK_NAME_HEADER;
      //     headerMapping.fieldName = 'priceList';
      //     break;
      //   case COLUMN_CODE.BOOKS_PRICE_LIST.CURRENCY:
      //     headerMapping.fieldName = 'currencies';
      //     break;
      //   case COLUMN_CODE.BOOKS_PRICE_LIST.EFFECTIVE_DATE:
      //     headerMapping.fieldName = 'effectiveDate';
      //     break;
      //   case COLUMN_CODE.BOOKS_PRICE_LIST.TYPE:
      //     headerMapping.fieldName = 'type';
      //     break;
      // }
    }

    headerMappingsPayload.push(headerMapping);
  });

  return headerMappingsPayload;
}
