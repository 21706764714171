import { Suspense, useEffect, useState } from 'react';
import {
  SignedIn,
  SignedOut,
  useAuth,
  useOrganizationList
} from '@clerk/clerk-react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from './managers/RouteManager';
import { DKSpinner } from 'deskera-ui-library';
import Empty from './components/common/Empty';
import Utility, { isNotEmptyObject, parseJWTToken } from './utility/Utility';
import SideBarService from './services/sidebar';
import EmailVerificationPage from './pages/EmailVerificationPage';
import AdminDashboard from './components/AdminPanel/AdminDashboard';
import TableIdDisplay from './components/TableIdDisplay';
import NewTable from './components/TableCreation/NewTable';
import Home from './components/AdminPanel/Home';
import AppDetail from './components/Application/AppDetail';
import ImportPortal from './components/import/Portal';
import httpClient from './http';
import SignUpAuth from './components/Auth/SingUpAuth';
import SignInAuth from './components/Auth/SignInAuth';
import NavigateContext from './Helper/NavigationContext';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { selectAppInfo, setAppInfo } from './redux/slices/CommonSlice';
import { isEmpty } from './utility/DKUtility';
import {
  fetchUsers,
  selectOrganizationInfo,
  selectUsersList,
  setOrganizationInfo
} from './redux/slices/authSlice';
import Settings from './pages/settings';
import Templates from './components/AdminPanel/Templates';
import Applications from './components/AdminPanel/Applications';
import TemplateDetails from './pages/published_templates/TemplateDetails';
import Main from './pages/Main';
import ApplicationService from './services/applications';
import Onboarding from './components/Auth/Onboarding';

import './App.css';
import TermsAndConditions from './components/AdminPanel/TermsAndConditions';
import Privacy from './components/AdminPanel/Privacy';

export const App = () => {
  const dispatch = useAppDispatch();
  const { isSignedIn, isLoaded } = useAuth();
  const [placeRoutes, setPlaceRoutes] = useState(false);
  const [isOrgCheckCompleted, setIsOrgCheckCompleted] = useState(false);
  const organizationInfo = useAppSelector(selectOrganizationInfo());
  const navigate = useNavigate(); // Hook from react-router-dom
  const appInformation = useAppSelector(selectAppInfo);
  const usersList = useAppSelector(selectUsersList());
  const { setActive } = useOrganizationList();

  useEffect(() => {
    if (window.location.hash.startsWith('#/')) {
      window.location = window.location.hash.replace('#', '');
    }
  });

  useEffect(() => {
    setPublishedAppKeys();
  }, []);

  useEffect(() => {
    if (!!isLoaded) {
      if (!!isSignedIn) {
        getAndSetClerkToken(true);
        setInterval(getAndSetClerkToken, 60 * 60 * 1000);
        SideBarService.setIsSideBarExpanded();
        callGetApis();
      } else {
        setPlaceRoutes(true);
      }
    }
  }, [isSignedIn, isLoaded]);

  // NETWORK CAlls

  const callGetApis = async () => {
    await updateOrgID(appInformation?.appId);
    if (!usersList) {
      callGetUsersList();
    }
  };

  const callGetUsersList = () => {
    dispatch(fetchUsers());
  };

  // LOCAL METHODS

  const checkIfOnboardingIsCompleted = (parsedToken) => {
    if (!parsedToken?.metadata?.onboardingComplete) {
      navigate(PAGE_ROUTES.ONBOARDING);
    }
  };

  const getAndSetClerkToken = async (checkForOnBoarding = false) => {
    const token = await window.Clerk.session.getToken({
      template: 'web-api'
    });
    let parsedToken = parseJWTToken(token);
    if (Utility.isEmptyObject(organizationInfo)) {
      dispatch(setOrganizationInfo(parsedToken ?? {}));
    }
    if (checkForOnBoarding) checkIfOnboardingIsCompleted(parsedToken);
    setPlaceRoutes(true);
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  const updateOrgID = async (appID) => {
    if (isNotEmptyObject(appID)) {
      const token = await window.Clerk.session.getToken({
        template: 'web-api'
      });
      let response = await ApplicationService.getOrgIdForApp(appID, token);
      await setActive({ organization: response?.tenantId });
      setIsOrgCheckCompleted(true);
    } else {
      setIsOrgCheckCompleted(true);
    }
  };

  const setPublishedAppKeys = () => {
    let appID = '';
    let urlParts = window.location.host.split('.');
    let [firstPart = ''] = urlParts;
    if (Utility.isPublishedURL(firstPart)) {
      appID = firstPart;
    }
    dispatch(
      setAppInfo({
        appId: appID,
        isPublishedApp: isNotEmptyObject(appID)
      })
    );
  };

  if (!placeRoutes)
    return (
      <div
        className="column align-items-center justify-content-center"
        style={{ width: '100vw', height: '100vh' }}
      >
        <div className="column shadow-s-2 border-radius-s border-radius-r p-h-r p-v-s bg-white">
          <DKSpinner title="Loading App..." />
        </div>
      </div>
    );

  return (
    <Suspense fallback={<DKSpinner />}>
      {isLoaded && isOrgCheckCompleted && !isEmpty(appInformation) ? (
        <SignedIn>
          <NavigateContext.Provider value={navigate}>
            <Routes>
              {appInformation?.isPublishedApp ? (
                <Route path={'/'} element={<AppDetail />}>
                  <Route
                    path={PAGE_ROUTES.TABLE_DETAILS}
                    element={<TableIdDisplay />}
                  />
                  <Route
                    path={PAGE_ROUTES.IMPORT_PORTAL}
                    element={<ImportPortal />}
                  />
                </Route>
              ) : (
                <Route
                  path="/"
                  element={<Navigate replace to={PAGE_ROUTES.HOME} />}
                />
              )}

              <Route path={PAGE_ROUTES.ONBOARDING} element={<Onboarding />} />
              <Route
                path={PAGE_ROUTES.EMAIL_VERIFICATION}
                element={<EmailVerificationPage />}
              />
              <Route path={PAGE_ROUTES.PUBLIC_PAGE} element={<Empty />} />

              <Route
                path={PAGE_ROUTES.ADMIN_DASHBOARD}
                element={<AdminDashboard />}
              >
                <Route
                  path={PAGE_ROUTES.APPLICATION_LIST}
                  element={<Applications />}
                />
                <Route path={PAGE_ROUTES.HOME} element={<Home />} />
                <Route path={PAGE_ROUTES.SETTINGS} element={<Settings />} />
                <Route path={PAGE_ROUTES.MARKETPLACE} element={<Templates />} />
                <Route
                  path={PAGE_ROUTES.TERMS_AND_CONDITIONS}
                  element={<TermsAndConditions />}
                />
                <Route path={PAGE_ROUTES.PRIVACY} element={<Privacy />} />
              </Route>
              {appInformation?.isPublishedApp ? (
                <>
                  <Route path={PAGE_ROUTES.APP_DETAILS} element={<AppDetail />}>
                    <Route
                      path={PAGE_ROUTES.TABLE_DETAILS}
                      element={<TableIdDisplay />}
                    />
                    <Route
                      path={PAGE_ROUTES.IMPORT_PORTAL}
                      element={<ImportPortal />}
                    />
                  </Route>
                </>
              ) : (
                <Route
                  path={PAGE_ROUTES.APP_DETAILS_ADMIN}
                  element={<AppDetail />}
                >
                  <Route
                    path={PAGE_ROUTES.TABLE_DETAILS_ADMIN}
                    element={<TableIdDisplay />}
                  />
                  <Route
                    path={PAGE_ROUTES.IMPORT_PORTAL_ADMIN}
                    element={<ImportPortal />}
                  />
                  {/* <Route
                    path={PAGE_ROUTES.AUTOMATION}
                    element={<Automation />}
                  /> */}
                </Route>
              )}

              {appInformation?.isPublishedApp ? (
                <Route path={'/create-table'} element={<NewTable />} />
              ) : (
                <Route path={'/create-table/:appId'} element={<NewTable />} />
              )}

              <Route
                path={PAGE_ROUTES.MARKETPLACE_DETAILS}
                element={<TemplateDetails />}
              />
            </Routes>
          </NavigateContext.Provider>
        </SignedIn>
      ) : (
        <></>
      )}
      <SignedOut>
        <Routes>
          <Route path={PAGE_ROUTES.HOME} element={<Main />} />
          <Route path="/sign-up/*" element={<SignUpAuth />} />
          <Route path="/sign-in/*" element={<SignInAuth />} />
          <Route
            path={PAGE_ROUTES.MARKETPLACE_DETAILS}
            element={<TemplateDetails />}
          />

          <Route
            path="*"
            element={<Navigate replace to={PAGE_ROUTES.HOME} />}
          />
        </Routes>
      </SignedOut>
    </Suspense>
  );
};
export default App;
