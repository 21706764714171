export const API_ENDPOINTS = {
  BASE: process.env.REACT_APP_BASE_URL,
  REDIRECT: process.env.REACT_APP_LOGIN_URL,
  FILES: {
    DOWNLOAD: `/v1/attachment/file-download`,
    UPLOAD: `/v1/attachment/file-upload/public`,
    GOOGLE_VIEWER: (filePath: string) =>
      `https://docs.google.com/viewer?url=${filePath}&embedded=true`,
    OFFICE_VIEWER: (filePath: string) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${filePath}`
  },
  FORM: {
    GET_ALL: (appId: string) => `/v1/app-builder/app/${appId}/entry-form`,
    GET: (tableId: string) => `/v1/app-builder/table/${tableId}/entry-form`,
    CREATE: (tableId: string) => `/v1/app-builder/table/${tableId}/entry-form`,
    UPDATE: (tableId: string) => `/v1/app-builder/table/${tableId}/entry-form`
  },
  APP: {
    FETCH: `/v1/app-builder/app`,
    CREATE_AI_APP: `/v1/app-builder/ai/create-app`,
    CREATE: `/v1/app-builder/app`,
    FETCH_BY_ID: (appId: string) => `/v1/app-builder/app/${appId}`,
    UPDATE: (appId: string) => `/v1/app-builder/app/${appId}`,
    DELETE: (appId: string) => `/v1/app-builder/app/${appId}`,
    COPY: (appId: string) => `/v1/app-builder/app/copy/${appId}`,
    DATA_SOURCE: `/v1/app-builder/datasource`,
    DATA_SOURCE_BY_ID: (id: string) =>
      `/v1/app-builder/datasource/${id}/record`,
    INTEGRATED_APPS: `/v1/app-builder/integrated-app`,
    FETCH_TABLE: `/v1/app-builder/table`,
    PUBLISH: (appId: string) => `/v1/app-builder/app/${appId}/publish`,
    GET_EDITABLE_COMPONENTS: (appId: string) =>
      `/v1/app-builder/ai/get-editable-components/${appId}`,
    UPDATE_COMPONENTS: `/v1/app-builder/ai/edit`,
    FETCH_USERS: '/v1/onboarding/user',
    GET_ORG_ID: (appId: string) => `/v1/app-builder/app/${appId}/org-info`
  },
  TABLE: {
    FETCH: (tableId: string) => `/v1/app-builder/table/${tableId}`,
    CREATE: `/v1/app-builder/table`,
    UPDATE: (tableId: string) => `/v1/app-builder/table/${tableId}`,
    DELETE: (tableId: string) => `/v1/app-builder/table/${tableId}`
  },
  RECORDS: {
    FETCH: (tableId: string) => `/v1/app-builder/table/${tableId}/paged-record`,
    FETCH_FOR_EDIT: (tableId: string, recordId: string) =>
      `/v1/app-builder/table/${tableId}/record/${recordId}?fetchAllRef=true`,
    FETCH_REF_RECORDS: (tableId: string) =>
      `/v1/app-builder/table/${tableId}/record`,
    ADD: (tableId: string) => `/v1/app-builder/table/${tableId}/record`,
    UPDATE: (tableId, recordId) =>
      `/v1/app-builder/table/${tableId}/record/${recordId}`,
    DELETE: (recordId, tableId) =>
      `/v1/app-builder/table/${tableId}/record/${recordId}`,
    BULK_UPDATE: (tableId: string) =>
      `/v1/app-builder/table/${tableId}/record-bulk`
  },
  COLUMN: {
    ADD: (tableId: string) => `/v1/app-builder/table/${tableId}/column`,
    ADD_BULK: (tableId: string) =>
      `/v1/app-builder/table/${tableId}/column/bulk`,
    UPDATE: (tableId: string, columnId: string) =>
      `/v1/app-builder/table/${tableId}/column/${columnId}`,
    SHIFT_COLUMN: (tableId) =>
      `/v1/app-builder/table/${tableId}/save-col-order`,
    FREEZE_COLUMN: (tableId) =>
      `/v1/app-builder/table/${tableId}/save-freeze-col`,
    DELETE_COLUMN: (tableID: string, columnId: string) =>
      `/v1/app-builder/table/${tableID}/column/${columnId}`
  },
  IMPORT_EXPORT: {
    UPLOAD_FILE: `/v1/attachment/file-upload`,
    UPLOAD_RECORDS: `/v1/app-builder/table/:tableId/record/import`,
    AUDIT_LOG: `/v1/exim/audit`,
    EXPORT_FILE: (tableId) => `/v1/crm/core/table/${tableId}/export`,
    GET_LINKED_ACCOUNT_EXPORT_DATA: ({ appName, module, username }) =>
      `/v1/crm/app-integration/export?app=${appName}&module=${module}&username=${username}`,
    FILE_DOWNLOAD: (relativePath: string) =>
      `/v1/attachment/file-download?file=${relativePath}`,
    GET_BOOKS_FILE: '/v1/crm/app-integration/export?module=ERP_CONTACT',
    AUTOMATION_LOGS: `/v1/crm/core/workflow/automation-log`,
    AUDIT_LOGS: `/v1/crm/core/audit`
  },
  LINK_APP: {
    GET_CONNECTED_USERS: `v1/crm/app-integration/mapping?limit=10&page=0&sort=createdAt&sortDir=desc`,
    GET_STATE: `v1/state/public/generate`,
    VALIDATE_STATE: `v1/state/public/validate`,
    CONNECT_USER_ACCOUNT: `v1/crm/app-integration/connect`,
    DISCONNECT_LINKED_ACCOUNT: ({ appName, username }) =>
      `v1/crm/app-integration/disconnect?app=${appName}&username=${username}`,
    office365LoginUrl: (username: string, config: any, state: any = `12345`) =>
      `https://login.microsoftonline.com/${
        config.tenantId
      }/oauth2/v2.0/authorize?client_id=${config.appId}&scope=${
        config.scopes
      }&redirect_uri=${encodeURIComponent(
        config.redirectUrl
      )}&state=${state}&response_type=code&prompt=login${
        username ? `&login_hint=${username}` : ''
      }`,
    googleAccountLoginUrl: (config: any) =>
      `https://accounts.google.com/o/oauth2/v2/auth?scope=${config.scopes}&response_type=code&redirect_uri=${config.redirectUrl}&access_type=offline&prompt=consent&client_id=${config.appId}`,
    facebookLoginUrl: (config: any) =>
      `https://www.facebook.com/v12.0/dialog/oauth?client_id=${config.appId}&redirect_uri=${config.redirectUrl}&state=${config.state}&scope=email,pages_show_list,pages_manage_metadata,pages_read_engagement,pages_manage_ads,leads_retrieval&auth_type=rerequest`
  },
  WORKFLOW: {
    GET: `v1/workflow/connection`,
    CREATE: `v1/workflow/connection-internal`
  },
  TEMPLATE: {
    POST_TEMPLATE: `/v1/app-builder/template`,
    GET_TEMPLATE: `/v1/app-builder/template`,
    INSTALL_TEMPLATE: (templateID: string) =>
      `/v1/app-builder/template/${templateID}/install`,
    DELETE_TEMPLATE: (templateID: string) =>
      `/v1/app-builder/template/${templateID}`,
    UPDATE_TEMPLATE: (templateID: string) =>
      `/v1/app-builder/template/${templateID}`,
    GENERATE_LANDING_PAGE: (templateId: string) =>
      `v1/app-builder/template/${templateId}/landing-page`,
    CONFIRM_LANDING_PAGE: (templateID: string) =>
      `v1/app-builder/template/${templateID}/landing-page`,
    UPDATE_PUBLISH_STATUS: (templateID: string, status) =>
      `v1/app-builder/template/${templateID}/status?published=${status}`,
    PUBLIC_TEMPLATE_DETAILS_BY_ID: (templateID: string) =>
      `/v1/app-builder/openep/template/${templateID}`,
    TEMPLATE_DETAILS_BY_ID: (templateID: string) =>
      `/v1/app-builder/template/${templateID}`
  },
  WEB_SOCKET: '/v1/app-builder/openep/ws'
};
