import { DKLabel } from 'deskera-ui-library';
import AutomationManager from '../../managers/AutomationManager';
import {
  AUTOMATION_ACTIONS,
  AUTOMATION_ELEMENT,
  AUTOMATION_TRIGGERS,
  INTERMEDIATE_CHILD_ACTION_TYPES,
  OBJECT_TYPES,
  SUPPORTED_DATE_ALERT_ACTIONS
} from '../../constants/Automation';
import Popup from '../../components/common/Popup';

interface IDefaultActionData {
  title: string;
  elementType: AUTOMATION_ELEMENT;
  type: AUTOMATION_ACTIONS;
  configuration: any;
}

interface IAddActionPopupProps {
  triggerType: AUTOMATION_TRIGGERS;
  parentStepType: AUTOMATION_ACTIONS | AUTOMATION_TRIGGERS;
  showOnlyIntermediateSteps: boolean;
  onClose: () => void;
  onSelect: (actionData: IDefaultActionData) => void;
}

const AddActionPopup = (props: IAddActionPopupProps) => {
  const getSupportedObjectTypesForWorkflow = () => {
    switch (props.triggerType) {
      case AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED:
      case AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED:
        return [OBJECT_TYPES.CONTACT, OBJECT_TYPES.DEAL];
      case AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED:
        return [OBJECT_TYPES.CONTACT, OBJECT_TYPES.DEAL, OBJECT_TYPES.ACTIVITY];
      case AUTOMATION_TRIGGERS.TICKET_CREATED:
      case AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED:
      case AUTOMATION_TRIGGERS.QUOTE_CREATED:
        return [];
      case AUTOMATION_TRIGGERS.DATE_ALERT:
        const dateAlertData = AutomationManager.steps?.find(
          (step) => step.type === AUTOMATION_TRIGGERS.DATE_ALERT
        );
        const objectType = dateAlertData?.configuration?.objectType;
        if (objectType === OBJECT_TYPES.ACTIVITY) {
          return [
            OBJECT_TYPES.CONTACT,
            OBJECT_TYPES.DEAL,
            OBJECT_TYPES.ACTIVITY
          ];
        } else if (objectType === OBJECT_TYPES.DEAL) {
          return [OBJECT_TYPES.CONTACT, OBJECT_TYPES.DEAL];
        } else {
          return [OBJECT_TYPES.CONTACT];
        }
      default:
        return props.parentStepType === AUTOMATION_ACTIONS.CREATE_DEAL
          ? [OBJECT_TYPES.CONTACT, OBJECT_TYPES.DEAL]
          : [OBJECT_TYPES.CONTACT];
    }
  };
  const getActions = () => {
    if (props.showOnlyIntermediateSteps) {
      return AutomationManager.getAllActions().filter((action) =>
        INTERMEDIATE_CHILD_ACTION_TYPES.includes(action.type)
      );
    } else {
      return AutomationManager.getAllActions();
    }
  };

  /* **************** RENDERERS **************** */
  const renderActionButton = (actionData: IDefaultActionData) => {
    return (
      <div
        className="row  cursor-hand unselectable listPickerBG border-radius-m mb-m border-s"
        onClick={() => {
          props.onSelect(actionData);
        }}
      >
        {AutomationManager.getActionIcon(actionData)}
        <DKLabel text={actionData.title} className="ml-m" />
      </div>
    );
  };

  const renderAllActions = () => {
    return (
      <div className="column mt-l">{getActions().map(renderActionButton)}</div>
    );
  };

  return (
    <Popup
      title="Add a new action"
      allowClose={true}
      onClose={props.onClose}
      popupWindowStyles={{
        maxWidth: 260
      }}
    >
      {renderAllActions()}
    </Popup>
  );
};

export default AddActionPopup;
