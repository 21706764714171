import { DKButton, DKIcons, DKLabel } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import './HtmlEditor.css';

function HtmlEditor(props: HtmlEditorProps) {
  const [htmlContent, setHtmlContent] = useState<string>();
  const [editor, setEditor] = useState<any>();
  const editorRef = useRef(null);

  const getComponentLabel = (icon: any, title: string) => {
    return `<div class="editor-btn-content">
                <img class="ic-r ic-s d-block" src="${icon}" style="object-fit: scale-down;">
                <div style="width: 8px;">
            </div><text class="text-black fs-r text-align-left text-wrap-none unselectable">${title}</text><div>`;
  };

  useEffect(() => {
    if (props.htmlContent) {
      setHtmlContent(props.htmlContent);
    }
  }, [props.htmlContent]);

  useEffect(() => {
    // Initialize GrapesJS editor
    const editor = grapesjs.init({
      container: editorRef.current,
      fromElement: true,
      width: '100%',
      height: '77vh',
      storageManager: false,
      jsInHtml: true,
      blockManager: {
        appendTo: '#blocks',
        blocks: [
          {
            id: 'section',
            label: getComponentLabel(DKIcons.ic_checkbox_empty, 'New Section'),
            attributes: {
              class: 'editor-btn'
            },
            content: '<section><h1>New Section</h1></section>'
          },
          {
            id: 'text',
            label: getComponentLabel(DKIcons.data_type.ic_text, 'Text'),
            attributes: {
              class: 'editor-btn'
            },
            content: '<p>Insert your text here</p>'
          },
          {
            id: 'image',
            label: getComponentLabel(DKIcons.doc_type.ic_jpg, 'Image'),
            attributes: {
              class: 'editor-btn'
            },
            content: { type: 'image' }
          }
        ]
      },
      canvas: {
        styles: [
          'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css',
          'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'
        ]
      }
    });

    // Load initial HTML content if available
    editor.setComponents(htmlContent);

    // Listen for changes in the editor and update the state
    editor.on('update', () => {
      setHtmlContent(editor.getHtml());
    });

    setEditor(editor);

    // Clean up on component unmount
    return () => editor.destroy();
  }, [htmlContent, setHtmlContent]);

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onSave = () => {
    if (props.onSave && editor) {
      const html = props.htmlContent;
      const htmlMetaContent = html.substring(0, html.indexOf('<body'));
      const htmlScriptContent = html.substring(
        html.lastIndexOf('<script>'),
        html.lastIndexOf('</script>') + 9
      );

      let updatedHtml = htmlMetaContent + editor.getHtml();
      if (htmlScriptContent) {
        updatedHtml += htmlScriptContent;
      }
      updatedHtml += '</html>';
      props.onSave(updatedHtml);
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{ maxWidth: '90vw', height: '90vh' }}
      >
        <div className="popup-header row parent-width justify-content-between mb-m">
          <div className="row fw-m fs-l">Preview Landing Page</div>
          <div className="row-reverse action-btn-wrapper">
            <DKButton
              className="ml-r bg-button text-white"
              title="Save"
              onClick={onSave}
            />
            <DKButton
              className="border-m bg-white"
              title="Cancel"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="flex parent-width border-m rounded">
          <div className="bg-gray2 p-m">
            <DKLabel className="fs-l fw-m pb-m" text="Components" />
            <div id="blocks" />
          </div>
          <div ref={editorRef} />
        </div>
      </div>
    </div>
  );
}

export interface HtmlEditorProps {
  htmlContent: string;
  onClose: () => void;
  onSave: (htmlContent: string) => void;
}

export default HtmlEditor;
