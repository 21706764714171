import React, { Component } from 'react';
import { DKLabel, DKButton, DKIcons, showAlert } from 'deskera-ui-library';
import { AUTOMATION_TRIGGERS } from '../../constants/Automation';
import AutomationManager from '../../managers/AutomationManager';
import AppIcons from '../../assets/icons/AppIcons';

class AddTriggerPopup extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window pt-s p-h-l"
          style={{ maxWidth: 300, width: 300 }}
        >
          {this.renderHeader()}
          {this.renderAllTriggers()}
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="row justify-content-between pt-s pb-m">
        <DKLabel text="Start a workflow when..." className="fw-m" />
        <DKButton icon={AppIcons.ic_close} onClick={this.props.onClose} />
      </div>
    );
  }

  renderAllTriggers() {
    return (
      <div className="column parent-width gap-3 pb-m">
        {AutomationManager.getAllTriggers().map((trigger) => {
          return this.renderTriggerButton(trigger);
        })}
      </div>
    );
  }

  renderTriggerButton(triggerData) {
    return (
      <div
        className="row p-s cursor-hand border-m border-radius-m listPickerBG  "
        onClick={() => {
          this.props?.onSelect?.(triggerData);
        }}
      >
        {AutomationManager.getTriggerIcon(triggerData.type)}
        <DKLabel text={triggerData.title} className="ml-m" />
      </div>
    );
  }
}

export default AddTriggerPopup;
