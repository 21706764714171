import { useEffect, useState } from 'react';
import SaveButtonComp from './SaveButtonComp';

import { Configuration, OpenAIApi } from 'openai';
import { useParams } from 'react-router-dom';
import httpClient from '../../http';

import {
  DKButton,
  DKIcon,
  DKIcons,
  DKInput,
  DKInputForm,
  DKLabel,
  INPUT_TYPE,
  removeLoader,
  showLoader
} from 'deskera-ui-library';
import { useNavigate } from 'react-router-dom';
import ApiConstants from '../../constants/ApiConstants';
import Popup from '../common/Popup';

import FieldMapPopup from './FieldMapPopup';
import {
  PAGE_ROUTES,
  ROUTE_APP_DETAIL_ID_IDENTIFIER,
  ROUTE_TABLE_DETAIL_ID_IDENTIFIER
} from '../../managers/RouteManager';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import Utility from '../../utility/Utility';
import { selectAppInfo } from '../../redux/slices/CommonSlice';
import { useAppSelector } from '../../redux/hooks';

export const formatKeyPath = (keyPath) => {
  let newValue = keyPath
    .split('_') // Split by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join words with spaces

  return newValue;
};

const NewTable = () => {
  const navigate = useNavigate();
  const appInformation = useAppSelector(selectAppInfo);
  const [dataSources, setDataSources] = useState([]);
  const [tableName, setTableName] = useState('');
  const [dataSourceId, setDataSourceId] = useState('');
  const [dataRows, setDataRows] = useState([]);
  const [dataSource, setDataSource] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fromScratchPopup, setFromScratchPopup] = useState(false);
  const [integratedDSPopup, setIntegratedDSPopup] = useState(false);
  const [fieldMapPopup, setFieldMapPopup] = useState(false);
  const [queryProp, setQueryProp] = useState('');
  const [integratedApps, setIntegratedApps] = useState([]);
  const [fieldPath, setFieldPath] = useState('');
  const { appId } = useParams();

  const fetchIntegratestDSList = async () => {
    httpClient
      .get(API_ENDPOINTS.APP.DATA_SOURCE, { params: { q: queryProp } })
      .then((response) => {
        setDataSources(response.data);
      })
      .catch((error) => console.error('Error fetching data sources:', error));
  };
  const fetchIntegratedApps = async () => {
    try {
      const response = await httpClient.get(API_ENDPOINTS.APP.INTEGRATED_APPS);
      const { data, totalCount } = response;
      setIntegratedApps(data);
    } catch (error) {
      console.error('Error fetching integrated apps:', error);
    }
  };

  useEffect(() => {
    fetchIntegratedApps();
    fetchIntegratestDSList();
  }, [queryProp]);

  useEffect(() => {
    if (isLoading) showLoader('Wait we are setting everything for you!');
    else removeLoader();
  }, [isLoading]);

  const fetchData = async (ds) => {
    const id = ds._id;
    setIsLoading(true);

    try {
      const response = await httpClient.get(
        API_ENDPOINTS.APP.DATA_SOURCE_BY_ID(id)
      );
      const data = response;
      let fieldPath = ds.metadata.fieldPath;
      console.log('fieldPath', fieldPath);
      const parsedData = parseDataByPath(data, fieldPath);
      setFieldPath(fieldPath);
      const transformedData = await transformDataWithOpenAI(parsedData);

      if (transformedData) {
        const rows = Object.keys(transformedData).map((key) => ({
          path: key,
          value: transformedData[key],
          deducedType: deduceType(transformedData[key]),
          name: formatKeyPath(key)
        }));
        setDataRows(rows); // Adjust this according to how you process the data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleOnScratch
  const onTableCreated = (data) => {
    let applicationId = appInformation?.isPublishedApp
      ? appInformation?.appId
      : appId;
    if (data?.id) {
      if (appInformation.isPublishedApp) {
        navigate(`/${data?.id}`);
      } else {
        navigate(
          PAGE_ROUTES.APP_ADMIN.replace(
            ROUTE_APP_DETAIL_ID_IDENTIFIER,
            applicationId
          ).replace(ROUTE_TABLE_DETAIL_ID_IDENTIFIER, data?.id)
        );
      }
    }
  };

  const jsonIntegratedApps =
    integratedApps.map((app) => ({
      ds_parent_name: app.ds_parent_name,
      img_url: app.img_url
      // Add other properties based on your data structure
    })) || [];

  const deduceType = (value) => {
    if (Array.isArray(value)) return 'text-array';
    if (typeof value === 'number') return 'number';
    return 'text'; // Default to text for simplicity
  };
  const transformDataWithOpenAI = async (parsedData) => {
    setIsLoading(true); // Start loading
    const prompt = `
              User will give you a json which will have details about path of a certain data and value of that data.

              Your Job is to form a new JSON of following format:
              {
                <path>: <its value>
              }

              Remember these rules very strictly:
              1) Your response should be a json obj and have no explanation, no extra text just a json object
              2) It should have no extra text and no explanation, only a json object.

              User gave this json:

              ${JSON.stringify(parsedData)}
              `;
    const key = ApiConstants.OPENAI_API_KEY;
    if (!key) {
      console.error('No OpenAI key found');
      setIsLoading(false); // Stop loading if there's an error
      return null;
    }

    const openai = new OpenAIApi(new Configuration({ apiKey: key }));
    try {
      const completion = await openai.createChatCompletion({
        model: 'gpt-4-0125-preview',
        messages: [{ role: 'system', content: prompt }],
        max_tokens: 1000,
        temperature: 0
      });

      const response = completion.data.choices[0].message?.content?.trim();
      const jsonResponse = JSON.parse(response);
      setIsLoading(false); // Stop loading after receiving response
      return jsonResponse;
    } catch (error) {
      console.error('Error parsing JSON from OpenAI response', error);
      setIsLoading(false); // Stop loading if there's an error
      return null;
    }
  };
  const handleTypeChange = (e, index) => {
    const newDataRows = [...dataRows];
    newDataRows[index].deducedType = e.target.value;
    setDataRows(newDataRows);
  };

  const handleNameChange = (e, index) => {
    const newDataRows = [...dataRows];
    newDataRows[index].name = e.target.value;
    setDataRows(newDataRows);
  };
  const parseDataByPath = (data, path) => {
    try {
      const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.'); // Handles both dot notation and brackets
      let currentPart = data;
      for (let part of parts) {
        if (currentPart[part] === undefined) {
          console.log(`Path not found: ${path} at ${part}`);
          return undefined; // Or any default value you see fit
        }
        currentPart = currentPart[part];
      }
      return currentPart;
    } catch (error) {
      console.error(`Error parsing data by path: ${path}`, error);
      return undefined; // Or any default value you see fit
    }
  };
  const onIntegratedDSButtonClicked = () => {};

  const CardIntegratedDS = ({ title, imageUrl, qr_string }) => (
    <button
      onClick={() => {
        setIntegratedDSPopup(true);
        setQueryProp(title);
      }}
      className="card w-36 h-36 flex flex-col bg-slate-200 rounded-lg justify-center gap-2 items-center"
    >
      <img className="w-14 h-14 mx-auto" src={imageUrl} alt={title} />

      <p className=" fw-l text-center">{title}</p>
    </button>
  );

  // Your component that renders the cards
  const CardIntegratedDSList = ({ cards }) => (
    <div className="card-container flex gap-8">
      {cards.map((card, index) => (
        <CardIntegratedDS key={index} {...card} />
      ))}
    </div>
  );
  const cardsIntegratedDSArray = jsonIntegratedApps.map((item) => ({
    title: item.ds_parent_name,
    imageUrl: item.img_url,
    qr_string: item.qr_param
    // Add more properties as needed
  }));

  const handleImport = () => {
    if (appInformation.isPublishedApp) {
      navigate(PAGE_ROUTES.IMPORT_PORTAL);
    } else {
      let applicationId = appInformation?.isPublishedApp
        ? appInformation?.appId
        : appId;
      navigate(
        PAGE_ROUTES.IMPORT_PORTAL_ADMIN.replace(
          ROUTE_APP_DETAIL_ID_IDENTIFIER,
          applicationId
        )
      );
    }
  };
  return (
    <div className="main-holder column align-items-center justify-content-center">
      <div className="flex mt-10">
        {' '}
        <div className="fs-xxxl fw-b  mx-auto ">
          How would you like to Create your Table?
        </div>
      </div>
      <div className="flex">
        <DKLabel
          text="On my own"
          className=" fs-xl  mt-6   mx-auto fw-l"
          style={{}}
        />
      </div>

      <div className="flex justify-center gap-8 mt-6">
        <button
          className="w-36 h-36  bg-cyan-100 shadow-md hover:shadow-xl duration-100 transition rounded-xl"
          onClick={() => {
            setFromScratchPopup(true);
          }}
        >
          <DKIcon src={DKIcons.ic_plus} className=" mx-auto" />
          <p className="mx-auto mt-3">From Scratch</p>
        </button>
        <button
          className="  w-36 h-36 bg-cyan-100 shadow-md rounded-xl"
          onClick={handleImport}
        >
          <DKIcon src={DKIcons.ic_books} className=" mx-auto" />
          <p className="mx-auto mt-3">Import with Data</p>
        </button>
      </div>
      {fromScratchPopup && (
        <Popup
          popupWindowStyles={{
            pointerEvents: 'auto'
          }}
          onClose={() => {
            setFromScratchPopup(false);
          }}
          className="p-h-m p-v-s"
        >
          <DKInputForm
            className="border-radius-s bg-white  w-full"
            direction="vertical"
            fields={[
              {
                key: 'name', // Change the key to 'name'
                placeholder: 'Enter table name',
                required: true,
                title: 'Table name',
                type: 'text',
                //   validator: () => {},
                value: ''
              }
            ]}
            onCancel={() => {
              setFromScratchPopup(false);
            }}
            onChange={(fieldValues) => {}}
            onSubmit={async (data) => {
              let applicationId = appInformation?.isPublishedApp
                ? appInformation?.appId
                : appId;
              const apiData = {
                databaseName: 'test',
                name: data[0].value,
                appId: applicationId
              };

              try {
                const table = await httpClient.post(
                  API_ENDPOINTS.TABLE.CREATE,
                  apiData
                );
                if (appInformation.isPublishedApp) {
                  let urlString = PAGE_ROUTES.TABLE_DETAILS.replace(
                    ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
                    table.id
                  );
                  navigate(`/${urlString}`);
                } else {
                  navigate(
                    PAGE_ROUTES.APP_ADMIN.replace(
                      ROUTE_APP_DETAIL_ID_IDENTIFIER,
                      applicationId
                    ).replace(ROUTE_TABLE_DETAIL_ID_IDENTIFIER, table.id)
                  );
                }
              } catch (error) {
                console.error('Error while creating the application', error);
              }

              setFromScratchPopup(false);
            }}
            style={{}}
            title="New Table"
          />
        </Popup>
      )}
      {/* <div className="flex">
        <DKLabel
          text="Using an Integrated Datasource"
          className=" fs-xl  mt-6   mx-auto fw-l"
          style={{}}
        />
      </div> */}

      {/* <div className="flex justify-center mt-6 ">
        <CardIntegratedDSList cards={cardsIntegratedDSArray} />
      </div> */}

      {integratedDSPopup && (
        <Popup
          popupWindowStyles={{
            pointerEvents: 'auto'
          }}
          className="p-h-m p-v-s"
          onClose={() => {
            setIntegratedDSPopup(false);
          }}
        >
          <div
            style={{
              height: 'auto'
            }}
          >
            <div className="box-border">
              <div className="row justify-content-between gap-1 mb-10">
                <DKLabel className="row fw-m" text="Add Table" />
                <div className="row justify-content-end">
                  <DKButton
                    className=""
                    onClick={() => {
                      setIntegratedDSPopup(false);
                    }}
                    title="Cancel"
                  />

                  <SaveButtonComp
                    tableName={tableName}
                    dataSourceId={dataSourceId}
                    onTableCreated={onTableCreated}
                    dataRows={dataRows}
                    setIsLoading={setIsLoading}
                    appId={
                      appInformation?.isPublishedApp
                        ? appInformation?.appId
                        : appId
                    }
                    setIntegratedDSPopup={setIntegratedDSPopup}
                  />
                </div>
              </div>
            </div>
            <div>
              <DKInput
                title="Table Name"
                type="text"
                className="mt-2"
                direction="HORIZONTAL"
                errorMessage="Enter valid field value"
                value={tableName}
                onChange={(data) => {
                  setTableName(data);
                }}
                onClick={() => {}}
              />
              <DKInput
                className="mt-4 flex "
                value={dataSource}
                formatter={(obj) => {
                  return obj.name;
                }}
                title="Select Datasource:"
                type={INPUT_TYPE.DROPDOWN}
                required={false}
                onChange={(value) => {
                  // setDataSource(selectedDataSource);
                }}
                dropdownConfig={{
                  // className: "p-2",
                  style: {},
                  allowSearch: true,
                  searchableKey: 'name',
                  data: dataSources,
                  renderer: (index, obj) => {
                    return <DKLabel text={`${obj.name}<br>`} />;
                  },
                  onSelect: (index, value) => {
                    const selectedId = value._id; // Assuming _id is the unique identifier in your data source
                    const selectedDataSource = dataSources.find(
                      (ds) => ds._id === selectedId
                    );
                    setDataSourceId(selectedId);
                    setDataSource(selectedDataSource);
                    fetchData(selectedDataSource);
                  }
                }}
              />
            </div>

            <button
              onClick={() => setFieldMapPopup(true)}
              className="underline mt-12  fw-m text-violet-800 "
            >
              Change field Mapping
            </button>
          </div>
        </Popup>
      )}
      {fieldMapPopup && (
        <FieldMapPopup
          setIntegratedDSPopup={setIntegratedDSPopup}
          setFieldMapPopup={setFieldMapPopup}
          dataRows={dataRows}
          handleNameChange={handleNameChange}
        />
      )}
    </div>
  );
};

export default NewTable;
