import { FunctionComponent } from 'react';
import {
  AUTOMATION_ACTIONS,
  DIRECT_CHILD_ACTION_TYPES,
  INTERMEDIATE_CHILD_ACTION_TYPES,
  SPLIT_TYPE_ACTIONS,
  STEP_CARD_WIDTH
} from '../../constants/Automation';
import Utility from '../../utility/Utility';
import Step from './Step';
import AutomationManager from '../../managers/AutomationManager';

interface ICanvasProps {
  tables: any[];
  jsonData: any;
  magnifyScale: number;
  steps: any[];
  adjacencyMatrix: any;
  metaData?: {
    stepIndexToUpdate: any;
    stepIndexToAdd: any;
    parentStepId: any;
    parentStepType: any;
    stepData: any;
    currentSelectedStepData: any;
    splitStepIndex: any;
    showOnlyIntermediateSteps: any;
  };
  onMetaDataChange: (metaData) => void;
  onDeleteStep: (data) => void;
}

const AutomationCanvas: FunctionComponent<ICanvasProps> = ({
  jsonData,
  magnifyScale,
  steps,
  onDeleteStep,
  tables,
  onMetaDataChange,
  adjacencyMatrix
}) => {
  const { outerRadiusFromLeft = 0, outerRadiusFromRight = 0 } = jsonData || {};

  const getLinkedChildNode = (childStepData) => {
    /* Intermediate actions for ex: (A wait action) can further have a Directly linked child */
    let nextStep = childStepData;
    while (
      nextStep &&
      INTERMEDIATE_CHILD_ACTION_TYPES.includes(nextStep.type)
    ) {
      const adjacentKeys = adjacencyMatrix[nextStep.id];
      nextStep = steps.find((step) => adjacentKeys?.includes(step.id));
      if (DIRECT_CHILD_ACTION_TYPES.includes(nextStep?.type)) {
        childStepData = nextStep;
      }
    }

    return DIRECT_CHILD_ACTION_TYPES.includes(childStepData?.type)
      ? childStepData
      : null;
  };

  const getStepWithChild = (
    stepIndex,
    parentId = null,
    parentType = null,
    splitLevel = 1
  ) => {
    const stepData = steps[stepIndex];

    if (!stepData) return null;

    const adjacentKeys = adjacencyMatrix[stepData.id];
    const childStepIndices =
      adjacentKeys?.map((childId) =>
        steps.findIndex((step) => step.id === childId)
      ) || [];

    let splitDimensions = null,
      leftChild = null,
      rightChild = null,
      directlyLinkedNodeData = null,
      canShowIntermediateSteps = false;

    if (SPLIT_TYPE_ACTIONS.includes(stepData.type as AUTOMATION_ACTIONS)) {
      splitDimensions = AutomationManager.getSplitStepDimensions(
        stepData.id,
        splitLevel
      );

      leftChild = getStepWithChild(
        childStepIndices[0],
        stepData.id,
        stepData.type,
        splitLevel + 1
      );

      rightChild = getStepWithChild(
        childStepIndices[1],
        stepData.id,
        stepData.type,
        splitLevel + 1
      );

      canShowIntermediateSteps = false;
    } else {
      leftChild = getStepWithChild(
        childStepIndices[0],
        stepData.id,
        stepData.type,
        splitLevel
      );

      const leftChildData = steps[childStepIndices[0]];

      directlyLinkedNodeData = getLinkedChildNode(leftChildData);

      canShowIntermediateSteps =
        directlyLinkedNodeData?.type === AUTOMATION_ACTIONS.SPLIT_WORKFLOW;
    }
    let selectedTable = null;
    if (stepData?.configuration?.tableId) {
      selectedTable = tables?.find(
        (table) => table._id === stepData?.configuration?.tableId
      );
    }
    return (
      <Step
        table={selectedTable}
        stepData={stepData as any}
        leftChild={leftChild}
        canShowAddActionOption={(splitIndex) => {
          const childStepData = steps[childStepIndices[splitIndex ?? 0]];
          const isDirectlyLinked = !Utility.isEmptyObject(
            getLinkedChildNode(childStepData)
          );
          return !isDirectlyLinked || canShowIntermediateSteps;
        }}
        rightChild={rightChild}
        splitDimensions={splitDimensions}
        onEdit={() => {
          onMetaDataChange({
            stepIndexToUpdate: stepIndex,
            stepIndexToAdd: null,
            parentStepId: parentId,
            parentStepType: parentType,
            currentSelectedStepData: stepData
          });
        }}
        onAddButtonClick={(childIndex) => {
          onMetaDataChange({
            parentStepId: stepData.id,
            parentStepType: stepData.type,
            stepIndexToAdd: stepIndex,
            splitStepIndex: childIndex,
            stepIndexToUpdate: null,
            showOnlyIntermediateSteps: canShowIntermediateSteps
          });
        }}
        onDelete={() => {
          onDeleteStep({
            stepIndex,
            parentId,
            stepData,
            directlyLinkedNodeData
          });
        }}
      />
    );
  };

  return (
    <>
      <div
        id="automation-step-canvas"
        className={`column parent-size align-items-${
          outerRadiusFromLeft > 2 ? 'start' : 'center'
        } show-scroll-bar`}
        style={{
          overflow: 'auto',
          paddingBottom: 100,
          paddingTop: 20
        }}
      >
        <div
          className="column flex-1 align-items-center"
          id={`auto-step-list-container`}
          style={{
            paddingLeft: outerRadiusFromLeft * STEP_CARD_WIDTH,
            paddingRight: outerRadiusFromRight * STEP_CARD_WIDTH,
            transform: `scale(${magnifyScale})`,
            transformOrigin: 'top',
            minWidth: '100%'
          }}
        >
          {getStepWithChild(0)}
        </div>
      </div>
    </>
  );
};

export default AutomationCanvas;
