import React from 'react';
import { Outlet } from 'react-router-dom';
import AppManager from '../../managers/AppManager';
import TopMenu from '../menu/TopMenu';

import LeftBar from '../menu/LeftBar';

const AdminDashboard = ({}) => {
  return (
    <div
      className="main-holder  row align-items-start app-font app-bg-color"
      style={{ overflowY: 'hidden' }}
    >
      {!AppManager.isPublicURL() && <LeftBar />}

      <div className="parent-width parent-height column flex-1">
        {!AppManager.isPublicURL() && !AppManager.isBookADemoURL() && (
          <TopMenu isHomePage />
        )}

        <div className="parent-width">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
