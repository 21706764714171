import { DKLabel, DKButton, DKInput } from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../constants/Enum';
import { useState } from 'react';

export default function InstallAppPopup(props: any) {
  const [appObj, setAppObj] = useState(props.app);

  const getHeader = () => {
    return (
      <div className="row justify-content-betwee bg-gray1 p-v-s">
        <div className="row p-2 fw-500">
          <DKLabel className="row" text="Install App" />
        </div>
        <div className="row-reverse gap-2 p-h-m">
          <DKButton
            className="bg-app text-white"
            title="Install"
            onClick={() => {
              props?.onSave(appObj);
            }}
          />
          <DKButton
            className="bg-white text-black border"
            title="Cancel"
            onClick={() => {
              props?.onCancel();
            }}
          />
        </div>
        <div></div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width p-4">
        <DKInput
          title="Name"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={appObj?.name}
          onChange={(text: string) => {
            setAppObj({ ...appObj, name: text });
          }}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div className="popup-window" style={{ padding: 0, paddingBottom: 20 }}>
        {getHeader()}
        {getBody()}
      </div>
    </div>
  );
}
