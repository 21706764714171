import { SignIn } from '@clerk/clerk-react';

export default function SignInAuth(props: any) {
  return (
    <div
      className="column align-items-center justify-content-center"
      style={{ width: '100vw', height: '100vh' }}
    >
      <SignIn signUpUrl="/sign-up" path="/sign-in" />
    </div>
  );
}
