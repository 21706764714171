import { API_ENDPOINTS } from '../constants/Endpoints';
import httpClient from '../http';
export default class ApplicationService {
  static makeAppPublic(payload: any) {
    let url = API_ENDPOINTS.TEMPLATE.POST_TEMPLATE;
    return httpClient.post(url, payload);
  }
  static generateLandingPage(templateId: string) {
    let url = API_ENDPOINTS.TEMPLATE.GENERATE_LANDING_PAGE(templateId);
    return httpClient.post(url);
  }
  static confirmLandingPage(landingPageData: any, templateId: string) {
    let url = API_ENDPOINTS.TEMPLATE.CONFIRM_LANDING_PAGE(templateId);
    return httpClient.put(url, landingPageData);
  }

  static updatePublishedStatus(templateId: string, status) {
    let url = API_ENDPOINTS.TEMPLATE.UPDATE_PUBLISH_STATUS(templateId, status);
    return httpClient.put(url);
  }

  static getPublicTemplateDetails(templateId: string) {
    let url = API_ENDPOINTS.TEMPLATE.PUBLIC_TEMPLATE_DETAILS_BY_ID(templateId);
    return httpClient.get(url, { params: { skipInterceptor: true } });
  }

  static getTemplateDetails(templateId: string) {
    let url = API_ENDPOINTS.TEMPLATE.TEMPLATE_DETAILS_BY_ID(templateId);
    return httpClient.get(url, { params: { skipInterceptor: true } });
  }

  static getOrgIdForApp(appId: string, token: string) {
    let url = API_ENDPOINTS.APP.GET_ORG_ID(appId);
    return httpClient.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }
}
