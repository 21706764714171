import { Component } from 'react';
import {
  IMappedHeader,
  handleUnmappedRequiredColumn,
  mapFileDataToSystem
} from '../../../utility/DataMapHelper';
import { validateRecord } from '../../../utility/RecordValidator';
import {
  DKLabel,
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKCheckMark,
  INPUT_TYPE,
  showAlert,
  showToast,
  TOAST_TYPE,
  getDateAsString,
  DKSpinner,
  DKFilterPopup,
  DKLine,
  DKSearchBar
} from 'deskera-ui-library';
import {
  convertCRMDateFormatToUILibraryFormat,
  DATE_FORMATS,
  DateUtil
} from '../../../utility/Date';
import {
  checkFilterConditionsForRecord,
  checkSearchQueryInRecord
} from '../../../utility/FilterUtil';
import SideBarService from '../../../services/sidebar';
import Utility, { getRandomNumber } from '../../../utility/Utility';
import debounce from '../../../utility/Debounce';
import { FILTER_LOGICAL_OPERATORS } from '../../../constants/Enum';
import { IColumn, ITableFilterCondition } from '../../../model/Table';
import {
  IMPORT_DEFAULT_DATE_FORMAT,
  MODULES_WITH_NESTED_IMPORT
} from '../../../constants/EximConstants';

interface IConfirmProps {
  tableName: string;
  systemHeaders: IColumn[] | null;
  mappedHeaders: IMappedHeader[];
  fileContent: any;
  importRecords: any;
  invalidRows: any;
  importProperties: any[];
  segmentColumnData: IColumn;
  selectedSegmentId: string[];
  selectedPipelineId: any;
  getTableColumns(tableName: string): [IColumn];
  setData(data: any): void;
  confirmationHandler(e: any): void;
  backStepHandler(e: any): void;
}

interface IConfirmState {
  importRecords: any[];
  systemHeaders: IColumn[];
  invalidRows: number[];
  showInvalidOnly: boolean;
  currentEditData: { columnKey: string; value: any } | null;
  expandPropsPanel: boolean;
  currentPage: number;
  windowWidth: number;
  isLoading: boolean;
  showRecordFilter: boolean;
  loadingFilter: boolean;
  useFilterRows: boolean;
  filters: ITableFilterCondition[];
  logicalOperator: FILTER_LOGICAL_OPERATORS;
  filteredRows: number[];
  searchQuery: string;
}

class Confirm extends Component<IConfirmProps, IConfirmState> {
  maxRecordsToDisplay = 25;
  dateFormatToDisplay =
    DateUtil.getOrgDateFormat() || DATE_FORMATS['DD-MM-YYYY'];

  constructor(props: IConfirmProps) {
    super(props);
    console.log('Confirm', props);

    this.state = {
      importRecords: props.importRecords || [],
      systemHeaders:
        props.systemHeaders || props.getTableColumns(props.tableName),
      invalidRows: props.invalidRows,
      showInvalidOnly: false,
      expandPropsPanel: false,
      currentPage: 1,
      currentEditData: null,
      windowWidth: SideBarService.getContainerWidth(),
      isLoading: true,
      showRecordFilter: false,
      loadingFilter: false,
      useFilterRows: false,
      filters: [],
      logicalOperator: FILTER_LOGICAL_OPERATORS.AND,
      filteredRows: [],
      searchQuery: ''
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState((prevState) => ({
      importRecords: nextProp.importRecords || [],
      systemHeaders:
        nextProp.systemHeaders ||
        this.props.getTableColumns(this.props.tableName),
      invalidRows: nextProp.invalidRows
    }));
  }

  componentDidMount() {
    const { mappedHeaders, fileContent, tableName } = this.props;

    if (!this.state.importRecords.length && mappedHeaders && fileContent) {
      handleUnmappedRequiredColumn(this.state.systemHeaders, mappedHeaders);
      setTimeout(() => {
        mapFileDataToSystem(
          {
            importTableName: tableName,
            importedHeaderMappings: mappedHeaders,
            tableColumns: this.state.systemHeaders
          },
          fileContent[0]
        )
          .then(
            (data: {
              importRecords: any[];
              invalidRows: any[];
              newHeaderOptionSet: Map<string, any[]>;
            }) => {
              const { importRecords, invalidRows, newHeaderOptionSet } = data;
              const newSystemHeaders =
                this.getUpdatedHeaderOptions(newHeaderOptionSet);
              this.props.setData({
                importRecords,
                invalidRows,
                systemHeaders: newSystemHeaders
              });
            }
          )
          .catch((err) => {
            console.log(err);

            showToast(err, TOAST_TYPE.FAILURE);
          })
          .finally(() => {
            this.setState({
              isLoading: false,
              expandPropsPanel: true
            });
          });
      }, 100);
    } else {
      this.setState({
        isLoading: false,
        expandPropsPanel: true
      });
    }

    window.addEventListener('onSideBarChange', this.windowSizeUpdated);
  }

  componentWillUnmount() {
    window.removeEventListener('onSideBarChange', this.windowSizeUpdated);
  }

  windowSizeUpdated = () => {
    this.setState({ windowWidth: SideBarService.getContainerWidth() });
  };

  getUpdatedHeaderOptions = (newHeaderOptionSet: Map<string, any[]>) => {
    return this.state.systemHeaders.map((header) => {
      const newHeader = { ...header };
      if (newHeaderOptionSet.has(newHeader.id)) {
        newHeader.options = newHeaderOptionSet.get(newHeader.id);
      }

      if (newHeader.options?.length) {
        newHeader.options = newHeader.options.map((option) => ({
          ...option,
          color:
            option.color || `data-grid-badge-color-${getRandomNumber(8) + 1}`
        }));
      }
      return newHeader;
    });
  };

  handlePropertiesUpdates = (
    event: any,
    propIndex: number,
    selectedOption: number
  ) => {
    const updatedProperties = [...this.props.importProperties];
    const updatedProperty = { ...updatedProperties[propIndex] };
    updatedProperty.options = updatedProperty.options.map(
      (option: any, optionIndex: number) => {
        if (selectedOption === optionIndex) {
          option.checked = true;
          updatedProperty.selectedOption = option.value;
        } else {
          option.checked = false;
        }
        return option;
      }
    );

    updatedProperties[propIndex] = updatedProperty;

    this.props.setData({
      importProperties: updatedProperties
    });
  };

  togglePropertiesPanel = () => {
    this.setState((prevState) => ({
      expandPropsPanel: !prevState.expandPropsPanel
    }));
  };

  getImportPropertiesPanel() {
    const { expandPropsPanel } = this.state;
    const hasEditableProperty = this.props.importProperties.some(
      (importProperty) =>
        importProperty.applicableFor.includes(this.props.tableName)
    );
    return hasEditableProperty ? (
      <div className="column bg-white mt-xl parent-width shadow-s border-radius-s position-relative">
        <div
          className={`column parent-width p-r cursor-hand ${
            expandPropsPanel && 'border-m-b'
          }`}
          onClick={this.togglePropertiesPanel}
        >
          <DKLabel
            text="Import Properties"
            className="fs-l fw-m parent-width justify-content-between"
          />
          <DKLabel
            text="Customize how you would like to import the new records in the system"
            className="text-gray fs-m mt-s"
          />
          <DKIcon
            src={
              expandPropsPanel ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2
            }
            className="position-absolute"
            style={{ right: 40, top: 25, height: 20 }}
          />
        </div>
        {expandPropsPanel &&
          this.props.importProperties.map((importProperty, propertyIndex) =>
            importProperty.applicableFor.includes(this.props.tableName) ? (
              <div className="column bg-white p-h-xl parent-width">
                <DKLabel
                  text={importProperty.title}
                  className="fs-m fw-m pt-r pb-s"
                />
                <div className="row justify-content-start border-m-b pb-m">
                  {importProperty.options.map((option, optionIndex) => (
                    <label
                      htmlFor={`import-${propertyIndex}-option-${optionIndex}`}
                      className="p-s row align-items-center width-auto cursor-hand"
                    >
                      <input
                        type="radio"
                        className="mb-xs"
                        id={`import-${propertyIndex}-option-${optionIndex}`}
                        checked={option.checked}
                        onChange={(e) =>
                          this.handlePropertiesUpdates(
                            e,
                            propertyIndex,
                            optionIndex
                          )
                        }
                      />
                      <DKLabel
                        className="fs-m pl-xs pr-l"
                        text={option.displayValue}
                      />
                    </label>
                  ))}
                </div>
              </div>
            ) : null
          )}
      </div>
    ) : null;
  }

  toggleAddCategoryPopup = (categoryType: string) => {};

  getProceedConfirmation = () => {
    const { invalidRows, importRecords } = this.state;

    let importMessage = '';

    if (invalidRows.length) {
      importMessage += `${invalidRows.length} out of ${importRecords.length} records contains invalid fields. <br>`;
    }

    importMessage += `Do you want to proceed? <br>`;

    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m'
      },
      {
        title: 'Proceed',
        className:
          (invalidRows.length ? 'bg-red' : 'bg-button') + ' text-white ml-r',
        onClick: (e) => this.props.confirmationHandler(e)
      }
    ];
    showAlert('Import Records', importMessage, buttons);
  };

  onProceedToNextStep = (e: any) => {
    const { importRecords } = this.state;

    /* for setting latest invalid rows */
    const invalidRows = importRecords
      .filter(
        (record) => record.invalidFields && record.invalidFields.length > 0
      )
      .map((record) => record.recordIndex);

    this.setState(
      {
        invalidRows
      },
      () => this.getProceedConfirmation()
    );
  };

  handleRecordValidations = (
    editedData: {
      rowData: any;
      rowIndex: any;
      columnKey: any;
    },
    invalidRows: any[],
    newOptionSet?: Map<string, any[]>
  ) => {
    /* Handle Validations */
    const invalidFields = validateRecord(
      editedData.rowData,
      this.state.systemHeaders,
      newOptionSet
    );
    editedData.rowData.invalidFields = invalidFields;

    let actualRecordIndex = editedData.rowData.recordIndex;
    let updatedInvalidRows = [...invalidRows];

    if (invalidFields.length && !invalidRows.includes(actualRecordIndex)) {
      updatedInvalidRows.push(actualRecordIndex);
    } else if (
      !invalidFields.length &&
      invalidRows.includes(actualRecordIndex)
    ) {
      updatedInvalidRows = updatedInvalidRows.filter(
        (invalidRowIndex) => invalidRowIndex !== actualRecordIndex
      );
    }

    return updatedInvalidRows;
  };

  onRowUpdate = (editedData: {
    rowData: any;
    rowIndex: any;
    columnKey: any;
    columnData: any;
  }) => {
    let updatedData = editedData.rowData[editedData.columnKey];
    if (
      editedData.columnData?.type === INPUT_TYPE.DATE &&
      !Utility.isEmptyObject(updatedData)
    ) {
      /* saving Dates in required format */
      updatedData = getDateAsString(updatedData, IMPORT_DEFAULT_DATE_FORMAT);
      editedData.rowData[editedData.columnKey] = updatedData;
    }

    /* check if more rows available and store the current edit */
    if (this.state.importRecords?.length > 1 && editedData.columnKey) {
      this.setState({
        currentEditData: {
          columnKey: editedData.columnKey,
          value: updatedData
        }
      });
    }

    /** applying price book data changes (i.e. effective date/type/currency) to all line item rows with matching price book name... */
    const columnData = editedData.columnData;
    // if (
    //   columnData &&
    //   columnData.tableName === TABLES.BOOKS_PRICE_LIST &&
    //   columnData.columnCode !== COLUMN_CODE.BOOKS_PRICE_LIST.NAME &&
    //   !columnData.isLineItemField
    // ) {
    //   const priceBookNameId = TableManger.getColumnId(
    //     TABLES.BOOKS_PRICE_LIST,
    //     COLUMN_CODE.BOOKS_PRICE_LIST.NAME
    //   );
    //   const priceBookNameToUpdateLineItems =
    //     editedData.rowData[priceBookNameId];

    //   priceBookNameToUpdateLineItems &&
    //     this.state.importRecords
    //       ?.filter(
    //         (row) => row[priceBookNameId] === priceBookNameToUpdateLineItems
    //       )
    //       .forEach((row) => {
    //         row[editedData.columnKey] = updatedData;

    //         const invalidFields = validateRecord(row, this.state.systemHeaders);
    //         row.invalidFields = invalidFields;

    //         return row;
    //       });
    // }

    const invalidFields = validateRecord(
      editedData.rowData,
      this.state.systemHeaders
    );
    editedData.rowData.invalidFields = invalidFields;

    this.setState({});
  };

  applyEditToAllRows = (e: any) => {
    const {
      currentEditData,
      importRecords,
      invalidRows,
      showInvalidOnly,
      useFilterRows,
      filteredRows
    } = this.state;

    const newImportRecords = importRecords?.map((row, index) => {
      if (useFilterRows && !filteredRows.includes(row.recordIndex)) return row;
      if (showInvalidOnly && !invalidRows.includes(row.recordIndex)) return row;

      row[currentEditData.columnKey] = currentEditData.value;

      const invalidFields = validateRecord(row, this.state.systemHeaders);
      row.invalidFields = invalidFields;

      return row;
    });

    this.setState({
      currentEditData: null
    });

    this.props.setData({
      importRecords: newImportRecords
    });
  };

  // updateStageColumnOptions = (pipelineData: {
  //   id: string;
  //   name: string;
  //   stages: any[];
  // }) => {
  //   if (!pipelineData?.stages) return;

  //   const { importRecords, invalidRows } = this.state;

  //   let newStageOptions = pipelineData.stages;
  //   let newInvalidRows = [...invalidRows];

  //   const stageColumnId = TableManger.getColumnId(
  //     TABLES.DEAL,
  //     COLUMN_CODE.DEAL.STAGE_ID
  //   );
  //   const defaultStageName =
  //     defaultRequiredColumnsToImport[
  //       `${TABLES.DEAL} ${COLUMN_CODE.DEAL.STAGE_ID}`
  //     ].defaultValue;

  //   const existingHeader = this.props.mappedHeaders.find(
  //     (header) => header.dkColKey === stageColumnId
  //   );

  //   if (!existingHeader) return;

  //   const existingOptions = existingHeader.options;

  //   const newHeaderOptionSet = new Map();

  //   const newImportRecords = importRecords.map((row, index) => {
  //     const currentValue =
  //       existingOptions.find((option: any) =>
  //         row[stageColumnId] ? option.id === row[stageColumnId][0] : false
  //       )?.name || defaultStageName;
  //     const { mappedOptions, updatedOptions } = mapStringToDropdown(
  //       currentValue,
  //       newStageOptions,
  //       INPUT_TYPE.SELECT
  //     );
  //     newStageOptions = updatedOptions;
  //     row[stageColumnId] = mappedOptions;
  //     newHeaderOptionSet.set(stageColumnId, newStageOptions);

  //     newInvalidRows = this.handleRecordValidations(
  //       {
  //         rowData: row,
  //         rowIndex: index,
  //         columnKey: stageColumnId
  //       },
  //       newInvalidRows,
  //       newHeaderOptionSet
  //     );

  //     return row;
  //   });

  //   existingHeader.options = newStageOptions;
  //   const newSystemHeaders = this.getUpdatedHeaderOptions(newHeaderOptionSet);

  //   this.props.setData({
  //     invalidRows: newInvalidRows,
  //     importRecords: newImportRecords,
  //     systemHeaders: newSystemHeaders
  //   });
  // };

  onColumnUpdate = ({ columnIndex, columnData }) => {
    if (
      columnData.type === INPUT_TYPE.SELECT ||
      columnData.type === INPUT_TYPE.MULTI_SELECT
    ) {
      const existingMappedHeader = this.props.mappedHeaders.find(
        (header: any) => header.dkColKey === columnData.id
      );

      if (Utility.isEmptyObject(existingMappedHeader)) return;

      existingMappedHeader.options = columnData.options;
    }
  };

  getRecordsToDisplay(recordStartIndex: number) {
    const {
      importRecords,
      invalidRows,
      showInvalidOnly,
      useFilterRows,
      filteredRows
    } = this.state;

    let displayRecords = [],
      totalRecordCount = 0;

    let filteredRecords = showInvalidOnly
      ? invalidRows.map((rowId) => importRecords[rowId])
      : importRecords;

    if (useFilterRows) {
      filteredRecords = filteredRecords.filter((record) =>
        filteredRows.includes(record.recordIndex)
      );
    }

    displayRecords = filteredRecords.slice(
      recordStartIndex,
      recordStartIndex + this.maxRecordsToDisplay
    );

    totalRecordCount = filteredRecords.length;

    return { displayRecords, totalRecordCount };
  }

  setFilteredRecordsToDisplay() {
    const {
      importRecords,
      filters,
      showInvalidOnly,
      logicalOperator,
      searchQuery
    } = this.state;

    let filteredRecords = [];
    const updatedInvalidRecords = showInvalidOnly
      ? importRecords.filter(
          (record) => !Utility.isEmptyObject(record.invalidFields)
        )
      : [];

    const isFilterApplied = filters.length > 0;
    const isSearchApplied = Boolean(searchQuery?.trim());

    if (isFilterApplied || isSearchApplied) {
      filteredRecords = showInvalidOnly ? updatedInvalidRecords : importRecords;

      filteredRecords = (filteredRecords || []).filter((record) => {
        const matchesFilters = isFilterApplied
          ? checkFilterConditionsForRecord(filters, record, {
              logicalOperator
            })
          : true;

        /* if filters not matching, then skipping search query check */
        if (!matchesFilters) return false;

        const matchesSearch = isSearchApplied
          ? checkSearchQueryInRecord(searchQuery, record)
          : true;
        return matchesFilters && matchesSearch;
      });
    }

    this.setState({
      useFilterRows: isFilterApplied || isSearchApplied,
      filteredRows: filteredRecords.map((record) => record.recordIndex),
      invalidRows: updatedInvalidRecords.map((record) => record.recordIndex),
      currentPage: 1,
      loadingFilter: false
    });
  }

  onSearchImportRecords = debounce((searchText: string) => {
    this.setState(
      { searchQuery: searchText, loadingFilter: true },
      this.setFilteredRecordsToDisplay
    );
  }, 400);

  getPaginationData() {
    const { currentPage, showInvalidOnly, loadingFilter } = this.state;

    const recordStartIndex = (currentPage - 1) * this.maxRecordsToDisplay;
    const { displayRecords, totalRecordCount } =
      this.getRecordsToDisplay(recordStartIndex);

    const currStartRowIndex = recordStartIndex + 1;
    const currEndRowIndex = recordStartIndex + displayRecords.length;
    const paginationHeader = loadingFilter
      ? 'processing...'
      : totalRecordCount
      ? `Showing ${currStartRowIndex}-${currEndRowIndex} of ${totalRecordCount} ${
          showInvalidOnly ? 'invalid' : 'imported'
        } records`
      : `No ${showInvalidOnly ? 'invalid' : 'imported'} records present`;

    const totalPageCount = Math.ceil(
      totalRecordCount / this.maxRecordsToDisplay
    );

    return {
      currentPage,
      displayRecords,
      totalPageCount,
      paginationHeader
    };
  }

  toggleInvalidRecords = () => {
    this.setState(
      (prev) => ({
        showInvalidOnly: !prev.showInvalidOnly,
        currentPage: 1,
        loadingFilter: true
      }),
      () => this.setFilteredRecordsToDisplay()
    );
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  isQuickFilterAvailable = (columnData) => {
    return [
      INPUT_TYPE.TEXT,
      INPUT_TYPE.EMAIL,
      INPUT_TYPE.PHONE,
      INPUT_TYPE.NUMBER,
      INPUT_TYPE.DATE,
      INPUT_TYPE.SELECT,
      INPUT_TYPE.MULTI_SELECT,
      INPUT_TYPE.DROPDOWN
    ].includes(columnData?.type);
  };

  /* **************** MAIN RENDERERS ************* */
  getRecordsPanelHeader(paginationHeader: string) {
    return (
      <div className="column parent-width">
        <div className="row m-v-r">
          <DKIcon
            src={DKIcons.ic_arrow_left2}
            className="ic-s cursor-hand"
            onClick={this.props.backStepHandler}
          />
          <DKLabel className="fs-l fw-m m-v-s ml-s" text={'Import Records'} />
          <DKButton
            title={`Confirm & Proceed`}
            className="bg-button text-white ml-auto"
            onClick={this.onProceedToNextStep}
          />
        </div>
        <div
          className="row m-v-r"
          style={{
            gap: 16
          }}
        ></div>
        <div className="row mt-r" style={{ gap: 16 }}>
          <DKLabel
            className="text-gray fs-m fw-r m-v-s"
            text={paginationHeader}
          />
          <div className="position-relative ml-auto">
            <DKIcon
              src={DKIcons.ic_filter}
              className="ic-s cursor-hand"
              onClick={() => this.setState({ showRecordFilter: true })}
            />
            {this.state.filters && this.state.filters.length > 0 ? (
              <div
                className="position-absolute bg-red"
                style={{
                  width: 5,
                  height: 5,
                  top: -2,
                  right: -2,
                  borderRadius: 3
                }}
              ></div>
            ) : null}
            {this.state.showRecordFilter ? (
              <div
                className="position-absolute parent-width z-index-3"
                style={{
                  minWidth: 550,
                  top: -50,
                  left: 0,
                  maxWidth: '90vw'
                }}
              >
                <DKFilterPopup
                  headerTitle="Filters"
                  filterData={[...this.state.filters]}
                  filterOperator={this.state.logicalOperator}
                  headers={[...this.state.systemHeaders]}
                  onSubmit={(data, logicalOperator) => {
                    this.setState(
                      {
                        filters: data,
                        logicalOperator: logicalOperator,
                        showRecordFilter: false,
                        loadingFilter: true
                      },
                      () => this.setFilteredRecordsToDisplay()
                    );
                  }}
                  onCancel={() => this.setState({ showRecordFilter: false })}
                />
              </div>
            ) : null}
          </div>
          {this.state.filters && this.state.filters.length > 0 ? (
            <DKButton
              title={'Clear filters'}
              className="text-underline text-red fw-m"
              style={{ padding: 0 }}
              onClick={() => {
                this.setState(
                  {
                    filters: [],
                    loadingFilter: true
                  },
                  () => this.setFilteredRecordsToDisplay()
                );
              }}
            />
          ) : null}
          <DKLine
            style={{ height: 30, width: 0 }}
            className="flex-shrink-0 border-s"
          />
          <DKCheckMark
            className="cursor-hand flex-shrink-0"
            title="Show invalid records only"
            isSelected={this.state.showInvalidOnly}
            onClick={this.toggleInvalidRecords}
          />
          <DKLine
            style={{ height: 30, width: 0 }}
            className="flex-shrink-0 border-s"
          />
          <DKSearchBar
            className="import-search-bar"
            searchText={this.state.searchQuery}
            onSearch={this.onSearchImportRecords}
          />
          <DKButton
            className="bg-button text-white"
            title="Apply current edit to all rows"
            onClick={this.applyEditToAllRows}
            disabled={!this.state.currentEditData}
          />
        </div>
      </div>
    );
  }

  getGenericRendererWithQuickFilter = (data, columnData) => {
    let value = data?.value;

    if (data && columnData.formatter) {
      value = columnData.formatter({
        value: data.value,
        rowIndex: data.rowIndex,
        rowData: data.rowData
      });
    }

    if (typeof value === 'string' && value.trim() === '') {
      value = '';
    }

    try {
      if (columnData.type === INPUT_TYPE.DATE) {
        value = DateUtil.getDateStrFromDate(
          new Date(value),
          this.dateFormatToDisplay
        );
      }
    } catch (err) {
      value = '';
    }

    return (
      <div
        className="row parent-width align-items-start hover-actions-container"
        style={{ minHeight: '100%' }}
      >
        {value ? (
          <DKLabel
            text={value}
            className="fs-m parent-width text-trailing "
            style={{
              textAlign:
                columnData.textAlign ||
                (columnData.type === INPUT_TYPE.NUMBER ? 'right' : 'left')
            }}
          />
        ) : null}
        <DKIcon
          className="ic-s cursor-hand action-wrapper ml-auto"
          src={DKIcons.ic_filter}
          onClick={(e) => {
            let { filters } = this.state;
            let filterValue = value;

            if (columnData.type === INPUT_TYPE.DATE) {
              try {
                filterValue = DateUtil.getDateFromStr(
                  value,
                  this.dateFormatToDisplay
                );
                filterValue = Utility.isValidDate(filterValue)
                  ? filterValue
                  : '';
              } catch (err) {
                filterValue = '';
              }
            }

            const newFilter: ITableFilterCondition = {
              key: columnData.key,
              condition: 'eq',
              value: filterValue
            };

            filters = filters || [];
            const existingCondition = filters.find(
              (filter) =>
                filter.key === columnData.key &&
                filter.value?.toString() === filterValue?.toString()
            );
            if (!existingCondition) {
              filters.push(newFilter);
            } else {
              /* Do nothing if currently selected quick filter already applied */
              return;
            }

            e.stopPropagation?.();

            this.setState(
              {
                filters,
                showRecordFilter: false
              },
              this.setFilteredRecordsToDisplay
            );
          }}
        />
      </div>
    );
  };

  getRecordsPanel() {
    const { currentPage, displayRecords, totalPageCount, paginationHeader } =
      this.getPaginationData();

    const isNestedImport = MODULES_WITH_NESTED_IMPORT.includes(
      this.props.tableName
    );

    let columns = this.state.systemHeaders.map((column) => {
      column = { ...column };
      column.renderer = this.isQuickFilterAvailable(column)
        ? (data) => this.getGenericRendererWithQuickFilter(data, column)
        : null;
      // if ([INPUT_TYPE.SELECT, INPUT_TYPE.MULTI_SELECT].includes(column.type)) {
      //   column.type = INPUT_TYPE.DROPDOWN;
      //   column.dropdownConfig = {
      //     data: column.options,
      //     allowSearch: true,
      //     searchableKey: "name"
      //   };
      // }

      return column;
    });

    if (isNestedImport) {
      columns = columns.filter((column) => column.uiVisible);
    }
    console.log(columns);

    return (
      <div className="import-records-panel position-relative bg-white shadow-s mt-xl p-v-s border-radius-s p-h-r">
        {this.getRecordsPanelHeader(paginationHeader)}
        <DKDataGrid
          width={this.state.windowWidth - 30}
          showHeader={false}
          updating={this.state.loadingFilter}
          columns={columns}
          rows={displayRecords}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowColumnSort={false}
          allowBulkOperation={false}
          onRowUpdate={this.onRowUpdate}
          onColumnUpdate={this.onColumnUpdate}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          onPageChange={this.onPageChange}
          dateFormat={convertCRMDateFormatToUILibraryFormat(
            this.dateFormatToDisplay
          )}
        />
      </div>
    );
  }

  getLoadingSection() {
    return (
      <div className="import-records-panel position-relative bg-white shadow-s mt-xl p-v-s border-radius-s p-h-r parent-width border-box">
        <DKSpinner title="Processing file data for preview. Please wait..." />
      </div>
    );
  }

  render() {
    return (
      <div className="column" style={{ width: this.state.windowWidth }}>
        {this.getImportPropertiesPanel()}
        {/* {this.getCategoriesSection()} */}
        {/* {this.getLoadingSection()} */}
        {this.state.isLoading
          ? this.getLoadingSection()
          : this.getRecordsPanel()}
      </div>
    );
  }
}

export default Confirm;
