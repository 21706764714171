import React from 'react';

import { DKIcon, DKLabel, DKButton } from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';

import { replaceStringInBrackets } from '../../utility/Utility';

const PromptPreview = ({ data, onClose }) => {
  return (
    <div className="transparent-background">
      <div className="popup-window">
        <div className="row justify-content-between">
          <div className="row width-auto">
            <DKIcon src={AppIcons.ic_app} className="ic-s-2 mr-r opacity-7" />
            <DKLabel text={data.title} />
          </div>
          <DKButton
            icon={AppIcons.ic_close}
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <DKLabel text="Prompt" className="fw-m mt-l" />
        <div className="column parent-width bg-gray1 p-l mt-r border-radius-m mb-l">
          <DKLabel text={replaceStringInBrackets(data.prompt)} className="'" />
        </div>
      </div>
    </div>
  );
};

export default PromptPreview;
