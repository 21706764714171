// @ts-ignore
import { INPUT_TYPE } from 'deskera-ui-library';
import {
  CHAT_BUBBLE_POSITION,
  INDIA_MART_LEAD_DATA_KEYS,
  JUST_DIAL_LEAD_DATA_KEYS
} from './Enum';

export const MAIN_HOLDER_ID = 'root';
export const MAX_SECTION_WIDTH = 1200;
export const CHART_HEIGHT = 220;
export const CHART_WIDTH = 418;
export const APP_NAME = 'CRM3';
export const URL_V1 = 'v1/';
export const CUSTOM_DOMAIN_WEBSITE = 'CRM3_CUSTOM_DOMAIN';
export const CUSTOM_DOMAIN_TIME = '5 minutes';
export const HIDE_CHAT_BUBBLE_CLASS = 'hide-chat-bubble';
export const KEY_LAST_OPENED_PATH = 'LAST_OPENED_PATH';
export const KEY_SESSION_CHUNK_LOAD_FAILED = 'KEY_SESSION_CHUNK_LOAD_FAILED';

export const MAX_RECORD_SIZE_FOR_BANNER = 10;
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 20;

export const NOTIFICATION_REFRESH_TIME = 10000;

export const DEFAULT_TRIAL_PLAN_DAYS = 15;

export const DETAIL_PAGE_LEFT_PANEL_WIDTH = 360;
export const DETAIL_PAGE_COMPOSER_CONTAINER_CLASS =
  'detail-page-email-composer';

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const DAY_INTERVAlS = [
  'Current',
  'A1-30 days',
  '31-60 days',
  '61-90 days',
  'Over 90 days'
];

export const CHAR_CODES = {
  ENTER: 13,
  BACKSPACE: 8,
  DELETE: 46,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  TOP_ARROW: 38,
  DOWN_ARROW: 40
};

/* For sanitizing inputs */
export const CHAR_ENTITY_MAP = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;'
};

export const VIEWPORT_SIZES = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  LARGE: 'lg'
};

export const BREAKPOINTS = {
  SMALL: 560,
  LARGE: 1000
};

export const CHART_INTERVALS = [
  {
    name: 'Last Week',
    interval: 'weekly'
  },
  {
    name: 'Last Month',
    interval: 'monthly'
  },
  {
    name: 'Last Year',
    interval: 'yearly'
  }
];

export const ACTIVITY_FILTER = [
  {
    name: 'Overdue',
    interval: 'overdue'
  },
  {
    name: 'Upcoming',
    interval: 'upcoming'
  },
  {
    name: 'Today',
    interval: 'today'
  },
  {
    name: 'Completed',
    interval: 'completed'
  }
];
export const TICKET_FILTER = [
  {
    name: 'Open',
    interval: 'open'
  },
  {
    name: 'Pending',
    interval: 'pending'
  },
  {
    name: 'Solved',
    interval: 'solved'
  }
];

export enum WS_EVENT_TYPE {
  PING = '_ping_',
  PONG = '_pong_',
  NEW_CHAT_THREAD = 'new-chat-thread',
  NEW_CHAT_MESSAGE = 'new-chat-message',
  CHAT_THREAD_CLOSED = 'chat-thread-closed',
  CHAT_MESSAGE_UPDATE = 'chat-message-updated',
  NEW_CONNECTION = 'new-connection',
  BELL_NOTIFICATION = 'bell-notification',
  SEQUENCE_COLUMN_CREATED = 'sequence-column-created'
}

export enum WEBSOCKET_READY_STATE {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

export const GUEST_USER_COOKIE = 'x-guest-chat-user';
export const MOBILE_DOWNLOAD_POPUP_CLOSED = 'mobile-download-popup-closed';
export const MOBILE_DOWNLOAD_BANNER_CLOSED = 'mobile-download-banner-closed';

export const decodeBase64Uri: (decodedData: string) => string = (
  encodedData: string
) => {
  return decodeURIComponent(
    Buffer.from(encodedData, 'base64').toString('ascii')
  );
};

export const encodeBase64Uri: (encodedData: string) => string = (
  decodedData: string
) => {
  return Buffer.from(encodeURIComponent(decodedData), 'ascii').toString(
    'base64'
  );
};
export const CONTACT_SOURCE = {
  WEB: 'Web',
  WEB_BULK: 'Contact Import',
  WEB_FORMS: 'Web Form',
  OFFICE_365: 'Microsoft 365 Import',
  ERP: 'Books Import'
};
export const MOBILE_APP_ACTIONS = {
  HOME: 'HOME',
  SCANNER: 'SCANNER',
  BACK: 'BACK'
};

export const FULL_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const CALENDAR_VIEWS = ['Day', 'Week', 'Month'];
export const YEARS = [
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030'
];

export const SMTP_HELP_LINKS = {
  GMAIL:
    'https://support.google.com/mail/answer/7126229?authuser=4&visit_id=637811109035473969-476327719&hl=en&rd=1#zippy=%2Cstep-change-smtp-other-settings-in-your-email-client',
  OUTLOOK:
    'https://support.microsoft.com/en-us/office/pop-imap-and-smtp-settings-for-outlook-com-d088b986-291d-42b8-9564-9c414e2aa040',
  YAHOO:
    'https://in.help.yahoo.com/kb/SLN4724.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAGLpxTbNqVoMI3lYE12umJrOlUI12VmRGHfEwv8BIMlc9l0coGOQwHCI85aPYS1u9vBeTkosjbb0XQOYrJRoP2xBfyyTNC0HDnFOQMBkronkzgO6mCqlt8kBYE_fNhPHbPd-oulXORYHemicr9sV7SbFbfh1qgf8MRugE9kZnMfC'
};
export const TICKET_DEFAULT_SORT_COLUMN = 'updated_at';
export const DESKERA_CARE_EMAIL = 'care@deskera.com';
export const TAX_ACCOUNT_CODE = 'ACC40010';
export const TAX_TYPE = ['PURCHASE', 'SALES', 'BOTH'];

export const PLACEHOLDER_IDENTIFIER = `custom_email_placeholder`;

export const ACTIVITY_DATE_FORMAT = 'EEE, MMM dd yyyy, HH:mm aa';
export const DEFAULT_ACCOUNTS_FOR_BOOKS_CONTACT = {
  RECEIVABLE_ACCOUNT: 'AC-0000003',
  PAYABLE_ACCOUNT: 'AC-0000013',
  PAYMENT_TERM: 4
};
export const DEFAULT_CURRENCY_CODE = 'USD';
export const DEFAULT_GST_TREATMENT = 'UNREGISTERED_BUSINESS';
export const DEFAULT_GST_TREATMENT_PEOPLE = 'CUSTOMER';
export const MODULES = {
  CONTACT: 'CONTACT',
  DEAL: 'DEAL',
  PIPELINE: 'PIPELINE',
  STAGE: 'STAGE',
  ACTIVITY: 'ACTIVITY',
  CAMPAIGN: 'CAMPAIGN',
  FORM: 'FORM',
  LANDING_PAGE: 'LANDING PAGE',
  /* FUNNEL:"FUNNEL", */
  AUTOMATION: 'AUTOMATION',
  CHAT_MESSAGE: 'CHAT',
  TICKET: 'TICKET',
  SUPPORT_EMAIL: 'SUPPORT EMAIL'
};
export const MODULE_EVENT = {
  ACTIVITY: {
    REC_CREATE: 'Activity created',
    REC_UPDATE: 'Activity updated',
    REC_DELETE: 'Activity deleted',
    REC_BULK_UPDATE: 'Bulk activity update',
    REC_BULK_DELETE: 'Bulk activity deleted',
    TABLE_PERMISSION_CHANGED: 'Table permission changed'
  },
  STAGE: {
    REC_CREATE: 'Stage created',
    REC_UPDATE: 'Stage updated',
    REC_DELETE: 'Stage deleted',
    REC_BULK_CREATE: 'On creating default stages'
  },
  PIPELINE: {
    REC_CREATE: 'Pipeline created',
    REC_UPDATE: 'Pipeline updated',
    REC_DELETE: 'Pipeline deleted'
  },
  CONTACT: {
    REC_CREATE: 'Contact added',
    REC_UPDATE: 'Contact updated',
    REC_DELETE: 'Contact deleted',
    REC_BULK_UPDATE: 'Bulk contacts updated',
    REC_BULK_DELETE: 'Bulk contacts deleted',
    REC_IMPORT: 'Contacts imported',
    TABLE_PERMISSION_CHANGED: 'Table permission changed'
  },
  DEAL: {
    REC_CREATE: 'Deal added',
    REC_UPDATE: 'Deal updated',
    REC_DELETE: 'Deal deleted',
    NOTE_CREATE: 'Note added',
    REC_BULK_UPDATE: 'Bulk deals updated',
    REC_BULK_DELETE: 'Bulk deals deleted',
    REC_IMPORT: 'Deals imported',
    TABLE_PERMISSION_CHANGED: 'Table permission changed'
  },
  CHAT_MESSAGE: {
    NEW_CHAT_MESSAGE: 'Chat message received'
  },
  TICKET: {
    REC_CREATE: 'Ticket added',
    REC_UPDATE: 'Ticket updated'
  },
  SUPPORT_EMAIL: {
    REC_CREATE: 'Replied on ticket'
  }
};

export const EXPORT_FILE_TYPE = ['PDF', 'XLS', 'CSV'];

export enum EXPORTED_FILES_TYPE_EXTENSION {
  CSV = 'CSV',
  XLS = 'XLS',
  XLSX = 'XLSX',
  PDF = 'PDF'
}

export const UNSUBSCRIBE_COLUMN = [
  {
    name: 'name',
    displayName: 'Name',
    type: 'text',
    editable: false
  },
  {
    name: 'email',
    displayName: 'Email',
    type: 'text',
    editable: false
  },
  {
    name: 'campaign',
    displayName: 'Campaign',
    type: 'text',
    editable: false
  },
  {
    name: 'publishedOn',
    displayName: 'Published On',
    type: 'date',
    editable: false
  },
  {
    name: 'unsubscribedAt',
    displayName: 'Unsubscribed At',
    type: 'date',
    editable: false
  }
];
export const ACTIVITY_REPORT_NAME = `Overdue Activities`;

/* ***************** CAMPAIGN CONSTANTS *************** */
export const ALL_CONTACT_SEGMENT = {
  id: -1,
  name: 'All',
  color: 'data-grid-badge-color-1'
};
export const CAMPAIGN_COLUMNS = [
  {
    name: 'Name',
    id: 'name',
    width: 280,
    key: 'name',
    type: 'text',
    columnCode: 'name',
    path: 'name'
  },
  {
    name: 'Email',
    id: 'email',
    width: 250,
    key: 'email',
    type: 'email',
    columnCode: 'email',
    path: 'email'
  },
  {
    name: 'Link',
    id: 'link',
    width: 250,
    key: 'link',
    type: 'select',
    columnCode: 'link',
    path: 'link'
  },
  {
    name: 'Ip Address',
    id: 'ipAddress',
    width: 300,
    key: 'ipAddress',
    type: 'text',
    columnCode: 'ipAddress',
    path: 'tracking_details.ip'
  },
  {
    name: 'State',
    id: 'region',
    width: 200,
    key: 'region',
    type: 'text',
    columnCode: 'region',
    path: 'tracking_details.location.region'
  },
  {
    name: 'Country',
    id: 'country',
    width: 200,
    key: 'country',
    type: 'text',
    columnCode: 'country',
    path: 'tracking_details.location.country'
  },
  {
    name: 'Device',
    id: 'device',
    width: 200,
    key: 'device',
    type: 'text',
    columnCode: 'device',
    path: 'tracking_details.deviceInfo.device.type'
  },
  {
    name: 'Browser',
    id: 'browser',
    width: 200,
    key: 'browser',
    type: 'text',
    columnCode: 'browser',
    path: 'tracking_details.deviceInfo.client.name'
  },
  {
    name: 'Operating System',
    id: 'os',
    width: 200,
    key: 'os',
    type: 'text',
    columnCode: 'os',
    path: 'tracking_details.deviceInfo.os.name'
  },
  {
    name: 'Unsubscribed',
    width: 150,
    key: 'isUnsubscribed',
    columnCode: 'isUnsubscribed',
    path: 'isUnsubscribed'
  },
  {
    name: 'Read Count',
    id: 'viewCount',
    width: 150,
    key: 'viewCount',
    type: 'number',
    columnCode: 'viewCount',
    path: 'viewCount'
  },
  {
    name: 'Link Click Count',
    id: 'count',
    width: 150,
    key: 'count',
    type: 'number',
    columnCode: 'count',
    path: 'count'
  }
];
export const TRACKING_PATHS = {
  IP_ADDRESS: 'tracking_details.ip',
  COUNTRY: 'tracking_details.location.country',
  DEVICE: 'tracking_details.deviceInfo.device.type',
  BROWSER: 'tracking_details.deviceInfo.client.name',
  OS: 'tracking_details.deviceInfo.os.name',
  NAME: 'name',
  VIEW_COUNT: 'viewCount',
  COUNTRY_ID: 'country',
  LINK: 'link',
  LINK_CLICK_COUNT: 'count'
};

export enum CAMPAIGN_TRACKING_TEXT {
  UNSUBSCRIBED = 'Unsubscribed',
  UNIQUE_VIEWS = 'Unique Views',
  TOTAL_EMAILS = 'Total Emails',
  TOTAL_VIEWS = 'Total Views'
}

export enum CAMPAIGN_TRACKING_EVENT_TYPE {
  EMAIL_TRACKING = 'EMAIL_TRACKING',
  EMAIL_LINK_TRACKING = 'EMAIL_LINK_TRACKING'
}

export enum CHART_TITLE {
  EMAILS = 'Emails',
  UNIQUE = 'Unique',
  UNSUBSCRIBED = 'Unsubscribed',
  VIEWS = 'Views',
  CLICKS = 'Clicks'
}

export enum TRACKING_COUNTS {
  TOTAL_EMAILS = 'totalEmails',
  TOTAL_VIEWS = 'totalViews',
  UNIQUE_VIEWS = 'uniqueEmailRead',
  TOTAL_LINK_CLICKS = 'totalLinkClicks',
  UNIQUE_LINK_CLICKS = 'uniqueLinkClicks',
  UNSUBSCRIBED = 'unsubscribed'
}

export const TRACKING_FILTER_OPTIONS = [
  'All',
  'Last week',
  'Last 30 days',
  'Custom date'
];

export const DELETE = 'Delete';
export const CANCEL = 'Cancel';

export const DESKERA_URL =
  'https://www.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool';
export const LANDING_PAGE_URL = 'http://report-builder-dev.deskera.xyz/';

/* ********** BOOKS DOCUMENT DESIGNER CONSTANTS ********* */
export const CONTACT_CODE_FUTURE_PLACEHOLDER = '{{contact_future_code}}';
export const DOCUMENT_DESIGNER = 'Document Designer Template';
export const DOCUMENT_AMOUNT_FUTURE_PLACEHOLDER = '{{amount_future_code}}';
export const DOCUMENT_CODE_FUTURE_PLACEHOLDER = '{{document_future_code}}';
export const PAYMENT_LINK_FUTURE_PLACEHOLDER = '{{payment_link_future_code}}';

export enum TEMPLATE_CATEGORY {
  QUOTE = 'QUOTE',
  INVOICE = 'INVOICE'
}

export enum DOC_TYPE {
  QUOTE = 'QUOTATION',
  SALES_INVOICE = 'SALES_INVOICE',
  INVOICE = 'INVOICE',
  PRODUCT_DOCUMENT = 'PRODUCT_DOCUMENT'
}

export enum BOOKS_MODULE {
  QUOTE = 'QUOTATION',
  INVOICE = 'INVOICE',
  CONTACT = 'CONTACT'
}

export enum DOCUMENT_MODE {
  VIEW = 'view',
  DRAFT = 'draft',
  NEW = 'new',
  EDIT = 'edit'
}

export enum FORM_ELEMENTS {
  DROPDOWN = 'dropdown',
  INPUT = 'input',
  SEARCHABLE = 'searchable',
  TOGGLE = 'toggle',
  DATE = 'date'
}

export enum BOOKS_CF_DROPDOWN_TYPE {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum E_TYPE {
  E_TYPE = 'IC'
}

export const DOC_TYPE_TO_MODULE_MAP = {};
export const DESIGNER_TEMPLATE = {
  id: 0,
  templateName: DOCUMENT_DESIGNER,
  appName: APP_NAME,
  templateData: ''
};
export const EMAIL_PAYLOAD = {
  application: 'application',
  asOfDate: 'asOfDate',
  bcc: 'bcc',
  body: 'body',
  category: 'category',
  category_name: 'categoryName',
  contact: 'contact',
  contactCode: 'contactCode',
  contactCodeWithName: 'contactCodeWithName',
  details: 'details',
  doc_subtotal: 'docSubTotal',
  endDate: 'endDate',
  fileFormat: 'fileFormat',
  fromDate: 'fromDate',
  groupByCurrency: 'groupByCurrency',
  limit: 'limit',
  module_name: 'moduleName',
  page: 'page',
  replyTo: 'replyTo',
  reportCode: 'reportCode',
  senderName: 'senderName',
  startDate: 'startDate',
  statementOfAccountRequest: 'statementOfAccountRequest',
  subject: 'subject',
  tax_amount: 'itemTaxAmt',
  tenant_billing: 'tenantBillingAddr',
  tenant_contact: 'tenantContactNum',
  tenant_name: 'tenantName',
  textArea: 'textArea',
  to: 'to',
  total_amount: 'totalAmt',
  total_discount: 'totalDiscount',
  total_item_amount: 'totalItemAmt',
  total_tax: 'totalTax',
  vendor: 'vendor',
  cc: 'cc'
};
export const PRINT_PAYLOAD_KEYS = {
  total_item_amount: 'totalItemAmt',
  itemCount: 'SNo',
  item_description: 'description',
  invoiceNumber: 'invoiceNumber',
  quoteNumber: 'quotationNumber',
  credit: 'CREDIT_NOTE',
  receive: 'RECEIVE_PAYMENT',
  module_name: 'moduleName',
  category_name: 'categoryName',
  orderNo: 'orderNo',
  BillNo: 'BillNo',
  IS_CUSTOM_TEMPLATE: 'customTemplate',
  DOCUMENT_TYPE_KEY: 'documentType',
  DOCUMENT_CODE_KEY: 'documentCode',
  template_id: 'templateId'
};
export const CURRENCY_PRECISION = 6;

export enum TemplateMappingEvent {
  EMAIL = 'EMAIL',
  PRINT = 'PRINT'
}

export const quoteEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Quote Date',
    action: 'quoteDate'
  },
  {
    hidden: false,
    text: 'Quote Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Quotation Number',
    action: 'quotationNumber'
  }
];
export const invoiceEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Invoice Date',
    action: 'invoiceDate'
  },
  {
    hidden: false,
    text: 'Invoice Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Due Date',
    action: 'dueDate'
  },
  {
    hidden: false,
    text: 'Due Amount',
    action: 'dueAmount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Invoice Number',
    action: 'invoiceNumber'
  }
];
export const DEFAULT_PRODUCT_UOM = 2;
/* ************************************************* */

export const NOTIFICATIONS = 'notifications';
export const BUBBLE_POSITIONS = [
  { title: 'Top Left', value: CHAT_BUBBLE_POSITION.TOP_LEFT },
  { title: 'Top Right', value: CHAT_BUBBLE_POSITION.TOP_RIGHT },
  { title: 'Bottom Right', value: CHAT_BUBBLE_POSITION.BOTTOM_RIGHT },
  { title: 'Bottom Left', value: CHAT_BUBBLE_POSITION.BOTTOM_LEFT }
];
export const CHAT_BUBBLE_DEFAULT_COLOR = '#651866';
export const NOTIFICATION_SOUND = [
  {
    displayName: 'None',
    value: ''
  },
  {
    displayName: 'Chime',
    value: 'chime'
  },
  {
    displayName: 'Alarm',
    value: 'alarm-sound'
  },
  {
    displayName: 'Ringtone',
    value: 'ringtone'
  },
  {
    displayName: 'Telephone',
    value: 'telephone'
  },
  {
    displayName: 'Friend Request',
    value: 'friend-request'
  }
];

export const GST_TREATMENT_VALUES: any[] = [
  {
    key: 'REGISTERED_BUSINESS_REGULAR',
    value: 'Registered Business - Regular'
  },
  {
    key: 'REGISTERED_BUSINESS_COMPOSITION',
    value: 'Registered Business - Composition'
  },
  {
    key: 'UNREGISTERED_BUSINESS',
    value: 'Unregistered Business'
  },
  {
    key: 'CUSTOMER',
    value: 'Consumer'
  },
  {
    key: 'OVERSEAS',
    value: 'Overseas'
  },
  {
    key: 'SPECIAL_ECONOMIC_ZONE',
    value: 'Special Economic Zone'
  },
  {
    key: 'DEEMED_EXPORT',
    value: 'Deemed Export'
  }
];
export const NON_GST_TREATMENT_VALUES = [
  'UNREGISTERED_BUSINESS',
  'CUSTOMER',
  'OVERSEAS'
];
export const EXPORT_OPTIONS = [`XLSX`, `CSV`];
export const REPORT_TYPES = {
  DEAL: 'crm3_deal_report__name',
  CONTACT: 'crm3_contact_report__name',
  ACTIVITY: 'crm3_activity_report__name'
};
export const RECORD_ID = 'crm3_record_id';
export const ACTIVITY_GROUP_BY_CONTACT = `Activities grouped by Contacts`;
export const TICKET_GROUP_BY_CONTACT = `Tickets grouped by Contacts`;
export const UNSUBSCRIBE_USERS = `Unsubscribe Users`;

export const REPORT_TABLE = {
  DEAL: 'crm3_deal_report',
  CONTACT: 'crm3_contact_report',
  ACTIVITY: 'crm3_activity_report',
  TICKET: 'crm3_ticket_report'
};

export const US_COUNTRY_CODE = 'US';
export const IN_COUNTRY_CODE = 'IN';

export const DEMO_TABS = {
  PREFERENCES: 'preferences',
  AVAILABILITY: 'availability',
  INTEGRATION: 'integration'
};
export const TIME_DURATION = [
  { title: '15 Min', value: '15min' },
  { title: '30 Min', value: '30min' },
  { title: '1 Hr', value: '60min' }
];
export const TIME_HOURS = [
  { title: '9:00 am', format: '9' },
  { title: '9:15 am', format: '9:15' },
  { title: '9:30 am', format: '9:30' },
  { title: '9:45 am', format: '9:45' },
  { title: '10:00 am', format: '10' },
  { title: '10:15 am', format: '10:15' },
  { title: '10:30 am', format: '10:30' },
  { title: '10:45 am', format: '10:45' },
  { title: '11:00 am', format: '11' },
  { title: '11:15 am', format: '11:15' },
  { title: '11:30 am', format: '11:30' },
  { title: '11:45 am', format: '11:45' },
  { title: '12:00 pm', format: '12' },
  { title: '12:15 pm', format: '12:15' },
  { title: '12:30 pm', format: '12:30' },
  { title: '12:45 pm', format: '12:45' },
  { title: '1:00 pm', format: '13' },
  { title: '1:15 pm', format: '13:15' },
  { title: '1:30 pm', format: '13:30' },
  { title: '1:45 pm', format: '13:45' },
  { title: '2:00 pm', format: '14' },
  { title: '2:15 pm', format: '14:15' },
  { title: '2:30 pm', format: '14:30' },
  { title: '2:45 pm', format: '14:45' },
  { title: '3:00 pm', format: '15' },
  { title: '3:15 pm', format: '15:15' },
  { title: '3:30 pm', format: '15:30' },
  { title: '3:45 pm', format: '15:45' },
  { title: '4:00 pm', format: '16' },
  { title: '4:15 pm', format: '16:15' },
  { title: '4:30 pm', format: '16:30' },
  { title: '4:45 pm', format: '16:45' },
  { title: '5:00 pm', format: '17' },
  { title: '5:15 pm', format: '17:15' },
  { title: '5:30 pm', format: '17:30' },
  { title: '5:45 pm', format: '17:45' },
  { title: '6:00 pm', format: '18' },
  { title: '6:15 pm', format: '18:15' },
  { title: '6:30 pm', format: '18:30' },
  { title: '6:45 pm', format: '18:45' }
];
export const BOOK_A_DEMO = 'bookademo';
export const DEMO_EMAIL = '@deskera.';

/* ****************** ACCOUNT CONSTANTS *************** */
export const ACCOUNT_NOTE_COLUMN_DISPLAY_NAME = `Additional Info`;

/* ********** BOOKS (QUOTE/INVOICE) CONSTANTS ********* */
export const DEFAULT_BOOKS_CONTACT_CODE = ' ';

export const DEFAULT_DRAFT_PAGE_SIZE = 10;

export const BOOKS_DOCUMENT_LIST = {
  PURCHASE: 'purhcase',
  SALES_ORDER: 'salesOrder',
  INVOICES: 'sales',
  QUOTES: 'quote',
  BILL: 'bill'
};

export const BOOKS_DOCUMENT_EXPORT_MODULE = {
  [BOOKS_DOCUMENT_LIST.INVOICES]: 'INVOICE_SALES',
  [BOOKS_DOCUMENT_LIST.QUOTES]: 'QUOTES'
};

export const BOOKS_DOCUMENT_PERMISSION_MODULE = {
  [BOOKS_DOCUMENT_LIST.INVOICES]: 'invoice',
  [BOOKS_DOCUMENT_LIST.QUOTES]: 'quote'
};

export const CONTACT_ADDRESS_EMPTY_STATE = {
  contactName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  preferred: true
};

export const LABELS = {
  PRODUCTS: 'Product',
  CONTACT: 'Contact',
  INVOICES: 'Invoice',
  BILLS: 'Bill',
  PURCHASE_ORDERS: 'Purchase Order',
  QUOTES: 'Quote',
  SALES_ORDER: 'Sales Order'
};

export const APPROVAL_STATUS_DISPLAY_VALUE = {
  PENDING_FOR_APPROVAL: 'Pending for approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NOT_REQUIRED: 'Not Required'
};

export enum BOOKS_CUSTOM_FIELD_TYPE {
  CURRENCY = 'CURRENCY',
  MULTI_SELECT = 'MULTISELECT',
  COUNTRY = 'COUNTRY',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  USER = 'USER'
}

export enum BOOKS_DOCUMENT_STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  active = 'active',
  Active = 'Active',
  INACTIVE = 'INACTIVE',
  inactive = 'inactive',
  Inactive = 'Inactive',
  OPEN = 'OPEN'
}

/* **************************************************** */

export const GOOGLE_MAP_URL = 'https://www.google.com/maps?q=';

/* ********** INDIAMART SETTINGS CONSTANTS ********* */
export const INDIA_MART_SYNC_LOG_COLUMNS = [
  {
    name: 'App name',
    id: 'integrationApiName',
    width: 200,
    key: 'integrationApiName',
    type: 'text',
    columnCode: 'integrationApiName',
    editable: false
  },
  {
    name: 'Total leads fetched',
    id: 'totalRecords',
    width: 200,
    key: 'totalRecords',
    type: 'number',
    columnCode: 'totalRecords',
    editable: false
  },
  {
    name: 'Total leads Synced',
    id: 'importCount',
    width: 200,
    key: 'importCount',
    type: 'number',
    columnCode: 'importCount',
    editable: false
  },
  {
    name: 'Synced from',
    id: 'startDate',
    width: 200,
    key: 'startDate',
    type: 'date',
    columnCode: 'startDate',
    editable: false
  },
  {
    name: 'Synced to',
    id: 'endDate',
    width: 200,
    key: 'endDate',
    type: 'date',
    columnCode: 'endDate',
    editable: false
  },
  {
    name: 'Status',
    id: 'status',
    width: 200,
    key: 'status',
    type: 'text',
    columnCode: 'status',
    editable: false
  },
  {
    name: 'Failure message',
    id: 'message',
    width: 200,
    key: 'message',
    type: 'text',
    columnCode: 'message',
    editable: false
  },
  {
    name: 'Synced at',
    id: 'createdAt',
    width: 200,
    key: 'createdAt',
    type: 'date',
    columnCode: 'createdAt',
    editable: false
  }
];

export const INDIA_MART_LEAD_COLUMNS = [
  {
    name: 'Name',
    id: INDIA_MART_LEAD_DATA_KEYS.SENDER_NAME,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.SENDER_NAME,
    type: 'text',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.SENDER_NAME,
    editable: false
  },
  {
    name: 'Email',
    id: INDIA_MART_LEAD_DATA_KEYS.SENDER_EMAIL,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.SENDER_EMAIL,
    type: 'email',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.SENDER_EMAIL,
    editable: false
  },
  {
    name: 'Phone',
    id: INDIA_MART_LEAD_DATA_KEYS.SENDER_MOBILE,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.SENDER_MOBILE,
    type: 'phone',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.SENDER_MOBILE,
    editable: false
  },
  {
    name: 'Address',
    id: INDIA_MART_LEAD_DATA_KEYS.SENDER_ADDRESS,
    width: 300,
    key: INDIA_MART_LEAD_DATA_KEYS.SENDER_ADDRESS,
    type: 'text',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.SENDER_ADDRESS,
    editable: false
  },
  {
    name: 'Organization',
    id: INDIA_MART_LEAD_DATA_KEYS.SENDER_COMPANY,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.SENDER_COMPANY,
    type: 'text',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.SENDER_COMPANY,
    editable: false
  },
  {
    name: 'Subject',
    id: INDIA_MART_LEAD_DATA_KEYS.SUBJECT,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.SUBJECT,
    type: 'text',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.SUBJECT,
    editable: false
  },
  {
    name: 'Query message',
    id: INDIA_MART_LEAD_DATA_KEYS.QUERY_MESSAGE,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.QUERY_MESSAGE,
    type: 'text',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.QUERY_MESSAGE,
    editable: false
  },
  {
    name: 'Query product name',
    id: INDIA_MART_LEAD_DATA_KEYS.QUERY_PRODUCT_NAME,
    width: 200,
    key: INDIA_MART_LEAD_DATA_KEYS.QUERY_PRODUCT_NAME,
    type: 'text',
    columnCode: INDIA_MART_LEAD_DATA_KEYS.QUERY_PRODUCT_NAME,
    editable: false
  }
];

export const JUST_DIAL_LEAD_COLUMNS = [
  {
    name: 'Name',
    id: JUST_DIAL_LEAD_DATA_KEYS.NAME,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.NAME,
    type: 'text',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.NAME,
    editable: false
  },
  {
    name: 'Email',
    id: JUST_DIAL_LEAD_DATA_KEYS.EMAIL,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.EMAIL,
    type: 'email',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.EMAIL,
    editable: false
  },
  {
    name: 'Mobile',
    id: JUST_DIAL_LEAD_DATA_KEYS.MOBILE,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.MOBILE,
    type: 'phone',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.MOBILE,
    editable: false
  },
  {
    name: 'Category',
    id: JUST_DIAL_LEAD_DATA_KEYS.CATEGORY,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.CATEGORY,
    type: 'text',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.CATEGORY,
    editable: false
  },
  {
    name: 'Phone',
    id: JUST_DIAL_LEAD_DATA_KEYS.PHONE,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.PHONE,
    type: 'phone',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.PHONE,
    editable: false
  },
  {
    name: 'Area',
    id: JUST_DIAL_LEAD_DATA_KEYS.AREA,
    width: 300,
    key: JUST_DIAL_LEAD_DATA_KEYS.AREA,
    type: 'text',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.AREA,
    editable: false
  },
  {
    name: 'City',
    id: JUST_DIAL_LEAD_DATA_KEYS.CITY,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.CITY,
    type: 'text',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.CITY,
    editable: false
  },
  {
    name: 'Pincode',
    id: JUST_DIAL_LEAD_DATA_KEYS.PINCODE,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.PINCODE,
    type: 'number',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.PINCODE,
    editable: false
  },
  {
    name: 'Company',
    id: JUST_DIAL_LEAD_DATA_KEYS.COMPANY,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.COMPANY,
    type: 'text',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.COMPANY,
    editable: false
  },
  {
    name: 'Branch Area',
    id: JUST_DIAL_LEAD_DATA_KEYS.BRANCH_AREA,
    width: 300,
    key: JUST_DIAL_LEAD_DATA_KEYS.BRANCH_AREA,
    type: 'text',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.BRANCH_AREA,
    editable: false
  },
  {
    name: 'Branch Pincode',
    id: JUST_DIAL_LEAD_DATA_KEYS.BRANCH_PINCODE,
    width: 200,
    key: JUST_DIAL_LEAD_DATA_KEYS.BRANCH_PINCODE,
    type: 'number',
    columnCode: JUST_DIAL_LEAD_DATA_KEYS.BRANCH_PINCODE,
    editable: false
  }
];
export const COUNTRY_CODES = {
  US: 'US',
  IN: 'IN',
  ID: 'ID',
  SG: 'SG',
  PH: 'PH',
  AE: 'AE', //Used for UAE
  MY: 'MY',
  AU: 'AU',
  NL: 'NL',
  BE: 'BE',
  CA: 'CA',
  NZ: 'NZ',
  UK: 'UK',
  DE: 'DE',
  SA: 'SA',
  IL: 'IL'
};

export enum PRODUCT_TYPE {
  TRACKED = 'TRACKED',
  NON_TRACKED = 'NONTRACKED',
  BILL_OF_MATERIALS = 'BILL_OF_MATERIALS'
}

export const UOM_NA_ID = 5;

export enum DOCUMENT_STATUS {
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  PROCESSED = 'PROCESSED',
  CLOSED = 'CLOSED'
}

export enum YT_THUMBNAIL_QUALITIES {
  'default',
  'hqdefault',
  'mqdefault',
  'sddefault',
  'maxresdefault'
}

export const VIDEO_PLACEHOLDER = `https://cdn-crm-dev.deskera.xyz/crm-html/video-placeholder.jpg`;

/* *************** TICKET FIELDS ************* */
export const TICKET_FIELDS = [
  {
    name: 'Ticket No',
    id: 'ticket_no'
  },
  {
    name: 'Subject',
    id: 'subject'
  },
  {
    name: 'Requestor',
    id: 'requestor'
  },
  {
    name: 'Assignee Name',
    id: 'assignee_name'
  },
  {
    name: 'Assignee Email',
    id: 'assignee_email'
  },
  {
    name: 'Priority',
    id: 'priority'
  },
  {
    name: 'Type',
    id: 'type'
  },
  {
    name: 'Status',
    id: 'status'
  }
];

export enum EMAIL_LIMIT {
  email_monthly_limit = 'email_monthly_limit',
  email_daily_limit = 'email_daily_limit'
}

export const CRM_NAME = 'crm';

export const MobileLandingScreenOptions = {
  CONTACT: 'CONTACT',
  SEGMENT: 'SEGMENT',
  DEALS: 'DEALS',
  ACTIVITIES: 'ACTIVITIES'
};

export enum NUMBER_FORMAT {
  US = 'us',
  IN = 'in',
  FR = 'fr',
  ES = 'es'
}

export const DATE_FORMAT_LIST = [
  { format: 'D MMM YYYY', serverFormat: '%e %b %Y' },
  { format: 'DD-MM-YYYY', serverFormat: '%d-%m-%Y' },
  { format: 'YYYY-MM-DD', serverFormat: '%Y-%m-%d' },
  { format: 'MM-DD-YYYY', serverFormat: '%m-%d-%Y' },
  { format: 'MM/DD/YYYY', serverFormat: '%m/%d/%Y' },
  { format: 'DD/MM/YYYY', serverFormat: '%d/%m/%Y' }
];

export enum POPUP_CALLBACKS_TYPE {
  SAVE_CUSTOM_NUMBER = 'saveCustomNumber',
  CLOSE_POPUP = 'closePopup',
  NONE = 'none',
  UPDATE_CURRENCY_EXCHANGE_RATE = 'updateCurrencyExchangeRate',
  SAVE_AUTO_UPDATE_FX_RATE = 'saveAutoUpdateFXRate'
}

export const NUMBER_REGEX_PATTERN: string = '^[0-9]+$';

export enum POPUP_CLICK_TYPE {
  CLOSE_POPUP = 'closePopup',
  SAVE_CUSTOM_DOCUMENT_NUMBER = 'saveNumber',
  SAVE_AUTO_UPDATE_FX_RATE = 'saveAutoUpdateFXRate',
  UPDATE_EXCHANGE_RATE = 'updateExchangeRate',
  NONE = 'none'
}

export enum CUSTOM_NUMBER_INPUT_MODULES {
  QUOTE = 'QUOTE',
  CONTACT = 'CRM3_CONTACT'
}

export const FORBID_ATTR = ['style'];
export const FORBID_TAGS = ['style'];

export enum TenantInfoSeqCodeStatus {
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED'
}

export const EMAIL_SOURCE_TYPE = {
  smtp: 'SMTP',
  office365: 'OFFICE365'
};

export const HTML_SPECIAL_CHAR = {
  '"': '&quot;',
  "'": '&apos;',
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;'
};

export const CUST_EXPORT_WO_PAY = 'EXPORT_WO_PAY';
export const CUST_EXPORT_W_PAY = 'EXPORT_W_PAY';
export const CUST_NA = 'NA';
export const CUST_SEZ_WO_PAY = 'SEZ_WO_PAY';
export const CUST_SEZ_W_PAY = 'SEZ_W_PAY';
export const VENDOR_IMPORT = 'IMPORT';
export const VENDOR_NA = 'NA';
export const VENDOR_SEZ_W_PAY = 'SEZ_W_PAY';
export const VENDOR_SEZ_WO_PAY = 'SEZ_WO_PAY';
export const REGISTERED_BUSINESS_REGULAR = 'REGISTERED_BUSINESS_REGULAR';
export const REGISTERED_BUSINESS_COMPOSITION =
  'REGISTERED_BUSINESS_COMPOSITION';
export const UNREGISTERED_BUSINESS = 'UNREGISTERED_BUSINESS';
export const CUSTOMER_TYPE = 'CUSTOMER';
export const OVERSEAS = 'OVERSEAS';
export const SPECIAL_ECONOMIC_ZONE = 'SPECIAL_ECONOMIC_ZONE';
export const DEEMED_EXPORT = 'DEEMED_EXPORT';
export const ITC_IS_REVERSED_ID = 'ITC_IS_REVERSED';
export const COUNTRY_SPECIFIC_URL_CODE = {
  IN: '/in',
  IL: '/il',
  SG: '/sg',
  US: '/us',
  INDONESIA: '/id',
  MALAYSIA: '/my',
  UAE: '/ae',
  PHILIPPINES: '/ph',
  CANADA: '/ca',
  UK: '/uk',
  ISRAEL: '/il'
};

export const PRODUCT_TRANSACTION_TYPE_SALES = 'BOTH,SALES';
export const PRODUCT_TRANSACTION_TYPE_PURCHASE = 'BOTH,PURCHASE';
export const DEFAULT_PRICE_BOOK_CURRENCY = 'INR';
export const DEFAULT_PRICE_BOOK_COUNTRY = 'IN';
export const QTY_ROUND_OFF_PRECISION = 6;
export const QUOTE_AMOUNT = 'Quote Amount';
export const PRIMARY_QUOTE_AMOUNT = 'Primary quote amount';

export enum COLUMN_TYPE {
  MANDATORY = 'mandatory',
  CUSTOM = 'custom'
}

export enum CURRENCY_CONSTANTS {
  SortDir = 'ASC',
  Sort = 'currencyName',
  Dir = 'ASC'
}

export enum CURRENCY_EXCHANGE_CONSTANTS {
  SortDir = 'ASC',
  Sort = 'currencyStatus',
  Dir = 'ASC'
}

export enum CURRENCY_HISTORY_CONSTANTS {
  SortDir = 'desc',
  Sort = 'exchangeRateDate',
  Dir = 'desc'
}

export enum STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  active = 'active',
  Active = 'Active',
  INACTIVE = 'INACTIVE',
  inactive = 'inactive',
  Inactive = 'Inactive',
  OPEN = 'OPEN'
}

export enum RECURRING_DOCUMENT_TYPE {
  RECURRING = 'RECURRING',
  NON_RECURRING = 'NON_RECURRING'
}

export const LOOKUP_FIELD_ENABLE_COL_TYPE = [
  INPUT_TYPE.MULTI_SELECT,
  INPUT_TYPE.DROPDOWN
];

export const hiddenColumnsForFieldRequire = {
  ACCOUNT: [`note`],
  CONTACT: []
};
export const CONTACT_STATUS_ACTIVE = 1;

export const REGEX_PATTERNS = {
  URL_PATTERN:
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/,
  NEW_LINE: /\n/
};

export const allowedImageType = ['image/jpeg', 'image/png', 'image/jpg'];

export const FILTER_CONDITIONS = {
  CONDITIONS_ALL: [
    { title: 'Equal to', value: 'eq' },
    { title: 'Contains', value: 'c' }
    // { title: "Starts with", value: "sw" },
    // { title: "Ends with", value: "ew" },
    // { title: "Greater than", value: "gt" },
    // { title: "Less than", value: "lt" },
    // { title: "Not Equal to", value: "neq" },
  ],
  CONDITIONS_TEXT: [
    { title: 'Equal to', value: 'eq' },
    { title: 'Contains', value: 'c' },
    { title: 'Not Equal to', value: 'neq' }
  ],
  CONDITIONS_SELECT: [
    { title: 'Contains', value: 'c' },
    // { title: "Equal to", value: "eq" },
    { title: 'Not Equal to', value: 'neq' }
  ],
  CONDITIONS_DATE: [
    { title: 'Equal to', value: 'eq' },
    { title: 'Greater than', value: 'gt' },
    { title: 'Less than', value: 'lt' }
  ],
  CONDITIONS_NUMBER: [
    { title: 'Equal to', value: 'eq' },
    { title: 'Greater than', value: 'gt' },
    { title: 'Less than', value: 'lt' },
    { title: 'Not Equal to', value: 'neq' }
  ]
};
