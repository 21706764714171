import { WORK_FLOW_APP_CODE } from '../../constants/Constants';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import httpClient from '../../http/index';
import AutomationManager from '../../managers/AutomationManager';
import Utility, { isNotEmptyObject } from '../../utility/Utility';
export const createWorkFlowConnection = () => {
  return httpClient.post(
    API_ENDPOINTS.WORKFLOW.CREATE,
    {
      app_code: WORK_FLOW_APP_CODE
    },
    {
      params: { skipInterceptor: true }
    }
  );
};
export const getWorkFlowConnection = () => {
  return httpClient.get(API_ENDPOINTS.WORKFLOW.GET, {
    params: { skipInterceptor: true }
  });
};
export const getOrCreateWorkflowConnection = async () => {
  let connections: any = [];
  try {
    let response = await getWorkFlowConnection();
    connections = response?.data;
    if (Utility.isEmptyObject(connections)) {
      response = await createWorkFlowConnection();
      connections = [response];
    }
    if (isNotEmptyObject(connections)) {
      const connectionId = connections?.find(
        (connection) => connection.app_code === WORK_FLOW_APP_CODE
      )?.id;

      AutomationManager.setConnectionId(connectionId);
    }
  } catch (error) {
    console.error('ERROR:Unable to get get/create workflow connection');
  }
};
