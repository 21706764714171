const dropdownValues = {
  'account name': [
    'Accounts Receivable',
    'Unrealized Gain/Loss',
    'WIP Inventory',
    'Retained Earnings',
    'Share Capital',
    'Stripe Fee',
    'Rounding Difference'
  ],
  year: ['2018', '2019', '2020', '2021', '2022', '2023', '2024'],
  'nature of expense': [
    'Operating expenses',
    'Cost of goods sold',
    'Depreciation',
    'Fixed costs',
    'Variable costs',
    'Utilities expense'
  ],
  Date: ['23 Sept 2024', '24 Sept 2024', '25 Sept 2024', '26 Sept 2024'],
  'Add more sections': ['Reports', 'Integrations', 'Settings'],
  'CRM App name': [
    'SalesGuard',
    'GrowthGenie',
    'CRM Mavericks',
    'ClientMatrix',
    'ConnectXpert'
  ],
  'App name': [
    'FlavorFusion',
    'TasteDash',
    'BiteBuddy',
    'SavoryScout',
    'Snack Shuttle',
    'Feastly',
    'DineDirect',
    'Meal Mate',
    'Hungry Hound',
    'GrubHub'
  ]
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

const getDropdownValues = (key) => {
  return dropdownValues[key];
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export const getDropdown = (placeholder) => {
  const rect = placeholder.getBoundingClientRect();

  let div_dropdown = document.createElement('div');
  div_dropdown.id = 'prompt-editor-dropdown';

  const placeholderText = placeholder.getAttribute('data-key');
  const dropdownValues =
    getDropdownValues(placeholderText) ||
    getDropdownValues(placeholderText.toLowerCase());
  if (!dropdownValues) {
    return;
  }

  div_dropdown.className =
    'bg-white border-m border-radius-s-2 shadow-s position-absolute';
  div_dropdown.style.width = '150px';
  div_dropdown.style.left = rect.left - 4 + 'px';
  div_dropdown.style.top = rect.top + 16 + 'px';

  renderRows(dropdownValues, placeholder, div_dropdown);

  return div_dropdown;
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

const renderRows = (dropdownValues, placeholder, div_dropdown) => {
  for (let i = 0; i < dropdownValues.length; i++) {
    renderRow(dropdownValues[i], div_dropdown, placeholder);
    renderLine(div_dropdown);
  }
};

////////////////////////////////////////////////////////////////////////

const renderRow = (title, div_dropdown, placeholder) => {
  let row = document.createElement('div');
  row.className = 'app-font p-h-r listPickerBG cursor-hand';
  row.innerHTML = title;
  row.style.paddingTop = '6px';
  row.style.paddingBottom = '6px';

  row.onclick = function () {
    placeholder.innerHTML = title;
    div_dropdown.remove();
  };

  div_dropdown.appendChild(row);
};

////////////////////////////////////////////////////////////////////////

const renderLine = (div_dropdown) => {
  let line = document.createElement('div');
  line.className = 'bg-gray2';
  line.style.width = '100%';
  line.style.height = '1px';

  div_dropdown.appendChild(line);
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
