import React from 'react';
import AppIcons from '../../assets/icons/AppIcons';
import { DKLabel, DKIcon } from 'deskera-ui-library';
import { AUTOMATION_TRIGGERS } from '../../constants/Automation';

const TriggerNode = ({ onClick, stepData = {} }) => {
  const { type } = stepData;

  const getText = () => {
    switch (type) {
      case AUTOMATION_TRIGGERS.RECORD_CREATED:
        return 'Start when record created';
      case AUTOMATION_TRIGGERS.RECORD_UPDATED:
        return 'Start when record updated';
      default:
        return 'Click to add a trigger';
    }
  };
  return (
    <div
      className="row width-auto p-h-r p-v-s bg-app mt-xxl border-radius-m cursor-hand"
      onClick={onClick}
    >
      <DKIcon src={AppIcons.automation.ic_trigger} />
      <DKLabel text={getText()} className="ml-r text-white fw-m" />
    </div>
  );
};

export default TriggerNode;
