import React, { Component } from 'react';

import {
  DKLabel,
  DKButton,
  DKIcons,
  DKIcon,
  DKListPicker
} from 'deskera-ui-library';
import { WAIT_INTERVALS } from '../../constants/Automation';
import AppIcons from '../../assets/icons/AppIcons';

/*
PROPS
- stepData
- onClose
- onSave
*/

class WaitEditor extends Component<any, any> {
  intervals;
  constructor(props) {
    super(props);

    const configData = this.props.stepData?.configuration || null;

    this.state = {
      needListPicker: false,
      data: {
        minutes: configData?.minutes ?? 2880,
        count: configData?.count ?? 2,
        intervalIndex:
          configData && configData.intervalIndex ? configData.intervalIndex : 0
      }
    };

    this.intervals = WAIT_INTERVALS;
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          className="column popup-window"
          style={{ padding: 0, maxWidth: 400 }}
        >
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderBottom()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  renderHeader() {
    return (
      <div className="row justify-content-between p-v-s p-h-m data-grid-bottom-border">
        <DKLabel text="Wait for (time)" className="fw-m" />
        <DKButton
          icon={AppIcons.ic_close}
          className=""
          onClick={this.props.onClose}
        />
      </div>
    );
  }

  renderBody() {
    return (
      <div className="column parent-width p-l">
        <DKLabel
          text="How long do you want to wait before next action?"
          className=""
        />
        <div className="mt-l row ">
          <input
            value={this.state.data.count}
            className=" p-h-m border-radius-m border-m"
            style={{ height: 30, width: 100, outline: 'none' }}
            type="number"
            onChange={(e: any) => {
              const newCount = Number(e.target.value);
              if (newCount <= 0) {
                e.target.value = 1;
                this.dataChanged(1, 'count');
                e.preventDefault();
                return;
              }
              this.dataChanged(newCount || 1, 'count');
              this.dataChanged(this.convertCountToMinutes(newCount), 'minutes');
            }}
          />
          <div
            className="row p-h-m border-radius-m border-m ml-m cursor-hand position-relative   "
            style={{ width: 'auto', height: 32 }}
            onClick={() => this.setVisibilityOfListPicker(true)}
          >
            <DKLabel
              text={this.intervals[this.state.data.intervalIndex]}
              className=""
            />
            <DKIcon src={DKIcons.ic_arrow_down2} className="ic-s ml-s" />
            {this.state.needListPicker && (
              <DKListPicker
                className="position-absolute shadow-m border-m"
                data={this.intervals}
                onClose={() => this.setVisibilityOfListPicker(false)}
                onSelect={(index, value) => {
                  this.dataChanged(index, 'intervalIndex');
                  this.dataChanged(
                    this.convertCountToMinutes(this.state.data.count),
                    'minutes'
                  );
                  setTimeout(() => {
                    this.setVisibilityOfListPicker(false);
                  }, 10);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  convertCountToMinutes(count) {
    const { intervalIndex } = this.state.data;

    if (intervalIndex === 0) {
      //days
      return count * 24 * 60;
    } else if (intervalIndex === 1) {
      //hours
      return count * 60;
    } else if (intervalIndex === 2) {
      //minutes
      return count;
    }
  }

  setVisibilityOfListPicker(visible) {
    this.setState({ needListPicker: visible });
  }

  renderBottom() {
    return (
      <div className="row pb-xl pl-s ">
        <DKButton
          title="Continue"
          icon={AppIcons.white.ic_send}
          isReverse
          className="bg-button text-white ml-m"
          onClick={() => {
            this.props.onSave(this.state.data);
            this.props.onClose();
          }}
        />
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  dataChanged(newValue, valueKey) {
    let data_new = this.state.data;
    data_new[valueKey] = newValue;
    this.setState({ data: data_new });
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
}

export default WaitEditor;
