import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import httpClient from '../../http';

import { DKLabel, showAlert, showToast } from 'deskera-ui-library';

import animationData from '../../assets/lottie/Animation - 1727086246021.json'; //
import LottiePlayer from '../LottiePlayer';

import { MAX_FILE_SIZE } from '../../constants/Constants';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import { getHumanReadableFileSize, uploadFileToAWS } from '../../services/file';
import PromptEditor from '../prompt-editor/PromptEditor';

import { useAuth } from '@clerk/clerk-react';
import { LOCAL_STORAGE_KEYS } from '../../constants/Enum';
import LoadingAnimation from '../prompt-editor/LoadingAnimation';
import AuthNavigation from '../common/AuthNavigation';
import { isNotEmptyObject } from '../../utility/Utility';
import AppCreateProcess from '../create-app/AppCreateProcess';

const Home = () => {
  const [apiResponseReceived, setApiResponseReceived] = useState(false);
  const [needAppCreateAnimation, setAnimationVisibility] = useState(false);
  const [showAuthNavigation, setShowAuthNavigation] = useState(false);
  const [needLoadingAnimation, setLoadingAnimationVisibility] = useState(false);
  const attachmentOpenFileRef = useRef();
  const [attachments, setAttachments] = useState();
  const [newAppPayload, setNewAppPayload] = useState(null);
  const { isSignedIn } = useAuth();

  const setPromptInLocalStorage = (promptMessage) => {
    try {
      let availablePrompts =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROMPT_MESSAGES)) ||
        [];
      let appendNewOnTop = [
        { title: promptMessage, prompt: promptMessage },
        ...availablePrompts
      ];
      appendNewOnTop.splice(5, appendNewOnTop.length - 5);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.PROMPT_MESSAGES,
        JSON.stringify(appendNewOnTop)
      );
    } catch (error) {
      console.log(error, 'Error occurred while prompt saving in Local storage');
    }
  };

  const handleCreateApp = async (prompt, themeColor) => {
    setLoadingAnimationVisibility(true);
    try {
      const response = await httpClient.post(API_ENDPOINTS.APP.CREATE_AI_APP, {
        prompt,
        theme: { backgroundColor: themeColor },
        fileUrls: attachments
      });
      setNewAppPayload((prevState) => ({
        ...prevState,
        appId: response.appId
      }));
      appCreated(response.appId);
    } catch (error) {
      showAlert('Error occurred!', error);
      setLoadingAnimationVisibility(false);
      setApiResponseReceived(true);
    } finally {
      setLoadingAnimationVisibility(false);
      setApiResponseReceived(true);
    }
  };

  const appCreated = (appId) => {
    setAnimationVisibility(true);
    // setTimeout(() => {
    //   showAlert(
    //     'Congratulations!',
    //     'Your application has been created successfully.',
    //     [
    //       { title: 'Back', className: 'bg-gray1 border-m' },
    //       {
    //         title: 'Go to App',
    //         className: 'bg-button text-white ml-r',
    //         onClick: () => {
    //           dispatch(setAppInfo({ appId: appId, isPublishedApp: false }));
    //           navigate(`/apps/${appId}`);
    //         }
    //       }
    //     ]
    //   );
    // }, 1000);
    setTimeout(() => {
      setAnimationVisibility(false);
    }, 5000);
  };

  const handleFileSelect = (e) => {
    if (!e.target.files) return;
    const files = Array.from(e.target.files);
    const fileExceedingLimit = files.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (fileExceedingLimit.length) {
      showAlert(
        'File size exceeds! ',
        `File size should not be more than ${getHumanReadableFileSize(
          MAX_FILE_SIZE
        )}. Please optimize <em>${fileExceedingLimit
          .map((file) => file.name)
          .join(', ')}</em> or select different file.`
      );
      return;
    }
    showToast('Uploading attachments...');

    Promise.all(files.map((file) => uploadFileToAWS(file)))
      .then((responseList) => {
        if (responseList && responseList.length > 0) {
          setAttachments(responseList);
        }
      })
      .catch((err) => {
        console.error('Error while attaching files ', err);
      })
      .finally(() => {});
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
          textAlign: 'center'
        }}
        className="position-relative md:px-8 md:pt-8"
      >
        {needLoadingAnimation && <LoadingAnimation />}
        <div className="column align-items-center gap-4 text-stone-950 my-auto">
          <DKLabel
            text="Introducing ERP.AI: The Future of Enterprise"
            className="p-h-r p-v-xs border-m border-radius-l bg-white shadow-s-2"
          />
          <h1 className="max-w-[650px] text-center font-instrument-serif text-3xl sm:text-4xl leading-tight sm:leading-normal lg:text-6xl lg:leading-[64px]">
            Production Grade ERP
            <br />
            <span className="italic text-core-ochre-700 dark:text-core-ochre">
              Prompt
            </span>{' '}
            to <span className="italic text-moringa-green">done</span> in 60s
          </h1>
          <p className="text-sm sm:text-base text-center">
            Create production-ready ERP applications with built-in AI
            capabilities
          </p>
          <PromptEditor
            apiResponseReceived={apiResponseReceived}
            onSubmit={(prompt, themeColor) => {
              if (isNotEmptyObject(prompt)) {
                setPromptInLocalStorage(prompt);
                if (isSignedIn) {
                  setNewAppPayload({
                    prompt,
                    themeColor,
                    textIndex: Math.floor(Math.random() * 20)
                  });
                  handleCreateApp(prompt, themeColor);
                } else {
                  if (!showAuthNavigation) {
                    setShowAuthNavigation(true);
                  }
                }
              }
            }}
            onBrowse={() => {
              if (attachmentOpenFileRef?.current) {
                attachmentOpenFileRef?.current?.click();
              }
            }}
          />
          <input
            id="dynamicFormAttachment"
            type="file"
            accept="image/*, application/pdf, .txt, .doc, .xls , .ppt, .docx, .xlsx, .pptx"
            multiple={true}
            ref={attachmentOpenFileRef}
            style={{ display: 'none' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => handleFileSelect(e)}
          />
        </div>

        {needAppCreateAnimation && (
          <div className="div position-absolute">
            <LottiePlayer animationData={animationData} />
          </div>
        )}
        <div className="text-center">
          <p className="text-xs sm:text-sm pb-2 lg:pb-0 text-gray-600">
            By using ERP.AI, you agree to our{' '}
            <Link to={'/terms'} className="underline hover:text-gray-800">
              Terms
            </Link>{' '}
            and have read our{' '}
            <Link to={'/privacy'} className="underline hover:text-gray-800">
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
      {showAuthNavigation && (
        <AuthNavigation
          onClose={() => {
            setApiResponseReceived((prev) => !prev);
            setShowAuthNavigation(false);
          }}
        />
      )}
      {newAppPayload && (
        <AppCreateProcess
          newAppPayload={newAppPayload}
          onClose={() => setNewAppPayload(null)}
        />
      )}
    </>
  );
};

export default Home;
