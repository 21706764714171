import { API_ENDPOINTS } from '../constants/Endpoints';
import httpClient from '../http/index';
export const getFormsByApp = ({ appId }: any) => {
  return httpClient.get(API_ENDPOINTS.FORM.GET_ALL(appId));
};
export const getFormByTable = ({ tableId }: any) => {
  return httpClient.get(API_ENDPOINTS.FORM.GET(tableId));
};
export const saveForm = (payload: any) => {
  return httpClient.post(API_ENDPOINTS.FORM.CREATE(payload.tableId), payload);
};
export const updateForm = (payload: any) => {
  return httpClient.put(API_ENDPOINTS.FORM.UPDATE(payload.tableId), payload);
};
