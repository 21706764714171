import { INPUT_TYPE } from 'deskera-ui-library';
import httpClient from '../http';
import { API_ENDPOINTS } from '../constants/Endpoints';
import { IColumn } from '../model/Table';

export const fetchTablesByAppId = ({ appId }: any): Promise<any> => {
  return httpClient.get(API_ENDPOINTS.APP.FETCH_TABLE, {
    params: {
      appId,
      includeFilters: true
    }
  });
};

export const fetchTable = ({ tableId }: any) => {
  return httpClient.get(API_ENDPOINTS.TABLE.FETCH(tableId));
};

export const fetchApp = ({ appId }: any) => {
  return httpClient.get(API_ENDPOINTS.APP.FETCH_BY_ID(appId));
};

export const addColumns = (tableId: string, payload: IColumn[]) => {
  return httpClient.post(API_ENDPOINTS.COLUMN.ADD_BULK(tableId), {
    columns: payload
  });
};
export const updateColumn = (
  tableId: string,
  columnId: string,
  payload: Partial<IColumn>
) => {
  return httpClient.put(
    API_ENDPOINTS.COLUMN.UPDATE(tableId, columnId),
    payload
  );
};

export const fetchEditableComponents = ({ appId }: any) => {
  return httpClient.get(API_ENDPOINTS.APP.GET_EDITABLE_COMPONENTS(appId));
};

export const updateComponents = (payload: any) => {
  return httpClient.patch(API_ENDPOINTS.APP.UPDATE_COMPONENTS, payload);
};

export const shiftColumns = (tableId: string, payload: string[]) => {
  return httpClient.post(API_ENDPOINTS.COLUMN.SHIFT_COLUMN(tableId), payload);
};

export const freezeColumns = (tableId: string, payload: string[]) => {
  return httpClient.post(API_ENDPOINTS.COLUMN.FREEZE_COLUMN(tableId), payload);
};

export const isSelectField = (column) => {
  return (
    column.type === INPUT_TYPE.SELECT ||
    column.type === INPUT_TYPE.MULTI_SELECT ||
    column.type === 'ref'
  );
};
export const addRecord = async (tableId, record) => {
  try {
    let response;
    response = await httpClient.post(
      API_ENDPOINTS.RECORDS.ADD(tableId),
      record
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const updateRecord = async (tableId, record) => {
  const { _id: recordId } = record;
  try {
    let response;
    response = await httpClient.put(
      API_ENDPOINTS.RECORDS.UPDATE(tableId, recordId),
      record
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllRecords = async (tableId) => {
  try {
    const response = await httpClient.get(
      API_ENDPOINTS.RECORDS.FETCH_REF_RECORDS(tableId)
    );

    return response;
  } catch (error) {
    return error;
  }
};
