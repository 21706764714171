import { CUSTOM_INPUT_TYPE } from './Constants';
import { FILTER_OPERATORS } from './Enum';

/* layout configs for automation elements */
export const SPLIT_GUTTER = 30;
export const STEP_CARD_WIDTH = 250;
export const CONNECTOR_DIMENSION = { width: 3, height: 30 };

export enum AUTOMATION_ACTIONS {
  SEND_EMAIL = 'SEND_EMAIL',
  CREATE_CONTACT = 'CREATE_CONTACT',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  UPDATE_RECORD = 'UPDATE_RECORD',
  CREATE_RECORD = 'CREATE_RECORD',
  CREATE_DEAL = 'CREATE_DEAL',
  UPDATE_DEAL = 'UPDATE_DEAL',
  SPLIT_WORKFLOW = 'SPLIT',
  WAIT_FOR_TIME = 'WAIT_FOR_TIME',
  WAIT_TILL = 'WAIT_FOR_TIME_TILL',
  SEND_SMS_MESSAGE = 'SEND_SMS_MESSAGE',
  SEND_WHATSAPP_MESSAGE = 'SEND_WHATSAPP_MESSAGE',
  ASSIGN_OWNER = 'ASSIGN_OWNER',
  END_WORKFLOW = 'END_WORKFLOW',
  CREATE_FACEBOOK_LEAD_ENTITY = 'CREATE_FACEBOOK_LEAD_ENTITY',
  CRM_APPROVAL = 'BOOKS_PLUS_APPROVAL',
  DEAL_UPDATE_APPROVAL = 'DEAL_UPDATE_APPROVAL',
  UPDATE_TICKET = 'UPDATE_TICKET'
}

export const SPLIT_TYPE_ACTIONS: AUTOMATION_ACTIONS[] = [
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.WAIT_TILL
];
export const INTERMEDIATE_CHILD_ACTION_TYPES = [
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW
];

export const DIRECT_CHILD_ACTION_TYPES = [
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY,
  AUTOMATION_ACTIONS.CRM_APPROVAL,
  AUTOMATION_ACTIONS.DEAL_UPDATE_APPROVAL
];
export enum AUTOMATION_ELEMENT {
  TRIGGER = 'Trigger',
  ACTION = 'Action'
}
export enum AUTOMATION_TRIGGERS {
  RECORD_CREATED = 'RECORD_CREATED',
  RECORD_UPDATED = 'RECORD_UPDATED',
  CAMPAIGN_PUBLISHED = 'CAMPAIGN_PUBLISHED',
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  CONTACT_ADDED = 'CONTACT_ADDED',
  VALUE_CHANGED = 'CONTACT_UPDATED',
  DEAL_VALUE_CHANGED = 'DEAL_UPDATED',
  ACTIVITY_VALUE_CHANGED = 'ACTIVITY_UPDATED',
  FACEBOOK_LEAD_ADDED = 'FACEBOOK_LEAD_ADDED',
  DEAL_STAGE_CHANGED = 'DEAL_STAGE_CHANGED',
  QUOTE_CREATED = 'BOOKS_CREATE_QUOTATION',
  DATE_ALERT = 'CRM_DATE_ALERT_TRIGGER',
  TICKET_CREATED = 'TICKET_CREATED',
  TICKET_VALUE_CHANGED = 'TICKET_UPDATED'
}
export const MAX_VISIBLE_STEP_VALUE_CHARACTERS = 40;

export enum SPLIT_ACTION_TYPE {
  CONTACT_CHECK = 'CONTACT_CHECK',
  CONTACT_ACTIVITY_CHECK = 'CONTACT_HAS_ACTIVITY_CHECK',
  // CONTACT_DEAL_CHECK= "CONTACT_HAS_DEAL_CHECK",
  DEAL_CHECK = 'DEAL_CHECK',
  DEAL_ACTIVITY_CHECK = 'DEAL_HAS_ACTIVITY_CHECK',
  // DEAL_CONTACT_CHECK= "DEAL_HAS_CONTACT_CHECK",
  ACTIVITY_CHECK = 'ACTIVITY_CHECK',
  ACTIVITY_CONTACT_CHECK = 'ACTIVITY_HAS_CONTACT_CHECK',
  ACTIVITY_DEAL_CHECK = 'ACTIVITY_HAS_DEAL_CHECK',
  EMAIL_OPENED = 'EMAIL_OPENED',
  EMAIL_LINK_CLICKED = 'EMAIL_LINK_CLICKED',
  BOOKS_QUOTATION_CHECK = 'BOOKS_QUOTATION_CHECK',
  TICKET_CHECK = 'TICKET_CHECK',
  TICKET_CONTACT_CHECK = 'TICKET_HAS_CONTACT_CHECK',
  TICKET_ACCOUNT_CHECK = 'TICKET_HAS_ACCOUNT_CHECK'
}
export const WAIT_INTERVALS = ['day', 'hour', 'minute'];
export const CONDITIONAL_FIELD_MAPPER_TYPE = [
  AUTOMATION_TRIGGERS.RECORD_CREATED,
  AUTOMATION_TRIGGERS.RECORD_UPDATED
];
export const OPERATOR_LIST = {
  EQUAL: {
    displayName: 'Equals to',
    symbol: '==',
    token: FILTER_OPERATORS.EQUAL
  },
  NOT_EQUAL: {
    displayName: 'Not Equals to',
    symbol: '!=',
    token: FILTER_OPERATORS.NOT_EQUAL
  },
  CONTAINS: {
    displayName: 'Contains',
    symbol: '=>',
    token: FILTER_OPERATORS.CONTAINS
  },
  GREATER: {
    displayName: 'Greater than',
    symbol: '=>',
    token: FILTER_OPERATORS.GREATER_THAN
  },
  GREATER_EQUAL: {
    displayName: 'Greater than or Equal',
    symbol: '=>',
    token: FILTER_OPERATORS.GREATER_EQUAL
  },
  LESSER: {
    displayName: 'Less than',
    symbol: '<=',
    token: FILTER_OPERATORS.LESS_THAN
  }
};
export enum OBJECT_TYPES {
  AUTOMATION = 'AUTOMATION',
  DEAL = 'DEAL',
  CONTACT = 'CONTACT',
  ACTIVITY = 'ACTIVITY',
  TICKET = 'TICKET'
}
export const STATUS_OPTIONS = {
  ACTIVE: [1],
  IN_ACTIVE: [2]
};

export const MAGNIFICATION_SCALE_UNIT = 0.05;
export const MIN_MAGNIFICATION_SCALE = 0.1;

export const INITIAL_MAGNIFICATION_SCALE = 1;
export const SUPPORTED_DATE_ALERT_ACTIONS = [
  AUTOMATION_ACTIONS.ASSIGN_OWNER,
  AUTOMATION_ACTIONS.UPDATE_CONTACT,
  AUTOMATION_ACTIONS.UPDATE_DEAL,
  AUTOMATION_ACTIONS.SEND_EMAIL,
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.END_WORKFLOW
];

export const ALLOWED_COLUMN_TYPES = [
  CUSTOM_INPUT_TYPE.TEXT,
  CUSTOM_INPUT_TYPE.NUMBER,
  CUSTOM_INPUT_TYPE.DATE,
  CUSTOM_INPUT_TYPE.SELECT,
  CUSTOM_INPUT_TYPE.MULTI_SELECT,
  CUSTOM_INPUT_TYPE.LONG_TEXT2,
  CUSTOM_INPUT_TYPE.REF
];

export const DROPDOWN_DEFAULT_OPTIONS = [
  {
    id: 'any',
    name: 'Any',
    color: 'data-grid-badge-color-6'
  },
  {
    id: 'none',
    name: 'None',
    color: 'data-grid-badge-color-7'
  }
];
