import React from 'react';
import { DKLabel } from 'deskera-ui-library';
import Popup from './Popup';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '../../managers/RouteManager';
import { LOCAL_STORAGE_KEYS } from '../../constants/Enum';
import { getERPAILogo } from '../../utility/Utility';

const AuthNavigation = ({ onClose }) => {
  const navigate = useNavigate();
  const renderButton = (title, style, className, onClick) => {
    return (
      <div
        className={
          'column parent-width p-v-s p-h-r border-radius-s-2 cursor-hand align-items-center fw-m ' +
          className
        }
        style={style}
        onClick={onClick}
      >
        <DKLabel text={title} className="text-align-center" />
      </div>
    );
  };

  return (
    <Popup
      closeOnOutsideClick={true}
      className="column"
      popupWindowStyles={{
        maxHeight: '96%',
        width: '90%',
        maxWidth: 400,
        padding: 30
      }}
      onClose={onClose}
    >
      <div className="column parent-size align-items-center">
        {getERPAILogo(true)}
        <DKLabel text="Continue with ERP.AI" className="fs-l mt-xl fw-m" />
        <DKLabel
          text="To use ERP.AI you need to login with your existing account or sign up as a new user."
          className="mt-r text-align-center"
        />
        <div className="column parent-width mt-xxl align-items-center">
          {renderButton(
            'Sign In',
            { borderWidth: 2, width: '90%' },
            'border-m bg-gray1',
            () => {
              localStorage.setItem(
                LOCAL_STORAGE_KEYS.SELECT_PROMPT_FROM_STORAGE,
                'true'
              );
              navigate(PAGE_ROUTES.SIGN_IN);
            }
          )}
          {renderButton(
            'Sign Up',
            { borderWidth: 2, width: '90%' },
            'bg-button text-white mt-m',
            () => {
              localStorage.setItem(
                LOCAL_STORAGE_KEYS.SELECT_PROMPT_FROM_STORAGE,
                'true'
              );
              navigate(PAGE_ROUTES.SIGN_UP);
            }
          )}
        </div>
        <DKLabel
          text="By using ERP.AI, you agree to <a class='underline-hover text-gray' href=' https://www.erp.ai/terms' target='_blank'>term and conditions</a>"
          className="mt-xxl text-gray  text-align-center"
        />
      </div>
    </Popup>
  );
};

export default AuthNavigation;
