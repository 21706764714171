import { DKLabel, DKLine } from 'deskera-ui-library';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import TextField from '../../components/common/TextFields';
import { IAccount } from '../../model/Settings';
interface IAccountSettings {
  settings?: IAccount;
}
export default function AccountSettings(props: IAccountSettings) {
  const getHeader = () => {
    return (
      <div className="column parent-width">
        <DKLabel className="fs-xl fw-m" text="Company Setting" />
        <DKLabel
          className=""
          text="You can delete company or delete account or reset your organization here"
        />
      </div>
    );
  };

  return (
    <div className="column parent-width">
      {getHeader()}
      <DKLine className="mt-l mb-l" />
      <div className="column parent-width gap-4">
        <div>
          <TextField
            required={true}
            title="Name"
            placeHolder="Name"
            type={CUSTOM_INPUT_TYPE.TEXT}
            onChange={(text: any) => {}}
            value=""
            options={[]}
          />
          <DKLabel
            className="text-gray mt-xs"
            text="This is the name that will be displayed on your profile and in emails."
          />
        </div>
        <div>
          <TextField
            required={true}
            title="Date Of Birth"
            placeHolder="Date Of Birth"
            type={CUSTOM_INPUT_TYPE.TEXT}
            onChange={(text: any) => {}}
            value=""
            options={[]}
          />
          <DKLabel
            className="text-gray mt-xs"
            text="Your date of birth is used to calculate your age."
          />
        </div>
        <div>
          <TextField
            title="Select Languages"
            placeHolder="Select Languages"
            type={CUSTOM_INPUT_TYPE.MULTI_SELECT}
            onChange={(text: any) => {}}
            value={''}
            options={[
              'English',
              'Spanish',
              'French',
              'German',
              'Italian',
              'Irish'
            ]}
          />
          <DKLabel
            className="text-gray mt-xs"
            text="This is the language that will be used in the dashboard."
          />
        </div>
      </div>
    </div>
  );
}
