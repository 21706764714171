import React from 'react';
import AppIcons from '../../assets/icons/AppIcons';
import { DKIcon } from 'deskera-ui-library';
import {
  AUTOMATION_ACTIONS,
  AUTOMATION_TRIGGERS
} from '../../constants/Automation';

export const ActionType = {
  ADD: 'add',
  UPDATE: 'update',
  WAIT: 'wait',
  END: 'end'
};

export const NodeColors = {
  GREEN: 'rgb(29, 172, 87)',
  BLUE: 'rgb(66, 80, 240)',
  ORANGE: 'rgb(242, 124, 12)',
  RED: 'rgb(233, 12, 13)',
  PINK: 'rgb(230, 0, 170)',
  PURPLE: 'rgb(106, 60, 206)',
  BLUE2: 'rgb(15, 113, 170)'
};

export const getAllNodeColors = () => {
  return Object.keys(NodeColors).map((key) => {
    return NodeColors[key];
  });
};

export const ActionColor = (type) => {
  var color = 'bg-gray';
  switch (type) {
    case AUTOMATION_ACTIONS.CREATE_RECORD:
    case AUTOMATION_TRIGGERS.CREATE_RECORD:
      color = NodeColors.GREEN;
      break;
    case AUTOMATION_ACTIONS.UPDATE_RECORD:
    case AUTOMATION_TRIGGERS.UPDATE_RECORD:
      color = NodeColors.BLUE;
      break;
    case AUTOMATION_ACTIONS.WAIT_FOR_TIME:
      color = NodeColors.ORANGE;
      break;
    case AUTOMATION_ACTIONS.END_WORKFLOW:
      color = NodeColors.RED;
      break;
  }

  return color;
};

const ActionIcon = ({ type, iconSize = 'ic-s-2' }) => {
  var icon = AppIcons.ic_home;

  switch (type) {
    case AUTOMATION_ACTIONS.CREATE_RECORD:
    case AUTOMATION_TRIGGERS.CREATE_RECORD:
      icon = AppIcons.automation.ic_add;
      break;
    case AUTOMATION_ACTIONS.UPDATE_RECORD:
    case AUTOMATION_TRIGGERS.UPDATE_RECORD:
      icon = AppIcons.automation.ic_update;
      break;
    case AUTOMATION_ACTIONS.WAIT_FOR_TIME:
      icon = AppIcons.automation.ic_wait;
      break;
    case AUTOMATION_ACTIONS.END_WORKFLOW:
      icon = AppIcons.automation.ic_end;
      break;
    default:
      break;
  }

  return (
    <div
      className={' border-radius-s-2 p-xs '}
      style={{ backgroundColor: ActionColor(type) }}
    >
      <DKIcon src={icon} className={iconSize} />
    </div>
  );
};

export default ActionIcon;
