import { FC } from 'react';
import {
  DKLabel,
  DKInput,
  INPUT_VIEW_DIRECTION,
  DKButton
} from 'deskera-ui-library';
import IFormField from '../../model/Form';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import TableInput from './TableInput';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface IFieldHolderProps {
  fields: IFormField[];
  formTitle: string;
  selectedField?: IFormField | null;
  onFieldClick: (field: IFormField) => void;
  onFieldChange: (dragIndex: number, dropIndex: number) => void;
}

const FieldHolder: FC<IFieldHolderProps> = ({
  fields,
  formTitle,
  selectedField,
  onFieldClick,
  onFieldChange
}) => {
  /************* Drag n Drop Start **************/
  const handleDragEnd = (event: any) => {
    if (event.source && event.destination) {
      onFieldChange(event.source.index, event.destination.index);
    }
  };

  const handleDragStart = (event: any) => {};

  /************* Drag n Drop End **************/

  const renderField = (field: IFormField, index: number) => {
    const { _id } = field;
    return (
      <Draggable key={`item-${_id}`} draggableId={`item-${_id}`} index={index}>
        {(providedR, snapshot) => (
          <div
            key={_id}
            className={`column parent-width cursor-hand mb-l ${
              selectedField?._id === _id ? 'field-highlighter' : ''
            }`}
            onClick={() => {
              onFieldClick(field);
            }}
            ref={providedR.innerRef}
            {...providedR.draggableProps}
            {...providedR.dragHandleProps}
          >
            {getField(field)}
          </div>
        )}
      </Draggable>
    );
  };
  const getField = (field: IFormField) => {
    const { type } = field;
    switch (type) {
      case CUSTOM_INPUT_TYPE.FILE:
        return getAttachmentField(field);
      case CUSTOM_INPUT_TYPE.TABLE:
        return <TableInput field={field} />;
      default:
        return getInputField(field);
    }
  };
  const getInputField = ({
    style,
    title,
    placeHolder,
    required,
    type
  }: IFormField) => {
    return (
      <div style={{ ...style, width: style.width + '%' }}>
        <DKInput
          className="parent-size"
          title={title}
          type={type}
          placeholder={placeHolder}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={required}
          value={null}
          readOnly={true}
        />
      </div>
    );
  };
  const getAttachmentField = ({ title }: IFormField) => {
    return (
      <div className="row-responsive parent-width justify-content-between align-items-start position-relative bg-white border-m border-radius-s-2">
        <div className="row bg-gray1 p-s">
          <div className="d-flex gap-5">
            <DKLabel className="parent-width fw-m" text={title} />

            <DKButton
              className="text-white bg-button p-0"
              title={`Browse`}
              onClick={(e) => {}}
            />
          </div>
        </div>
        <div className="row parent-width flex-wrap  p-v-m" style={{ gap: 10 }}>
          <div className="row text-gray align-items-center justify-content-center p-v-xl">
            No files attached
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="column p-xxl parent-width">
      <DKLabel text={formTitle} className="fs-xxl fw-m mb-xxl" />
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="column parent-width"
            >
              {fields.map(renderField)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default FieldHolder;
