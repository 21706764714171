export default class ThemeManager {
  static themeColor = '';
  static isThemeLight = false;

  static setThemeColor(color) {
    ThemeManager.themeColor = color;
    ThemeManager.isThemeLight = ThemeManager.checkIfLightTheme(color);
  }

  static getThemeColor() {
    return ThemeManager.themeColor;
  }

  static isLightTheme() {
    return ThemeManager.isThemeLight;
  }

  static checkIfLightTheme(color = ThemeManager.themeColor) {
    var r, g, b, hsp;

    // Check if the color is in RGB format
    if (color.match(/^rgb/)) {
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );
      r = parseInt(color[1]);
      g = parseInt(color[2]);
      b = parseInt(color[3]);
    } else {
      // If HEX, convert it to RGB
      color = +(
        '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
      );
      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // Calculate HSP (Highly Sensitive Poo) value
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Determine if the color is light or dark
    return hsp > 127.5;
  }
}
