import React from 'react';
import AppIcons from '../../assets/icons/AppIcons';
import { DKIcon } from 'deskera-ui-library';

const LineArrow = ({ onClick }) => {
  return (
    <div className="column align-items-center">
      <div
        className="column justify-content-center align-items-center bg-gray"
        style={{ width: 1, height: 80 }}
      >
        <div
          className="bg-white border-m border-radius-m p-xs cursor-hand"
          onClick={onClick}
        >
          <DKIcon src={AppIcons.automation.ic_plus} className="ic-s" />
        </div>
      </div>
      <DKIcon
        src={AppIcons.ic_arrow_down}
        className="opacity-5"
        style={{ marginTop: -13 }}
      />
    </div>
  );
};

export default LineArrow;
