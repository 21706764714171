import ReactDOMServer from "react-dom/server";
import React, { Component, createRef } from "react";

import {
  DKButton,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  getDataTypeIcon,
  // INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  isEmpty,
  showToast,
  DKListPicker2,
  TOAST_TYPE,
} from "deskera-ui-library";

import {
  FORMULA_TOKEN_IDENTIFIER,
  FORMULA_TOKEN_TYPES,
  FORMULA_TOOLBAR,
} from "../constants/Enum";
// import { INPUT_TYPE, KEY_CODES } from "../Constants/Enum";
import { INPUT_TYPE, KEY_CODES } from "../constants/Enum";
// import debounce from "../Utility/Debounce";
import debounce from "../utility/Debounce";
// import { getRandomAlphaNumericString } from "../Utility/DKUtility";
import { getRandomAlphaNumericString } from "../utility/Utility";
// import { computeExpressionValue } from "../Utility/DataGridUtility";
import { computeExpressionValue } from "../utility/DataGridUtility";

/* Props:
    - columns
    - formula
    - onSaveFormula
    - onClose
*/

class DKFormEditorPers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formula: props.formula || [],
      canValidate: false,
      isValid: false,
      needSymbolPicker: false,
      needFieldPicker: false,
    };

    this.formulaInputRef = createRef();
    this.tokenIdBeforeCaretRef = createRef();
    this.caretPositionData = null;
  }

  componentDidMount() {
    this.refreshPlaceholdersWithColumnData();
  }

  componentDidUpdate() {
    window.requestAnimationFrame(() => {
      this.setCaretPosition();
    });
  }

  componentDidCatch(err) {
    console.error("Formula editor component error", err);
    this.props.onClose();
  }

  refreshPlaceholdersWithColumnData = () => {
    const { formula, columns } = this.props;
    if (!formula?.length || !columns?.length) return;

    const newFormula = [...formula];
    let isChanged = false;
    newFormula.forEach((tokenData) => {
      if (tokenData.type === FORMULA_TOKEN_TYPES.OPERAND && tokenData.dynamic) {
        const column = columns.find((column) => column.id === tokenData.value);
        if (column && column.name !== tokenData.token) {
          isChanged = true;
          tokenData.token = column.name;
        }
      }
    });

    if (isChanged) {
      this.setState({ formula: newFormula });
    }
  };

  onAddToken = ({ type, token, value, dynamic }) => {
    const formula = [...this.state.formula];
    const tokenData = {
      type,
      token,
      value,
      id: getRandomAlphaNumericString(4),
    };

    if (type === FORMULA_TOKEN_TYPES.OPERAND) {
      tokenData.dynamic = dynamic;
    }

    let tokenIndexToInsertAfter = -1;
    if (this.tokenIdBeforeCaretRef.current) {
      tokenIndexToInsertAfter = formula.findIndex(
        (tokenData) => tokenData.id === this.tokenIdBeforeCaretRef.current
      );
    }

    if (tokenIndexToInsertAfter !== null && tokenIndexToInsertAfter !== -1) {
      formula.splice(tokenIndexToInsertAfter + 1, 0, tokenData);
    } else if (this.caretPositionData !== null) {
      formula.unshift(tokenData);
    } else {
      formula.push(tokenData);
    }

    this.tokenIdBeforeCaretRef.current = tokenData.id;

    if (type === FORMULA_TOKEN_TYPES.OPERAND && !dynamic) {
      this.caretPositionData = {
        insideTokenElement: true,
        offset: 1,
      };
    } else {
      this.caretPositionData = {
        insideTokenElement: false,
        offset: 1,
      };
    }

    let isValid = false;
    if (this.state.canValidate) {
      isValid = this.isValidFormula(formula);
    }

    this.setState({
      formula,
      isValid,
    });
  };

  onClickRemoveToken = () => {
    let formula = [...this.state.formula];
    const tokenIndexToRemove = this.tokenIdBeforeCaretRef.current
      ? formula.findIndex(
          (tokenData) => tokenData.id === this.tokenIdBeforeCaretRef.current
        )
      : -1;

    if (tokenIndexToRemove === -1) {
      this.caretPositionData = null;
      formula = formula.slice(0, -1);
    } else {
      formula.splice(tokenIndexToRemove, 1);

      const prevToken = formula[tokenIndexToRemove - 1];
      /* Keeping cursor at start only, in case first token gets deleted */
      const nextToken = formula[tokenIndexToRemove];
      this.tokenIdBeforeCaretRef.current = prevToken?.id || null;
      this.caretPositionData =
        prevToken || nextToken
          ? {
              insideTokenElement: false,
              offset: 1,
            }
          : null;
    }

    let isValid = false;
    if (this.state.canValidate) {
      isValid = this.isValidFormula(formula);
    }

    this.setState({
      formula,
      isValid,
    });
  };

  getCaretPosition = (e) => {
    try {
      const range = window.getSelection().getRangeAt(0);
      const parentTokenNode = range.startContainer.closest
        ? range.startContainer.closest(`.${FORMULA_TOKEN_IDENTIFIER}`)
        : range.startContainer.parentElement;
      const siblingTokenNode = range.startContainer.previousSibling;

      let tokenIdToInsertAfter = null;

      if (
        parentTokenNode &&
        parentTokenNode.classList.contains(FORMULA_TOKEN_IDENTIFIER)
      ) {
        tokenIdToInsertAfter = parentTokenNode.id;
        this.caretPositionData = {
          insideTokenElement: true,
          offset: range.startOffset,
        };
      } else if (
        siblingTokenNode &&
        siblingTokenNode.classList.contains(FORMULA_TOKEN_IDENTIFIER)
      ) {
        tokenIdToInsertAfter = siblingTokenNode.id;
        this.caretPositionData = {
          insideTokenElement: false,
          offset: range.startOffset,
        };
      } else {
        this.caretPositionData = range.startContainer.nextSibling
          ? {
              insideTokenElement: false,
              offset: range.startOffset,
            }
          : null;
      }

      this.tokenIdBeforeCaretRef.current = tokenIdToInsertAfter;
    } catch (err) {}
  };

  setCaretPosition = () => {
    let tokenNode = null;
    const selection = window.getSelection();
    const range = document.createRange();

    try {
      if (this.tokenIdBeforeCaretRef.current) {
        tokenNode = document.getElementById(this.tokenIdBeforeCaretRef.current);

        if (tokenNode && !this.caretPositionData?.insideTokenElement) {
          tokenNode = tokenNode.nextSibling;
        }
      }

      if (!tokenNode) {
        tokenNode =
          this.caretPositionData === null
            ? this.formulaInputRef.current.lastChild
            : this.formulaInputRef.current.firstChild;
      }

      range.selectNodeContents(tokenNode);
    } catch (err) {
      range.selectNodeContents(this.formulaInputRef.current);
    }

    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);

    this.formulaInputRef.current.focus();
  };

  syncFormulaTokensFromInput = debounce(() => {
    const tokenElements = this.formulaInputRef.current.querySelectorAll(
      `.${FORMULA_TOKEN_IDENTIFIER}`
    );
    const tokenIndexToElementMap = {};
    tokenElements.forEach((tokenElement) => {
      const tokenId = tokenElement.id;
      tokenIndexToElementMap[tokenId] = tokenElement;
    });

    let changed = false;
    const formula = [];
    this.state.formula.forEach((tokenData) => {
      const tokenElement = tokenIndexToElementMap[tokenData.id];
      if (!tokenElement) {
        changed = true;
        return;
      }
      if (
        tokenData.type === FORMULA_TOKEN_TYPES.OPERAND &&
        !tokenData.dynamic
      ) {
        const value = isNaN(Number(tokenElement.textContent))
          ? ""
          : Number(tokenElement.textContent);

        if (value !== tokenData.value) {
          changed = true;
        }

        formula.push({
          ...tokenData,
          token: value.toString(),
          value: value,
        });
      } else {
        formula.push(tokenData);
      }
    });

    if (!changed) return;

    let isValid = false;
    if (this.state.canValidate) {
      isValid = this.isValidFormula(formula);
    }

    this.setState({ formula, isValid });
  }, 300);

  onFormulaInputKeyDown = (e) => {
    if (
      ![
        KEY_CODES.ARROW_UP,
        KEY_CODES.ARROW_DOWN,
        KEY_CODES.ARROW_LEFT,
        KEY_CODES.ARROW_RIGHT,
        KEY_CODES.BACKSPACE,
        KEY_CODES.DELETE,
        KEY_CODES.PERIOD,
      ].includes(e.code) &&
      isNaN(Number(e.key))
    ) {
      e.preventDefault();
      return false;
    }

    /* Not allowing entering numbers outside placeholder */
    if (!isNaN(e.key) || e.code === KEY_CODES.PERIOD) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const parentTokenNode = range.startContainer.closest
        ? range.startContainer.closest(`.${FORMULA_TOKEN_IDENTIFIER}`)
        : range.startContainer.parentElement;

      if (
        !parentTokenNode ||
        !parentTokenNode.classList.contains(FORMULA_TOKEN_IDENTIFIER)
      ) {
        e.preventDefault();
        return false;
      }
    }

    if (
      ![
        KEY_CODES.ARROW_UP,
        KEY_CODES.ARROW_DOWN,
        KEY_CODES.ARROW_LEFT,
        KEY_CODES.ARROW_RIGHT,
      ].includes(e.key)
    ) {
      this.syncFormulaTokensFromInput();
    }
  };

  isValidFormula = (formula = this.state.formula) => {
    let dynamicValues = {},
      flag = true;

    this.props.columns?.forEach((column) => {
      dynamicValues[column.id] = 1;
    });

    try {
      let prevTokenType = "";
      formula.forEach((tokenData) => {
        if (
          prevTokenType === tokenData.type &&
          tokenData.type === FORMULA_TOKEN_TYPES.OPERAND
        ) {
          throw new Error(
            "Invalid formula, operands can not be placed together."
          );
        }

        prevTokenType = tokenData.type;
      });

      const expressionResult = computeExpressionValue(formula, dynamicValues);

      if (
        expressionResult === null ||
        expressionResult === "" ||
        isNaN(expressionResult)
      )
        flag = false;
    } catch (e) {
      flag = false;
    }

    return flag;
  };

  onSaveTapped = () => {
    /* Validate formulae */
    if (!this.isValidFormula()) {
      this.setState({
        canValidate: true,
        isValid: false,
      });
      return;
    }

    if (this.props.onSaveFormula) {
      this.props.onSaveFormula(this.state.formula);
    } else {
      alert("Callback onSaveFormula required for saving...");
    }
  };

  /* ************** RENDERERS ************** */

  renderHeader() {
    return (
      <div className="row parent-width justify-content-between">
        <DKLabel text={"Formula editor"} className=" fw-m" />
        <div className="row width-auto" style={{ gap: 8 }}>
          <DKButton
            className="bg-gray2"
            title="Close"
            onClick={this.props.onClose}
          />
          <DKButton
            className="dk-bg-button bg-button text-white"
            title="Save"
            onClick={this.onSaveTapped}
          />
        </div>
      </div>
    );
  }

  renderToolBar() {
    return (
      <div
        className="row parent-width flex-wrap border-m p-r"
        style={{ gap: 8, borderWidth: "0 0 1px" }}
      >
        <DKLabel text="Insert:" className="fw-m" />
        {
          /* GROUP */
          FORMULA_TOOLBAR[FORMULA_TOKEN_TYPES.GROUP].map((tokenData) => (
            <DKButton
              className="bg-gray2"
              style={{ padding: 6, height: 24, borderRadius: 4 }}
              title={tokenData.value}
              disabled={false}
              onClick={() =>
                this.onAddToken({
                  type: FORMULA_TOKEN_TYPES.GROUP,
                  token: tokenData.token,
                  value: tokenData.value,
                })
              }
            />
          ))
        }
        <div className="position-relative">
          <DKButton
            className="bg-gray2"
            style={{ padding: 6, height: 24, borderRadius: 4 }}
            title="Field"
            icon={DKIcons.ic_arrow_down2}
            isReverse={true}
            onClick={() => this.setState({ needFieldPicker: true })}
          />
          {this.state.needFieldPicker && (
            <DKListPicker2
              data={(this.props.columns || []).filter(
                (column) => column.type === INPUT_TYPE.NUMBER
              )}
              // data={this.props.columns || []}
              // data={["dasd", "dakhl"]}
              className="bg-white border-m shadow-s position-absolute z-index-3"
              style={{ top: 0, left: 0, width: 200 }}
              displayKey="name"
              allowSearch={true}
              searchableKey="name"
              onSelect={(index, column) => {
                this.onAddToken({
                  type: FORMULA_TOKEN_TYPES.OPERAND,
                  token: column.name,
                  value: column.id,
                  dynamic: true,
                });
                this.setState({ needFieldPicker: false });
              }}
              onClose={() => this.setState({ needFieldPicker: false })}
            />
          )}
        </div>
        <DKButton
          className="bg-gray2"
          title={"Number"}
          style={{ padding: 6, height: 24, borderRadius: 4 }}
          disabled={false}
          onClick={() =>
            this.onAddToken({
              type: FORMULA_TOKEN_TYPES.OPERAND,
              token: "0",
              value: 0,
              dynamic: false,
            })
          }
        />
        <div className="position-relative">
          <DKButton
            className="bg-gray2"
            style={{ padding: 6, height: 24, borderRadius: 4 }}
            title="Symbol"
            icon={DKIcons.ic_arrow_down2}
            isReverse={true}
            onClick={() => this.setState({ needSymbolPicker: true })}
          />
          {this.state.needSymbolPicker && (
            <DKListPicker2
              data={FORMULA_TOOLBAR[FORMULA_TOKEN_TYPES.OPERATOR]}
              className="bg-white border-m shadow-s position-absolute z-index-3"
              style={{ top: 0, left: 0, width: 120 }}
              renderer={(index, tokenData) => (
                <DKLabel text={`${tokenData.value} ${tokenData.token}`} />
                // <DKLabel text={tokenData} />
              )}
              onSelect={(index, tokenData) => {
                this.onAddToken({
                  type: FORMULA_TOKEN_TYPES.OPERATOR,
                  token: tokenData.token,
                  value: tokenData.value,
                });
                this.setState({ needSymbolPicker: false });
              }}
              onClose={() => this.setState({ needSymbolPicker: false })}
            />
          )}
        </div>
        <DKButton
          className="bg-gray2"
          title={"⬅"}
          style={{ padding: 6, height: 24, borderRadius: 4 }}
          disabled={!this.state.formula?.length}
          onClick={() => this.onClickRemoveToken()}
        />
        <DKButton
          className="bg-gray2 text-red"
          title={"Clear"}
          style={{ padding: 6, height: 24, borderRadius: 4 }}
          disabled={!this.state.formula?.length}
          onClick={() => this.setState({ formula: [] })}
        />
      </div>
    );
  }

  getTokenDisplayElements() {
    const tokenElements = [];

    this.state.formula.forEach((tokenData, index) => {
      let tokenElement = null;

      switch (tokenData.type) {
        case FORMULA_TOKEN_TYPES.GROUP:
          tokenElement = (
            <span
              className={`${FORMULA_TOKEN_IDENTIFIER} fs-xl`}
              style={{ margin: "0 2px" }}
              data-token-type={FORMULA_TOKEN_TYPES.GROUP}
              data-token-index={index}
              contentEditable={false}
              key={tokenData.id}
              id={tokenData.id}
            >
              {tokenData.value}
            </span>
          );
          break;
        case FORMULA_TOKEN_TYPES.OPERATOR:
          tokenElement = (
            <span
              className={`${FORMULA_TOKEN_IDENTIFIER} data-grid-badge-color-8 p-xs circle display-block text-align-center ic-s line-height-1`}
              style={{ width: 16, margin: "0 2px" }}
              data-token-type={FORMULA_TOKEN_TYPES.OPERATOR}
              data-token-index={index}
              contentEditable={false}
              key={tokenData.id}
              id={tokenData.id}
            >
              {tokenData.value}
            </span>
          );
          break;
        case FORMULA_TOKEN_TYPES.OPERAND:
          tokenElement = (
            <span
              className={`${FORMULA_TOKEN_IDENTIFIER} p-xs border-radius-s line-height-1 ${
                tokenData.dynamic
                  ? "data-grid-badge-color-7"
                  : "data-grid-badge-color-9"
              }`}
              style={{ margin: "0 2px" }}
              data-token-type={FORMULA_TOKEN_TYPES.OPERAND}
              data-token-index={index}
              contentEditable={tokenData.dynamic ? false : undefined}
              id={tokenData.id}
            >
              &#xFEFF;
              {tokenData.token}
            </span>
          );
          break;
        default:
      }

      tokenElements.push(tokenElement);
      tokenElements.push(<>&#xFEFF;</>);
    });

    return <>&#xFEFF;{tokenElements}</>;
  }

  renderBody() {
    return (
      <div className="column parent-width flex-1 border-m border-radius-s mt-l mb-r position-relative">
        {this.renderToolBar()}
        <div
          className={`parent-width p-r border-box text-align-left ${
            this.state.canValidate && !this.state.isValid
              ? "border-red border-radius-s"
              : ""
          }`}
          style={{ minHeight: 80, outline: "none", overflowX: "auto" }}
          contentEditable={true}
          ref={this.formulaInputRef}
          onKeyDown={this.onFormulaInputKeyDown}
          onSelect={() => setTimeout(this.getCaretPosition, 10)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          dangerouslySetInnerHTML={{
            __html: ReactDOMServer.renderToStaticMarkup(
              this.getTokenDisplayElements()
            ),
          }}
        ></div>
        {this.state.canValidate && !this.state.isValid ? (
          <DKLabel
            className="fs-error text-red text-red position-absolute"
            text="Please enter a valid formula"
            style={{ top: "100%", left: 0 }}
          />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ overflow: "visible" }}>
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }
}

export default DKFormEditorPers;
