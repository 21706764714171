import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IImportState {
  table: any;
}
const initialState: IImportState = {
  table: {}
};
const importState = createSlice({
  name: 'import',
  initialState,
  reducers: {
    setTable: (state, action: PayloadAction<any>) => {
      state.table = action.payload;
    }
  }
});
const selfSelector = (state: RootState) => state?.import;
export const selectTableToImport = () =>
  createSelector(selfSelector, (state: IImportState) => state.table);

export const { setTable } = importState.actions;
export default importState.reducer;
