import { DKInput, INPUT_VIEW_DIRECTION } from 'deskera-ui-library';
import { FC } from 'react';
interface ITextField {
  title: string;
  placeHolder: string;
  type: string;
  onChange: (text: string) => void;
  options: any;
  value: any;
  required?: boolean;
  readOnly?: boolean;
}

const TextField: FC<ITextField> = ({
  onChange,
  options,
  placeHolder,
  title,
  value,
  readOnly,
  required,
  type
}) => {
  return (
    <div
      className="column"
      style={{
        width: 450
      }}
    >
      <DKInput
        type={type}
        valueStyle={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderWidth: 1.5,
          borderColor: 'lightGray',
          borderStyle: 'solid',
          padding: 8
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        title={title}
        placeHolder={placeHolder}
        onChange={onChange}
        options={options}
        required={!!required}
        value={value}
        readOnly={!!readOnly}
      />
    </div>
  );
};
export default TextField;
