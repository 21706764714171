import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IRefreshTriggerState {
  refreshTable: boolean;
  tableIds: string[];
}

const initialState: IRefreshTriggerState = {
  refreshTable: false,
  tableIds: []
};

const refreshTriggerSlice = createSlice({
  name: 'refreshTrigger',
  initialState,
  reducers: {
    refreshTables: (state, action: PayloadAction<string[]>) => {
      if (action.payload?.length) {
        state.refreshTable = true;
        state.tableIds = action.payload;
      }
    },
    refreshTableComplete: (state, action: PayloadAction<string>) => {
      if (action.payload?.length) {
        state.refreshTable = false;
        state.tableIds = [];
      }
    }
  }
});

const selfSelector = (state: RootState) => state?.refreshTrigger;

export const selectRefreshTrigger = () =>
  createSelector(
    selfSelector,
    (state: IRefreshTriggerState) => state.refreshTable
  );

export const { refreshTables, refreshTableComplete } =
  refreshTriggerSlice.actions;

export default refreshTriggerSlice.reducer;
