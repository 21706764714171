import React from 'react';
import ReactDOM from 'react-dom';
import { DKIcon, DKIcons, DKSpinner, DKLabel } from 'deskera-ui-library';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import Utility from '../../utility/Utility';
import ic_doc from '../../assets/icons/ic_doc.png';
import ic_img from '../../assets/icons/ic_img.png';
import ic_pdf from '../../assets/icons/ic_pdf.png';
import ic_ppt from '../../assets/icons/ic_ppt.png';
import ic_xlsx from '../../assets/icons/ic_xlsx.png';
import { Attachment } from '../../model/File';
import Popup from './Popup';

const styles: { [key: string]: React.CSSProperties } = {
  attachmentWrapper: {
    width: 200,
    height: 200,
    overflow: 'hidden',
    gap: 8
  },
  mainWrapper: {
    gap: 15
  },
  attachment: {
    maxWidth: 200,
    height: 160,
    overflow: 'hidden'
  },
  attachmentDefaultIcon: {
    width: 80,
    height: 80
  },
  attachmentTitle: {
    height: 24,
    maxWidth: '100%'
  },
  attachmentTitles: {
    width: 200,
    height: 170,
    overflow: 'hidden',
    top: 0,
    background: 'rgba(255,255,255,0.5)',
    wordBreak: 'break-word',
    gap: 16
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
};

const PLAIN_DOC_EXTENSIONS = [
  'text/plain',
  'application/txt',
  'text/csv',
  'application/vnd.ms-excel'
];

const FILE_EXTENSION_GROUP = {
  ALL: null,
  IMAGE: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg',
    'image/svg+xml'
  ],
  XLS: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
  ],
  DOC: [
    'text/plain',
    'application/txt',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  PDF: ['application/pdf'],
  PPT: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]
};
const FileWrapper = ({
  file,
  type,
  className = '',
  style = {},
  needLoader = true,
  isDarkTheme = false
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const needPngBackground = isDarkTheme && file.type === 'image/png';
  return (
    <>
      {isLoading && needLoader && (
        <div className="row width-auto justify-content-center position-absolute">
          <DKSpinner isWhite={isDarkTheme} />
        </div>
      )}
      {type === 'image' ? (
        <img
          style={{
            ...styles.attachment,
            background: needPngBackground
              ? 'url(https://i.ibb.co/F7ys5Pg/png-Background.png)'
              : 'transparent',
            padding: needPngBackground ? 4 : 0,
            ...style
          }}
          src={file.path}
          alt={file.title}
          className={`paren-size mt-auto ${className || ''}`}
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
        />
      ) : (
        <embed
          style={{
            ...styles.attachment,
            ...style
          }}
          src={file.path}
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
          className={`paren-size mt-auto ${className || ''}`}
          title={file.title}
        ></embed>
      )}
    </>
  );
};

const getAttachmentTitleWithIcon = (
  attachment: Attachment,
  isDarkTheme?: boolean
) => {
  let icon: string;
  if (FILE_EXTENSION_GROUP.PDF.includes(attachment.type)) {
    icon = ic_pdf;
  } else if (FILE_EXTENSION_GROUP.DOC.includes(attachment.type)) {
    icon = ic_doc;
  } else if (FILE_EXTENSION_GROUP.PPT.includes(attachment.type)) {
    icon = ic_ppt;
  } else if (FILE_EXTENSION_GROUP.XLS.includes(attachment.type)) {
    icon = ic_xlsx;
  } else {
    icon = ic_img;
  }

  return (
    <div
      className="row align-items-center mt-auto"
      style={styles.attachmentTitle}
    >
      <DKIcon src={icon} className="ic-xs-2" />
      <DKLabel
        text={attachment.title}
        className={'ml-r pb-xs ' + (isDarkTheme ? '  text-white ' : '')}
        style={styles.ellipsis}
      />
    </div>
  );
};

const AttachmentPreview = ({
  attachment,
  maxWidth = '90vw',
  maxHeight = '100vh',
  needLoader = true,
  onClose = null,
  onDelete = null,
  headerIconButton = null,
  isPopup = false,
  darkTheme = false
}) => {
  if (Utility.isEmptyObject(attachment)) return null;

  function getDocumentPreviewPath() {
    let documentPreviewPath: string;

    if (
      [...FILE_EXTENSION_GROUP.IMAGE, ...FILE_EXTENSION_GROUP.PDF].includes(
        attachment.type
      )
    ) {
      documentPreviewPath = attachment.path;
    } else if ([...PLAIN_DOC_EXTENSIONS].includes(attachment.type)) {
      /* Showing: plain docs in google docs web viewer & others in office web viewer
        Ref: https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be#gistcomment-2310091 */
      documentPreviewPath = API_ENDPOINTS.FILES.GOOGLE_VIEWER(attachment.path);
    } else {
      /* Showing: xls, xlsx, doc, docx, ppt, pptx in Office web viewer */
      documentPreviewPath = API_ENDPOINTS.FILES.OFFICE_VIEWER(attachment.path);
    }

    return documentPreviewPath;
  }

  const onClickBackground = (e) => {
    if (!e.target.classList.contains('attachment-fullscreen-background'))
      return;
    onClose?.();
  };

  const isImage = FILE_EXTENSION_GROUP.IMAGE.includes(attachment.type);

  return (
    <div
      className={
        'column parent-size align-items-center flex-1 ' +
        (isPopup ? '' : ' mt-r ')
      }
    >
      <div
        className={
          'row justify-content-between ' + (isPopup ? ' p-r ' : ' mb-r ')
        }
        style={{
          width: isPopup ? '100%' : maxWidth,
          maxWidth: isPopup ? '100%' : maxWidth,
          gap: isPopup ? 24 : 8,
          background: isPopup && darkTheme ? 'rgb(0, 0, 0, 0.9)' : 'transparent'
        }}
      >
        <div
          className="row width-auto text-ellipsis"
          style={{
            WebkitLineClamp: 1
          }}
        >
          {getAttachmentTitleWithIcon(attachment, darkTheme)}
        </div>
        {headerIconButton ? (
          <DKIcon
            src={headerIconButton.icon}
            className={`ic-s ml-auto cursor-hand`}
            onClick={headerIconButton.onClick}
          />
        ) : null}
        <DKIcon
          src={darkTheme ? DKIcons.white.ic_download : DKIcons.ic_download}
          className={`${isPopup ? 'ic-s-2' : 'ic-s'} ${
            headerIconButton ? '' : 'ml-auto'
          } cursor-hand`}
          onClick={(e) => {
            e.stopPropagation?.();
            Utility.openInNewTab(attachment.path);
          }}
        />
        {onDelete ? (
          <DKIcon
            src={darkTheme ? DKIcons.white.ic_delete : DKIcons.ic_delete}
            className={`${isPopup ? 'ic-s-2' : 'ic-s'} cursor-hand`}
            onClick={onDelete}
          />
        ) : null}
        {onClose ? (
          <DKIcon
            src={darkTheme ? DKIcons.white.ic_close : DKIcons.ic_close}
            className={`${isPopup ? 'ic-s-2' : 'ic-s'} cursor-hand`}
            onClick={onClose}
          />
        ) : null}
      </div>
      <div
        className="column flex-1 parent-width align-items-center justify-content-center attachment-fullscreen-background"
        onClick={onClickBackground}
      >
        <FileWrapper
          file={{
            ...attachment,
            path: getDocumentPreviewPath()
          }}
          style={{
            objectFit: 'contain',
            width: isImage || !isPopup ? 'auto' : '40vw',
            height: isImage || !isPopup ? 'auto' : maxHeight,
            maxHeight: `calc(${maxHeight} - 90px)`,
            maxWidth: maxWidth
          }}
          className="mb-auto"
          type={isImage ? 'image' : 'embed'}
          needLoader={needLoader}
          isDarkTheme={darkTheme}
        />
      </div>
    </div>
  );
};
export const getAttachmentPreviewInPopup = (
  attachment: Attachment,
  handlers?: {
    onDelete?: () => void;
    onClose?: () => void;
  }
) => {
  const id = `attachment-preview-popup-${new Date().getTime()}`;
  let div = document.createElement('div');
  div.className = 'attachment-preview-popup app-font';
  div.setAttribute('id', id);

  const handleOnClose = () => {
    handlers?.onClose?.();
    const attachmentPopupElement = document.getElementById(id);
    ReactDOM.unmountComponentAtNode(attachmentPopupElement);
    attachmentPopupElement?.remove();
  };

  ReactDOM.render(
    <Popup
      popupWindowStyles={{
        overflowY: 'hidden',
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        background: 'rgba(0, 0, 0, 0.85)',
        padding: 0,
        borderRadius: 0,
        top: 0,
        left: 0,
        transform: 'translate(0, 0)'
      }}
    >
      <AttachmentPreview
        attachment={attachment}
        isPopup={true}
        onClose={handleOnClose}
        onDelete={
          handlers?.onDelete
            ? () => {
                handleOnClose();
                handlers.onDelete();
              }
            : null
        }
        darkTheme={true}
      />
    </Popup>,
    document.body.appendChild(div)
  );
};

export default AttachmentPreview;
