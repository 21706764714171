import { UserButton, useUser } from '@clerk/clerk-react';
import {
  DKButton,
  DKIcon,
  DKTooltipWrapper,
  removeLoader,
  showAlert,
  showLoader,
  DKListPicker,
  DKInputForm
} from 'deskera-ui-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppIcons from '../../assets/icons/AppIcons';

import { API_ENDPOINTS } from '../../constants/Endpoints';
import httpClient from '../../http';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAppInfo } from '../../redux/slices/CommonSlice';
import ApplicationService from '../../services/applications';
import HtmlEditor from '../code-editor/HtmlEditor';
import EditApplicationAndTemplate from '../prompt-editor/EditApplicationAndTemplate';
import Popup from '../common/Popup';
import {
  PAGE_ROUTES,
  ROUTE_APP_DETAIL_ID_IDENTIFIER,
  ROUTE_TABLE_DETAIL_ID_IDENTIFIER
} from '../../managers/RouteManager';
import { addTables } from '../../redux/slices/tableSlice';

const TopMenu = ({ appId, app }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appInformation = useAppSelector(selectAppInfo);
  const { user } = useUser();
  const [showMarkAsPublicPopup, setShowMarkAsPublicPopup] = useState(false);
  const [isAppMarkedPublic, setIsAppMarkedPublic] = useState(false);
  const [htmlEditorPopup, setHtmlEditorPopup] = useState({
    visible: false,
    data: null,
    templateId: null
  });
  const [fromScratchPopup, setFromScratchPopup] = useState(false);
  const [showCreateTableOptions, setShowCreateTableOptions] = useState(false);

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const publishApp = async () => {
    try {
      const response = await httpClient.patch(API_ENDPOINTS.APP.PUBLISH(appId));
      appPublished(response.app.internalAppUrl);
    } catch (error) {
      showAlert('Error occurred!', error);
    } finally {
    }
  };

  const appPublished = (url) => {
    showAlert('App published!', 'Your app has been successfully published.', [
      { title: 'Ok', className: 'bg-gray1 border-m' },
      {
        title: 'Goto published URL',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          window.open(url);
        }
      }
    ]);
  };

  // Call API

  const installApp = async (appObj) => {
    try {
      showLoader();
      await httpClient.post(
        API_ENDPOINTS.TEMPLATE.INSTALL_TEMPLATE(appObj._id),
        {
          name: appObj.name,
          description: ''
        }
      );
      setShowMarkAsPublicPopup(false);
      removeLoader();
    } catch (error) {
      setShowMarkAsPublicPopup(false);
      removeLoader();
    }
  };

  const confirmLandingPageByTemplateId = (landingPage, templateId) => {
    if (landingPage) {
      process.env.REACT_APP_GEN_LP
        ? showLoader('Confirming your landing page...')
        : showLoader();
      ApplicationService.confirmLandingPage(landingPage, templateId)
        .then((response) => {
          removeLoader();
          if (response?.success) {
            console.log(response, 'Landing page confirmed');
            process.env.REACT_APP_GEN_LP
              ? showAlert(
                  'Success',
                  'Your app is now available to the public.',
                  [
                    { title: 'Back', className: 'bg-gray1 border-m' },
                    {
                      title: 'Go to landing page',
                      className: 'bg-button text-white ml-r',
                      onClick: () => {
                        window.open(`/marketplace/${appId}`, '_blank');
                      }
                    }
                  ]
                )
              : showAlert(
                  'Success',
                  'Your app is now available to the public.',
                  [
                    { title: 'Back', className: 'bg-gray1 border-m' },
                    {
                      title: 'Go to marketplace',
                      className: 'bg-button text-white ml-r',
                      onClick: () => {
                        navigate(`/marketplace`);
                      }
                    }
                  ]
                );
            setIsAppMarkedPublic(true);
          } else {
            setShowMarkAsPublicPopup(false);
          }
        })
        .catch((error) => {
          setShowMarkAsPublicPopup(false);
          removeLoader();
        });
    }
  };

  const generateLandingPage = (templateId) => {
    showLoader('Generating landing page...');
    ApplicationService.generateLandingPage(templateId)
      .then((response) => {
        removeLoader();
        console.log(response, 'Landing page genrrated');
        setHtmlEditorPopup({
          visible: true,
          data: { ...response.data.landingPage },
          templateId
        });
      })
      .catch((error) => {
        setShowMarkAsPublicPopup(false);
        removeLoader();
      });
  };

  const markAsPublicClicked = (appObj) => {
    let payload = {
      appId: appId,
      description: appObj.description,
      logoData: appObj.logoData,
      name: appObj.name,
      published: true
    };
    showLoader('Marking app public...');
    ApplicationService.makeAppPublic(payload)
      .then((response) => {
        setShowMarkAsPublicPopup(false);
        removeLoader();
        process.env.REACT_APP_GEN_LP
          ? generateLandingPage(response.id)
          : confirmLandingPageByTemplateId(
              {
                config: {},
                html: '<div>In progress</div>'
              },
              response.id
            );
      })
      .catch((error) => {
        setShowMarkAsPublicPopup(false);
        removeLoader();
      });
  };

  const onCloseHtmlEditor = () => {
    setHtmlEditorPopup({
      visible: false,
      data: null,
      templateId: null
    });
  };

  const onSaveHtmlEditor = (htmlContent) => {
    if (htmlContent) {
      confirmLandingPageByTemplateId(
        {
          ...htmlEditorPopup.data,
          html: htmlContent
        },
        htmlEditorPopup.templateId
      );
    }
    setHtmlEditorPopup({
      visible: false,
      data: null,
      templateId: null
    });
  };

  const createTableFromScratch = () => {
    return fromScratchPopup ? (
      <Popup
        popupWindowStyles={{
          pointerEvents: 'auto'
        }}
        onClose={() => {
          setFromScratchPopup(false);
        }}
        className="p-h-m p-v-s"
      >
        <DKInputForm
          className="border-radius-s bg-white  w-full"
          direction="vertical"
          fields={[
            {
              key: 'name', // Change the key to 'name'
              placeholder: 'Enter table name',
              required: true,
              title: 'Table name',
              type: 'text',
              //   validator: () => {},
              value: ''
            }
          ]}
          onCancel={() => {
            setFromScratchPopup(false);
          }}
          onChange={(fieldValues) => {}}
          onSubmit={async (data) => {
            let applicationId =
              appInformation?.appId?.length > 0 ? appInformation.appId : appId;
            const apiData = {
              databaseName: 'test',
              name: data[0].value,
              appId: applicationId
            };

            try {
              const table = await httpClient.post(
                API_ENDPOINTS.TABLE.CREATE,
                apiData
              );
              if (appInformation.isPublishedApp) {
                let urlString = PAGE_ROUTES.TABLE_DETAILS.replace(
                  ROUTE_TABLE_DETAIL_ID_IDENTIFIER,
                  table.id
                );
                navigate(`/${urlString}`);
              } else {
                navigate(
                  PAGE_ROUTES.APP_ADMIN.replace(
                    ROUTE_APP_DETAIL_ID_IDENTIFIER,
                    applicationId
                  ).replace(ROUTE_TABLE_DETAIL_ID_IDENTIFIER, table.id)
                );
              }
            } catch (error) {
              console.error('Error while creating the application', error);
            }

            setFromScratchPopup(false);
          }}
          style={{}}
          title="New Table"
        />
      </Popup>
    ) : null;
  };

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div
        className="parent-width bg-white row justify-content-between pr-r p-v-s data-grid-bottom-border z-index-3 flex-shrink-0"
        style={{ borderColor: 'rgb(230, 230, 230)' }}
      >
        <div>
          {appInformation.isPublishedApp ? (
            <div className="row width-auto gap-1 ml-l">
              <span style={{ fontSize: 16 }}>👋</span> Welcome
              <b>{user?.fullName ? user?.fullName : ''}</b>
            </div>
          ) : (
            <DKButton
              icon={AppIcons.ic_arrow_left}
              title={'Goto admin dashboard'}
              onClick={() => {
                dispatch(addTables({}));
                navigate(PAGE_ROUTES.HOME);
              }}
            />
          )}
        </div>
        <div className="row width-auto position-relative">
          <div className="column position-relative">
            <DKButton
              icon={AppIcons.ic_add_table}
              title="New Table"
              className=" border-m mr-r"
              onClick={() => {
                setFromScratchPopup(true);
                // setShowCreateTableOptions(true);
              }}
            />
            {showCreateTableOptions && (
              <DKListPicker
                data={['From Scratch', 'Import with data']}
                onSelect={(index, value) => {
                  if (index === 0) {
                    setFromScratchPopup(true);
                  } else if (index === 1) {
                    if (appInformation.isPublishedApp) {
                      navigate(PAGE_ROUTES.IMPORT_PORTAL);
                    } else {
                      let applicationId =
                        appInformation?.appId?.length > 0
                          ? appInformation.appId
                          : appId;
                      navigate(
                        PAGE_ROUTES.IMPORT_PORTAL_ADMIN.replace(
                          ROUTE_APP_DETAIL_ID_IDENTIFIER,
                          applicationId
                        )
                      );
                    }
                  }
                  setShowCreateTableOptions(false);
                }}
                onClose={() => {
                  setShowCreateTableOptions(false);
                }}
                className="border-m shadow position-absolute"
                style={{
                  width: '140px',
                  top: 40
                }}
                icons={[]}
              />
            )}
          </div>
          {!app?.publishedTemplate && !isAppMarkedPublic && (
            <div
              className="row-reverse bg-white border-m mr-r border-radius-m"
              style={{ paddingRight: 8 }}
            >
              <DKTooltipWrapper
                content={
                  'Public apps will be available to all the users and they can use it as a template to create their own applications.'
                }
                tooltipClassName={'row'}
              >
                <DKIcon className="ic-s ml-s" src={AppIcons.ic_info} />
              </DKTooltipWrapper>
              <DKButton
                title="Mark as Public"
                style={{ paddingRight: 0 }}
                onClick={() => {
                  setShowMarkAsPublicPopup(true);
                }}
              />
            </div>
          )}
          {!appInformation.isPublishedApp && (
            <DKButton
              isReverse
              icon={AppIcons.white.ic_send}
              title={'Publish App'}
              className="bg-button text-white mr-r"
              onClick={() => {
                publishApp();
              }}
            />
          )}
          <UserButton />
        </div>
      </div>
      {showMarkAsPublicPopup && (
        <EditApplicationAndTemplate
          isAlreadyPublic={false}
          app={app}
          onSave={(appObj) => {
            markAsPublicClicked(appObj);
          }}
          onCancel={() => {
            setShowMarkAsPublicPopup(false);
          }}
        />
      )}
      {htmlEditorPopup.visible && htmlEditorPopup.data?.html && (
        <HtmlEditor
          htmlContent={htmlEditorPopup.data.html}
          onClose={onCloseHtmlEditor}
          onSave={onSaveHtmlEditor}
        />
      )}
      {createTableFromScratch()}
    </>
  );
};

export default TopMenu;
