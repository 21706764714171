import {
  DKButton,
  DKIcon,
  DKLabel,
  DKSidebar,
  DKSpinner
} from 'deskera-ui-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppIcons from '../../assets/icons/AppIcons';
import ic_no_data from '../../assets/icons/ic_no_data.png';
import { useAppDispatch } from '../../redux/hooks';
import { setAppInfo } from '../../redux/slices/CommonSlice';
import Utility from '../../utility/Utility';
import TopMenu from '../Application/TopMenu';
import DataGridHolder from '../common/DataGridHolder';

export default function AppPreview({ app, selectedTable, appCreated, appId }) {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageSize = 13;

  // Functions to handle mouse enter and leave
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const customHeaderRenderer = () => {
    return (
      <div
        style={{
          color: 'white',
          alignSelf: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
        }}
        className="row p-h-r pb-m  mb-s fs-m fw-m text-align-left"
      >
        {app.name}
      </div>
    );
  };

  const getMenuItems = () => {
    if (Utility.isEmptyObject(app.tables)) return [];
    let categories = {};
    let tables: any[] = Object.values(app.tables);
    for (let table of tables) {
      if (categories[table.category]) {
        categories[table.category].push(table);
      } else {
        categories[table.category] = [table];
      }
    }

    return Object.keys(categories).map((key: string) => ({
      name: key,
      onClick: () => {},
      subItems: categories[key].map((table: any) => ({
        className:
          table.tableName === selectedTable ? 'active-menu-button' : '',
        name: table.tableName,
        onClick: () => {}
      })),
      expandByDefault: true
    }));
  };

  const getNoDataView = () => {
    let noDataTitle = 'No data found';
    let noDataText = 'Once data is available, it will appear here';

    return (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute"
        style={{ top: '40%' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={noDataTitle} className="fw-m mt-l" />
        <DKLabel text={noDataText} className="text-gray mt-s" />
      </div>
    );
  };

  const getTableColumns = () => {
    if (app.tables?.[selectedTable]?.columns?.length) {
      return app.tables[selectedTable].columns?.map((col: any) => {
        return {
          name: col.name,
          id: col.name,
          key: col.name,
          columnCode: col.name,
          width: 180,
          type: col.type,
          options: col.options || []
        };
      });
    }
    return [];
  };

  const getRowData = () => {
    return app.records?.[selectedTable] || [];
  };

  const getGrid = () => {
    const width = window.innerWidth - 760;
    return (
      <DataGridHolder
        width={width}
        tableId={selectedTable}
        columnsData={[]}
        totalRecords={getRowData().length}
        columnData={[]}
        onContextCancel={() => {}}
        updating={false}
        currentPage={1}
        totalPageCount={Math.ceil(getRowData().length / pageSize)}
        onPageChange={() => {}}
        filterData={[]}
        filterOperator={'and'}
        onRowUpdate={() => {}}
        onColumnAdd={() => {}}
        onColumnShift={() => {}}
        onColumnUpdate={() => {}}
        onColumnFreezeToggle={() => {}}
        onSearch={() => {}}
        onFilter={() => {}}
        handleSort={() => {}}
        buttons={[]}
        columns={getTableColumns()}
        rows={getRowData().slice(0, pageSize)}
        onRowOpenClick={() => {}}
        title={selectedTable}
        onRowSelect={() => {}}
        allowAdvancedBulkEdit={false}
        onAdvancedBulkEdit={() => {}}
        refreshData={() => {}}
        isAllRowSelected={false}
        onAllRowSelect={() => {}}
        showContextMenu={false}
      />
    );
  };

  return (
    <div
      className="parent-width parent-height row align-items-start app-font app-bg-color border-radius-m relative"
      style={{ overflowY: 'hidden' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DKSidebar
        className="parent-height"
        menuItemList={getMenuItems()}
        collapsedWidth={0}
        expandedWidth={230}
        isMenuExpanded={true}
        onExpandCollapse={() => {}}
        customHeaderRenderer={customHeaderRenderer}
        allowTenantSelection={false}
      />

      <div className="column parent-width parent-height border-m rounded-r-lg">
        <TopMenu app={app} appId={'app-id'} />
        <div
          className={'p-h-xl pt-l overflow-y-auto parent-width parent-height'}
        >
          {selectedTable && (
            <div className="fade-in column parent-width parent-height overflow-x-auto">
              {getGrid()}
              {Utility.isEmptyObject(app.records?.[selectedTable]) &&
                getNoDataView()}
            </div>
          )}
        </div>
      </div>

      {isHovered && (
        <div className="fade-in column parent-width parent-height border-m border-radius-s align-items-center justify-content-center position-absolute bg-opacity-10 backdrop-brightness-50 bg-black/30 shadow-lg border-white border-opacity-20 z-index-6">
          {appCreated ? (
            <DKButton
              title="Open App"
              isReverse
              icon={AppIcons.white.ic_open}
              className="bg-button text-white"
              onClick={() => {
                dispatch(
                  setAppInfo({
                    appId: appId,
                    isPublishedApp: false
                  })
                );
                navigate(`/apps/${appId}`);
              }}
            />
          ) : (
            <DKSpinner title="Creating app..." isWhite={true} />
          )}
        </div>
      )}
    </div>
  );
}
