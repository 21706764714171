import { ChevronDown } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const StickySidebar = ({ data }) => {
  const [navbarHeight, setNavbarHeight] = useState(70)
  const [showDropdown, setShowDropdown] = useState(false)
  const [screenWidth, setScreenWidth] = useState(1024)

  useEffect(() => {
    const navHeight = document.getElementById('navbar')?.offsetHeight ?? 70
    setNavbarHeight(navHeight)
    setScreenWidth(window.innerWidth)

    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const sections = document.querySelectorAll('section[id]')
    const sideBarLinks = document.querySelectorAll('#sidebar-links a')

    const handleScroll = () => {
      const scrollPosition = window.scrollY + navbarHeight + 100
      let currentSectionId = null
      const sectionsArray = Array.from(sections).sort((a, b) => (a as HTMLElement).offsetTop - (b as HTMLElement).offsetTop)

      sideBarLinks.forEach(link => {
        link.classList.remove('text-orange-400', 'dark:text-orange-700')
      })

      for (let i = 0; i < sectionsArray.length; i++) {
        const section = sectionsArray[i];
        if (section &&
            (section as HTMLElement).offsetTop <= scrollPosition &&
            (section as HTMLElement).offsetTop + (section as HTMLElement).offsetHeight > scrollPosition
        ) {
          currentSectionId = section.id
          break
        }
      }

      if (currentSectionId) {
        const correspondingLink = document.querySelector(
          `#sidebar-links a[href*=${currentSectionId}]`
        )
        if (correspondingLink) {
          correspondingLink.classList.add('text-orange-400', 'dark:text-orange-700')
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [navbarHeight])

  const scrollToId = (id) => {
    const element = document.getElementById(id)
    if (element) {
      const yOffset = -navbarHeight - 20
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const SideBarItem = ({ item }) => (
    <a
      href={`#${item.url}`}
      className="text-xs text-stone-950/50 pr-2 no-underline hover:text-orange-700 dark:hover:text-orange-700"
      onClick={(e) => {
        e.preventDefault()
        scrollToId(item.url)
      }}
    >
      {item.name}
    </a>
  )

  return (
    <div className="relative flex h-auto w-fit text-start flex-col lg:min-w-[300px] lg:max-w-[345px] lg:flex-row lg:items-stretch">
      <button
        type="button"
        aria-label="Toggle table of contents"
        className="flex h-full items-center justify-start lg:hidden"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div className={`mr-1 transition-transform ${showDropdown ? 'rotate-0' : '-rotate-90'}`}>
            <ChevronDown size={16}/>
        </div>
        <p className="ml-3 font-semibold">
          TABLE OF CONTENTS
        </p>
      </button>
      
      <div
        className={`relative w-full lg:sticky lg:block ${!showDropdown ? 'hidden' : 'block'}`}
        style={{
          top: screenWidth < 1024 ? 0 : navbarHeight + 10,
          maxHeight: screenWidth < 1024 ? '100%' : `calc(100vh - ${navbarHeight + 10}px)`,
        }}
      >
        <div className="overflow-auto h-full w-full custom-scrollbar">
          <div id="sidebar-links" className="prose prose-stone flex flex-col whitespace-normal">
            <ul className="list-disc pl-6 space-y-2">
              {data.map((item, index) => (
                <li key={index}>
                  <SideBarItem item={item} />
                  {item.subContent && (
                    <ul className="list-circle mt-2">
                      {item.subContent.map((subItem, subIndex) => (
                        <li key={subIndex} >
                          <SideBarItem item={subItem} />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StickySidebar;