import { configureStore } from '@reduxjs/toolkit';
import tenantReducer from './slices/tenantSlice';
import applicationReducer from './slices/applicationSlice';
import importReducer from './slices/importSlice';
import formBuilderSlice from './slices/formBuilderSlice';
import refreshTriggerReducer from './slices/refreshTriggerSlice';
import authSlice from './slices/authSlice';
import commonReducers from './slices/CommonSlice';
import tableReducer from './slices/tableSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    applications: applicationReducer,
    import: importReducer,
    formBuilder: formBuilderSlice,
    table: tableReducer,
    tenant: tenantReducer,
    refreshTrigger: refreshTriggerReducer,
    common: commonReducers
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
