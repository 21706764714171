import { FunctionComponent, Fragment, useState, useEffect } from 'react';
import {
  DKSpinner,
  DKIcon,
  Toggle,
  DKLine,
  showAlert,
  DKLabel,
  DKButton
} from 'deskera-ui-library';
import '../../styles/automation.scss';
import AutomationCanvas from './Canvas';
import AutomationManager, {
  COLUMN_CODE
} from '../../managers/AutomationManager';
import Utility from '../../utility/Utility';
import {
  AUTOMATION_ACTIONS,
  AUTOMATION_ELEMENT,
  AUTOMATION_TRIGGERS,
  INITIAL_MAGNIFICATION_SCALE,
  INTERMEDIATE_CHILD_ACTION_TYPES,
  MAGNIFICATION_SCALE_UNIT,
  MIN_MAGNIFICATION_SCALE,
  OBJECT_TYPES,
  STATUS_OPTIONS
} from '../../constants/Automation';
import AddTriggerPopup from './Trigger';
import FieldMapper from './FieldMapper';
import AddActionPopup from './Action';
import { useAppSelector } from '../../redux/hooks';
import WaitEditor from './WaitEditor';
import SaveAutomation from './SaveAutomation';
import { addRecord, updateRecord } from '../../services/table';
import AppIcons from '../../assets/icons/AppIcons';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import TriggerNode from '../../components/automation-ui/TriggerNode';
import { selectTables } from '../../redux/slices/tableSlice';

interface IAutomationProps {
  onSave: (response) => void;
  onClose: () => void;
}

const Automation: FunctionComponent<IAutomationProps> = ({
  onClose,
  onSave
}) => {
  const showLoader = false;
  const [steps, setSteps] = useState(AutomationManager.getAllSteps());
  const [adjacencyMatrix, setAdjacencyMatrix] = useState(
    AutomationManager.getAdjacencyMatrix()
  );
  const [metaData, setMetaData] = useState({
    stepIndexToUpdate: null,
    stepIndexToAdd: null,
    parentStepId: null,
    parentStepType: null,
    stepData: null,
    triggerType: null,
    currentSelectedStepData: null,
    splitStepIndex: null,
    showOnlyIntermediateSteps: null
  });
  const [magnifyScale, magnify] = useState<number>(INITIAL_MAGNIFICATION_SCALE);
  const [apiInProgress, setAPIInProgress] = useState<boolean>(false);
  const [popupVisibility, setPopupVisibility] = useState({
    showTriggerPopup: false,
    showAddActionPopup: false,
    showWaitEditor: false,
    showSaveAutomation: false,
    showFieldMapperPopup: false
  });
  const tables = useAppSelector(selectTables());
  const tablesArray = Object.values(tables ?? {})?.filter(
    (table: any) => table.objectType !== OBJECT_TYPES.AUTOMATION
  );
  const [isAutomationEnabled, setIsEnabled] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      AutomationManager.resetAllData();
      AutomationManager.setData(null);
    };
  }, []);

  useEffect(() => {
    if (AutomationManager.isDataAvailable()) {
      const { data: { cells = {} } = {} } = AutomationManager.getData();
      const [status] = cells[
        AutomationManager.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)
      ];
      setIsEnabled(status === STATUS_OPTIONS.ACTIVE[0]);
    }
  }, [tables]);

  const onChangeMagnifierLevel = (changeBy = MAGNIFICATION_SCALE_UNIT) => {
    if (magnifyScale + changeBy <= MIN_MAGNIFICATION_SCALE) return;

    magnify((prevState) => prevState + changeBy);
  };

  const renderWaitEditor = () => {
    return (
      <WaitEditor
        stepData={metaData.currentSelectedStepData}
        onClose={() => {
          setPopupVisibility((pre) => ({ ...pre, showWaitEditor: false }));
        }}
        onSave={(data) => {
          const metaDataCopy = {
            ...metaData,
            currentSelectedStepData: {
              ...metaData.currentSelectedStepData,
              configuration: data
            }
          };
          setMetaData(metaDataCopy);
          saveStep(metaDataCopy);
          setPopupVisibility((pre) => ({ ...pre, showWaitEditor: false }));
        }}
      />
    );
  };
  const renderHeader = () => {
    const isEditMode = AutomationManager.isDataAvailable();
    return (
      <div className="row p-m shadow-s-2 bg-white justify-content-between">
        <div className="row width-auto">
          <DKLabel
            text={AutomationManager.getAutomationName()}
            className="fw-m"
          />
        </div>

        <div className="row width-auto gap-2">
          <DKButton
            title="Cancel"
            className="border-m"
            onClick={onClose}
            disabled={apiInProgress}
          />

          <DKButton
            disabled={apiInProgress}
            icon={AppIcons.white.ic_save}
            title="Save"
            className="border-m bg-button text-white"
            onClick={onSaveAutomation}
          />

          {isEditMode && (
            <div className="row width-auto p-v-xs p-h-r border-m border-radius-m">
              {<DKLabel text="Enabled" className="mr-r" />}
              <Toggle
                style={{ top: 1 }}
                isOn={isAutomationEnabled}
                color="bg-crm"
                onChange={() => {
                  setIsEnabled((prev) => !prev);
                }}
              />
            </div>
          )}
          {/* {isEditMode && (
            <DKButton icon={AppIcons.red.ic_delete} className="border-red" />
          )} */}
        </div>
      </div>
    );
  };

  const renderMagnifier = () => {
    if (Utility.isEmptyObject(steps)) return null;

    return (
      <div
        className="column bg-gray1 border-radius-s shadow-s position-absolute"
        style={{
          right: 16,
          bottom: 16
        }}
      >
        <DKButton
          title={'+'}
          className="justify-content-center fw-m "
          style={{
            width: '100%'
          }}
          onClick={() => onChangeMagnifierLevel(0.05)}
        />
        <DKLine
          style={{
            width: '100%',
            height: 4
          }}
        />
        <DKButton
          title={'-'}
          className="justify-content-center fw-m "
          style={{
            width: '100%'
          }}
          onClick={() => onChangeMagnifierLevel(-0.05)}
        />
      </div>
    );
  };

  const renderAddActionPopup = () => {
    return (
      <AddActionPopup
        triggerType={metaData.triggerType}
        parentStepType={metaData.parentStepType}
        showOnlyIntermediateSteps={metaData.showOnlyIntermediateSteps}
        onClose={() => {
          setPopupVisibility((pre) => ({ ...pre, showAddActionPopup: false }));
        }}
        onSelect={(actionData) => {
          const metaDataCopy = {
            ...metaData,
            currentSelectedStepData: actionData
          };
          setMetaData(metaDataCopy);
          if (actionData.type === AUTOMATION_ACTIONS.END_WORKFLOW) {
            saveStep(metaDataCopy);
          } else if (actionData.type === AUTOMATION_ACTIONS.WAIT_FOR_TIME) {
            setPopupVisibility((pre) => ({
              ...pre,
              showAddActionPopup: false,
              showWaitEditor: true
            }));
          } else {
            setPopupVisibility((pre) => ({
              ...pre,
              showAddActionPopup: false,
              showFieldMapperPopup: true
            }));
          }
        }}
      />
    );
  };

  const renderStartTriggerButton = () => {
    return (
      <TriggerNode
        onClick={() => {
          setPopupVisibility((prev) => ({ ...prev, showTriggerPopup: true }));
        }}
      />
    );
  };

  const renderAddTriggerPopup = () => {
    return (
      <AddTriggerPopup
        onClose={() => {
          setPopupVisibility((prev) => ({
            ...prev,
            showTriggerPopup: false
          }));
        }}
        onSelect={(triggerData) => {
          let newTriggerData = { ...triggerData };

          setMetaData((pre) => ({
            ...pre,
            triggerType: newTriggerData.type,
            currentSelectedStepData: newTriggerData
          }));

          setPopupVisibility((prev) => ({
            ...prev,
            showFieldMapperPopup: true
          }));
        }}
      />
    );
  };

  const renderFieldMapperPopup = () => {
    return (
      <FieldMapper
        tables={tablesArray}
        onSave={onFieldMapperSave}
        onClose={() => {
          setPopupVisibility((pre) => ({
            ...pre,
            showFieldMapperPopup: false
          }));
        }}
        stepData={metaData.currentSelectedStepData}
      />
    );
  };

  const renderSavePopup = () => {
    return (
      <SaveAutomation
        title="New automation"
        header="Automation Name"
        headerIcon="📝 "
        subHeader="Enter a name to help you remember what this automation is all about."
        onCancel={() => {
          setAPIInProgress(false);
          setPopupVisibility((pre) => ({ ...pre, showSaveAutomation: false }));
        }}
        onSave={saveAutomation}
        allowDomainSelection={false}
      />
    );
  };

  const saveAutomation = async ({ title: automationName }) => {
    try {
      let json_data = Utility.encodeJSON({
        steps: AutomationManager.steps,
        adjacencyMatrix: AutomationManager.adjacencyMatrix
      });

      let data = {
        json: json_data,
        name: automationName
      };

      const automationTable: any = Object.values(tables)?.find(
        (table: any) => table.objectType === OBJECT_TYPES.AUTOMATION
      );
      AutomationManager.automationColumns =
        automationTable?.columnsMetaData ?? [];

      const payload = AutomationManager.isDataAvailable()
        ? AutomationManager.getRequestToUpdate(data)
        : AutomationManager.getRequestToSave(data);
      payload[AutomationManager.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] = [
        isAutomationEnabled ? 1 : 2
      ];
      let response = null;
      if (AutomationManager.isDataAvailable()) {
        response = await updateRecord(automationTable._id, {
          cells: payload,
          _id: AutomationManager.getRecordId()
        });
      } else {
        response = await addRecord(automationTable._id, { cells: payload });
      }

      if (response) {
        setAPIInProgress(false);
        onSave(response);
      }
    } catch (error) {
      setAPIInProgress(false);
      console.error(error);
      showAlert('Error', 'Error occurred while saving automation!');
    } finally {
      setAPIInProgress(false);
    }
  };

  const onSaveAutomation = () => {
    setAPIInProgress(true);
    AutomationManager.setupSplitActionConfigurations();
    if (AutomationManager.isDataAvailable()) {
      saveAutomation({ title: AutomationManager.getAutomationName() });
    } else {
      setPopupVisibility((pre) => ({ ...pre, showSaveAutomation: true }));
    }
  };

  const onReset = () => {
    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m'
      },
      {
        title: 'Reset',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          AutomationManager.resetAllData();
          setSteps([]);
          setAdjacencyMatrix({});
          magnify(1);
          setMetaData({
            stepIndexToUpdate: null,
            stepIndexToAdd: null,
            parentStepId: null,
            parentStepType: null,
            stepData: null,
            triggerType: null,
            currentSelectedStepData: null,
            splitStepIndex: null,
            showOnlyIntermediateSteps: null
          });
        }
      }
    ];

    showAlert(
      'Reset all actions?',
      'Deleting this actions will delete it permanently. This automation will be reset.',
      buttons
    );
  };

  const onMetaDataChange = (data) => {
    const { currentSelectedStepData } = data;
    if (data.stepIndexToAdd !== null) {
      setPopupVisibility((pre) => ({ ...pre, showAddActionPopup: true }));
    } else {
      if (currentSelectedStepData.elementType === AUTOMATION_ELEMENT.ACTION) {
        if (currentSelectedStepData.type === AUTOMATION_ACTIONS.END_WORKFLOW) {
          setPopupVisibility((pre) => ({
            ...pre,
            showAddActionPopup: true,
            showFieldMapperPopup: false
          }));
        } else if (
          currentSelectedStepData.type === AUTOMATION_ACTIONS.WAIT_FOR_TIME
        ) {
          setPopupVisibility((pre) => ({
            ...pre,
            showAddActionPopup: false,
            showWaitEditor: true
          }));
        } else {
          setPopupVisibility((pre) => ({
            ...pre,
            showAddActionPopup: false,
            showFieldMapperPopup: true
          }));
        }
      } else {
        if (
          Object.values(AUTOMATION_TRIGGERS).includes(
            currentSelectedStepData.type as AUTOMATION_TRIGGERS
          )
        ) {
          setPopupVisibility((pre) => ({
            ...pre,
            showFieldMapperPopup: true
          }));
        }
      }
    }
    setMetaData((prev) => ({ ...prev, ...data }));
  };

  const onFieldMapperSave = (fieldsData, logicalOperator, table) => {
    const metaDataCopy = {
      ...metaData,
      currentSelectedStepData: {
        ...metaData.currentSelectedStepData,
        configuration: { fields: fieldsData, tableId: table._id },
        logicalOperator: logicalOperator
      }
    };
    setMetaData(metaDataCopy);
    saveStep(metaDataCopy);
    setPopupVisibility((pre) => ({
      ...pre,
      showTriggerPopup: false,
      showFieldMapperPopup: false
    }));
  };

  const saveStep = (data, isNewWaitNodeRequired?: boolean) => {
    if (
      data.stepIndexToUpdate !== null &&
      data.stepIndexToUpdate !== undefined
    ) {
      updateStep(data);
    } else {
      addNewStep(data, isNewWaitNodeRequired);
    }
    setMetaData((pre) => ({
      ...pre,
      currentSelectedStepData: null,
      stepIndexToAdd: null,
      stepIndexToUpdate: null
    }));
    setSteps(AutomationManager.getAllSteps());

    setAdjacencyMatrix(AutomationManager.getAdjacencyMatrix());
    setPopupVisibility((pre) => ({
      ...pre,
      showAddActionPopup: false,
      showTriggerPopup: false
    }));
  };

  const addNewStep = (data, isNewWaitNodeRequired?: boolean) => {
    AutomationManager.addNewStep(
      data.currentSelectedStepData,
      data.parentStepId,
      data.stepIndexToAdd,
      data.splitStepIndex,
      isNewWaitNodeRequired
    );
  };

  const updateStep = (data) => {
    AutomationManager.updateStep(
      data.currentSelectedStepData,
      data.stepIndexToUpdate
    );
  };

  const onDeleteStep = ({
    stepIndex,
    parentId,
    stepData,
    directlyLinkedNodeData
  }) => {
    const directlyLinkedNodeType = directlyLinkedNodeData?.type;

    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m'
      },
      {
        title: 'Delete',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          const isIntermediateStep = INTERMEDIATE_CHILD_ACTION_TYPES.includes(
            stepData.type as AUTOMATION_ACTIONS
          );

          if (isIntermediateStep) {
            /* Allowing to delete intermediate steps independently */
            AutomationManager.deleteStep({
              stepIndex: stepIndex,
              parentId: parentId
            });
          } else {
            /* Deleting any directly linked nodes if any along with current step */
            AutomationManager.deleteStep({
              stepIndex: stepIndex,
              parentId: parentId,
              hasLinkedStep: !Utility.isEmptyObject(directlyLinkedNodeType),
              linkedStepType: directlyLinkedNodeType
            });
          }
          setMetaData((pre) => ({
            ...pre,
            currentSelectedStepData: null,
            stepIndexToAdd: null,
            stepIndexToUpdate: null
          }));
          setSteps(AutomationManager.getAllSteps());
          setAdjacencyMatrix(AutomationManager.getAdjacencyMatrix());
        }
      }
    ];

    showAlert(
      'Delete Action?',
      `Deleting this action will delete it permanently. You can add more actions later.`,
      buttons
    );
  };
  if (showLoader) {
    return (
      <div className="row parent-size justify-content-center align-items-center">
        <DKSpinner title="Loading Data..." />
      </div>
    );
  }
  return (
    <Fragment>
      <div className="parent-size  position-relative overflow-hidden">
        {renderHeader()}
        <div className="parent-height parent-width column align-items-center">
          {steps.length === 0 && renderStartTriggerButton()}
          {popupVisibility.showAddActionPopup && renderAddActionPopup()}
          {popupVisibility.showWaitEditor && renderWaitEditor()}
          <AutomationCanvas
            jsonData={AutomationManager.uiJson}
            tables={tablesArray}
            magnifyScale={magnifyScale}
            adjacencyMatrix={adjacencyMatrix}
            steps={steps}
            onMetaDataChange={onMetaDataChange}
            onDeleteStep={onDeleteStep}
          />
        </div>
        {/* {renderMagnifier()} */}
      </div>
      {popupVisibility.showTriggerPopup && renderAddTriggerPopup()}
      {popupVisibility.showFieldMapperPopup && renderFieldMapperPopup()}
      {popupVisibility.showSaveAutomation && renderSavePopup()}
    </Fragment>
  );
};

export default Automation;

export const showAutomationPopup = ({ onSave, onClose }) => {
  const id = `automation-popup-${new Date().getTime()}`;
  let div = document.createElement('div');
  div.className = 'app-font';
  div.setAttribute('id', id);
  const root = createRoot(document.body.appendChild(div));
  const onCloseClicked = () => {
    root.unmount();
    onClose?.();
  };
  const onSaveClicked = (response) => {
    onSave?.(response);
    root.unmount();
  };
  root.render(
    <Provider store={store}>
      <div className="transparent-background">
        <div
          className="popup-window dotted-background"
          style={{
            height: '90vh',
            width: '90vw',
            maxWidth: 1300,
            maxHeight: 800,
            padding: 0
          }}
        >
          <Automation onClose={onCloseClicked} onSave={onSaveClicked} />
        </div>
      </div>
    </Provider>
  );
};
