import { LIST_MODULE } from '../../constants/Enum';
import ApplicationList from './ApplicationsList';

export default function Applications(props: any) {
  return (
    <div className="column parent-width">
      <ApplicationList listModule={LIST_MODULE.APPLICATIONS} />
    </div>
  );
}
