// applicationSlice.ts

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface AppInfo {
  appId?: string;
  isPublishedApp?: boolean;
}

interface CommonState {
  appInfo: AppInfo;
}

const initialState: CommonState = {
  appInfo: {}
};

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setAppInfo(state, action) {
      state.appInfo = action.payload;
    }
  },
  extraReducers: (builder) => {}
});

export const { setAppInfo } = CommonSlice.actions;

export const selectAppInfo = (state: RootState) => state.common.appInfo;

export default CommonSlice.reducer;
