import { INPUT_TYPE } from 'deskera-ui-library';
export const CUSTOM_INPUT_TYPE = {
  ...INPUT_TYPE,
  REF: 'ref',
  ATTACHMENT: 'attachment',
  TABLE: 'lines_ref',
  LONG_TEXT2: 'long_text'
};
export const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
export const COLUMN_DETAIL_SUFFIX = '_detail';
export const CLERK_TOKEN_REFETCH_DELAY = 60000;

export const REGEX = {
  WEBSITE_DOMAIN:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  SLUG_URL: /^[a-zA-Z\d-_]+$/,
  EMAIL_PATTERN:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  IS_NUMBER_A_PERCENT: /^(\d+)(\.(\d+))?(%)?$/,
  GST_IN_REGEX_PATTERN:
    /^([0][1-9]|[1-2][0-9]|[3][0-8])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
  HEX_COLOR: /^#([0-9a-f]{3}){1,2}$/i,
  WHATSAPP: /^((\D+)?0+)|\D/g,
  ALPHANUMERIC_WITH_SPACE_IN_BETWEEN: /[^A-Za-z0-9 ]+/,
  SPACES: / +/,
  HYPHEN: '-',
  MOBILE_REGEXP_VALIDATION:
    /^(\+(([0-9]){1,3})(-|.))?((((([0-9]){2,3})(-|.)){1,2}([0-9]{4,10}))|([0-9]{10}))$/,
  REMOVE_PATTERN_NUMBER_WITH_BRACKET: /\(([^)]+)\)/g,
  WA_TEMPLATE_PLACEHOLDER: /{{[0-9a-zA-Z]}}/gm,
  YOUTUBE_VIDEO:
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
  TEXT_TO_LINK:
    /(\b(((https?|ftp|file):)\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
  HTML_PATTERN: /<[^>]*>/g,
  SANITIZE_HTML_CHAR: /[&<>"'`=\/]/g,
  PERCENT_NUMBER: /^(\d+)(\.(\d+))?(%)?$/,
  DDMMYYYY_DATE: /\d{2}-\d{2}-\d{4}/
};

export const WORK_FLOW_APP_CODE = 'erpai_app_builder';
