import React, { useState, useEffect } from 'react';

import { DKLabel, DKButton } from 'deskera-ui-library';

import PromptSection from './PromptSection';
import ThemePopup from './ThemePopup';

import {
  removeHtmlTagsAndNormalizeSpaces,
  replaceStringInBrackets,
  changeTheme,
  sanitizeHTML,
  isNotEmptyObject
} from '../../utility/Utility';

import { getDropdown } from './PromptEditorDropdown';
import AppIcons from '../../assets/icons/AppIcons';
import { LOCAL_STORAGE_KEYS } from '../../constants/Enum';

const PromptEditor = ({ onSubmit, apiResponseReceived, onBrowse }) => {
  const getDefaultThemeColor = () => {
    return 'Chocolate';
  };

  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [themeColor, setThemeColor] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_THEME) ??
      getDefaultThemeColor()
  );
  const [needThemePopup, setThemePopupVisibility] = useState(false);

  let editor = null;

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    changeTheme('rgb(29, 29, 31)');
    checkForComingFromSignInSingUp();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [apiResponseReceived]);

  useEffect(() => {
    const placeholders = document.getElementsByClassName('editor-placeholder');
    for (let i = 0; i < placeholders.length; i++) {
      placeholders[i].onclick = function () {
        const div_dropdown = getDropdown(placeholders[i]);
        if (div_dropdown) {
          document.getElementById('root').appendChild(div_dropdown);
        }
      };
    }
  }, [prompt]);

  useEffect(() => {
    adjustEditorOuterHeight();
    moveCursorToEnd(getEditor());
  }, [prompt]);

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  function handleClickOutside(event) {
    const dropdown = document.getElementById('prompt-editor-dropdown');
    if (dropdown && !dropdown.contains(event.target)) {
      dropdown.remove();
    }
  }

  /**
   * @description- this function will check if user prompted something before singIn/singUp and will repopulate that prompt, and create app from that
   */
  const checkForComingFromSignInSingUp = () => {};
  if (
    isNotEmptyObject(
      localStorage.getItem(LOCAL_STORAGE_KEYS.SELECT_PROMPT_FROM_STORAGE)
    )
  ) {
    const customPrompts =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROMPT_MESSAGES)) ||
      [];
    if (isNotEmptyObject(customPrompts)) {
      const [firstPrompt] = customPrompts;
      let finalPrompt = replaceStringInBrackets(firstPrompt?.prompt ?? '');
      setPrompt(finalPrompt);
      setLoading(true);
      finalPrompt = removeHtmlTagsAndNormalizeSpaces(finalPrompt);
      onSubmit(finalPrompt, themeColor);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECT_PROMPT_FROM_STORAGE);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_THEME);
    }
  }

  const adjustEditorOuterHeight = () => {
    const outerBox = document.getElementById('id-outer-editir-box');
    const innerBox = getEditor();
    // Set the outer box height to match the inner box height
    outerBox.style.height = `${
      innerBox.offsetHeight + editorStyle.padding * 2 + 25
    }px`;
  };

  const moveCursorToEnd = (contentEle) => {
    const range = document.createRange(); // Create a new Range object
    const selection = window.getSelection(); // Get the current selection

    // Set the start of the range to be at the end of contentEle
    range.setStart(contentEle, contentEle.childNodes.length);
    range.collapse(true); // Collapse to make it a single point

    selection.removeAllRanges(); // Clear any existing selections
    selection.addRange(range); // Add the new range
  };

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  const editorStyle = {
    padding: 18,
    width: 710,
    height: 90
  };

  const getEditor = () => {
    if (editor == null) {
      editor = document.getElementById('id-prompt-editor');
    }

    return editor;
  };

  const getEditorValue = () => {
    return sanitizeHTML(getEditor().innerHTML);
  };

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  const renderPlaceholder = () => {
    return (
      <div
        contentEditable="false"
        enabled="false"
        className="text-gray position-absolute fs-r text-align-left"
        style={{
          top: editorStyle.padding - 2,
          left: editorStyle.padding
        }}
      >
        Build restaurant app which facilitate efficient management of restaurant
        operations, covering menus, reservations, orders and customer feedback.
      </div>
    );
  };

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  const renderInputSection = () => {
    return (
      <div className="position-relative parent-width sm:mt-l">
        <div
          id="id-outer-editir-box"
          className="position-relative bg-button mt-r border-radius-r border-s prompt-editor-shadow text-white "
          style={{
            width: '90vw',
            maxWidth: editorStyle.width,
            minHeight: editorStyle.height
          }}
        >
          {!prompt && renderPlaceholder()}
          {/* DOMPurify.sanitize( */}
          <div
            id="id-prompt-editor"
            contentEditable="true"
            className="position-absolute fs-r text-align-left "
            dangerouslySetInnerHTML={{ __html: prompt }}
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              width: '90vw',
              maxWidth: editorStyle.width - editorStyle.padding * 2,
              minHeight: editorStyle.height - editorStyle.padding * 2,
              top: editorStyle.padding - 2,
              left: editorStyle.padding,
              maxHeight: 200,
              overflow: 'auto'
            }}
            onKeyUp={(e) => {
              console.log('getEditorValue() -- ', getEditorValue());
              if (getEditorValue() === '' || getEditorValue() === '<br>') {
                setPrompt('');
              } else if (prompt === '') {
                setPrompt(getEditorValue());
              }
              adjustEditorOuterHeight();
            }}
          />
        </div>

        {renderSubmitButton()}
      </div>
    );
  };

  const renderThemeSection = () => {
    return (
      <div className="position-relative">
        <div
          className="row width-auto position-absolute cursor-hand "
          style={{ top: -8, right: 0 }}
          onClick={() => {
            setThemePopupVisibility(!needThemePopup);
          }}
        >
          <DKLabel
            text="Theme"
            style={{ right: 20, top: -8 }}
            className="text-gray"
          />
          <div
            className="border-radius-s ml-r"
            style={{
              height: 15,
              width: 15,
              backgroundColor: themeColor
            }}
          />
        </div>

        {needThemePopup && (
          <ThemePopup
            className="position-absolute "
            style={{ top: -5, right: 20 }}
            selectedColor={themeColor}
            onSelect={(color) => {
              setThemeColor(color);
              localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_THEME, color);
              setThemePopupVisibility(false);
            }}
            onClose={() => {
              setThemePopupVisibility(false);
            }}
          />
        )}
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div
        className="row position-absolute width-auto align-items-center"
        style={{ right: editorStyle.padding, bottom: editorStyle.padding }}
      >
        {renderThemeSection()}
        {/* <DKButton
          title={'Upload'}
          icon={AppIcons.ic_link}
          className={'  ml-m border-m '}
          style={{
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: 8,
            paddingRight: 8,
            borderColor: 'gray'
          }}
          disabled={loading}
          onClick={() => {
            showAlert(
              'Upload document - WIP',
              'This feature is currently WIP. Here you will be able to upload content/document as a prompt to build an application.'
            );
            // onBrowse();
          }}
        /> */}
        <DKButton
          title={loading ? 'Loading..' : 'Create'}
          icon={loading ? null : AppIcons.white.ic_send}
          isReverse
          className={
            '  ml-l  text-white ' + (!prompt || loading ? 'opacity-5' : '')
          }
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: 'rgb(60, 60, 60)'
          }}
          disabled={!prompt || loading}
          onClick={() => {
            if (!prompt) {
              return;
            }
            setLoading(true);
            const finalPrompt = removeHtmlTagsAndNormalizeSpaces(
              getEditorValue()
            );
            onSubmit(finalPrompt, themeColor);
          }}
        />
      </div>
    );
  };

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div>{renderInputSection()}</div>
      <PromptSection
        onApply={(data) => {
          setPrompt(
            getEditorValue() + replaceStringInBrackets(data.prompt) + ' '
          );
        }}
      />
    </>
  );
};

export default PromptEditor;
