import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IForm } from '../../model/Form';
import { getFormsByApp } from '../../services/form';

interface IFormBuilderState {
  forms: {
    [key: string]: IForm[];
  };
}
const initialState: IFormBuilderState = {
  forms: {}
};
export const fetchAllForms = createAsyncThunk(
  'fetchAllForms',
  async ({ appId }: any, thunkAPI) => {
    const response = await getFormsByApp({ appId });
    return response;
  }
);

const formBuilderState = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    addForms: (state, action: PayloadAction<any>) => {
      const { appId, forms } = action.payload;
      state.forms[appId] = forms;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllForms.fulfilled, (state, action) => {})
      .addCase(fetchAllForms.rejected, (state, action) => {});
  }
});
const selfSelector = (state: RootState) => state?.formBuilder;
export const selectFormByAppId = (appId) =>
  createSelector(
    selfSelector,
    (state: IFormBuilderState) => state.forms[appId] ?? []
  );

export const { addForms } = formBuilderState.actions;
export default formBuilderState.reducer;
