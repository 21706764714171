import { DKLabel, DKLine } from 'deskera-ui-library';
import { CUSTOM_INPUT_TYPE } from '../../constants/Constants';
import TextField from '../../components/common/TextFields';
import { ICompanySettings } from '../../model/Settings';
interface ICompanySettingsProps {
  settings?: ICompanySettings;
}
const COMPANY_SIZE = ['1-10', '1-100', '1-1000', '1-10000'];

const COUNTRY_ORIGIN = [
  'India',
  'USA',
  'Australia',
  'New Zealand',
  'Singapore',
  'Canada'
];

const TAX_CYCLE = ['Monthly', 'Quarterly', 'Yearly'];
const CURRENCIES = ['INR', 'SGD', 'USD', 'CND'];
export default function CompanySettings(props: ICompanySettingsProps) {
  const {
    baseCurrency,
    companyAddress,
    companyName,
    companySize,
    countryOfOrigin,
    financialYearStartDate,
    taxCycle
  } = props.settings;
  const getHeader = () => {
    return (
      <div className="column parent-width">
        <DKLabel className="fs-xl fw-m" text="Company Setting" />
        <DKLabel
          className=""
          text="You can delete company or delete account or reset your organization here"
        />
      </div>
    );
  };

  return (
    <div className="column parent-width">
      {getHeader()}
      <DKLine className="mt-l mb-l" />
      <div className="column parent-width gap-4">
        <TextField
          readOnly
          title="Company Name"
          placeHolder="Company Name"
          type={CUSTOM_INPUT_TYPE.TEXT}
          onChange={(text: any) => {}}
          value={companyName}
          options={[]}
        />
        <TextField
          readOnly
          title="Company Size"
          placeHolder="1-10"
          type={CUSTOM_INPUT_TYPE.SELECT}
          onChange={(text: any) => {}}
          value={[COMPANY_SIZE.findIndex((c) => c === companySize)]}
          options={COMPANY_SIZE}
        />
        <TextField
          readOnly
          title="Country Of Origin"
          placeHolder="Country Of Origin"
          type={CUSTOM_INPUT_TYPE.SELECT}
          onChange={(text: any) => {}}
          value={[COUNTRY_ORIGIN.findIndex((c) => c === countryOfOrigin)]}
          options={COUNTRY_ORIGIN}
        />
        <TextField
          readOnly
          title="Tax Cycle"
          placeHolder="Monthly"
          type={CUSTOM_INPUT_TYPE.SELECT}
          onChange={(text: any) => {}}
          value={[TAX_CYCLE.findIndex((c) => c === taxCycle)]}
          options={TAX_CYCLE}
        />
        <TextField
          readOnly
          title="Base Currency"
          placeHolder="INR"
          type={CUSTOM_INPUT_TYPE.SELECT}
          onChange={(text: any) => {}}
          value={[CURRENCIES.findIndex((c) => c === baseCurrency)]}
          options={CURRENCIES}
        />
        <TextField
          title="Financial Year Start Date"
          placeHolder="Financial Year Start Date"
          type={CUSTOM_INPUT_TYPE.DATE}
          onChange={(text: any) => {}}
          readOnly
          value={
            financialYearStartDate
              ? new Date(financialYearStartDate)
              : new Date()
          }
          options={[]}
        />
        <TextField
          readOnly
          title="Company Address"
          placeHolder="Company Address"
          type={CUSTOM_INPUT_TYPE.TEXT}
          onChange={(text: any) => {}}
          value={companyAddress}
          options={[]}
        />
      </div>
    </div>
  );
}
