import { FunctionComponent } from 'react';
import AppManager from '../managers/AppManager';
import TopMenu from '../components/menu/TopMenu';
import Home from '../components/AdminPanel/Home';

interface IMainProps {}

const Main: FunctionComponent<IMainProps> = () => {
  return (
    <div
      className="main-holder row align-items-start app-font app-bg-color"
      style={{ overflowY: 'hidden' }}
    >
      <div className="parent-width parent-height column flex-1">
        {!AppManager.isPublicURL() && !AppManager.isBookADemoURL() && (
          <TopMenu />
        )}

        <div className="parent-width">
          <Home />
        </div>
      </div>
    </div>
  );
};

export default Main;
