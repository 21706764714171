import ic_home from './ic_home.png';
import ic_app from './ic_app.png';
import ic_deployment from './ic_deployment.png';
import ic_table from './ic_table.png';
import ic_items from './ic_items.png';
import ic_report from './ic_report.png';
import ic_automation from './ic_automation.png';
import ic_doc from './ic_doc 2.png';
import ic_page from './ic_page.png';
import ic_search from './ic_search.png';
import ic_sort from './ic_sort.png';
import ic_more from './ic_more.png';
import ic_link from './ic_link.png';
import ic_web from './ic_web.png';
import ic_grid from './ic_grid.png';
import ic_list from './ic_list.png';
import ic_arrow_right from './ic_arrow_right.png';
import ic_arrow_left from './ic_arrow_left.png';
import ic_arrow_down from './ic_arrow_down.png';
import ic_close from './ic_close.png';
import ic_delete from './ic_delete.png';
import ic_edit from './ic_edit.png';
import ic_copy from './ic_copy.png';
import ic_save from './ic_save.png';
import ic_info from './ic_info.png';
import ic_settings from './ic_settings.png';
import ic_profile2 from './ic_profile2.png';
import ic_align_center from './ic_align_center.png';
import ic_align_left from './ic_align_left.png';
import ic_align_right from './ic_align_right.png';
import ic_arrow_right_long from './ic_arrow_right_long.png';
import ic_open from './ic_open.png';
import ic_success from './ic_success.png';
import ic_apply from './ic_apply.png';
import ic_plus from './ic_plus.png';
import ic_add_action from './ic_add_action.png';
import ic_if_else from './ic_if_else.png';
import ic_split from './ic_split.png';
import ic_marketplace from './ic_marketplace.png';
import ic_install from './ic_install.webp';
import ic_ai from './ic_ai.png';
import ic_attachment from './ic_attachment.png';
import ic_form from './ic_form.png';
import ic_admin from './ic_admin.png';
import ic_add_table from './ic_add_table.png';
import ic_add_column from './ic_add_column.png';
import ic_import from './ic_import.png';

/// WHITE ICONS
import ic_white_home from './white/ic_home.png';
import ic_white_app from './white/ic_app.png';
import ic_white_deployment from './white/ic_deployment.png';
import ic_white_table from './white/ic_table.png';
import ic_white_page from './white/ic_page.png';
import ic_white_arrow_right from './white/ic_arrow_right.png';
import ic_white_arrow_down from './white/ic_arrow_down.png';
import ic_white_send from './white/ic_send.png';
import ic_white_close from './white/ic_close.png';
import ic_white_copy from './white/ic_copy.png';
import ic_white_save from './white/ic_save.png';
import ic_white_delete from './white/ic_delete.png';
import ic_white_ai from './white/ic_ai.png';
import ic_white_open from './white/ic_open.png';
import ic_white_checkmark from './white/ic_checkmark.png';
import ic_white_triangle from './white/ic_triangle.png';
import ic_white_form from './white/ic_form.png';
import ic_white_add from './white/ic_add.png';

//RED icons
import ic_red_delete from './red/ic_delete.png';

//AUTOMATION icons
import ic_automation_add from './automation/ic_add.png';
import ic_automation_end from './automation/ic_end.png';
import ic_automation_trigger from './automation/ic_trigger.png';
import ic_automation_update from './automation/ic_update.png';
import ic_automation_wait from './automation/ic_wait.png';
import ic_automation_plus from './automation/ic_plus.png';

const AppIcons = {
  ic_home: ic_home,
  ic_app: ic_app,
  ic_deployment: ic_deployment,
  ic_table: ic_table,
  ic_items: ic_items,
  ic_report: ic_report,
  ic_automation: ic_automation,
  ic_doc: ic_doc,
  ic_page: ic_page,
  ic_search: ic_search,
  ic_sort: ic_sort,
  ic_more: ic_more,
  ic_link: ic_link,
  ic_web: ic_web,
  ic_grid: ic_grid,
  ic_list: ic_list,
  ic_arrow_right: ic_arrow_right,
  ic_arrow_left: ic_arrow_left,
  ic_arrow_down: ic_arrow_down,
  ic_close: ic_close,
  ic_delete: ic_delete,
  ic_edit: ic_edit,
  ic_copy: ic_copy,
  ic_save: ic_save,
  ic_info: ic_info,
  ic_settings: ic_settings,
  ic_profile2: ic_profile2,
  ic_align_center: ic_align_center,
  ic_align_left: ic_align_left,
  ic_align_right: ic_align_right,
  ic_success: ic_success,
  ic_arrow_right_long: ic_arrow_right_long,
  ic_open: ic_open,
  ic_apply: ic_apply,
  ic_plus: ic_plus,
  ic_add_action: ic_add_action,
  ic_if_else: ic_if_else,
  ic_split: ic_split,
  ic_marketplace: ic_marketplace,
  ic_install: ic_install,
  ic_ai: ic_ai,
  ic_attachment: ic_attachment,
  ic_form: ic_form,
  ic_admin: ic_admin,
  ic_add_table: ic_add_table,
  ic_add_column: ic_add_column,
  ic_import: ic_import,
  white: {
    ic_home: ic_white_home,
    ic_app: ic_white_app,
    ic_deployment: ic_white_deployment,
    ic_page: ic_white_page,
    ic_table: ic_white_table,
    ic_arrow_right: ic_white_arrow_right,
    ic_arrow_down: ic_white_arrow_down,
    ic_send: ic_white_send,
    ic_close: ic_white_close,
    ic_copy: ic_white_copy,
    ic_save: ic_white_save,
    ic_delete: ic_white_delete,
    ic_ai: ic_white_ai,
    ic_open: ic_white_open,
    ic_checkmark: ic_white_checkmark,
    ic_triangle: ic_white_triangle,
    ic_form: ic_white_form,
    ic_add: ic_white_add
  },
  red: {
    ic_delete: ic_red_delete
  },
  automation: {
    ic_add: ic_automation_add,
    ic_update: ic_automation_update,
    ic_trigger: ic_automation_trigger,
    ic_end: ic_automation_end,
    ic_wait: ic_automation_wait,
    ic_plus: ic_automation_plus
  }
};

export default AppIcons;
