import { SignUp } from '@clerk/clerk-react';

export default function SignUpAuth(props: any) {
  return (
    <div
      className="column align-items-center justify-content-center"
      style={{ width: '100vw', height: '100vh' }}
    >
      <SignUp
        signInUrl="/sign-in"
        forceRedirectUrl="/home"
        fallbackRedirectUrl="/home"
        path="/sign-up"
        afterSignOutUrl="/sign-in"
      />
    </div>
  );
}
