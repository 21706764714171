import { FC, useState } from 'react';
import { DKLabel, DKIcon, DKSearchBar, DKLine } from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';
import { IForm } from '../../model/Form';
import { FORM_BUILDER_ACTION } from '../../constants/Enum';

interface IFormListProps {
  forms: IForm[];
  onFormAction?: (type: FORM_BUILDER_ACTION, form: IForm) => void;
}

const FormList: FC<IFormListProps> = ({ onFormAction, forms }) => {
  const [search, setSearch] = useState<string>(null);

  const filteredForms = search
    ? forms?.filter((form: any) =>
        form?.name?.toLowerCase().includes(search?.toLowerCase())
      )
    : forms;
  return (
    <div className="column parent-size overflow-hidden">
      <div className="row align-items-center justify-content-between mb-m">
        <div className="fs-r text-align-left fw-m ">
          Forms ({forms?.length ?? 0})
        </div>
        <DKSearchBar
          onSearch={setSearch}
          placeholder="Search..."
          searchText={search}
        />
      </div>
      <div className="parent-width overflow-auto hide-scroll-bar">
        {filteredForms.map((form) => (
          <div
            id="form-row"
            key={form.tableId}
            className="row p-xs justify-content-between cursor-hand"
            onClick={() => onFormAction(FORM_BUILDER_ACTION.EDIT, form)}
          >
            <div className="column parent-width">
              <div className="row p-v-s">
                <DKIcon src={AppIcons.ic_items} className="ic-s" />
                <DKLabel text={form.title} className="ml-r" />
              </div>
              <DKLine className="mt-xs" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormList;
