import { DKButton, DKLabel, DKSpinner } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import AppIcons from '../../assets/icons/AppIcons';
import Utility, { changeTheme, getERPAILogo } from '../../utility/Utility';

import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import { useAppDispatch } from '../../redux/hooks';
import { setAppInfo } from '../../redux/slices/CommonSlice';
import Popup from '../common/Popup';
import useWebSocket from '../common/useWebSocket';
import './AppCreateProcess.css';
import AppPreview from './AppPreview';

const AppCreateProcess = ({ newAppPayload, onClose }) => {
  const [app, setApp] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [appCreated, setAppCreated] = useState(false);
  const [showPromptPopup, setShowPromptPopup] = useState(false);
  const [stepList, setStepList] = useState([
    {
      title: 'Analyzing...',
      subTitle: 'Understanding the requirements '
    }
  ]);
  const [isClamped, setIsClamped] = useState(false);
  const promptRef = useRef(null);
  const stepContentRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const WS_URL =
    API_ENDPOINTS.BASE.replace('https://', 'wss://') + API_ENDPOINTS.WEB_SOCKET;

  const onMessageReceived = (data: any) => {
    try {
      const message = JSON.parse(data);
      if (message?.eventType) {
        if (message.eventType === 'AI_APP_NAME_UPDATE') {
          const parsedData = JSON.parse(message.message);
          if (parsedData.appName) {
            setApp((prevState) => ({ ...prevState, name: parsedData.appName }));
            addStep({
              title: `Creating ${parsedData.appName}`,
              subTitle: 'Building modules and related tables'
            });
          }
        } else if (message.eventType === 'AI_TABLE_UPDATE') {
          const parsedData = JSON.parse(message.message);
          if (parsedData.tableName) {
            setApp((prevState) => {
              const newState: any = { ...prevState };
              if (newState.tables) {
                newState.tables[parsedData.tableName] = parsedData;
              } else {
                newState.tables = {
                  [parsedData.tableName]: parsedData
                };
              }
              return newState;
            });
            setSelectedTable(parsedData.tableName);
            addStep({
              title: `Created table ${parsedData.tableName}`,
              subTitle: `Organized table designed for ${parsedData.tableName}.`
            });
          }
        } else if (message.eventType.match(/AI_(.*)_RECORD_UPDATE/)) {
          let tableName = message.eventType.match(/AI_(.*)_RECORD_UPDATE/)?.[1];
          // title case tableName
          tableName = tableName
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());

          if (tableName) {
            setApp((prevState) => {
              const newState: any = { ...prevState };
              const parsedData = JSON.parse(message.message);
              const record = parsedData?.record;
              if (!Utility.isEmptyObject(record)) {
                if (newState?.records) {
                  if (newState.records[tableName]) {
                    newState.records[tableName].push(record);
                  } else {
                    newState.records[tableName] = [record];
                    addStep({
                      title: `Updating table ${tableName} with demo records.`,
                      subTitle: `An example record has been added to table ${tableName}.`
                    });
                  }
                } else {
                  newState.records = { [tableName]: [record] };
                  addStep({
                    title: `Created table ${tableName}`,
                    subTitle: `An example record has been added to table ${tableName}.`
                  });
                }
              }
              return newState;
            });
            if (app.tables?.[tableName]) {
              setSelectedTable(tableName);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in parsing message', error);
    }
  };

  useWebSocket(WS_URL, onMessageReceived);

  useEffect(() => {
    changeTheme(newAppPayload.themeColor);
  }, []);

  useEffect(() => {
    if (newAppPayload.appId && !appCreated) {
      addStep({
        title: 'Finalising...',
        subTitle: 'Few more seconds and we are good to start.'
      });
      setTimeout(() => {
        setAppCreated(true);
        addStep({
          title: 'App created successfully',
          subTitle:
            'Your app has been created successfully, you can now start using it.'
        });
      }, 2000);
    }
  }, [newAppPayload]);

  useEffect(() => {
    if (promptRef.current) {
      const isOverflowing =
        promptRef.current.scrollHeight > promptRef.current.clientHeight;
      setIsClamped(isOverflowing);
    }
  }, [newAppPayload.prompt]);

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const getPromptPopup = () => {
    return (
      <Popup
        title="Prompt"
        popupWindowStyles={{
          overflow: 'visible',
          minWidth: newAppPayload.prompt?.length > 1000 ? '70vw' : 'unset'
        }}
        onClose={() => setShowPromptPopup(false)}
        allowClose={true}
      >
        <div
          id="id-prompt-editor"
          className="fs-r text-align-left mt-xl"
          dangerouslySetInnerHTML={{ __html: newAppPayload.prompt }}
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            maxHeight: 400,
            overflow: 'auto'
          }}
        />
      </Popup>
    );
  };

  const renderStepsSection = () => {
    return (
      <div
        className="column pb-r p-h-r parent-height"
        style={{ minWidth: 400, maxWidth: 400 }}
      >
        <div className="bg-white border-m border-radius-r p-l parent-width mb-l">
          <p ref={promptRef} className="fs-m line-clamp-4 text-left">
            {newAppPayload.prompt}
          </p>
          {isClamped && (
            <DKButton
              title="View More..."
              className="fs-r line-clamp-4 text-right pt-m cursor-pointer"
              onClick={() => {
                setShowPromptPopup(true);
              }}
            />
          )}
          {showPromptPopup && getPromptPopup()}
        </div>
        <div className="bg-white border-m border-radius-r p-xl parent-size">
          <DKLabel text={getRandomText()} />
          <div className="bg-gray1 border-radius-r border-m mt-l p-v-m">
            {getSteps()}
          </div>
        </div>
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderLoadingDots = () => {
    return (
      <div className="loading-dots mt-m">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  const getSteps = () => {
    return (
      <div
        ref={stepContentRef}
        className="column parent-width pt-s p-h-l content scroll-smooth pb-l"
        style={{
          minHeight: '55vh',
          maxHeight: '55vh'
        }}
      >
        {stepList.map((step, index) => {
          return (
            <div key={index} className="row align-items-start p-v-s fade-in">
              <DKLabel
                text="✓"
                className="fw-m fs-l mr-r"
                style={{ color: 'green' }}
              />
              <div className="column">
                <DKLabel text={step.title} className="fw-m" />
                <DKLabel text={step.subTitle} className="mt-xs" />
              </div>
            </div>
          );
        })}
        {!appCreated && renderLoadingDots()}
      </div>
    );
  };

  const addStep = (step: any) => {
    setStepList((prevState) => [...prevState, step]);
    setTimeout(() => {
      scrollStepsToBottom();
    }, 100);
  };

  const scrollStepsToBottom = () => {
    stepContentRef.current.scrollTo({
      top: stepContentRef.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const renderGridSection = () => {
    return (
      <div className="column bg-white parent-height flex-1 border-m border-radius-r ml-s">
        <div className="row justify-content-between p-h-l p-v-r">
          <DKButton
            title="App Preview"
            className="border-radius-l bg-gray1 "
            onClick={() => {
              scrollStepsToBottom();
            }}
          />
        </div>
        <div className="column p-h-l parent-width parent-height">
          <div className="column parent-width parent-height mb-l">
            {/* <DKLabel
              text="https://make.erpai.dev/apps/671fabef6cc58500134310ab"
              className="text-gray p-v-s p-h-r"
            /> */}
            {!Utility.isEmptyObject(app) ? (
              <AppPreview
                app={app}
                selectedTable={selectedTable}
                appCreated={appCreated}
                appId={newAppPayload.appId}
              />
            ) : (
              <div className="column parent-width parent-height border-m border-radius-r align-items-center justify-content-center">
                <DKSpinner title="Loading app..." />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getRandomText = () => {
    const texts = [
      "We're kicking off the process to build your app. Let's get started!",
      'The journey to build your app begins now. Ready to dive in!',
      "We're officially starting the app build. Let's make it happen!",
      'Your app creation is underway. Exciting steps ahead!',
      "The app process has begun. Let's bring your project to life!",
      "We're getting started with building your app. Let's do this!",
      'The app creation process starts now. Big things are coming!',
      "We're starting the app build process. Let's make magic happen!",
      'The app project is officially underway. Ready to transform ideas!',
      "We're launching the app build. Time to get things moving!",
      'Starting the app creation process now. Exciting journey ahead!',
      "We're diving into the app build. Let's create something amazing!",
      "Your app build process begins now. Let's make it outstanding!",
      "The app development process has begun. Let's innovate together!",
      "Starting work on your app. Let's bring your vision to reality!",
      "The process to build your app is underway. Let's go big!",
      "We're beginning the app project. Time to get things rolling!",
      "The app journey starts here. Let's create something remarkable!",
      "We're getting started on your app. Let's make it happen!",
      'Your app build is beginning now. Exciting steps ahead!'
    ];

    return texts[newAppPayload.textIndex];
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  return newAppPayload ? (
    <div className="transparent-background">
      <div
        className="bg-gray1 popup-window"
        style={{
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
          borderRadius: 0,
          backgroundColor: 'rgb(246, 246, 246)',
          paddingTop: 0
        }}
      >
        <div className="row justify-content-between p-r">
          {getERPAILogo()}
          <div className="row width-auto">
            <DKButton
              title="Back"
              className="bg-white border-m mr-r"
              onClick={() => {
                changeTheme('rgb(29, 29, 31)');
                onClose();
              }}
            />
            {appCreated && (
              <DKButton
                title="Goto App"
                isReverse
                icon={AppIcons.white.ic_arrow_right}
                className="bg-button text-white"
                onClick={() => {
                  dispatch(
                    setAppInfo({
                      appId: newAppPayload.appId,
                      isPublishedApp: false
                    })
                  );
                  navigate(`/apps/${newAppPayload.appId}`);
                }}
              />
            )}
          </div>
        </div>
        <div className="row parent-height align-items-start">
          {renderStepsSection()}
          {renderGridSection()}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AppCreateProcess;
