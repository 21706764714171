import React, { useState, useEffect } from 'react';

import { DKLabel } from 'deskera-ui-library';

const ThemePopup = ({
  className,
  style,
  selectedColor,
  onSelect,
  onClose,
  needTitle = true
}) => {
  const themes = [
    'RoyalBlue',
    'Crimson',
    'FireBrick',
    'MediumVioletRed',
    'MediumPurple',
    'RebeccaPurple',
    'DarkOrchid',
    'Purple',
    'SlateBlue',
    'SeaGreen',
    'DarkCyan',
    'Navy',
    'Chocolate',
    'rgb(69, 73, 82)',
    'rgb(233, 233, 233)'
  ];
  const [currentColor, setCurrentColor] = useState(selectedColor || themes[0]);

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  function handleClickOutside(event) {
    const popup = document.getElementById('id-app-theme-popup');
    if (popup && !popup.contains(event.target)) {
      onClose();
    }
  }

  const renderColors = () => {
    return (
      <div className="row flex-wrap mt-l mb-s" style={{ gap: 10 }}>
        {themes.map((color) => {
          return (
            <div
              className="column justify-content-center align-items-center cursor-hand border-radius-s text-white"
              style={{ backgroundColor: color, height: 20, width: 20 }}
              onClick={() => {
                setCurrentColor(color);
                onSelect(color);
              }}
            >
              {color === currentColor && <>✓</>}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div
      id="id-app-theme-popup"
      className={
        'p-r border-radius-s-2 border-m shadow-s bg-white z-index-1 ' +
        className
      }
      style={{
        ...{
          width: 235
        },
        ...style
      }}
    >
      {needTitle && <DKLabel text="Select theme" className="fw-m mb-xs" />}
      <DKLabel
        text="This will be applied to your app, can be changed later "
        className=" text-gray "
      />
      {renderColors()}
    </div>
  );
};

export default ThemePopup;
