import { INPUT_TYPE } from 'deskera-ui-library';
export const getDefaultColumn = (column = null) => {
  return {
    key: column.id,
    id: column.id,
    title: column.name,
    filter: true,
    name: column.name,
    type: column.type,
    options: column.options,
    systemField: column.systemField,
    required: column.required,
    editable: column.editable,
    hidden: column.hidden,
    uiVisible: column.uiVisible,
    textAlign: column.textAlign,
    formula: column.formula || null,
    width: column.width,
    refTable: column.refTable,
    aiConfig: column.aiConfig,
    style: column.style
  };
};
export const getActionColumn = () => {
  return {
    key: 'actions',
    name: 'Actions',
    type: INPUT_TYPE.BUTTON,
    width: 110,
    options: []
  };
};
export const processRows = (rows, columns) => {
  return rows.map((item) => ({
    _id: item._id,
    ...item.cells
  }));
};
