import React, { Component } from 'react';
import {
  DKLabel,
  DKInput,
  DKButton,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { getCapitalized } from '../../utility/Utility';

class SaveAutomation extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title ? this.props.title : '',
      submitTapped: false
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.bindEnter);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.bindEnter);
  }

  bindEnter = (evt) => {
    evt = evt || window.event;
    if (evt.keyCode === 13) {
      this.saveTapped();
    }
  };

  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window justify-content-center align-items-center justify-content-start-mobile"
          style={{ padding: 30, paddingTop: 20 }}
        >
          <DKLabel
            text={`<span style="font-size:30px">${this.props.headerIcon}</span> ${this.props.header}`}
            className="fw-m fs-xl"
          />
          {this.props.subHeader && (
            <DKLabel
              text={this.props.subHeader}
              className="text-gray mt-s text-align-center"
            />
          )}
          <div className="parent-width">
            <DKInput
              className="mt-xl"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              name="Name *"
              value={this.state.title}
              onChange={(value) =>
                this.setState({ title: getCapitalized(value) })
              }
              invalid={this.state.title.trim() === ''}
              autoFocus={true}
            />
          </div>

          {this.getFooter()}
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onCancel();
  };

  saveTapped = () => {
    this.setState({ submitTapped: true });
    let title = this.state.title;
    if (title && title.trim().length > 0) {
      this.props.onSave({
        title: this.state.title
      });
    }
  };

  getFooter() {
    return (
      <div className="row mt-xl justify-content-center">
        <DKButton
          title="Cancel"
          className="bg-gray1 border-m"
          onClick={this.cancelTapped}
        />
        <DKButton
          title="Save"
          className="bg-button text-white ml-m"
          onClick={this.saveTapped}
        />
      </div>
    );
  }
}

export default SaveAutomation;
