import {
  DKIcon,
  DKIcons,
  DKInputForm,
  DKLabel,
  removeLoader,
  showAlert,
  showToast
} from 'deskera-ui-library';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppIcons from '../assets/icons/AppIcons';
import ic_no_data from '../assets/icons/ic_no_data.png';
import { OBJECT_TYPES } from '../constants/Automation';
import {
  COLUMN_DETAIL_SUFFIX,
  CUSTOM_INPUT_TYPE,
  MAX_FILE_SIZE
} from '../constants/Constants';
import { API_ENDPOINTS } from '../constants/Endpoints';
import { attachmentRenderer } from '../Helper/Renderers';
import {
  getActionColumn,
  getDefaultColumn,
  processRows
} from '../Helper/table';
import httpClient from '../http/index';
import AutomationManager from '../managers/AutomationManager';
import {
  PAGE_ROUTES,
  ROUTE_APP_DETAIL_ID_IDENTIFIER
} from '../managers/RouteManager';
import { showAutomationPopup } from '../pages/automation';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { selectAppInfo } from '../redux/slices/CommonSlice';
import {
  refreshTableComplete,
  selectRefreshTrigger
} from '../redux/slices/refreshTriggerSlice';
import { getHumanReadableFileSize, uploadFileToAWS } from '../services/file';
import SideBarService from '../services/sidebar';
import { shiftColumns, freezeColumns } from '../services/table';
import Utility, {
  isNotEmptyObject,
  isString,
  shiftArrayElement
} from '../utility/Utility';
import { showAddColumnPopup } from './AddColumnPopup';
import AddNewRecord from './common/AddNewRecords';
import DataGridHolder from './common/DataGridHolder';
import Popup from './common/Popup';
import { useUser } from '@clerk/clerk-react';
import {
  fetchTableById,
  selectTableById,
  addColumn as addColumnInStore,
  updateColumn as updateColumnInStore,
  selectFilter,
  updateFilter,
  selectTables
} from '../redux/slices/tableSlice';
import { FILTER_CONDITIONS } from '../constants/Constant';
import { FILTER_LOGICAL_OPERATORS } from '../constants/Enum';

const attachmentColumnRenderer = (data, callback, showAddButton = false) => {
  const attachmentsToDisplay = attachmentRenderer(data) || [];
  if (showAddButton) {
    attachmentsToDisplay.unshift(
      <DKIcon
        src={DKIcons.ic_add}
        className="ic-xs-2 cursor-hand opacity-5"
        onClick={(e) => {
          e?.stopPropagation?.();
          callback();
        }}
      />
    );
  }
  return (
    <div
      className="row align-items-start parent-height hide-scroll-bar"
      style={{ overflowX: 'auto', gap: 4 }}
    >
      {attachmentsToDisplay}
    </div>
  );
};

const buildPutPayload = ({ rowData, columnData }) => {
  const payload = {};
  const { id, type } = columnData;
  if (type === CUSTOM_INPUT_TYPE.REF || type === CUSTOM_INPUT_TYPE.DROPDOWN) {
    payload[id] = rowData?.[id]?._id ? [rowData[id]._id] : [];
  } else {
    payload[id] = rowData[id];
  }
  return payload;
};

function TableIdDisplay(props) {
  const navigate = useNavigate();
  const { appId, tableId } = useParams();
  const {
    user: { id: userId }
  } = useUser();
  const [columnsData, setColumnsData] = useState(null);
  const [recordData, setRecordData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [columnData, setColumnData] = useState([]);
  const [showRecordPopup, setShowRecordPopup] = useState(false);
  const [showNameFieldModal, setShowNameFieldModal] = useState(false);
  const attachmentOpenFileRef = useRef();
  const selectedRow = useRef(null);
  const browseAttachmentColumnRef = useRef();
  const [isBulkUpdateSelected, setIsBulkUpdateSelected] = useState(false);
  const refreshTableData = useAppSelector(selectRefreshTrigger());
  const dispatch = useAppDispatch();
  const appInformation = useAppSelector(selectAppInfo);
  const tableData = useAppSelector(selectTableById(tableId));
  const tables = useAppSelector(selectTables());
  const [searchQuery, setSearchQuery] = useState('');

  const filtersData = useAppSelector(selectFilter());

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize = 30;

  const totalPageCount = Math.ceil(totalRecords / pageSize);

  useEffect(() => {
    setRecordData([]);
    setColumnData([]);
    if (Utility.isEmptyObject(tableData)) {
      setIsLoading(true);
      dispatch(fetchTableById({ tableId }));
    }
  }, [tableId]);

  useEffect(() => {
    if (isNotEmptyObject(tableData)) processTableData();
  }, [tableData, tables]);

  const handleUpdateTableName = async (name) => {
    const payload = { name };
    try {
      await httpClient.put(API_ENDPOINTS.TABLE.UPDATE(tableId), payload);
      fetchTableData(tableId);
      removeLoader();
    } catch (error) {
      console.error('Failed to update table name:', error);
    }
  };

  const callGetRowDataForEdit = async (rowData) => {
    let data = await httpClient.get(
      API_ENDPOINTS.RECORDS.FETCH_FOR_EDIT(tableId, rowData._id)
    );
    selectedRow.current = data;
    onRowOpenClick({ rowData: data });
  };

  const getGridContextMenu = (isRowButton = false) => {
    const contextMenuButtons = [];
    contextMenuButtons.push({
      title: isRowButton ? '' : 'Edit',
      icon: AppIcons.ic_edit,
      onClick: ({ rowData }) => {
        callGetRowDataForEdit(rowData);
      },
      className: 'p-0'
    });
    contextMenuButtons.push({
      title: isRowButton ? '' : 'Delete',
      icon: AppIcons.ic_delete,
      onClick: ({ rowData }) => {
        onRowDelete(rowData);
      },
      className: 'p-0'
    });
    return contextMenuButtons;
  };

  const processTableData = () => {
    const getColumnDropDownConfig = (item) => {
      return {
        style: {},
        allowSearch: true,
        disableCallOnMount: true,
        searchableKey: 'name',
        data: [],
        renderer: (index, obj) => {
          const cells = obj?.cells;
          return (
            <DKLabel needIcon text={`${cells?.[item?.nameField] ?? ''}`} />
          );
        },
        onSelect: (index, value) => {
          console.log(value, index);
        },
        searchApiConfig: {
          method: 'POST',
          getUrl: (val) => {
            return `${API_ENDPOINTS.BASE}/v1/app-builder/table/${item.refTable?._id}/paged-record`;
          },
          dataParser: (response) => {
            return response.data;
          },
          getHeaders: () => {
            return {
              'Access-Control-Allow-Credentials': true,
              Authorization: httpClient.defaults.headers.common['Authorization']
            };
          },
          getPayload: (searchText) => {
            return {
              pageNo: 1,
              pageSize: pageSize,
              fetchAllRef: true,
              q: `search=${searchText}`,
              conditions: [],
              logicalOperator: FILTER_LOGICAL_OPERATORS.AND
            };
          }
        }
      };
    };

    try {
      const { columnsMetaData, userColOrder, userFreezeCol } = tableData;
      setColumnsData(tableData);

      let columnDataForGrid = columnsMetaData.map((item) => {
        const column = getDefaultColumn(item);
        column.systemField = false;
        column.freezed = userFreezeCol?.[userId]?.includes(column.id);
        switch (item.type) {
          case CUSTOM_INPUT_TYPE.REF:
            if (
              isNotEmptyObject(item?.refTable?._id) &&
              isNotEmptyObject(tables)
            ) {
              const refTable = tables?.[item?.refTable?._id];
              column.nameField = refTable?.nameField;
              column.type = CUSTOM_INPUT_TYPE.DROPDOWN;
              column.renderer = ({ rowData }) => {
                const [firstRecord] =
                  rowData[`${item.id}${COLUMN_DETAIL_SUFFIX}`] ?? [];
                return firstRecord?.cells?.[column.nameField] ?? '';
              };
              column.dropdownConfig = getColumnDropDownConfig({
                ...item,
                nameField: refTable?.nameField
              });
            } else {
              return '';
            }
            break;
          case CUSTOM_INPUT_TYPE.FILE:
            column.editable = false;
            column.renderer = (data) =>
              attachmentColumnRenderer(
                data,
                () => {
                  selectedRow.current = data.rowData;
                  addAttachment(column);
                },
                true
              );
            break;
          case CUSTOM_INPUT_TYPE.FORMULA:
            column.editable = false;
            break;
          default:
            break;
        }

        column.filterConditions = getFilterConditions(column);
        return column;
      });

      columnDataForGrid = columnDataForGrid.filter((item) => !!item.uiVisible);
      if (isNotEmptyObject(userColOrder?.[userId])) {
        const columnsOrder = userColOrder?.[userId];
        columnDataForGrid.sort((a, b) => {
          return columnsOrder.indexOf(a.id) - columnsOrder.indexOf(b.id);
        });
      }

      columnDataForGrid.push(getActionColumn());
      setColumnData(Utility.manualDeepCopyObject(columnDataForGrid));
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTableData = async (tableId) => {
    dispatch(fetchTableById({ tableId }));
  };

  const fetchRecords = useCallback(
    async (query, sortCol, sortDir) => {
      let userFilters = filtersData?.[tableId]
        ? { ...filtersData?.[tableId] }
        : {
            query: [],
            logicalOperator: FILTER_LOGICAL_OPERATORS.AND
          };

      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        fetchAllRef: true,
        q: query,
        sortCol: sortCol,
        sortDir: sortDir
      };

      if (query) {
        params.q = query;
      }

      const requestBody = {
        conditions: [],
        logicalOperator:
          userFilters.logicalOperator || FILTER_LOGICAL_OPERATORS.AND
      };
      if (userFilters.query.length > 0) {
        const conditions = userFilters.query.map((filter) => {
          return {
            colId: filter.key,
            opr: filter.condition,
            value: filter?.value?._id ? [filter?.value?._id] : filter.value
          };
        });
        requestBody.conditions = conditions;
      }

      try {
        const result = await httpClient.post(
          API_ENDPOINTS.RECORDS.FETCH(tableId),
          requestBody,
          {
            params
          }
        );

        setRecordData(processRows(result.data, columnData));
        setTotalRecords(result.totalCount);
      } catch (error) {
        console.error('Failed to fetch record data:', error);
      }
    },
    [tableId, filtersData, currentPage]
  );

  const handleSearch = useCallback(
    (searchText) => {
      setSearchQuery(searchText);
      const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
          const later = () => {
            clearTimeout(timeout);
            func(...args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      };
      const debouncedSearch = debounce((query) => {
        fetchRecords(query);
      }, 500);
      debouncedSearch(searchText);
    },
    [fetchRecords]
  );

  const handleFilter = (newFilterData) => {
    let currentFilters = filtersData?.[tableId]
      ? { ...filtersData?.[tableId] }
      : {
          query: [],
          logicalOperator: FILTER_LOGICAL_OPERATORS.AND
        };
    const updatedFilters = {
      ...currentFilters,
      logicalOperator:
        newFilterData.logicalOperator || currentFilters.logicalOperator,
      query: [...newFilterData.query]
    };
    dispatch(updateFilter({ tableId: tableId, filter: updatedFilters }));
  };

  const handleSort = (data) => {
    const { order, columnData } = data;
    const sortDir = order === 'ASC' ? 1 : -1;
    const sortCol = columnData.key;

    fetchRecords(searchQuery, sortCol, sortDir);
  };

  useEffect(() => {
    if (refreshTableData) {
      fetchTableData(tableId).finally(() => {
        dispatch(refreshTableComplete(tableId));
      });
    }
  }, [refreshTableData]);

  useEffect(() => {
    if (columnsData) {
      fetchRecords(searchQuery);
    }
  }, [fetchRecords, columnsData, searchQuery]);

  const addColumn = async (data) => {
    try {
      dispatch(addColumnInStore({ tableId, column: data }));
      await httpClient.post(API_ENDPOINTS.COLUMN.ADD(tableId), data);
      fetchTableData(tableId);
    } catch (error) {
      console.error('Failed to add column data:', error);
    }
  };

  const onColumnUpdate = async ({ columnData }) => {
    try {
      dispatch(updateColumnInStore({ tableId, column: columnData }));
      await httpClient.put(
        API_ENDPOINTS.COLUMN.UPDATE(tableId, columnData.id),
        columnData
      );

      fetchTableData(tableId);
    } catch (error) {
      console.error('Failed to update column data:', error);
    }
  };

  const onColumnShift = (data) => {
    let { newIndex, oldIndex } = data;
    const allColumns = [...columnData];

    if (newIndex === -1 || oldIndex === -1) return;

    let newColumnArray = shiftArrayElement(allColumns, oldIndex, newIndex);
    const columnIdArray = newColumnArray.map((column) => column.key);
    setColumnData(newColumnArray);
    shiftColumns(tableId, columnIdArray).then((data) => {
      fetchTableData(tableId);
    });
  };

  const onColumnFreezeToggle = async (data) => {
    if (!data?.column?.id) return;
    try {
      const { userFreezeCol } = tableData;
      let freezedColumns = userFreezeCol?.[userId] || [];

      if (data.freezed) {
        freezedColumns = freezedColumns.concat(data.column.id);
      } else {
        freezedColumns = freezedColumns.filter(
          (columnId) => columnId !== data.column.id
        );
      }

      freezeColumns(tableId, freezedColumns).then((data) => {
        fetchTableData(tableId);
      });
    } catch (err) {
      console.error('Failed to freeze column: ', err);
    }
  };

  const makeNameField = async (data) => {
    try {
      data.isNameField = true;
      await httpClient.put(API_ENDPOINTS.COLUMN.UPDATE(tableId, data.id), {
        ...data,
        isNameField: true
      });

      fetchTableData(tableId);
    } catch (error) {
      console.error('Failed to update column data:', error);
    }
  };

  const onRowEdit = async ({ rowIndex, rowData, columnData }) => {
    let payload = {
      cells: buildPutPayload({ rowData, columnData })
    };
    try {
      if (rowData._id) {
        await httpClient.put(
          API_ENDPOINTS.RECORDS.UPDATE(tableId, rowData._id),
          payload
        );
      }
    } catch (error) {
      console.error('Failed to edit row data:', error);
    }
    fetchRecords();
  };

  const currentNameFieldColumnIndex = columnData.findIndex(
    (column) => columnsData.nameField && column.id === columnsData.nameField
  );

  const closeRecordPopup = () => {
    setShowRecordPopup(false);
    selectedRow.current = null;
    setIsBulkUpdateSelected(false);
  };

  const handleOpenRefTabModal = () =>
    showAddColumnPopup(
      {
        tableName: columnsData.name,
        columnDList: columnData,
        appId: appInformation.isPublishedApp ? appInformation.appId : appId,
        tableId: tableId
      },
      (data) => {
        fetchTableData(tableId);
      },
      () => {}
    );

  const handleSelectNameField = async (column) => {
    await makeNameField(column);
    setShowNameFieldModal(false);
  };

  const addUpdateRecord = async (record, recordId = null) => {
    try {
      let response;
      if (recordId) {
        response = await httpClient.put(
          API_ENDPOINTS.RECORDS.UPDATE(tableId, recordId),
          record
        );
      } else {
        response = await httpClient.post(
          API_ENDPOINTS.RECORDS.ADD(tableId),
          record
        );
      }

      if (!Utility.isEmptyObject(response)) {
        fetchRecords();
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  const openAttachmentPicker = () => {
    attachmentOpenFileRef.current.click();
  };

  const initializeImageSelect = () => {
    document.body.onfocus = checkIt;
  };

  const checkIt = () => {
    if (document.querySelector('#dynamicFormAttachment')) {
      const fileElement = document.querySelector('#dynamicFormAttachment');
      if (fileElement.files && fileElement.files.length === 0) {
        return;
      }
    }
  };

  const handleFileSelect = (e) => {
    if (!e.target.files && !browseAttachmentColumnRef.current) return;
    const files = Array.from(e.target.files);
    const fileExceedingLimit = files.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (fileExceedingLimit.length) {
      showAlert(
        'File size exceeds! ',
        `File size should not be more than ${getHumanReadableFileSize(
          MAX_FILE_SIZE
        )}. Please optimize <em>${fileExceedingLimit
          .map((file) => file.name)
          .join(', ')}</em> or select different file.`
      );
      return;
    }
    showToast('Uploading attachments...');
    Promise.all(files.map((file) => uploadFileToAWS(file)))
      .then((responseList) => {
        let hasValidAttachment = false;
        let encodedAttachment = null;
        encodedAttachment =
          selectedRow.current[browseAttachmentColumnRef.current.key];
        let attachments = Utility.isEmptyObject(encodedAttachment)
          ? []
          : Utility.decodeJSON(encodedAttachment);

        responseList.forEach((attachmentPath, index) => {
          const file = files[index];
          if (
            !Utility.isEmptyObject(attachmentPath) &&
            file &&
            isString(attachmentPath)
          ) {
            attachments.push({
              path: attachmentPath,
              title: file.name,
              type: file.type
            });
            hasValidAttachment = true;
          }
        });

        if (!hasValidAttachment) return;
        const payload = {
          cells: {
            [browseAttachmentColumnRef.current.key]:
              Utility.encodeJSON(attachments)
          }
        };
        addUpdateRecord(payload, selectedRow.current._id);
        selectedRow.current = null;
      })
      .catch((err) => {
        console.error('Error while attaching files ', err);
      })
      .finally(() => {});
  };

  const getAttachmentPicker = () => {
    return (
      <input
        id="dynamicFormAttachment"
        type="file"
        accept="image/*, application/pdf, .txt, .doc, .xls , .ppt, .docx, .xlsx, .pptx"
        multiple={true}
        ref={attachmentOpenFileRef}
        style={{ display: 'none' }}
        onClick={(e) => {
          initializeImageSelect();
          e.stopPropagation();
        }}
        onChange={handleFileSelect}
      />
    );
  };

  const addAttachment = (field) => {
    browseAttachmentColumnRef.current = field;
    openAttachmentPicker();
  };

  const getNoDataView = () => {
    let noDataTitle = '';
    let noDataText = '';

    if (props.updating) {
      noDataTitle = 'Loading...';
      noDataText = 'Please wait';
    } else {
      noDataTitle = props.noDataTitle ? props.noDataTitle : 'No data found';
      noDataText = props.noDataText
        ? props.noDataText
        : 'Once data is available, it will appear here';
    }

    return (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute"
        style={{ top: '40%', pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={noDataTitle} className="fw-m mt-l" />
        <DKLabel text={noDataText} className="text-gray mt-s" />
      </div>
    );
  };

  const onRowOpenClick = ({ rowData, rowIndex }) => {
    selectedRow.current = rowData;
    if (tableData.objectType === OBJECT_TYPES.AUTOMATION) {
      AutomationManager.automationColumns = tableData?.columnsMetaData ?? [];
      AutomationManager.setData({ data: rowData });
      showAutomationPopup({
        onSave: () => {
          fetchRecords();
        }
      });
    } else {
      setShowRecordPopup(true);
    }
  };

  const deleteRecord = async (row) => {
    try {
      await httpClient.delete(API_ENDPOINTS.RECORDS.DELETE(row._id, tableId));
      fetchRecords();
    } catch (error) {
      console.log(error);
    }
  };

  const getDeleteConfirmation = (row) => {
    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m'
      },
      {
        title: 'Delete',
        className: 'bg-red text-white ml-r',
        onClick: () => deleteRecord(row)
      }
    ];
    showAlert(
      'Delete Record?',
      'Deleting this record will delete it permanently you will not be able to restore it.',
      buttons
    );
  };

  const onRowDelete = (row) => {
    getDeleteConfirmation(row);
  };

  const handleImport = () => {
    if (appInformation.isPublishedApp) {
      navigate(PAGE_ROUTES.IMPORT_PORTAL);
    } else {
      navigate(
        PAGE_ROUTES.IMPORT_PORTAL_ADMIN.replace(
          ROUTE_APP_DETAIL_ID_IDENTIFIER,
          appInformation?.isPublishedApp ? appInformation?.appId : appId
        )
      );
    }
  };

  const onAddNew = () => {
    if (tableData?.objectType === OBJECT_TYPES.AUTOMATION) {
      showAutomationPopup({
        onSave: () => {
          fetchRecords();
        }
      });
    } else {
      setShowRecordPopup(true);
    }
  };

  const getGridHeaderButtons = () => {
    return [
      {
        // icon: AppIcons.white.ic_add,
        className: 'bg-button text-white ml-r',
        onClick: onAddNew,
        title: '+ New Record'
      },
      {
        icon: AppIcons.ic_add_column,
        className: 'bg-white ml-r border-m bg-white',
        onClick: handleOpenRefTabModal,
        title: 'New Column'
      },
      {
        icon: DKIcons.ic_import,
        className: 'bg-white ml-r border-m bg-white',
        onClick: handleImport,
        title: 'Import'
      },
      {
        className: 'bg-white ml-r border-m bg-white',
        onClick: () => setShowNameFieldModal(true),
        icon: DKIcons.ic_settings
      }
    ];
  };

  const onContextCancel = () => {
    let copyRecordData = [...recordData]?.map((item) => {
      return {
        ...item,
        selected: false
      };
    });
    setRecordData(copyRecordData);
  };

  const onAdvancedBulkEdit = () => {
    recordData.filter((row) => row.selected).map((row) => row._id);
    setShowRecordPopup(true);
    setIsBulkUpdateSelected(true);
  };

  const getGrid = () => {
    let currentFilters = filtersData?.[tableId]
      ? Utility.deepCloneObject(filtersData?.[tableId])
      : {
          query: [],
          logicalOperator: FILTER_LOGICAL_OPERATORS.AND
        };
    return (
      <DataGridHolder
        tableId={tableId}
        title={`${
          columnsData ? `${columnsData.name} (${totalRecords})` : 'Loading...'
        }`}
        updating={isLoading}
        allowBulkOperation
        allowColumnEdit
        allowColumnShift
        allowColumnSort
        allowColumnWebhook={false}
        allowExpand={false}
        allowFilter
        allowRowEdit
        allowColumnDelete
        allowSearch
        width={SideBarService.getContainerWidth()}
        currentPage={currentPage}
        needShadow={false}
        needBorder={false}
        needTrailingColumn={true}
        allowFormulaColumn={false}
        onPageChange={(data) => setCurrentPage(data)}
        totalPageCount={totalPageCount}
        filterData={[...currentFilters.query]}
        filterOperator={currentFilters.logicalOperator}
        onRowUpdate={(data) => onRowEdit(data)}
        onColumnAdd={(data) => addColumn(data.columnData)}
        onColumnUpdate={(data) => onColumnUpdate(data)}
        onColumnShift={onColumnShift}
        onColumnFreezeToggle={onColumnFreezeToggle}
        onSearch={handleSearch}
        onFilter={handleFilter}
        onSort={(data) => handleSort(data)}
        buttons={getGridHeaderButtons()}
        columns={columnData}
        rows={recordData.map((item) => ({
          ...item,
          rowButtons: getGridContextMenu(true),
          rowContextMenu: getGridContextMenu()
        }))}
        onRowOpenClick={({ rowData }) => callGetRowDataForEdit(rowData)}
        allowAdvancedBulkEdit={true}
        onRowSelect={(data) => {
          let copyRecordData = [...recordData];
          copyRecordData[data.rowIndex].selected =
            !copyRecordData[data.rowIndex].selected;
          setRecordData(copyRecordData);
        }}
        onContextCancel={onContextCancel}
        refreshData={fetchRecords}
        onAdvancedBulkEdit={onAdvancedBulkEdit}
        isAllRowSelected={
          isNotEmptyObject(recordData) &&
          recordData.every((item) => item.selected)
        }
        showContextMenu={recordData.some((item) => item.selected)}
        onAllRowSelect={(data) => {
          setRecordData(
            recordData.map((item) => {
              return { ...item, selected: data.selected };
            })
          );
        }}
      />
    );
  };

  const getFilterConditions = (columnData) => {
    let conditionList = FILTER_CONDITIONS.CONDITIONS_ALL;

    if (
      columnData.type === CUSTOM_INPUT_TYPE.SELECT ||
      columnData.type === CUSTOM_INPUT_TYPE.MULTI_SELECT ||
      columnData.type === CUSTOM_INPUT_TYPE.DROPDOWN
    ) {
      conditionList = FILTER_CONDITIONS.CONDITIONS_SELECT;
    } else if (columnData.type === CUSTOM_INPUT_TYPE.DATE) {
      conditionList = FILTER_CONDITIONS.CONDITIONS_DATE;
    } else if (columnData.type === CUSTOM_INPUT_TYPE.NUMBER) {
      conditionList = FILTER_CONDITIONS.CONDITIONS_NUMBER;
    } else if (
      columnData.type === CUSTOM_INPUT_TYPE.TEXT ||
      columnData.type === CUSTOM_INPUT_TYPE.LONG_TEXT ||
      columnData.type === CUSTOM_INPUT_TYPE.LONG_TEXT2
    ) {
      conditionList = FILTER_CONDITIONS.CONDITIONS_TEXT;
    }

    return conditionList;
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="column parent-size align-items-start">
      {showNameFieldModal && (
        <Popup
          onClose={() => setShowNameFieldModal(false)}
          popupWindowStyle={{ padding: 20 }}
        >
          <DKInputForm
            title="Table Settings"
            className=""
            direction="vertical"
            fields={[
              {
                key: 'name',
                placeholder: 'Table Name',
                required: false,
                title: 'Change table name',
                type: 'text',
                value: columnsData.name
              },
              {
                key: 'nameField',
                label: 'NameField Column',
                type: 'select',
                options: columnData
                  .filter((column) => column.type !== 'button')
                  .map((column) => column.name),
                placeholder: 'Select a Column',
                required: false,
                title: 'Set name field',
                value:
                  currentNameFieldColumnIndex >= 0
                    ? [currentNameFieldColumnIndex.toString()]
                    : []
              }
            ]}
            onCancel={() => setShowNameFieldModal(false)}
            onSubmit={(formData) => {
              const tableNameField = formData.find(
                (field) => field.key === 'name'
              );
              if (tableNameField.value && tableNameField.value.trim() !== '') {
                handleUpdateTableName(tableNameField.value.trim());
              }
              const nameFieldColumnIndex = formData.find(
                (field) => field.key === 'nameField'
              );
              if (nameFieldColumnIndex.value) {
                const selectedColumn =
                  columnData[nameFieldColumnIndex.value[0]];
                if (selectedColumn) {
                  handleSelectNameField(selectedColumn);
                }
              } else {
              }
              setShowNameFieldModal(false);
            }}
          />
        </Popup>
      )}
      {getGrid()}
      {Utility.isEmptyObject(recordData) && getNoDataView()}
      {showRecordPopup && (
        <AddNewRecord
          isBulkUpdateSelected={isBulkUpdateSelected}
          selectedRecordIds={recordData
            .filter((row) => row.selected)
            .map((row) => row._id)}
          columns={columnsData?.columnsMetaData}
          onSave={() => {
            fetchRecords();
            closeRecordPopup();
          }}
          onCancel={closeRecordPopup}
          tableId={tableId}
          appId={appInformation?.isPublishedApp ? appInformation?.appId : appId}
          record={selectedRow.current}
        />
      )}
      {getAttachmentPicker()}
    </div>
  );
}

export default TableIdDisplay;
