export type API_Params = {
  pageNo: number;
  pageSize: number;
  sortDir: -1 | 1;
  sortCol: string;
  q: string;
};
export const initialAPIParams: API_Params = {
  pageNo: 1,
  pageSize: 20,
  sortCol: null,
  sortDir: null,
  q: null
};
export type APIPayload = {
  data: any[];
  pageNo?: number;
  pageSize?: number;
  sortCol?: string;
  sortDir?: -1 | 1;
  totalCount?: number;
};
