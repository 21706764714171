import { REGEX_PATTERNS } from '../constants/Constant';

export function getCapitalized(name) {
  let str = name;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function replaceNewLineWithLineBreaks(str) {
  return str?.replaceAll(new RegExp(REGEX_PATTERNS.NEW_LINE, 'g'), '<br />');
}

export function replaceTextLinkWithAnchor(str) {
  return str?.replaceAll(
    new RegExp(REGEX_PATTERNS.URL_PATTERN, 'gi'),
    "<a href='$1' target='_blank'>$1</a>"
  );
}

export function isData(data) {
  return data !== null && data !== undefined;
}

export function toCurrencyFormat(number, currency = null) {
  // return number
  return (
    (currency ? currency : '$ ') +
    parseFloat('' + number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
}

export function toKFormat(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
    : Math.sign(num) * Math.abs(num);
}

export function encodeString(json) {
  let temp = encodeURI(json);
  return btoa(temp);
}

export function decodeString(base64String) {
  return JSON.parse(atob(base64String));
}

export function encodeStringForTemplate(json) {
  return btoa(unescape(encodeURIComponent(json)));
}

export function decodeStringForTemplate(base64String) {
  return JSON.parse(decodeURIComponent(escape(atob(base64String))));
}

export function isDevice() {
  if (window.screen.width <= 1142) {
    return true;
  }
  return false;
}

export function openInNewTab(url) {
  window.open(url, '_blank');
}

export function isValidNumber(number) {
  return number.match(/^[0-9]+$/) === null;
}
export function isValidEmail(email) {
  if (email === undefined || email === null || email.trim() === '') {
    return false;
  }
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
}

export function getRandomAlphaNumericString(length) {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
export function getRandomNumber(number) {
  return Math.floor(Math.random() * number) + 1;
}

////////////////////////////////////////////////////////
//////////// START - LOCAL STORAGE FEATURES ////////////
////////////////////////////////////////////////////////

export function setPersistentValue(value, key) {
  localStorage.setItem(key, value);
}

export function getPersistentValue(key) {
  return localStorage.getItem(key);
}

export function getURLOptionValue(urlOption) {
  return urlOption === undefined || urlOption === null
    ? false
    : urlOption.toLowerCase() === 'true'
    ? true
    : false;
}
export function isEmpty(value) {
  if (value === null || value === undefined) return true;
  if (typeof value === 'number' && !isNaN(value)) {
    return false;
  }
  if (value.length === 0) return true;
  if (Array.isArray(value) || typeof value === 'string') return !value.length;
  return Object.keys(value).length === 0;
}
export function copyToClipboard(valueToCopy) {
  let textArea = document.createElement('textarea');
  textArea.value = valueToCopy;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}
export function validateAgainstPattern(string, pattern) {
  return pattern.test(string);
}
export function getFirstAndLastDayByMonth(
  month = new Date().getMonth(),
  year = new Date().getFullYear()
) {
  return [
    // DataParser.formatDate(new Date(year, month, 1)),
    // DataParser.formatDate(new Date(year, month + 1, 0)),
  ];
}

export function isMacintosh() {
  var deviceDetect = navigator.platform;
  var appleDevicesArr = [
    'MacIntel',
    'MacPPC',
    'Mac68K',
    'Macintosh',
    'iPhone',
    'iPod',
    'iPad',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad Simulator',
    'Pike v7.6 release 92',
    'Pike v7.8 release 517'
  ];

  if (appleDevicesArr.includes(deviceDetect)) {
    return true;
  }

  return false;
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function getDivById(id) {
  var div = document.getElementById(id);
  return div;
}
export function getDivTopById(id) {
  var div = getDivById(id);
  return div.offsetTop;
}
export function getDivBottomById(id) {
  var div = getDivById(id);
  return div.offsetTop + div.offsetHeight;
}
export function getDivLeftById(id) {
  var div = getDivById(id);
  return div.offsetLeft;
}
export function getDivRightById(id) {
  var div = getDivById(id);
  return div.offsetLeft + div.offsetWidth;
}

export function getMouseX(e) {
  return e.pageX;
}
export function getMouseY(e) {
  return e.pageY;
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function swapArrayElement(arr, fromIndex, toIndex) {
  var b = arr[fromIndex];
  arr[fromIndex] = arr[toIndex];
  arr[toIndex] = b;
  return arr;
}

export function shiftArrayElement(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export function sortArray(arr, sortOrder, keyToSort) {
  return arr.sort(function (obj1, obj2) {
    const i1 = sortOrder === 'ASC' ? obj1[keyToSort] : obj2[keyToSort];
    const i2 = sortOrder === 'ASC' ? obj2[keyToSort] : obj1[keyToSort];
    if (!isNaN(i1)) {
      return Number(i1) - Number(i2);
    }
    if (i1 < i2) {
      return -1;
    }
    if (i1 > i2) {
      return 1;
    }
    return 0;
  });
}
