import {
  DKLabel,
  DKButton,
  DKSpinner,
  DKInput,
  INPUT_TYPE,
  showAlert,
  DKLine
} from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';
import { createRef, useState } from 'react';
import { INPUT_VIEW_DIRECTION } from '../../constants/Enum';
import { allowedImageType } from '../../constants/Constant';
import ThemePopup from '../prompt-editor/ThemePopup';
import httpClient from '../../http';
import { API_ENDPOINTS } from '../../constants/Endpoints';
import { useAppDispatch } from '../../redux/hooks';
import { editApplication } from '../../redux/slices/applicationSlice';
import { MAX_FILE_SIZE } from '../../constants/Constants';

interface CreateApplicationProps {
  onSave: () => void;
  onCancel: () => void;
  appObj?: any;
}

export default function CreateApplicationPopup(props: CreateApplicationProps) {
  const dispatch = useAppDispatch();
  const [canValidate, setCanValidate] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const importRef = createRef<HTMLInputElement>();
  const [themeColor, setThemeColor] = useState(
    props?.appObj?.theme?.backgroundColor ?? 'rgb(69, 73, 82)'
  );
  const [appObj, setAppObj] = useState<any>(
    props.appObj ?? {
      name: '',
      theme: { backgroundColor: themeColor },
      description: '',
      logoData: ''
    }
  );
  const [updating, setUpdating] = useState(false);

  const editApplicationById = async () => {
    try {
      await dispatch(
        editApplication({
          ...appObj,
          _id: props.appObj?._id ?? null,
          theme: { backgroundColor: themeColor }
        })
      );
      setUpdating(false);
      props.onSave();
    } catch (error) {
      showAlert('Failed to rename application');
      setUpdating(false);
    }
  };

  const createApplication = async () => {
    try {
      await httpClient.post(API_ENDPOINTS.APP.CREATE, {
        ...appObj,
        theme: { backgroundColor: themeColor }
      });
      setUpdating(false);
      props.onSave();
    } catch (error) {
      console.error('Error while creating the application', error);
      setUpdating(false);
    }
  };

  const onSaveClick = async () => {
    setCanValidate(true);
    if (appObj.name) {
      setUpdating(true);
      if (props?.appObj?._id) {
        await editApplicationById();
      } else {
        await createApplication();
      }
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-v-l p-h-m">
        <div className="row fw-500">
          <DKLabel
            className="row ml-m fw-m"
            text={props?.appObj?._id ? 'Edit Application' : 'New App'}
          />
        </div>
        <DKButton
          icon={AppIcons.ic_close}
          onClick={() => {
            if (!updating) {
              props?.onCancel();
            }
          }}
        />
      </div>
    );
  };

  const getLogoImage = () => {
    if (appObj?.logoData) {
      return `data:image/png;base64,${appObj.logoData}`;
    }
    return AppIcons.ic_app; // Default icon if no logoData
  };

  const renderThemeSection = () => {
    return (
      <div className="column parent-width mt-xl ">
        <DKLabel text="Select Theme" />
        <ThemePopup
          className="justify-self-center parent-width mt-s"
          style={{
            width: '100%',
            backgroundColor: 'rgb(246, 246, 249)',
            borderColor: 'rgb(230, 230, 230)'
          }}
          selectedColor={themeColor}
          needTitle={false}
          onSelect={(color) => {
            setThemeColor(color);
          }}
          onClose={() => {}}
        />
      </div>
    );
  };

  const getLogoImageSection = () => {
    return (
      <>
        {!appObj?.logoData && (
          <>
            <div className="row width-auto justify-content-start flex">
              <div
                className={` ${
                  logoLoading
                    ? ' bg-chip-gray'
                    : 'border-blue text-blue bg-chip-blue'
                }   p-v-s p-h-r border-radius-r cursor-pointer flex items-center justify-center`}
                style={{
                  border: 'dashed',
                  borderWidth: 1,
                  height: 70,
                  width: 70
                }}
                onClick={() => {
                  if (!logoLoading) importRef.current.click();
                }}
              >
                {!logoLoading && <DKLabel className="" text={`Logo`} />}
                {logoLoading && (
                  <DKSpinner iconClassName="ic-s" className="column pl-0" />
                )}
              </div>
            </div>
          </>
        )}
        {appObj.logoData && (
          <div className="row width-auto justify-content-start flex">
            <div className="column position-relative border-radius-r cursor-pointer items-center justify-center">
              <img
                onClick={() => {
                  if (!logoLoading) importRef.current.click();
                }}
                src={getLogoImage()}
                alt=""
                className={`product-image  text-blue border-radius-r cursor-pointer flex items-center justify-center`}
                style={{
                  height: 70,
                  width: 70,
                  aspectRatio: 1
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width p-h-xl" style={{ paddingBottom: 0 }}>
        <div
          className=" border-radius-s-2 border-m"
          style={{
            backgroundColor: 'rgb(246, 246, 249)',
            borderColor: 'rgb(230, 230, 230)'
          }}
        >
          <div className="row gap-3 p-h-r p-v-m">
            {getLogoImageSection()}
            <DKLabel
              className="opacity-9"
              text="Enter application name and pick a logo for your application. Recommended size is 200x200px."
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={appObj?.name}
              onChange={(text: string) => {
                setAppObj({ ...appObj, name: text });
              }}
              type={INPUT_TYPE.TEXT}
            />
          </div>
          <div className="column parent-width bg-gray3" style={{ height: 1 }} />
          <DKInput
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={appObj?.name}
            className="p-h-s p-v-xs"
            valueStyle={{ backgroundColor: 'rgba(0,0,0,0)', border: 'none' }}
            placeholder="Application name"
            autoFocus
            onChange={(text: string) => {
              setAppObj({ ...appObj, name: text });
            }}
            type={INPUT_TYPE.TEXT}
          />
        </div>
        {canValidate && appObj?.name?.length < 1 && (
          <DKLabel
            text="Application name is required"
            className="text-red mt-s ml-xs"
          />
        )}

        <DKInput
          className="mt-xl"
          title="Add Description"
          placeholder="Write something about your application"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={appObj?.description}
          valueStyle={{ borderRadius: 6 }}
          onChange={(text: string) => {
            setAppObj({ ...appObj, description: text });
          }}
          type={INPUT_TYPE.LONG_TEXT}
        />
        {renderThemeSection()}
        <input
          ref={importRef}
          style={{ visibility: 'hidden' }}
          type="file"
          accept={allowedImageType.toString()}
          title=""
          className=""
          onChange={(event: any) => {
            const file = event.target.files[0]; // {{ edit_1 }}
            if (file) {
              if (file.size > MAX_FILE_SIZE) {
                showAlert(
                  'File Too Large',
                  'The file you are trying to upload exceeds the maximum allowed size of 1 MB. Please select a smaller file and try again.'
                );
                return;
              }
              const reader = new FileReader(); // {{ edit_2 }}
              reader.onloadend = () => {
                const base64data = reader.result as string;
                const base64String = base64data.split(',')[1];
                setAppObj({ ...appObj, logoData: base64String }); // {{ edit_4 }} Set logoData in Base64 format
              };
              reader.readAsDataURL(file); // {{ edit_5 }}
            }
          }}
        />
      </div>
    );
  };

  const getFooter = () => {
    return (
      <div className="row-reverse gap-2 pb-xl p-h-xl">
        <div
          className={`row border-radius-s-2 ${
            updating
              ? ' bg-gray3 text-white align-self-end pr-2'
              : ' bg-app text-white align-self-end'
          }`}
          style={{ width: 'auto' }}
        >
          <DKButton
            isReverse={true}
            title={updating ? 'Saving...' : 'Save'}
            onClick={async () => {
              setCanValidate(true);
              if (!updating) {
                await onSaveClick();
              }
            }}
          />
          {updating && (
            <DKSpinner iconClassName="ic-s" className="width-auto" />
          )}
        </div>
        <DKButton
          className={`row border-radius-s-2 ${
            updating
              ? ' bg-gray3 text-white align-self-end pr-2'
              : ' bg-gra1 text-black border'
          }`}
          title="Cancel"
          onClick={() => {
            if (!updating) {
              props?.onCancel();
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background fade-animation">
      <div
        className="prompt-editor-shadow popup-window fade-animation "
        style={{
          padding: 0,
          paddingBottom: 0,
          maxWidth: 450,
          borderRadius: 8
        }}
      >
        {getHeader()}
        {getBody()}
        {getFooter()}
      </div>
    </div>
  );
}
