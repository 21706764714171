import Source from './steps/Source';
import FileImporter from './steps/FileImporter';
import Mapper from './steps/MatchHeader';
import Confirm from './steps/Confirm';
import { generateUpdatedRecordsFile } from '../../utility/SystemFileUpload';
import {
  DETAIL_ADDRESS_CUSTOM_FIELDS,
  excludedColumnCodes,
  IMPORT_DEFAULT_DATE_FORMAT,
  IMPORT_FILE_MODULE,
  IMPORT_MODULE_TYPE,
  IMPORT_SOURCE_TYPE,
  MODULES_WITH_NESTED_IMPORT
} from '../../constants/EximConstants';
import ImportExportService from '../../services/ImportExport';
import {
  IHeadersGroup,
  modifyMappingsForPayload
} from '../../utility/DataMapHelper';
import Utility from '../../utility/Utility';
import { IColumn } from '../../model/Table';
import { store } from '../../redux/store';

export default class StepsManager {
  static getImportColumnFilterFunction(
    tableName: string,
    importTableName: string
  ) {
    return (column: IColumn) => {
      let isImportable = true;
      const columnsToExclude = [...excludedColumnCodes];

      isImportable =
        isImportable && !columnsToExclude.includes(column.columnCode);

      return isImportable;
    };
  }
  static getColumns(tableName: string, importTableName: string) {
    // const filterEditable = tableName !== TABLES.BOOKS_PRICE_LIST;
    const columns: IColumn[] = [];

    columns.sort((col1: IColumn, col2: IColumn) => col1.index - col2.index);

    return columns;
  }

  static getColumnsToImport(tableName: string) {
    const importColumns = store.getState().import?.table?.columnsMetaData;
    return importColumns.map((column) => ({ ...column, key: column.id }));
  }

  static async getUploadFileRelativeURL(file: any, tableName: string) {
    const fileUploadResponse = await ImportExportService.getUploadFileURL(
      file,
      'IMPORT_APP_BUILDER_RECORD'
    );
    return fileUploadResponse.relativePath;
  }

  static async uploadNewFile(
    fileData: { content: any; fileName: string; fileType: string },
    headers: IHeadersGroup,
    tableName: string,
    selectedSource: string
  ) {
    const csvFile = await generateUpdatedRecordsFile(
      {
        fileContent: fileData.content,
        baseFileName: fileData.fileName,
        fileType: fileData.fileType
      },
      headers,
      { selectedSource, tableName }
    );
    const filePath = await StepsManager.getUploadFileRelativeURL(
      csvFile,
      tableName
    );
    return filePath;
  }

  static uploadRecords(
    data: {
      segmentId: any;
      pipelineId?: string | null;
      properties: any[];
      headers: IHeadersGroup;
      filePath: string;
      auditLogId: any;
    },
    tableName: string,
    selectedSource: string
  ) {
    let dataInsight = {
      dateFormat: IMPORT_DEFAULT_DATE_FORMAT,
      segmentId: data.segmentId,
      pipelineId: data.pipelineId
    };

    data.properties?.forEach((importProp) => {
      dataInsight[importProp.payloadKey] = importProp.selectedOption;
    });

    if (MODULES_WITH_NESTED_IMPORT.includes(tableName)) {
      dataInsight = {
        dateFormat: IMPORT_DEFAULT_DATE_FORMAT,
        isAdvance: true
      } as any;
    }

    const mapping = modifyMappingsForPayload({
      headers: data.headers,
      tableName,
      selectedSource
    });

    const payload = {
      dataInsight,
      mapping
    };

    const queryParams: any = {
      tableId: store.getState().import.table?._id,
      module: 'IMPORT_APP_BUILDER_RECORD',
      relativeFilePath: data.filePath,
      auditLogId: data.auditLogId
    };

    return ImportExportService.uploadRecords(queryParams, payload, false);
  }

  static async getAllConnectedUsers() {
    let response = null;
    try {
      response = await ImportExportService.getExistingConnectedUsers();
    } catch (err) {
      console.log(err);
    } finally {
      return response?.content;
    }
  }

  static async exportDataFileFromLinkedAccount(
    username: string,
    appName: string
  ) {
    let response = null;

    try {
      const module = IMPORT_MODULE_TYPE[IMPORT_SOURCE_TYPE.importOffice365];
      response = await ImportExportService.getLinkedAccountExportDataUrl(
        appName,
        module,
        username
      );
    } catch (err) {
      console.log(err);
    } finally {
      return response;
    }
  }

  static async connectUserAccount(authCode: string, appConfig: any) {
    let response = null,
      allConnectedUsers = null;

    try {
      response = await ImportExportService.connectDataSourceAccount(
        authCode,
        appConfig
      );
      allConnectedUsers = await StepsManager.getAllConnectedUsers();
    } catch (err) {
      console.log(err);
    } finally {
      return { response, allConnectedUsers };
    }
  }

  static getDataSourceStep(props: StepCreationInfo) {
    // let loggedInUserDetails = [
    //   {
    //     appName: IMPORT_SOURCE_TYPE.importFromDeskeraBooks,
    //     connected: true,
    //     id: UserManager.getUserIamID(),
    //     username: UserManager.getUserEmail()
    //   }
    // ];
    // const importingFromDeskeraBooks =
    //   IMPORT_SOURCE_TYPE[props.state.selectedSource] ===
    //   IMPORT_SOURCE_TYPE.importFromDeskeraBooks;
    let sourceStep = null;
    switch (IMPORT_SOURCE_TYPE[props.state.selectedSource]) {
      // case IMPORT_SOURCE_TYPE.importOffice365:
      // case IMPORT_SOURCE_TYPE.importGoogle:
      // case IMPORT_SOURCE_TYPE.importFromDeskeraBooks:
      //   sourceStep = (
      //     <AccountSelector
      //       fileInfo={props.state.fileInfo}
      //       selectedSource={props.state.selectedSource}
      //       selectedUser={props.state.selectedUser}
      //       connectedUsers={
      //         importingFromDeskeraBooks
      //           ? loggedInUserDetails
      //           : props.state.connectedUsers
      //       }
      //       exportDataFile={StepsManager.exportDataFileFromLinkedAccount}
      //       generateAuditLog={(fileData) =>
      //         StepsManager.generateAuditLog(fileData, {
      //           isAlternateSource: true,
      //           tableName: props.tableName
      //         })
      //       }
      //       disconnectUser={StepsManager.disconnectUser}
      //       setData={props.setData}
      //     />
      //   );
      //   break;
      case IMPORT_SOURCE_TYPE.importFromLocal:
      default:
        sourceStep = (
          <FileImporter
            // generateAuditLog={(fileData) =>
            //   StepsManager.generateAuditLog(fileData, {
            //     isAlternateSource: false,
            //     tableName: props.tableName
            //   })
            // }
            tableName={props.tableName}
            fileInfo={props.state.fileInfo}
            currentStep={props.state.currentStep}
            setData={props.setData}
          />
        );
    }

    return sourceStep;
  }

  static getAllCreationSteps(
    onClickHandler: (e: any, expectedStepIndex: number) => void,
    importTarget: string
  ) {
    const hasAlternateImportSources = false;
    const stepIndexModifier = hasAlternateImportSources ? 0 : -1;

    const allCreationSteps = [
      {
        title: 'Data Source',
        requiredProps: hasAlternateImportSources ? ['selectedSource'] : [],
        reqError: `` /* Show error message as per selected source */,
        getErrorMessage: (state) =>
          state?.selectedSource &&
          IMPORT_SOURCE_TYPE[state.selectedSource] !==
            IMPORT_SOURCE_TYPE.importFromLocal
            ? `Please link an account with contacts to continue`
            : `Please upload a file to continue`,
        needContinueAction: (state) => !Utility.isEmptyObject(state.fileInfo),
        getChild: (props: StepCreationInfo) =>
          StepsManager.getDataSourceStep(props),
        onClick: (event: any) => onClickHandler(event, stepIndexModifier + 1)
      },
      {
        title: 'Map Columns',
        requiredProps: ['fileContent'],
        reqError: 'Please map or skip the unmapped columns to continue',
        needContinueAction: () => true,
        getChild: (props: StepCreationInfo) => (
          <Mapper
            {...props.state}
            setData={props.setData}
            tableName={props.tableName}
            getTableColumns={StepsManager.getColumnsToImport}
          />
        ),
        onClick: (event: any) => onClickHandler(event, stepIndexModifier + 2)
      },
      {
        title: 'Confirm Import',
        requiredProps: ['mappedHeaders'],
        reqError: 'Please provide data for required columns',
        needContinueAction: () => false,
        getChild: (props: StepCreationInfo) => (
          <Confirm
            {...props.state}
            setData={props.setData}
            tableName={props.tableName}
            backStepHandler={(e) => onClickHandler(e, stepIndexModifier + 2)}
            confirmationHandler={(e) =>
              onClickHandler(e, stepIndexModifier + 4)
            }
            // segmentColumnData={StepsManager.getSegmentColumn()}
            getTableColumns={StepsManager.getColumnsToImport}
          />
        ),
        onClick: (event: any) => onClickHandler(event, stepIndexModifier + 3)
      }
    ];

    if (hasAlternateImportSources) {
      allCreationSteps.unshift({
        title: 'Select Source',
        requiredProps: [],
        reqError: 'Please select an import source to continue',
        getErrorMessage: null,
        needContinueAction: (state) =>
          !Utility.isEmptyObject(state.selectedSource),
        getChild: (props: StepCreationInfo) => (
          <Source
            selectedSource={props.state.selectedSource}
            connectedUsers={props.state.connectedUsers}
            getAllConnectedUsers={StepsManager.getAllConnectedUsers}
            setData={props.setData}
          />
        ),
        onClick: (event: any) => onClickHandler(event, stepIndexModifier)
      });
    }

    return allCreationSteps;
  }
}

export interface StepCreationInfo {
  state: any;
  tableName: string;
  setData: (data: any) => void;
}
