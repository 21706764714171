import React from 'react';
import { DKButton, DKLabel } from 'deskera-ui-library';
import { OrganizationSwitcher, useAuth } from '@clerk/clerk-react';
import { PAGE_ROUTES } from '../../managers/RouteManager';
import { useNavigate } from 'react-router-dom';
import { getERPAILogo } from '../../utility/Utility';

const TopMenu = () => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  const renderLogo = () => {
    return getERPAILogo();
  };

  return (
    <div
      id="erp-ai-header"
      className="z-[1] row pr-[2px] p-5 justify-content-between"
    >
      <div class="flex md:min-w-[218px]">
        {isSignedIn ? (
          <OrganizationSwitcher
            afterSelectOrganizationUrl={PAGE_ROUTES.HOME}
            hidePersonal
          />
        ) : (
          renderLogo()
        )}
      </div>

      {window.location.pathname === PAGE_ROUTES.HOME && (
        <div
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          className="display-only-web"
        >

        </div>
      )}

      {isSignedIn && (
        <div className="row-reverse width-auto mr-l md:min-w-[218px]">
          {renderLogo()}
        </div>
      )}
      {!isSignedIn && (
        <div
          className="row width-auto gap-2 mr-l "
          style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
        >
          <DKButton
            title="Sign In"
            className="bg-gray2 border-m"
            style={{ borderColor: 'rgb(190, 190, 190)' }}
            onClick={() => {
              navigate(PAGE_ROUTES.SIGN_IN);
            }}
          />
          <DKButton
            title="Get started for free"
            className=" text-white bg-button"
            onClick={() => {
              navigate(PAGE_ROUTES.SIGN_UP);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TopMenu;
