import { DKLabel, DKSpinner, DKIcon } from 'deskera-ui-library';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateComponents } from '../../services/table';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { refreshTables } from '../../redux/slices/refreshTriggerSlice';
import ContextMenu from './ContextMenu';
import AppIcons from '../../assets/icons/AppIcons';
import { IContext } from './PromptHelper';
import Utility from '../../utility/Utility';
import { selectAppInfo } from '../../redux/slices/CommonSlice';

function PromptPopup() {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [promtInProgress, setPromtInProgress] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>();
  const [context, setContext] = useState<IContext[]>([]);
  const [promptResponse, setPromptResponse] = useState<any>();
  const dispatch = useAppDispatch();
  const { appId } = useParams();
  const appInformation = useAppSelector(selectAppInfo);

  const sendPrompt = () => {
    let appIdValue = appInformation?.isPublishedApp
      ? appInformation?.appId
      : appId;
    if (context.length && prompt && appIdValue) {
      setPromtInProgress(true);
      const payload = {
        componentIds: context.map((item) => item.id),
        prompt,
        appId: appIdValue
      };
      updateComponents(payload)
        .then((response) => {
          setPromptResponse({
            error: false,
            message: 'Prompt successfully executed'
          });
          setPrompt('');
          dispatch(refreshTables(payload.componentIds));
        })
        .catch((error) => {
          console.error(error);
          setPromptResponse({
            error: true,
            message: 'Some error occured'
          });
        })
        .finally(() => {
          setPromtInProgress(false);
        });
    }
  };

  const promptPopup = () => {
    const sendBtnDisabled = !prompt || !context.length || promtInProgress;

    return (
      <div
        className="popup-windows  column position-absolute z-index-3 bg-white p-xl prompt-editor-shadow border-m z"
        style={{
          width: 400,
          bottom: 0,
          right: 0,
          borderRadius: 8
        }}
      >
        <DKIcon
          src={AppIcons.ic_close}
          className="position-absolute right-4 top-4 cursor-hand ic-s"
          onClick={() => setShowPopup((prevState) => !prevState)}
        />
        <ContextMenu
          appId={appInformation?.isPublishedApp ? appInformation?.appId : appId}
          context={context}
          setContext={setContext}
          disabled={promtInProgress}
        />
        <textarea
          className="resize-vertical p-2 border border-radius-m outline-none bg-gray1 border-m overflow-auto parent-width mt-l mb-s"
          style={{
            minHeight: 100,
            borderWidth: 2
          }}
          placeholder={'Enter prompt (e.g create column name delivery date)'}
          value={prompt}
          onChange={(e) => {
            setPromptResponse(null);
            setPrompt(e.target.value);
          }}
          disabled={promtInProgress}
        />
        {
          <DKLabel
            className={promptResponse?.error ? 'text-red' : 'text-green'}
            text={promptResponse?.message}
          />
        }
        <div
          className={
            'flex align-items-center mt-m border-radius-s-2 p-h-m p-v-xs align-items-center bg-button  ' +
            (sendBtnDisabled ? 'cursor-not-allowed' : 'cursor-hand')
          }
          onClick={() => {
            if (!sendBtnDisabled) {
              sendPrompt();
            }
          }}
        >
          <DKLabel
            className={`text-white mr-xs ${promtInProgress ? 'opacity-7' : ''}`}
            text={`${promtInProgress ? 'Processing... ' : 'Send'}`}
          />
          {promtInProgress ? (
            <DKSpinner iconClassName="ic-s" isWhite={true} />
          ) : (
            <DKIcon className="ic-s" src={AppIcons.white.ic_send} />
          )}
        </div>
      </div>
    );
  };

  return (
    // <div className={showPopup ? 'transparent-background' : ''}>
    <div className="position-fixed" style={{ bottom: 24, right: 24 }}>
      {showPopup && promptPopup()}
      <div
        className="row bg-white border-m border-radius-s-2 p-s cursor-hand shadow-l"
        onClick={() => setShowPopup((prevState) => !prevState)}
      >
        <DKIcon
          src={showPopup ? AppIcons.white.ic_close : AppIcons.ic_ai}
          style={{ marginTop: 0 }}
          className="ic-s-2"
        />
        <DKLabel text="Copilot" className=" ml-r" />
      </div>
    </div>
    // </div>
  );
}

export default PromptPopup;
