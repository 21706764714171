import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import httpClient from '../../http';
import { API_ENDPOINTS } from '../../constants/Endpoints';
interface IAuthSlice {
  organizationInfo: any;
  userInfo: any;
  userList: any;
}

const initialState: IAuthSlice = {
  organizationInfo: {},
  userInfo: {},
  userList: {}
};

export const fetchUsers = createAsyncThunk(
  'applications/fetchUsers',
  async () => {
    const response = await httpClient.get(API_ENDPOINTS.APP.FETCH_USERS);
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setOrganizationInfo: (state, action: PayloadAction<string>) => {
      state.organizationInfo = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<string>) => {}
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      let usersList: any = {};
      action.payload.forEach((user: any) => {
        usersList[user.userId] = user;
      });
      state.userList = usersList;
    });
  }
});
export const { setOrganizationInfo, setUserInfo } = authSlice.actions;

const selfSelector = (state: RootState) => state?.auth;
export const selectOrganizationInfo = () =>
  createSelector(selfSelector, (state: IAuthSlice) => state.organizationInfo);
export const selectUsersList = () =>
  createSelector(selfSelector, (state: IAuthSlice) => state.userList);

export default authSlice.reducer;
