import { generateBlob, getFile } from '../utility/ExportData';
import { IHeadersGroup, modifyRecordForUpload } from './DataMapHelper';

export async function generateUpdatedRecordsFile(
  fileData: {
    fileContent: any;
    baseFileName: string;
    fileType: string;
  },
  headers: IHeadersGroup,
  sourceData: { selectedSource: string; tableName: string }
) {
  const { fileContent, baseFileName, fileType } = fileData;
  if (!fileContent?.length) {
    return Promise.reject('No records to import');
  }

  const content = modifyRecordForUpload(fileContent, headers, sourceData);

  const csvContent = await generateBlob(content, fileType);

  const csvFile = getFile(csvContent, `updated_${baseFileName}`, fileType);

  return csvFile;
}
