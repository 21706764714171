import { Component, Fragment } from 'react';
import { IMappedHeader, dataMapper } from '../../../utility/DataMapHelper';
import {
  MODULES_WITH_NESTED_IMPORT,
  errorInfoTypes,
  headerMapDisplaySections
} from '../../../constants/EximConstants';
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKButton,
  DKListPicker,
  DKListPicker2,
  showToast,
  TOAST_TYPE,
  INPUT_TYPE
} from 'deskera-ui-library';
import { showAddColumnPopup } from '../../AddColumnPopup';
import { IColumn } from '../../../model/Table';
import { getRandomAlphaNumericString } from '../../../utility/Utility';

interface IMapperProps {
  tableName: string;
  fileContent: any;
  selectedSource: string | null;
  mappedHeaders: IMappedHeader[];
  escapeUnMapped: boolean;
  systemHeaders: IColumn[];
  getTableColumns: (tableName: string) => IColumn[];
  setData: (data: any) => void;
}

interface IMapperState {
  mappedHeaders: IMappedHeader[];
  escapeUnMapped: boolean;
  isMappingCompleted: boolean;
  activeToolTip: string | null;
  dkHeaderList: IColumn[];
  availableImportedKeys: string[];
}

class Mapper extends Component<IMapperProps, IMapperState> {
  dkHeaderColumnKey = headerMapDisplaySections[1].key;
  defaultHeaderOption: any = {
    name: 'Assign Field',
    id: 0,
    type: INPUT_TYPE.TEXT,
    options: null
  };
  errorInfoTypes = errorInfoTypes;
  canAddField = true;

  constructor(props: IMapperProps) {
    super(props);
    console.log('MatchHeaders', props);

    this.state = {
      mappedHeaders: props.mappedHeaders || [],
      escapeUnMapped: props.escapeUnMapped || false,
      isMappingCompleted: false,
      activeToolTip: null,
      dkHeaderList:
        props.systemHeaders || props.getTableColumns(props.tableName),
      availableImportedKeys: []
    };

    // this.canAddField = PermissionService.getInstance().isUserPermitted(
    //   this.props.tableName,
    //   [USER_ACTION_TYPES.FIELD_CREATE]
    // );
  }

  componentDidMount() {
    try {
      const importedDataRow = this.props.fileContent[0]?.[0] || {};
      if (!this.state.mappedHeaders?.length) {
        /* FileContent consists of multiple sheets data &
          each sheet contains multiple rows */
        const isNestedImport = MODULES_WITH_NESTED_IMPORT.includes(
          this.props.tableName
        );
        const mappedHeaders = dataMapper(
          isNestedImport ? this.props.fileContent[0] : importedDataRow,
          this.state.dkHeaderList,
          this.props.selectedSource
        );

        this.handleHeaderMappingUpdates(mappedHeaders, false);
      }

      const availableImportedKeys = Object.keys(importedDataRow);
      this.setState({ availableImportedKeys });
    } catch (err) {
      this.setState({ mappedHeaders: null });
      showToast('No data present in uploaded file', TOAST_TYPE.FAILURE);
    }
  }

  onMappingData = (
    mappedData: { isMappingValid: boolean; updatedValues: IMappedHeader[] },
    shouldEscapeUnMapped: boolean
  ) => {
    if (!mappedData.isMappingValid) {
      mappedData.updatedValues = null;
    }

    this.props.setData({
      mappedHeaders: mappedData.updatedValues,
      escapeUnMapped: shouldEscapeUnMapped,
      /* Resetting values for triggering data map operations */
      importRecords: null,
      systemHeaders: null,
      invalidRows: null
    });
  };

  handleValidations = (
    rowValues: IMappedHeader[],
    shouldEscapeUnmapped: boolean
  ) => {
    let isMappingValid = true;
    const headerRowMap = new Map();
    const duplicateRowIds = [];
    let unmappedRows = 0;

    const newRowValues: IMappedHeader[] = rowValues.map((rowData, rowIndex) => {
      const newRowData = { ...rowData };

      if (newRowData.uiHidden) {
        newRowData.isValid = true;
        newRowData.isEscaped = !newRowData[this.dkHeaderColumnKey];
        newRowData.errorInfo = null;
      } else if (
        !newRowData[this.dkHeaderColumnKey] ||
        (newRowData.isCustomMapping && !newRowData.importedKeys?.length)
      ) {
        /* Valid Row - if user has opted for escaping unmapped rows */
        newRowData.isValid = shouldEscapeUnmapped;
        isMappingValid = shouldEscapeUnmapped;
        /* Set If the unmappedRow escaped from validations */
        newRowData.isEscaped = shouldEscapeUnmapped;
        newRowData.errorInfo = shouldEscapeUnmapped
          ? this.errorInfoTypes.escaped
          : this.errorInfoTypes.unmapped;
        unmappedRows++;
      } else if (headerRowMap.has(newRowData[this.dkHeaderColumnKey])) {
        const duplicateRowId = headerRowMap.get(
          newRowData[this.dkHeaderColumnKey]
        );
        duplicateRowIds.push(duplicateRowId);
        newRowData.isValid = false;
        newRowData.isEscaped = false;
        isMappingValid = false;
        newRowData.errorInfo = this.errorInfoTypes.duplicateMapping;
      } else {
        newRowData.isValid = true;
        newRowData.isEscaped = false;
        newRowData.errorInfo = null;
        headerRowMap.set(newRowData[this.dkHeaderColumnKey], rowIndex);
      }

      if (newRowData.type === INPUT_TYPE.DATE) {
        newRowData.errorInfo = `${this.errorInfoTypes.dateWarning}\n${
          newRowData.errorInfo || ''
        }`;
      }

      return newRowData;
    });

    duplicateRowIds.forEach((duplicateRowId) => {
      newRowValues[duplicateRowId].isValid = false;
      newRowValues[duplicateRowId].errorInfo =
        this.errorInfoTypes.duplicateMapping;
    });

    /* When All the rows are unmapped */
    if (unmappedRows === rowValues.length) {
      isMappingValid = false;
    }

    return { updatedValues: newRowValues, isMappingValid };
  };

  handleHeaderMappingUpdates = (
    mappedHeaders: IMappedHeader[],
    shouldEscapeUnMapped: boolean
  ) => {
    const validatedData = this.handleValidations(
      mappedHeaders,
      shouldEscapeUnMapped
    );

    this.setState({
      escapeUnMapped: shouldEscapeUnMapped,
      mappedHeaders: validatedData.updatedValues,
      isMappingCompleted: validatedData.isMappingValid
    });

    this.onMappingData(validatedData, shouldEscapeUnMapped);
  };

  handleUpdateSelection = (selectedValue: number, rowId: string) => {
    /* By default setting to assign field */
    let selectedHeader = this.defaultHeaderOption;

    if (selectedValue) {
      selectedHeader = this.state.dkHeaderList[selectedValue - 1];
    }

    const updatedHeaderMappings = this.state.mappedHeaders.map((headerRow) => {
      if (headerRow.rowId === rowId) {
        headerRow[this.dkHeaderColumnKey] = selectedHeader.id;
        headerRow.selectedOption = selectedHeader.name;
        headerRow.type = selectedHeader.type;
        headerRow.options = selectedHeader.options;
        headerRow.colTableName = selectedHeader.tableName;
        headerRow.showPicker = false;
      } else if (headerRow[this.dkHeaderColumnKey] === selectedHeader.id) {
        /* Reset other fileHeaders mapped to same system header  */
        headerRow[this.dkHeaderColumnKey] = this.defaultHeaderOption.id;
        headerRow.selectedOption = this.defaultHeaderOption.name;
        headerRow.type = this.defaultHeaderOption.type;
        headerRow.options = this.defaultHeaderOption.options;
      }

      return headerRow;
    });

    this.handleHeaderMappingUpdates(updatedHeaderMappings, false);
  };

  handleAddCustomMapping = () => {
    const updatedMappings = [...this.state.mappedHeaders];

    updatedMappings.push({
      ...updatedMappings[0],
      rowId: `custom-row-${getRandomAlphaNumericString(4)}`,
      importedKey: ``,
      importedKeys: [],
      sampleData: `-`,
      selectedOption: this.defaultHeaderOption.name,
      [this.dkHeaderColumnKey]: this.defaultHeaderOption.id,
      type: this.defaultHeaderOption.type,
      options: this.defaultHeaderOption.options,
      isCustomMapping: true,
      uiHidden: false
    });

    this.handleHeaderMappingUpdates(updatedMappings, this.state.escapeUnMapped);
  };

  handleCustomImportedKeySelection = (selectedValue: string, rowId: string) => {
    const updatedHeaderMappings = this.state.mappedHeaders.map((headerRow) => {
      const updatedHeaderRow = { ...headerRow };
      // updatedHeaderRow.showImportHeaderPicker = false;

      if (updatedHeaderRow.rowId === rowId) {
        updatedHeaderRow.importedKeys = updatedHeaderRow.importedKeys || [];
        updatedHeaderRow.importedKeys = headerRow.importedKeys.includes(
          selectedValue
        )
          ? headerRow.importedKeys.filter((key) => key !== selectedValue)
          : [...headerRow.importedKeys, selectedValue];

        const fileRowData = this.props.fileContent[0]?.[0] || {};
        const sampleData = updatedHeaderRow.importedKeys
          .map((key) => fileRowData[key])
          .filter((item) => Boolean(item?.toString().trim()))
          .join(', ');
        updatedHeaderRow.sampleData = sampleData;
      }

      return updatedHeaderRow;
    });

    this.handleHeaderMappingUpdates(updatedHeaderMappings, false);
  };

  handleCustomMappingDeletion = (rowId: string) => {
    const updatedHeaderMappings = this.state.mappedHeaders.filter(
      (headerRow) => headerRow.rowId !== rowId
    );

    this.handleHeaderMappingUpdates(
      updatedHeaderMappings,
      this.state.escapeUnMapped
    );
  };

  onClickEscapeMappingCheckbox = () => {
    const { mappedHeaders, escapeUnMapped } = this.state;
    this.handleHeaderMappingUpdates(mappedHeaders, !escapeUnMapped);
  };

  handlePickerDisplay = (rowId: any, hide?: boolean) => {
    const newMappedHeaders = this.state.mappedHeaders.map((header) => {
      if (header.rowId === rowId) {
        header.showPicker = !hide;
      } else {
        header.showPicker = false;
      }

      return header;
    });

    this.setState({ mappedHeaders: newMappedHeaders });
  };

  handleImportedKeyPickerDisplay = (rowId: any, hide?: boolean) => {
    const newMappedHeaders = this.state.mappedHeaders.map((header) => {
      if (header.rowId === rowId) {
        header.showImportHeaderPicker = !hide;
      } else {
        header.showImportHeaderPicker = false;
      }

      return header;
    });

    this.setState({ mappedHeaders: newMappedHeaders });
  };

  onSaveNewField = ({ columnData, columnId }, rowId) => {
    const newIndex = this.state.dkHeaderList.length;

    this.setState(
      (prevState) => ({
        dkHeaderList: [
          ...prevState.dkHeaderList,
          {
            ...columnData,
            id: columnId,
            key: columnId,
            index: newIndex,
            tableName: this.props.tableName
          }
        ]
      }),
      () => this.handleUpdateSelection(newIndex + 1, rowId)
    );
  };

  getNewFieldPopup = (rowId) => {
    showAddColumnPopup(
      {
        tableName: this.props.tableName,
        appId: '',
        tableId: '',
        columnDList: []
      },
      (response) => {
        const { data, columnData } = response;
        if (data?.id && columnData) {
          showToast('Field added successfully', TOAST_TYPE.SUCCESS);
          this.onSaveNewField({ columnData, columnId: data.id }, rowId);
        }
      }
    );
  };

  getGridHeader() {
    return (
      <Fragment>
        <DKLabel
          text="Column Mapper"
          className="fw-m fs-l m-v-s p-h-r text-align-center parent-width border-box"
        />
        <DKLabel
          text="Map your columns with headers present in the system"
          className="text-gray text-align-center parent-width mb-m p-h-r border-box"
        />
        <div className="row bg-gray1 justify-content-between align-items-center p-v-r p-h-xl mt-l">
          {headerMapDisplaySections.map((column) => (
            <DKLabel
              key={column.key}
              text={
                column.getSourceHeaderName?.(this.props.selectedSource) ||
                column.name
              }
              className="fw-m fs-m"
              style={{ width: 250 }}
            />
          ))}
        </div>
      </Fragment>
    );
  }

  getWarningToolTip(toolTipId, errorInfo) {
    return (
      <div
        className="position-absolute z-index-1 m-h-r"
        style={{ right: 20 }}
        onMouseOver={() => {
          this.setState({ activeToolTip: toolTipId });
        }}
        onMouseOut={() => {
          this.setState({ activeToolTip: null });
        }}
      >
        <DKIcon src={DKIcons.ic_info} className="ic-s-2 cursor-hand" />
        {this.state.activeToolTip === toolTipId && (
          <div
            className="bg-white border-red position-absolute border-radius-s pr-s pt-xs pb-xs"
            style={{
              left: 30,
              top: -5,
              width: 200
            }}
          >
            {errorInfo}
          </div>
        )}
      </div>
    );
  }

  getCustomImportHeaderSection(rowData: IMappedHeader) {
    const { availableImportedKeys } = this.state;
    return (
      <div className="column" style={{ width: 250 }}>
        {rowData.showImportHeaderPicker ? (
          <DKListPicker2
            title="Imported headers"
            className="position-absolute border-m shadow-m z-index-3"
            style={{ top: 10, left: 0, width: 250, marginRight: 'auto' }}
            data={availableImportedKeys}
            multiSelect={true}
            selectedIndexes={(rowData.importedKeys || []).map((key) =>
              availableImportedKeys.indexOf(key)
            )}
            checkMarkColor={`bg-button`}
            onSelect={(
              selectedImportedKeyIndex: number,
              selectedImportedKey: string
            ) =>
              this.handleCustomImportedKeySelection(
                selectedImportedKey,
                rowData.rowId
              )
            }
            onClose={() =>
              this.handleImportedKeyPickerDisplay(rowData.rowId, true)
            }
            button={
              this.canAddField
                ? {
                    title: 'Create New Field',
                    className: 'bg-button text-white',
                    onClick: () => this.getNewFieldPopup(rowData.rowId)
                  }
                : null
            }
          />
        ) : (
          <div
            className="row cursor-hand"
            style={{ gap: 8 }}
            onClick={() => this.handleImportedKeyPickerDisplay(rowData.rowId)}
          >
            <DKLabel
              className="width-auto text-ellipsis"
              style={{ maxWidth: 200, paddingLeft: 0 }}
              text={
                !rowData.importedKeys?.length
                  ? 'Select file header(s)'
                  : rowData.importedKeys.join(', ')
              }
            />
            <DKIcon src={DKIcons.ic_arrow_down2} className="ic-s" />
          </div>
        )}
      </div>
    );
  }

  getRow(rowData: IMappedHeader, headerListOptions: string[]) {
    const rowClassNames = [];
    if (rowData.isEscaped) {
      rowClassNames.push('text-gray');
      rowClassNames.push('bg-chip-gray');
    } else if (!rowData.isValid) {
      rowClassNames.push('border-red');
      rowClassNames.push('data-grid-badge-color-4');
    }

    return (
      <div
        key={rowData.rowId}
        id={rowData.rowId}
        style={{ height: '50px' }}
        className={`position-relative row border-m-b align-items-center justify-content-between p-v-r p-h-xl ${rowClassNames.join(
          ' '
        )}`}
      >
        {rowData.isCustomMapping ? (
          this.getCustomImportHeaderSection(rowData)
        ) : (
          <DKLabel
            text={rowData.importedKey}
            className=""
            style={{ width: 250 }}
          />
        )}
        <div className="column" style={{ width: 250 }}>
          {rowData.showPicker ? (
            <DKListPicker
              title="System Fields"
              className="position-absolute border-m shadow-m z-index-3"
              style={{ top: 10, left: 250, width: 250, marginRight: 'auto' }}
              data={headerListOptions}
              onSelect={(selectedDisplayHeader) => {
                this.handleUpdateSelection(
                  selectedDisplayHeader,
                  rowData.rowId
                );
              }}
              onClose={() => {
                this.handlePickerDisplay(rowData.rowId, true);
              }}
              button={
                this.canAddField
                  ? {
                      title: 'Create New Field',
                      className: 'bg-button text-white',
                      onClick: () => this.getNewFieldPopup(rowData.rowId)
                    }
                  : null
              }
            />
          ) : (
            <DKButton
              className="justify-content-between"
              style={{ minWidth: 150, maxWidth: 220, paddingLeft: 0 }}
              title={rowData.selectedOption}
              icon={DKIcons.ic_arrow_down2}
              isReverse={true}
              onClick={() => this.handlePickerDisplay(rowData.rowId)}
            />
          )}
        </div>
        <DKLabel
          text={rowData.sampleData}
          className={`fs-m parent-height text-trailing border-box pr-xl`}
          style={{ width: rowData.errorInfo ? 220 : 250, whiteSpace: 'nowrap' }}
        />
        {rowData.isCustomMapping ? (
          <DKIcon
            className="ic-s cursor-hand position-absolute"
            style={{ right: rowData.errorInfo ? 6 : 30 }}
            src={DKIcons.ic_delete}
            onClick={() => this.handleCustomMappingDeletion(rowData.rowId)}
          />
        ) : null}
        {rowData.errorInfo && (
          <Fragment>
            <div
              style={{
                width: 30,
                height: '100%'
              }}
            ></div>
            {this.getWarningToolTip(
              `tooltip-${rowData.rowId}`,
              rowData.errorInfo
            )}
          </Fragment>
        )}
      </div>
    );
  }

  getGridRows() {
    const { dkHeaderList } = this.state;
    const headerListOptions = [this.defaultHeaderOption.name];

    dkHeaderList.forEach((header) => {
      headerListOptions.push(header.name);
    });

    const isPriceBookImport = false;

    return this.state.mappedHeaders ? (
      <Fragment>
        {this.state.mappedHeaders.map((headerRow) =>
          headerRow.uiHidden ? null : this.getRow(headerRow, headerListOptions)
        )}
        <div className="row p-h-l pt-m parent-width justify-content-between">
          <label
            htmlFor="validationEscaper"
            className="row cursor-hand width-auto"
          >
            <input
              id="validationEscaper"
              type="checkbox"
              className="cursor-hand"
              checked={this.state.escapeUnMapped}
              onChange={this.onClickEscapeMappingCheckbox}
            />
            <DKLabel text="Skip columns that do not map" />
          </label>
          {/* {isPriceBookImport ? null : (
            <DKButton
              className="ic-s cursor-hand fw-m text-app"
              title="+ Add custom mapping"
              onClick={() => this.handleAddCustomMapping()}
            />
          )} */}
        </div>
      </Fragment>
    ) : (
      <DKLabel text="No mappings found" className="p-xl bg-white text-red" />
    );
  }

  render() {
    return (
      <div className="column bg-white position-relative p-v-r shadow-s border-radius-s">
        {this.getGridHeader()}
        {this.getGridRows()}
      </div>
    );
  }
}

export default Mapper;
