import { INPUT_TYPE } from 'deskera-ui-library';
import Utility, { isUndefined } from './Utility';
import { bufferTypes, supportedMimeTypes } from '../constants/EximConstants';

export function getFile(blobContent, fileName, fileType) {
  const file = new File(
    [blobContent],
    `${fileName}_${new Date().getTime()}.${fileType}`,
    { type: getMimeType(fileType) }
  );

  return file;
}
export function getFileByMimeType(blobContent, fileName) {
  const mimeType = blobContent.type;
  const file = new File(
    [blobContent],
    `${fileName}_${new Date().getTime()}.${mimeType.split('/')?.[1]}`,
    { type: mimeType }
  );

  return file;
}

export function generateBlob(content, fileType) {
  return import('xlsx').then((XLSX) => {
    const workSheet = XLSX.utils.json_to_sheet(content);
    Object.keys(workSheet).map((cellKey) => {
      const newCellData = Utility.makeCopyOfObject(workSheet[cellKey]);
      if (newCellData.t === 'n') {
        newCellData.z = '0';
        newCellData.t = 's';
        newCellData.w = newCellData.v?.toString();
        workSheet[cellKey] = newCellData;
      }
    });

    const workBook = {
      Sheets: { Sheet1: workSheet },
      SheetNames: ['Sheet1']
    };
    const excelBuffer = XLSX.write(workBook, {
      bookType: fileType,
      type: getBufferType(fileType)
    });
    return new Blob([excelBuffer], { type: getMimeType(fileType) });
  });
}

export function triggerDownload(blobContent, fileName, altSrc) {
  const downloadAnchor = document.createElement('a');
  downloadAnchor.setAttribute('download', fileName);
  /* Checking If download feature supported in browser */
  if (!isUndefined(downloadAnchor.download)) {
    downloadAnchor.href = blobContent
      ? URL.createObjectURL(blobContent)
      : altSrc;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  }
}

function getMimeType(fileType) {
  return supportedMimeTypes[fileType] || 'text/csv';
}

function getBufferType(fileType) {
  return bufferTypes[fileType] || 'array';
}

export function exportDataHandler(
  exportData,
  fileType,
  fileTitle = 'download'
) {
  return new Promise((resolve, reject) => {
    let mimeType = getMimeType(fileType);
    if (!exportData || !mimeType) {
      reject('Please select data or valid file type to proceed');
    }
    try {
      generateBlob(exportData, fileType).then((blobContent) => {
        triggerDownload(blobContent, `${fileTitle}.${fileType}`);
        resolve('File generated successfully');
      });
    } catch (err) {
      reject(err);
    }
  });
}

export function getProcessedData(tableHeaders, tableRows) {
  const processedData = tableRows.map((row) => {
    const processedRow = {};

    tableHeaders.forEach((exportHeader) => {
      let displayValue = '';

      if (
        exportHeader.type === INPUT_TYPE.SELECT ||
        exportHeader.type === INPUT_TYPE.MULTI_SELECT
      ) {
        const selectedOptions = row[exportHeader.id];

        if (selectedOptions?.length) {
          const options = exportHeader.options;
          const displayOptions = selectedOptions.reduce(
            (computedVal, optionId) => {
              const selectedOption = options.filter(
                (option) => option.id === optionId
              );
              return selectedOption.length
                ? `${computedVal}${selectedOption[0].name},`
                : computedVal;
            },
            ''
          );

          displayValue = displayOptions.slice(0, -1);
        } else {
          displayValue = '';
        }
      } else {
        displayValue = row[exportHeader.id] || '';
      }

      processedRow[exportHeader.name] = displayValue;
    });

    return processedRow;
  });

  return processedData;
}
