import React, { useState, useEffect } from 'react';

import { DKButton, DKLabel, DKLine, DKIcon, DKIcons } from 'deskera-ui-library';
import AppIcons from '../../assets/icons/AppIcons';
import settingIcon from '../../assets/lucide/settings2.svg';
import clockIcon from '../../assets/lucide/clock9.svg';

import PromptPreview from './PromptPreview';
import NewPromptForm from './NewPromptForm';

import appIcon from '../../assets/lucide/app-icon.svg';
import { LOCAL_STORAGE_KEYS } from '../../constants/Enum';
import { useAuth } from '@clerk/clerk-react';

const PromptSection = ({ onApply }) => {
  const [prompts, setPrompts] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [promptIndex, setPromptIndex] = useState(-1);
  const [needPromptForm, setPromptFormVisibility] = useState(false);

  const { isSignedIn } = useAuth();

  useEffect(() => {
    setPrompts(samplePrompts);
  }, []);

  const samplePrompts = [
    {
      title: 'Create my restaurant POS',
      prompt: `Build restaurant app named [App name] which facilitate efficient management of restaurant operations, covering menus, reservations, orders, payments, customer feedback and [Add more sections].`
    },
    {
      title: 'Build my real Estate CRM',
      prompt: `Build Real Estate CRM app named [CRM App name] facilitate efficient management of client relationships, property listings, transactions, and user interactions within a real estate CRM application.`
    },
    {
      title: 'Develop my boutique inventory',
      prompt:
        'Develop a Boutique Inventory app which provide a structured framework for managing products, categories, suppliers, sales, customers, and inventory adjustments within a boutique inventory application.'
    },
    {
      title: 'Create accounting app',
      prompt:
        'Build accounting app for managing accounts, transactions, ledger entries, financial reporting, and user access within an accounting application.'
    }
  ];

  const customPrompts =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROMPT_MESSAGES)) || [];

  const tabClicked = (index) => {
    setTabIndex(index);
    switch (index) {
      case 0: //sample prompts
        setPrompts(samplePrompts);
        break;
      case 1: //custom prompts
        setPrompts(customPrompts);
        break;
      default:
    }
  };

  const renderSamplePrompts = () => {
    return prompts.map((data, index) => {
      return (
        <>
          {index !== 0 && (
            <DKLine style={{ backgroundColor: 'rgba(0,0,0,0.06)' }} />
          )}
          <div className="column parent-width">
            <div className="row justify-content-between p-h-l p-v-s">
              <div className="row width-auto">
                <DKIcon src={appIcon} className="ic-s opacity-6 mr-r" />
                <DKLabel text={data.title} className="opacity-9" />
              </div>
              <div className="row width-auto">
                <DKButton
                  icon={AppIcons.ic_page}
                  className=" opacity-6"
                  style={{ padding: 5, paddingTop: 2, paddingBottom: 2 }}
                  onClick={() => {
                    setPromptIndex(index);
                  }}
                />
                <DKButton
                  title="Apply"
                  icon={AppIcons.ic_apply}
                  className="border-m bg-buttons text-whites ml-r  opacity-7"
                  style={{
                    padding: 8,
                    paddingTop: 3,
                    paddingBottom: 3,
                    borderColor: 'gray',
                    borderRadius: 6
                  }}
                  onClick={() => {
                    onApply(prompts[index]);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      );
    });
  };
  const activeStyle = {
    borderBottom: '1.5px solid',
    borderRadius: 0
  };
  return (
    <div
      className="mt-r flex w-full flex-col rounded-lg border border-stone-200/70 bg-stone-50/70 shadow-none dark:border-stone-800 dark:bg-transparent max-w-[700px]"
      style={{ minHeight: 220, borderColor: 'rgb(0,0,0,0.07)' }}
    >
      <div className="inline-flex items-center h-10 w-full justify-start rounded-none border-b border-b-stone-200/70 bg-transparent p-0 text-stone-500 dark:border-b-stone-200">
        <div className="row width-auto prompt-section-tabs">
          <DKButton
            icon={settingIcon}
            title="Presets"
            className={`text-black ${
              tabIndex === 0 ? 'active fw-m opacity-100' : 'opacity-50'
            }`}
            style={tabIndex === 0 && isSignedIn ? activeStyle : null}
            onClick={() => {
              tabClicked(0);
            }}
          />
          {isSignedIn && (
            <DKButton
              icon={clockIcon}
              title="Recents"
              className={`text-black ${
                tabIndex === 1 ? 'fw-m opacity-100' : 'opacity-50'
              }`}
              style={tabIndex === 1 ? activeStyle : null}
              onClick={() => {
                tabClicked(1);
              }}
            />
          )}
        </div>
      </div>
      {renderSamplePrompts()}
      {promptIndex > -1 && (
        <PromptPreview
          data={prompts[promptIndex]}
          onClose={() => {
            setPromptIndex(-1);
          }}
        />
      )}
      {needPromptForm && (
        <NewPromptForm
          onClose={() => {
            setPromptFormVisibility(false);
          }}
          onSave={(prompt) => {
            customPrompts.unshift(prompt);
            setPrompts(customPrompts);
            setPromptFormVisibility(false);
          }}
        />
      )}
    </div>
  );
};

export default PromptSection;
