import { DKButton, DKInput, DKLabel, DKSpinner } from 'deskera-ui-library';
import { createRef, useState } from 'react';
import AppIcons from '../../assets/icons/AppIcons';
import { allowedImageType } from '../../constants/Constant';
import { INPUT_TYPE, INPUT_VIEW_DIRECTION } from '../../constants/Enum';

export default function EditApplicationAndTemplate(props: any) {
  const [appObj, setAppObj] = useState(props.app);
  const [canValidate, setCanValidate] = useState(false);
  const importRef = createRef<HTMLInputElement>();
  const [logoLoading, setLogoLoading] = useState(false);
  const getHeader = () => {
    return (
      <div className="row justify-content-betwee bg-gray1 p-v-s p-h-xs">
        <div className="row fw-500">
          <DKLabel className="row ml-m" text="Mark As Public" />
        </div>
        <div className="row-reverse gap-2 mr-s">
          <DKButton
            title="Cancel"
            onClick={() => {
              props?.onCancel();
            }}
          />
        </div>
        <div></div>
      </div>
    );
  };

  const getLogoImage = () => {
    if (appObj?.logoData) {
      return `data:image/png;base64,${appObj.logoData}`;
    }
    return AppIcons.ic_app; // Default icon if no logoData
  };

  const getLogoImageSection = () => {
    return (
      <>
        {!appObj?.logoData && (
          <>
            <div className="row width-auto justify-content-start flex">
              <div
                className={` ${
                  logoLoading
                    ? 'border bg-chip-gray'
                    : 'border-blue text-blue bg-chip-blue'
                }   p-v-s p-h-r border-radius-s cursor-pointer flex items-center justify-center`}
                style={{
                  border: 'dashed',
                  borderWidth: 1,
                  height: 100,
                  width: 100
                }}
                onClick={() => {
                  if (!logoLoading) importRef.current.click();
                }}
              >
                {!logoLoading && (
                  <DKLabel className="fs-s" text={`+ Add Logo`} />
                )}
                {logoLoading && (
                  <DKSpinner iconClassName="ic-s" className="column pl-0" />
                )}
              </div>
            </div>
          </>
        )}
        {appObj.logoData && (
          <div className="row width-auto justify-content-start flex">
            <div
              className="column position-relative p-v-s p-h-r border-radius-s cursor-pointer items-center justify-center"
              style={{
                height: 100,
                width: 100
              }}
            >
              {/* <DKIcon
                src={AppIcons.ic_delete}
                className="bg-gray3 cursor-pointer product-image-delete-icon p-xs border-radius-s position-absolute"
                style={{ right: 0, top: 0, marginLeft: 'auto' }}
                onClick={() => {
                  setAppObj({ ...appObj, logoData: null });
                }}
              /> */}
              <img
                onClick={() => {
                  if (!logoLoading) importRef.current.click();
                }}
                src={getLogoImage()}
                alt=""
                className={`product-image border text-blue border-radius-s cursor-pointer flex items-center justify-center`}
                style={{
                  height: 100,
                  width: 100
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width p-4">
        <div className="row gap-2">
          {getLogoImageSection()}
          <DKInput
            title="Title"
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={appObj?.name}
            onChange={(text: string) => {
              setAppObj({ ...appObj, name: text });
            }}
            type={INPUT_TYPE.TEXT}
          />
        </div>
        <DKInput
          className="mt-4"
          title="Description"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={appObj?.description}
          onChange={(text: string) => {
            setAppObj({ ...appObj, description: text });
          }}
          type={INPUT_TYPE.LONG_TEXT}
        />
        <input
          ref={importRef}
          style={{ visibility: 'hidden' }}
          type="file"
          accept={allowedImageType.toString()}
          title=""
          className=""
          onChange={(event: any) => {
            const file = event.target.files[0]; // {{ edit_1 }}
            if (file) {
              const reader = new FileReader(); // {{ edit_2 }}
              reader.onloadend = () => {
                const base64data = reader.result as string;
                const base64String = base64data.split(',')[1];
                setAppObj({ ...appObj, logoData: base64String }); // {{ edit_4 }} Set logoData in Base64 format
              };
              reader.readAsDataURL(file); // {{ edit_5 }}
            }
          }}
        />
        {!props.isAlreadyPublic && (
          <DKLabel
            className="p-s border-radius-s bg-deskera-secondary"
            text="<b>Please note:</b><br/>If you mark an app as public, it will be available to other users as template. These templates can be used by others to create their own apps."
          />
        )}
        <DKButton
          icon={AppIcons.white.ic_send}
          isReverse={true}
          className="mt-l bg-app text-white align-self-end"
          title="Continue"
          onClick={() => {
            setCanValidate(true);
            props?.onSave(appObj);
          }}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div className="popup-window" style={{ padding: 0, paddingBottom: 0 }}>
        {getHeader()}
        {getBody()}
      </div>
    </div>
  );
}
